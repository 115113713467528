import { FloorType } from 'commons';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import {
  TrackingClickOptions,
  TrackingFloor,
} from '../../../api/tracking/TrackingTypes';
import { trackClickEvent } from '../../../api/tracking';
import { TrackingElementPage2 } from '../TrackingElements';
import { updateTariffOptions } from '../../../api/tracking/tracking.helpers';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onRButtonsTracking: (value: FloorType) => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();
  const {
    productAttrs: { tariffOptions },
  } = trackingOptions;

  const onRButtonsTracking = (_typeOfFloor: FloorType): void => {
    const divisor = '=';
    const { 0: key } = TrackingFloor.CELLAR.split(divisor);
    let element: TrackingElementPage2;
    let value: string;
    let updatedTariffs = tariffOptions;

    switch (_typeOfFloor) {
      case 'basement':
        value = TrackingFloor.CELLAR.split(divisor)[1];
        element = TrackingElementPage2.RBUTTON_CELLAR;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
      case 'groundFloor':
        value = TrackingFloor.GROUNDFLOOR.split(divisor)[1];
        element = TrackingElementPage2.RBUTTON_GROUNDFLOOR;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
      case 'firstFloor':
        value = TrackingFloor.FLOOR_1ST.split(divisor)[1];
        element = TrackingElementPage2.RBUTTON_1STFLOOR;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
      case 'secondFloor':
        value = TrackingFloor.FLOOR_2ND.split(divisor)[1];
        element = TrackingElementPage2.RBUTTON_2NDFLOOR;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
      case 'otherFloor':
        value = TrackingFloor.FLOOR_3RD_ABOVE.split(divisor)[1];
        element = TrackingElementPage2.RBUTTON_3RDFLOOR_ABOVE;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
    }

    trackClickEvent(element, {
      productAttrs: { tariffOptions: updatedTariffs },
    });
  };

  return {
    trackingOptions,
    onRButtonsTracking,
  };
};

export default useClickTracking;
