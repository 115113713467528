import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const OtherNaturalHazards = (): JSX.Element => {
  return (
    <div data-testid="other-natural-hazards-info">
      <h3 data-testid="other-natural-hazards-title">
        {step7.otherNaturalHazards.title}
      </h3>
      <p
        data-testid="other-natural-hazards-p1"
        dangerouslySetInnerHTML={{
          __html: step7.otherNaturalHazards.paragraph1,
        }}
      ></p>
      <p
        data-testid="other-natural-hazards-p2"
        dangerouslySetInnerHTML={{
          __html: step7.otherNaturalHazards.paragraph2,
        }}
      ></p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li>{step7.otherNaturalHazards.listOptions1.a}</li>
        <li>{step7.otherNaturalHazards.listOptions1.b}</li>
        <li>{step7.otherNaturalHazards.listOptions1.c}</li>
        <li>{step7.otherNaturalHazards.listOptions1.d}</li>
        <li>{step7.otherNaturalHazards.listOptions1.e}</li>
        <li>{step7.otherNaturalHazards.listOptions1.f}</li>
        <li>{step7.otherNaturalHazards.listOptions1.g}</li>
        <li>{step7.otherNaturalHazards.listOptions1.h}</li>
      </ul>
      <p
        data-testid="other-natural-hazards-p3"
        dangerouslySetInnerHTML={{
          __html: step7.otherNaturalHazards.paragraph3,
        }}
      ></p>
      <p data-testid="other-natural-hazards-p4">
        {step7.otherNaturalHazards.paragraph4}
      </p>
    </div>
  );
};

export default OtherNaturalHazards;
