import { ServerRoute } from '../../../types';

export const getInsuranceCompanies: ServerRoute = {
  method: 'GET',
  path: '/vorversicherungen',
};

export const getUrl = (path: string): string => {
  const baseUrl = process.env.INSURANCE_COMPANIES_URL;
  return `${baseUrl}${path}`;
};
