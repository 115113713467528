import { useState, ChangeEvent } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { setFirstName, setSurname } from '../../personalDataSlice';
import { useNameDataTypes, PersonalDataForm } from '../../PersonalDataTypes';
import translations from '../../../../constants/translations';

export const useNameData = (form: PersonalDataForm): useNameDataTypes => {
  const dispatch = useAppDispatch();
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const { firstName, lastname } = form;
  const nameValidationRegex =
    /^[a-zA-ZÄÖÜäöüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß0-9][a-zA-ZÄÖÜäöüÄÖÜüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß0-9 ,.\-'&+*\/()]{0,29}$/i;

  const handleValidationFirstName = (): void => {
    if (
      firstName.length < 2 ||
      /\d/.test(firstName) ||
      !nameValidationRegex.test(firstName)
    ) {
      setFirstNameErrorMessage(translations.step9.firstNameErrorMessage);
    } else {
      setFirstNameErrorMessage('');
    }
  };

  const handleValidationSurname = (): void => {
    if (
      lastname.length < 2 ||
      /\d/.test(lastname) ||
      !nameValidationRegex.test(lastname)
    ) {
      setLastNameErrorMessage(translations.step9.lastNameErrorMessage);
    } else {
      setLastNameErrorMessage('');
    }
  };

  const handleOnChangeFirstName = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setFirstName(e.target.value));
  };

  const handleOnChangeSurname = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSurname(e.target.value));
  };
  return {
    handleOnChangeFirstName,
    handleOnChangeSurname,
    handleValidationFirstName,
    handleValidationSurname,
    firstName,
    lastname,
    firstNameErrorMessage,
    lastNameErrorMessage,
    setFirstNameErrorMessage,
    setLastNameErrorMessage,
  };
};

export default useNameData;
