export function debounce<F extends (...params: unknown[]) => void>(
  func: F,
  timeout = 300
): (...params: unknown[]) => void {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: unknown[]): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, timeout, ...args);
  };
}
