import React from 'react';
import type { FormSectionProps } from './FormSectionTypes';

const FormSection = ({
  children,
  className,
  maxWidth = 'l',
}: FormSectionProps): JSX.Element => {
  return (
    <section
      className={`${className || ''} esc_container esc_container--${maxWidth}`}
    >
      {children}
    </section>
  );
};

export default FormSection;
