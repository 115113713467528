import aemIntegration from '../aemIntegration';

import {
  TrackingBrand,
  TrackingBrokerModus,
  TrackingCategory,
  TrackingContent,
  TrackingConversionTypes,
  TrackingOptionsName,
  TrackingPagePurpose,
} from './TrackingTypes';
import { getTrackingDate } from './tracking.helpers';
import { retrieveTrackingData, storeTrackingData } from '../../storage/storage';

export * from './pageLoadedEvent';
export * from './generalClickEvent';

export const initTracking = async (
  pathName: string,
  businessId: string
): Promise<void> => {
  const pageName = aemIntegration.getTrackingPageName(pathName);
  const { shortName, productGroup, brokerModus } =
    aemIntegration.getTrackingConstants();
  const template: TrackingContent = {
    page: {
      attributes: {
        displayedBrand: TrackingBrand.ERGO,
        brokerModus: TrackingBrokerModus[brokerModus ? 'ON' : 'OFF'],
      },
      pageInfo: {
        pageName: `${shortName}:${pageName}`,
        issueDate: getTrackingDate(),
        sysEnv: window.location.hostname,
      },
      category: {
        pagePurpose: TrackingPagePurpose.SALE,
        primaryCategory: TrackingCategory.HR,
      },
    },
    product: [
      {
        productInfo: { productName: shortName },
        attributes: { tariffOptions1: TrackingOptionsName.Rechner },
        category: { primaryCategory: productGroup },
      },
    ],
    transaction: {
      transactionID: businessId,
      attributes: { conversionType: TrackingConversionTypes.SALE_FUNNEL_START },
    },
  };

  const initialObject = retrieveTrackingData() ?? template;

  storeTrackingData(initialObject);
  return Promise.resolve();
};
