import { RootState } from '../../types';
import { getRisikoOrtFromOffer } from './generics';
import { livingPlaceTypeMapping } from 'commons';

export const getLivingPlaceTypeFromOffer = (
  state: RootState
): string | undefined => getRisikoOrtFromOffer(state)?.risikotyp;

export const isLivingPlaceUpdated = (state: RootState): boolean => {
  const offerValue = getRisikoOrtFromOffer(state)?.risikotyp;
  return (
    livingPlaceTypeMapping[state.livingPlace.form.typeOfHouse] !== offerValue
  );
};
