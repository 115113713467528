import Details from '@eg/elements/Details';
import React from 'react';
import type { AccordionProps } from './AccordionTypes';

const Accordion = ({
  children,
  summaryName,
  isInitiallyOpen = false,
  onOpen,
}: AccordionProps): JSX.Element => {
  const onToggleHandler = (isOpen: boolean): void => {
    if (onOpen && isOpen) {
      onOpen();
    }
  };

  return (
    <Details
      summary={summaryName}
      isInitiallyOpen={isInitiallyOpen}
      onToggledOpen={onToggleHandler}
      data-testid={`accordin-summaryName-${summaryName}`}
    >
      {children}
    </Details>
  );
};

export default Accordion;
