import { useAppSelector } from '../../../store/hooks';
import { useForm } from '../../../store/useForm';
import { usePerson } from '../../../hooks/usePerson';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getInsuranceCoverageFields } from '../../../store/offerSlice';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';

import {
  getInsuranceCoverageFromOffer,
  getPremiumFromOffer,
  getSelectedVariantFromOffer as offerSelector,
} from '../../../store/offerSlice';

import {
  getBikeInsuranceCoverageData,
  getCalculatedValue,
  getContractPeriodData,
  getDeductibleAmount,
  getLivingLocation,
  getLivingType,
  getPaymentMethodData,
  getProductsData,
  getSquareMeters,
  getTariffData,
  getTrackedGender,
} from '../tracking.data.helpers';

import {
  PageLoadedContext,
  TrackingGender,
  TrackingOptionsName,
} from '../TrackingTypes';
import { getStep } from '../tracking.helpers';
import { dateFirstFormat } from '../../../helpers/dates/dates';

export const useTrackingData = (): PageLoadedContext => {
  const fields = useSelector(getInsuranceCoverageFields);
  const { pathname } = useLocation();
  const { tarifvariante: tariff } = useAppSelector(offerSelector) || {};
  const { netPremium } = useAppSelector(getPremiumFromOffer) || {};
  const { paymentMethod } = useAppSelector(getInsuranceCoverageFromOffer) || {};
  const {
    typeOfHouse,
    typeOfFloor,
    sizeInSM,
    productModules: products,
    bicycleAndEbikeCoInsuredAmount: bikeAmount,
    bicycleAndEbikeCoInsuredCover: bikeOption,
    contractPeriod,
    deductibleAmount,
    insuranceStartDate,
    gender,
    isAddressCorrected,
    zipCode: postalCode,
    streetName,
    streetNumber,
    city,
    email,
    telephoneNumber,
    prefixNumber,
    firstName,
    lastname,
  } = useForm();
  const { dateOfBirth } = usePerson();

  const step = getStep(pathname);

  let tariffOptions1 = `${TrackingOptionsName.Rechner}`;
  let calculatedValue = 0;
  let zipCode = '';
  let insuranceStart = '';
  let birthday = '';
  let trackedGender = TrackingGender.UNKNOWN;

  if (step > 0) {
    tariffOptions1 = getLivingType(tariffOptions1, typeOfHouse);
  }

  if (step > 1) {
    tariffOptions1 = getLivingLocation(tariffOptions1, typeOfFloor);
  }

  if (step > 2) {
    tariffOptions1 = getSquareMeters(tariffOptions1, sizeInSM);
  }

  if (step > 3) {
    zipCode = postalCode || zipCode;
  }

  if (step > 4) {
    insuranceStart = insuranceStartDate
      ? dateFirstFormat(insuranceStartDate)
      : insuranceStart;
  }

  if (step > 5) {
    birthday = dateOfBirth ? dateFirstFormat(dateOfBirth) : birthday;
  }

  if (step > 6) {
    // 1. Tarif
    const tariffLabel = 'Tarif';

    tariffOptions1 = getTariffData(tariffOptions1, tariffLabel, tariff);
    // 2-7. EDieb | Schutzb | Glas | NatG | UnbG | Fahrrad
    tariffOptions1 = getProductsData(
      tariffOptions1,
      products,
      fields,
      bikeAmount,
      tariff
    );

    // 8. FaKaDie
    tariffOptions1 = getBikeInsuranceCoverageData(tariffOptions1, bikeOption);
    // 9. Zahlung
    tariffOptions1 = getPaymentMethodData(tariffOptions1, paymentMethod);
    // 10. Dauer
    tariffOptions1 = getContractPeriodData(tariffOptions1, contractPeriod);
    // 11. SB
    tariffOptions1 = getDeductibleAmount(tariffOptions1, deductibleAmount);
  }
  // calculatedValue
  calculatedValue = getCalculatedValue(netPremium, paymentMethod);
  if (step > 8) {
    trackedGender = getTrackedGender(gender);
  }
  return {
    tariffOptions1,
    calculatedValue,
    zipCode,
    ...(Boolean(streetName) && { streetName }),
    ...(Boolean(streetNumber) && { streetNumber }),
    ...(Boolean(city) && { city }),
    email,
    telephoneNumber,
    prefixNumber,
    firstName,
    lastname,
    insuranceStart,
    birthday,
    gender: trackedGender,
    isAddressCorrected,
  };
};

export const useTrackingOptions = (): TrackingClickOptions => {
  const {
    zipCode,
    insuranceStartDate,
    gender,
    streetName,
    streetNumber,
    city,
    email,
    firstName,
    lastname,
    telephoneNumber,
    prefixNumber,
  } = useForm();
  const { dateOfBirth } = usePerson();
  const { tariffOptions1: tariffOptions, calculatedValue } = useTrackingData();

  let profileInfo = {};
  const isPersonalDataSet =
    email || firstName || lastname || telephoneNumber || prefixNumber;

  if (isPersonalDataSet) {
    profileInfo = {
      email,
      firstName,
      lastname,
      prefixNumber,
      telephoneNumber,
    };
  }

  const trackingOptions = {
    productAttrs: {
      tariffOptions,
      insuranceStart: dateFirstFormat(insuranceStartDate),
    },
    transaction: { calculatedValue },
    userProfile: {
      attributes: {
        ...((zipCode || streetName || streetNumber || city) && {
          address: { zipCode, streetName, streetNumber, city },
        }),
        birthday: dateFirstFormat(dateOfBirth),
        gender: getTrackedGender(gender),
      },
      ...(isPersonalDataSet && { profileInfo }),
    },
  };

  return trackingOptions;
};
