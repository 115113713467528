import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const GrossNegligence = (): JSX.Element => {
  return (
    <div data-testid="gross-negligence-info">
      <h3 data-testid="gross-negligence-title">
        {step7.grossNegligence.title}
      </h3>
      <p
        data-testid="gross-negligence-p1"
        dangerouslySetInnerHTML={{
          __html: step7.grossNegligence.paragraph1,
        }}
      ></p>
      <p data-testid="gross-negligence-p2">
        {step7.grossNegligence.paragraph2}
      </p>
      <p
        data-testid="gross-negligence-p3"
        dangerouslySetInnerHTML={{
          __html: step7.grossNegligence.paragraph3,
        }}
      ></p>
      <p
        data-testid="gross-negligence-p4"
        dangerouslySetInnerHTML={{
          __html: step7.grossNegligence.paragraph4,
        }}
      ></p>
      <p
        data-testid="gross-negligence-p5"
        dangerouslySetInnerHTML={{
          __html: step7.grossNegligence.paragraph5,
        }}
      ></p>
    </div>
  );
};

export default GrossNegligence;
