import './InformationRow.scss';

import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';

import type { InformationRowProps } from './InformationRowTypes';

const InformationRow = ({
  title,
  subtitle,
  icon,
  showTooltip,
  toolTipComponent,
  price,
  dataTestId = 'information-wrapper',
  onOpened,
}: InformationRowProps): JSX.Element => {
  const onOpenHandler = (isOpen: boolean): void => {
    if (onOpened && isOpen) {
      return onOpened();
    }
  };

  return (
    <div data-testid={dataTestId} className="information-wrapper">
      {icon && <div className="information-wrapper__icon">{icon}</div>}
      <div className="information-text">
        <div className="information-text__title">
          {title} {price}
        </div>
        {!!subtitle && <p className="information-text__subtitle">{subtitle}</p>}
      </div>
      {showTooltip && (
        <TooltipIcon
          isModal
          className="information-tooltip__icon"
          data-testid="tooltip-information-row"
          onToggledOpen={onOpenHandler}
        >
          {toolTipComponent ? toolTipComponent : null}
        </TooltipIcon>
      )}
    </div>
  );
};

export default InformationRow;
