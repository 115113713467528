import { getDatesDifference, isValidDateObject } from '../utils/dates';

/**
 * This function will validate if the entered date is
 * not in the past and less than one year in the future.
 */
export const validateStartDate = (str_date = ''): boolean => {
  const providedDate = new Date(str_date);

  if (!isValidDateObject(providedDate)) {
    return false;
  }

  const datesDifference = getDatesDifference(providedDate);

  // yyyyMMdd
  // 00009999
  // This means the difference should never be 1 year nor
  // greater
  return datesDifference > 0 && datesDifference <= 9999;
};
