import TooltipIcon from '@eg/elements/TooltipIcon';
import React from 'react';
import { RadioDrawer } from '../../../../../components/RadioDrawer/RadioDrawer';
import translations from '../../../../../constants/translations';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { useForm } from '../../../../../store/useForm';
import { setElectronicOrMechatronicLocks } from '../../../additionalQuestionsSlice';
import { ExistingDoorLocksQuestionProps } from '../../../AdditionalQuestionsTypes';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { existingDoorLocksValidations } from '../../../utils/validations/existingDoorLocks';
import { getErrorMessage } from '../../../utils/validations/validators';
import { ExistingDoorLocksQuestionInfo } from './ExistingDoorLocksQuestionInfo';

interface Props extends ExistingDoorLocksQuestionProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (error: string) => void;
}

const ExistingDoorLocksQuestion = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const { hasElectronicOrMechatronicLocks, dispatch } = useForm();
  const validations = useFormValidations(
    hasElectronicOrMechatronicLocks,
    existingDoorLocksValidations
  );

  const displayError = (field: keyof typeof validations): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = translations.step8.pleaseSelectDoorLocksRadio;
    }
    if (error) {
      updateTrackingErrors(error);
    }
    return error;
  };

  const doorLocksError = displayError('selected')
    ? [displayError('selected')]
    : undefined;

  const handleOnChangeRadioDrawer = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const value = target.value === 'true';
    dispatch(setElectronicOrMechatronicLocks(value));

    if (onClick) {
      const trulyRadioBtn = 'RBUTTON_DOOR_LOCKS_YES';
      const falsyRadioBtn = 'RBUTTON_DOOR_LOCKS_NO';
      onClick(value ? trulyRadioBtn : falsyRadioBtn);
    }
  };

  const onModalOpenHandler = (isOpen: boolean): void => {
    if (isOpen && onClick) {
      onClick('INFOICON_DOOR_LOCKS');
    }
  };

  return (
    <div className="additional-question">
      <RadioDrawer
        title={translations.step8.existingDoorLocks}
        subtitle={translations.step8.existingDoorLocksQuestion}
        value={hasElectronicOrMechatronicLocks.selected}
        onChange={handleOnChangeRadioDrawer}
        tooltip={
          <TooltipIcon isModal={true} onToggledOpen={onModalOpenHandler}>
            <ExistingDoorLocksQuestionInfo />
          </TooltipIcon>
        }
      />
      <ErrorsForInput
        errors={doorLocksError}
        updateTrackingErrors={updateTrackingErrors}
      />
    </div>
  );
};

export default ExistingDoorLocksQuestion;
