import * as constants from './constants';
import * as offers from './offers';
import * as roles from './roles';

export * from './offers/types';

const { getUrl, ...endpoints } = constants;

const offerEngineApi = {
  endpoints,
  offers,
  roles,
};

export default offerEngineApi;
