import type { TypesOfDate } from '../../../InsuranceDateTypes';

import {
  addDaysFromTodayFormatted,
  addDaysGetDayOfTheWeek,
  firstDayOfTheNextMonthFormatted,
  firstDayOfTheNextMonthDayOfTheWeek,
} from './dates';
import translations from '../../../../../constants/translations';
import { TrackingElementPage5 } from '../../../TrackingElements';
import { addDates } from '../../../../../helpers/dates/dates';

export const typesOfDates: {
  [Property in TypesOfDate]: {
    value: TypesOfDate;
    label: string;
    subtitle: string;
    trackingElement: TrackingElementPage5;
    dataTestId: string;
  };
} = {
  tomorrow: {
    value: 'tomorrow',
    label: translations.step5.constantTomorrowLabel,
    subtitle: `${addDaysGetDayOfTheWeek(1)}, ${addDaysFromTodayFormatted(1)}`,
    trackingElement: TrackingElementPage5.RBUTTON_TOMORROW,
    dataTestId: 'button-tomorrow',
  },
  nextMonth: {
    value: 'nextMonth',
    label: translations.step5.constantNextMonthLabel,
    subtitle: `${firstDayOfTheNextMonthDayOfTheWeek(
      addDates({ datesToAdd: 1 })
    )}, ${firstDayOfTheNextMonthFormatted(addDates({ datesToAdd: 1 }))}`,
    trackingElement: TrackingElementPage5.RBUTTON_NEXT,
    dataTestId: 'button-next-month',
  },
  anotherDate: {
    value: 'anotherDate',
    label: translations.step5.constantAnotherDateLabel,
    subtitle: translations.step5.constantAnotherDateSubtitle,
    trackingElement: TrackingElementPage5.RBUTTON_OTHER,
    dataTestId: 'button-another-date',
  },
};

export const typesOfDatesValues = Object.keys(typesOfDates);
