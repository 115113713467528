import { createAgencyHandler } from './agencies/index.handlers';
import { createDocumentHandler } from './document/index.handlers';
import {
  deleteBankDataHandler,
  getBankDataFromIbanHandler,
} from './payments/index.handlers';
import {
  createPersonHandler,
  readPersonHandler,
  updatePersonHandler,
} from './persons/index.handler';

const spcsHandlers = [
  createAgencyHandler,
  createDocumentHandler,
  deleteBankDataHandler,
  getBankDataFromIbanHandler,
  createPersonHandler,
  readPersonHandler,
  updatePersonHandler,
];

export default spcsHandlers;
