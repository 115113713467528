import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const SumInsured = (): JSX.Element => {
  return (
    <div data-testid="sum-insured-info">
      <h3 data-testid="sum-insured-title">{step7.sumInsured.title}</h3>
      <p
        data-testid="sum-insured-p1"
        dangerouslySetInnerHTML={{
          __html: step7.sumInsured.paragraph1,
        }}
      ></p>
      <p
        data-testid="sum-insured-p2"
        dangerouslySetInnerHTML={{
          __html: step7.sumInsured.paragraph2,
        }}
      ></p>
      <p
        data-testid="sum-insured-p3"
        dangerouslySetInnerHTML={{
          __html: step7.sumInsured.paragraph3,
        }}
      ></p>
      <p
        data-testid="sum-insured-p4"
        dangerouslySetInnerHTML={{
          __html: step7.sumInsured.paragraph4,
        }}
      ></p>
    </div>
  );
};

export default SumInsured;
