import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddressErrors,
  isValidPostalCode,
  isValidStreetName,
  isValidStreetNumber,
} from 'commons';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import { step4 } from '../../constants/step4';
import translations from '../../constants/translations';
import {
  customerAddress,
  getCitiesFromAddress,
} from '../../services/offerService/offerService';
import { saveOffer, savePerson } from '../../store/offerSlice';
import type { RootState } from '../../store/types';
import type { AddressFormTypes, AddressState } from './AddressTypes';

const initialState: AddressState = {
  isValidated: false,
  isCorrected: false,
  isLoading: false,
  isLoadingZipCode: false,
  oldForm: {
    zipCode: undefined,
    city: '',
    streetName: '',
    streetNumber: '',
  },
  openConfirmationModal: false,
  disabledContinue: true,
  streetNameError: undefined,
  streetNumberError: undefined,
  zipCodeOptions: [],
  zipCodeError: undefined,
  cityError: undefined,
  form: {
    zipCode: undefined,
    city: '',
    streetName: '',
    streetNumber: '',
  },

  zursZone: '',
};

export const fetchCityFromAddress = createAsyncThunk(
  'customerAddress/zipCode',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const {
        app: { businessId, personId = '' },
        address: { form: addressForm },
      } = state;

      const response = await getCitiesFromAddress(
        businessId,
        personId,
        addressForm
      );
      dispatch(setCity(response[0].city));
      return response;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const addressFetchValidation = createAsyncThunk(
  'address/validation',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;

      const response = await customerAddress(
        state.app.businessId,
        state.app.personId,
        state.address.form
      );

      if (response?.angebot) {
        const offer = response.angebot as Angebot;
        dispatch(saveOffer(offer));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      return {
        ...response,
      };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const parseAddressErrors = (errors: AddressErrors[]): Partial<AddressState> => {
  const streetNameErrors = [
    AddressErrors.streetName,
    AddressErrors.streetNameLength,
    AddressErrors.streetAddressLength,
    AddressErrors.addressNotIdentified,
    AddressErrors.correctTheAddressEntered,
    AddressErrors.invalidAddress,
  ];

  const streetNumberErrors = [
    AddressErrors.streetNumber,
    AddressErrors.streetNumberLength,
  ];

  const zipCodeErrors = [
    AddressErrors.majorCustomerZipcode,
    AddressErrors.postalCode,
  ];

  const addressErrors: Partial<AddressState> = {};

  if (errors.some(error => streetNameErrors.includes(error))) {
    addressErrors.streetNameError = translations.step4.streetNameError;
  }

  if (errors.some(error => streetNumberErrors.includes(error))) {
    addressErrors.streetNumberError = translations.step4.streetNumberError;
  }

  if (errors.some(error => zipCodeErrors.includes(error))) {
    addressErrors.zipCodeError = translations.step4.zipCodeErrorInvalid;
  }

  if (errors.some(error => error === AddressErrors.city)) {
    addressErrors.cityError = translations.step4.cityError;
  }

  return addressErrors;
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setFormValidationStatus: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
    setRiskZone: (state, action: PayloadAction<string>) => {
      state.zursZone = action.payload;
    },
    setStreetName: (
      state,
      action: PayloadAction<AddressFormTypes['streetName']>
    ) => {
      if (action.payload.length) {
        state.streetNameError = undefined;
      }
      state.form.streetName = action.payload;
      state.isValidated = false;
      state.isCorrected = false;
    },
    setStreetNumber: (
      state,
      action: PayloadAction<AddressFormTypes['streetNumber']>
    ) => {
      state.form.streetNumber = action.payload;
      state.isCorrected = false;
      if (state.streetNumberError) {
        state.streetNumberError = undefined;
      }
    },
    setZipCode: (state, action: PayloadAction<AddressFormTypes['zipCode']>) => {
      if (!!action.payload.length) {
        state.zipCodeError = undefined;
        state.form.city = '';
      }

      state.form.zipCode = action.payload;
      state.isValidated = false;
      state.isCorrected = false;
    },
    setCity: (
      state,
      action: PayloadAction<AddressFormTypes['city'] | undefined>
    ) => {
      if (action.payload?.length) {
        state.cityError = undefined;
      }

      state.form.city = action.payload;
    },
    setModalOpen: (state, action: PayloadAction<boolean>) => {
      state.openConfirmationModal = action.payload;
    },
    setDisabledContinue: (state, action: PayloadAction<boolean>) => {
      state.disabledContinue = action.payload;
    },
    clearZipCodeOptions: state => {
      state.zipCodeOptions = [];
    },
    resetZursCode: state => {
      state.zursZone = '';
    },
    resetStreetNameErrors: state => {
      state.streetNameError = undefined;
    },
    validateStreetName: (state, action: PayloadAction<string>) => {
      state.streetNameError = !isValidStreetName(action.payload)
        ? step4.streetNameError
        : undefined;
    },
    validateStreetNumber: (state, action: PayloadAction<string>) => {
      state.streetNumberError = !isValidStreetNumber(action.payload)
        ? step4.streetNumberError
        : undefined;
    },
    validateZipCode: (state, action: PayloadAction<string>) => {
      state.zipCodeError = !isValidPostalCode(action.payload)
        ? step4.zipCodeErrorInvalid
        : undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCityFromAddress.pending, state => {
      state.isLoadingZipCode = true;
      state.zipCodeError = undefined;
    });
    builder.addCase(fetchCityFromAddress.fulfilled, (state, action) => {
      state.isLoadingZipCode = false;
      // Only replace values when new array has values.
      // This is preventing a bug when selecting an address within the suggestions
      if (action.payload.length) {
        state.zipCodeOptions = action.payload;
        state.form.city = '';
      } else {
        state.zipCodeError = translations.step4.zipCodeErrorInvalid;
        state.zipCodeOptions = [];
        state.form.city = '';
      }
    });
    builder.addCase(fetchCityFromAddress.rejected, (state, action) => {
      state.isLoadingZipCode = false;
      state.zipCodeError = translations.step4.zipCodeErrorInvalid;
      state.zipCodeOptions = [];
      state.form.city = '';
      setCity('');
      console.error(action.error.message);
    });

    builder.addCase(addressFetchValidation.pending, state => {
      state.zursZone = '';
      state.isLoading = true;
      state.isValidated = false;
      state.disabledContinue = true;
      state.isCorrected = false;
    });

    builder.addCase(addressFetchValidation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disabledContinue = false;
      const { zuersGefaehrdungsklasse: zuers } =
        action.payload.angebot?.risikoOrt || {};

      if (zuers) {
        state.zursZone = zuers;
      } else {
        state.zursZone = '';
      }

      if (
        action.payload?.correctedAddress &&
        (action.payload?.correctedAddress?.streetName !==
          state.form.streetName ||
          action.payload?.correctedAddress?.zipCode !== state.form.zipCode)
      ) {
        state.isCorrected = true;
      } else {
        state.isCorrected = false;
      }

      if (zuers === 'ZUERS_GK_0' || zuers === 'ZUERS_GK_9') {
        state.isValidated = false;
        state.disabledContinue = true;
        state.openConfirmationModal = true;
        state.oldForm = { ...state.form };
      } else {
        state.isValidated = true;
      }

      if (action.payload.correctedAddress) {
        state.form = {
          ...state.form,
          ...action.payload.correctedAddress,
        };
      }
    });

    builder.addCase(addressFetchValidation.rejected, (state, action) => {
      state.isLoading = false;
      state.isValidated = false;
      state.disabledContinue = true;

      const errors = (action.payload as { errors?: AddressErrors[] }).errors;

      if (errors) {
        const addressErrors = parseAddressErrors(errors);
        for (const error in addressErrors) {
          state[error] = addressErrors[error];
        }
      }
    });
  },
});

export const {
  setFormValidationStatus,
  setZipCode,
  setCity,
  setStreetName,
  setStreetNumber,
  clearZipCodeOptions,
  resetZursCode,
  resetStreetNameErrors,
  setRiskZone,
  validateStreetName,
  validateStreetNumber,
  validateZipCode,
  setDisabledContinue,
  setModalOpen,
} = addressSlice.actions;

export const isAddressValid = ({
  address: { form, cityError, streetNameError, zipCodeError },
}: RootState): boolean => {
  const { zipCode, city, streetNumber, streetName } = form;

  if (
    !(zipCode && city && streetName && streetNumber) ||
    !!cityError ||
    !!streetNameError ||
    zipCodeError
  ) {
    return false;
  }

  return true;
};

export const selectIsValidated = (state: RootState): boolean =>
  state.address.isValidated;

export const addressIsCorrected = (state: RootState): boolean =>
  state.address.isCorrected;

export const selectOldAddressData = (state: RootState): AddressFormTypes =>
  state.address.oldForm;

export const selectIsLoading = (state: RootState): boolean =>
  state.address.isLoading;

export const selectIsLoadingZipCode = (state: RootState): boolean =>
  state.address.isLoadingZipCode;

export const zipCodeOptionsSelector = (state: RootState): AddressFormTypes[] =>
  state.address.zipCodeOptions;

export const getStreetNameError = ({
  address,
}: RootState): string | undefined => address.streetNameError;

export const getStreetNumberError = ({
  address,
}: RootState): string | undefined => address.streetNumberError;

export const getZipCodeError = ({ address }: RootState): string | undefined =>
  address.zipCodeError;

export const getCityError = ({ address }: RootState): string | undefined =>
  address.cityError;

export const isZursValueSelector = ({ address }: RootState): string => {
  return address.zursZone;
};

export const getModalState = ({ address }: RootState): boolean => {
  return address.openConfirmationModal;
};

export const getContinueState = ({ address }: RootState): boolean => {
  return address.disabledContinue;
};

export default addressSlice.reducer;
