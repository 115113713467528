import { useEffect, useState } from 'react';

interface AemIntegrationReturn {
  adjustContentToHeader: () => void;
  adjustHeaderToAEMWrapper: () => void;
  aemDimensions: { nav: number; header: number };
}

export const useAemIntegration = (): AemIntegrationReturn => {
  const getAemNavHeight = (): number =>
    document.querySelector('.page__navi')?.clientHeight ?? 0;
  const getHeaderElement = (): HTMLElement | null =>
    document.querySelector('.header');
  const getHeaderHeight = (): number =>
    document.querySelector('.header')?.clientHeight ?? 0;
  const getMainElement = (): HTMLElement | null =>
    document.querySelector('#b2c-hausrat-otr');

  const [aemDimensions, setAemDimensions] = useState({
    nav: getAemNavHeight(),
    header: getHeaderHeight(),
  });

  useEffect(() => {
    const handleResize = (): void => {
      const navHeight = getAemNavHeight();
      const headerHeight = getHeaderHeight();
      if (navHeight && headerHeight) {
        setAemDimensions({
          nav: navHeight,
          header: headerHeight,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const adjustHeaderToAEMWrapper = (): void => {
    const header = getHeaderElement();
    if (header) {
      header.style.marginTop = `${getAemNavHeight()}px`;
    }
  };

  const adjustContentToHeader = (): void => {
    const headerHeight = getHeaderHeight();
    const mainElement = getMainElement();
    if (mainElement) {
      mainElement.style.paddingTop = `${headerHeight}px`;
    }
  };

  return {
    adjustContentToHeader,
    adjustHeaderToAEMWrapper,
    aemDimensions,
  };
};
