import React from 'react';
import { useForm } from '../../../../../store/useForm';
import {
  setPreviousHouseholdDamageCost,
  setPreviousHouseholdDamageType,
  setPreviousHouseholdDamageYear,
} from '../../../additionalQuestionsSlice';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { TextInput } from '../../../../../components/TextInput/TextInput';

import './OneDamageHouseholdFields.scss';
import '../../../AdditionalQuestions.scss';
import { HouseholdDamage } from 'commons/apis/hausrat/types';
import {
  previousHouseholdDamageErrors,
  previousHouseholdDamageValidations,
} from '../../../utils/validations/previousHouseholdDamage';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import {
  getErrorMessage,
  getSanitizedNumber,
} from '../../../utils/validations/validators';
import {
  OneDamageHouseholdFieldsProps,
  PreviousDamageKey,
} from '../../../AdditionalQuestionsTypes';
import translations from '../../../../../constants/translations';
import DropDown from '../../../../../components/DropDown/DropDown';
import { DropdownOption } from '../../../../../components/DropDown/DropDown.d';

const OneDamageHouseholdFields = ({
  isGoNextClicked,
  updateTrackingErrors,
}: OneDamageHouseholdFieldsProps): JSX.Element => {
  const { previousHouseholdDamage, dispatch } = useForm();
  const validations = useFormValidations(
    previousHouseholdDamage,
    previousHouseholdDamageValidations
  );

  const displayError = (field: PreviousDamageKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = previousHouseholdDamageErrors[field];
    }
    if (error) {
      updateTrackingErrors(error);
    }

    return error;
  };

  const damageCostError = displayError('cost')
    ? [displayError('cost')]
    : undefined;

  const damageTypeError = displayError('type')
    ? [displayError('type')]
    : undefined;

  const damageYearError = displayError('year')
    ? [displayError('year')]
    : undefined;

  const damageTypes: {
    label: string;
    value: HouseholdDamage;
  }[] = [
    { label: translations.step8.fire, value: 'fire' },
    { label: translations.step8.burglary, value: 'burglary' },
    { label: translations.step8.robbery, value: 'robbery' },
    { label: translations.step8.tapWater, value: 'tapWater' },
    { label: translations.step8.storm, value: 'storm' },
    { label: translations.step8.vandalism, value: 'vandalism' },
    {
      label: translations.step8.otherNaturalHazards,
      value: 'otherNaturalHazards',
    },
    { label: translations.step8.other, value: 'other' },
  ];

  const getDamageYearOptions = (): DropdownOption[] => {
    const date = new Date(Date.now());
    const currentYear = date.getFullYear();
    const result: DropdownOption[] = [];
    for (let i = 0; i < 6; i++) {
      result.push({
        label: String(currentYear - i),
        value: String(currentYear - i),
      });
    }
    return result;
  };

  const handleChangeInsuranceDamageType = (value: string): void => {
    dispatch(setPreviousHouseholdDamageType(value as HouseholdDamage));
  };

  const handleChangeInsuranceDamageYear = (value: string): void => {
    const sanitizedValue = getSanitizedNumber(value);
    if (sanitizedValue !== false) {
      dispatch(setPreviousHouseholdDamageYear(sanitizedValue));
    }
  };

  const handleChangeInsuranceDamageAmount = ({ target: { value } }): void => {
    const sanitizedValue = getSanitizedNumber(value);
    if (sanitizedValue !== false) {
      dispatch(setPreviousHouseholdDamageCost(sanitizedValue));
    }
  };

  const damageCostErrorElements = (): JSX.Element[] => {
    if (previousHouseholdDamage.cost !== undefined && damageCostError) {
      return [
        <>
          <ErrorsForInput
            errors={[translations.step8.errorExeed]}
            updateTrackingErrors={updateTrackingErrors}
          />
        </>,
      ];
    }
    if (previousHouseholdDamage.cost == undefined && isGoNextClicked) {
      return [
        <>
          <ErrorsForInput
            errors={[translations.step8.pleaseStateTheAmountOfTheDamage]}
            updateTrackingErrors={updateTrackingErrors}
          />
        </>,
      ];
    }
    return undefined;
  };
  const damageTypeOptions = [...damageTypes];

  const damageYearOptions = getDamageYearOptions();

  return (
    <>
      <p>{translations.step8.informationAboutDamageQuestion}</p>
      <div className="additional-question-field">
        <DropDown
          id="hausholdDamageType"
          label={translations.step8.damageType}
          className="additional-question__select"
          placeholder={translations.step8.pleaseSelect}
          onChange={handleChangeInsuranceDamageType}
          value={previousHouseholdDamage.type}
          errors={damageTypeError}
          display="inline"
          options={damageTypeOptions}
        />
      </div>
      <div className="additional-question-field">
        <TextInput
          className="additional-question__field"
          id="previousHouseholdInsuranceDamageAmount"
          label={translations.step8.damageAmount}
          display="inline"
          type="number"
          value={previousHouseholdDamage.cost}
          placeholder="z.B. 500 €"
          onChange={handleChangeInsuranceDamageAmount}
          errors={damageCostErrorElements()}
        />
      </div>

      <div className="additional-question-field">
        <DropDown
          label={translations.step8.damageYear}
          className="additional-question__select"
          placeholder={translations.step8.pleaseSelect}
          value={previousHouseholdDamage.year?.toString()}
          onChange={handleChangeInsuranceDamageYear}
          errors={damageYearError}
          display="inline"
          id="hausholdDamageYear"
          options={damageYearOptions}
        ></DropDown>
      </div>
    </>
  );
};

export default OneDamageHouseholdFields;
