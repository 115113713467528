import { useState, ChangeEvent } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { noErgoemailValidation } from 'commons';

import { setEmail } from '../../personalDataSlice';
import {
  useEmailValidationTypes,
  PersonalDataForm,
} from '../../PersonalDataTypes';
import translations from '../../../../constants/translations';

const useEmailValidation = (
  form: PersonalDataForm
): useEmailValidationTypes => {
  const dispatch = useAppDispatch();
  const [errorMessage, setEmailErrorMessage] = useState('');
  const { email } = form;
  const emailValidationResults = noErgoemailValidation(email);
  const handleOnBlurEmailValidation = (): void => {
    if (!email) {
      setEmailErrorMessage(translations.step9.emailAddressMissing);
    } else if (emailValidationResults === 'ergo email provided') {
      setEmailErrorMessage(translations.step9.ergoEmailAddressInvalid);
    } else if (!emailValidationResults) {
      setEmailErrorMessage(translations.step9.emailAddressInvalid);
    } else {
      setEmailErrorMessage('');
    }
  };
  const onSubmitHandleEmailValidation = (): void => {
    if (emailValidationResults === 'ergo email provided') {
      setEmailErrorMessage(translations.step9.ergoEmailAddressInvalid);
    } else if (!emailValidationResults) {
      setEmailErrorMessage(translations.step9.emailAddressInvalid);
    } else {
      setEmailErrorMessage('');
    }
  };
  const handleOnChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setEmail(e.target.value));
  };
  return {
    errorMessage,
    email,
    handleOnChangeEmail,
    handleOnBlurEmailValidation,
    setEmailErrorMessage,
    onSubmitHandleEmailValidation,
  };
};

export default useEmailValidation;
