import React from 'react';
import { RadioDrawer } from '../../../../../components/RadioDrawer/RadioDrawer';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { useForm } from '../../../../../store/useForm';
import { setPreviousGlassDamage } from '../../../additionalQuestionsSlice';
import { previousGlassDamageValidations } from '../../../utils/validations/previousGlassDamage';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { PreviousGlassDamageQuestionFields } from './PreviousGlassDamageQuestionFields';
import { getErrorMessage } from '../../../utils/validations/validators';
import {
  PreviousDamageKey,
  PreviousGlassDamageQuestionProps,
} from '../../../AdditionalQuestionsTypes';
import translations from '../../../../../constants/translations';

interface Props extends PreviousGlassDamageQuestionProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (error: string) => void;
}

export const PreviousGlassDamageQuestion = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const { previousGlassDamage, dispatch } = useForm();
  const validations = useFormValidations(
    previousGlassDamage,
    previousGlassDamageValidations
  );

  const displayError = (field: PreviousDamageKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = translations.step8.pleaseSelectPreviousGlassInsuranceDamageRadio;
    }
    if (error) {
      updateTrackingErrors(error);
    }
    return error;
  };

  const selectedError = displayError('selected')
    ? [displayError('selected')]
    : undefined;

  const handleOnChangeRadioDrawer = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const value = target.value === 'true';
    dispatch(setPreviousGlassDamage(value));

    if (onClick) {
      const trulyRadioBtn = 'RBUTTON_DAMAGE_GLAS_YES';
      const falsyRadioBtn = 'RBUTTON_DAMAGE_GLAS_NO';
      onClick(value ? trulyRadioBtn : falsyRadioBtn);
    }
  };

  const onClickRadioBtn = (id: string): void => {
    onClick && onClick(id);
  };

  return (
    <div className="additional-question">
      <RadioDrawer
        title={translations.step8.previousDamageInLast5Years}
        subtitle={translations.step8.previousGlassDamageMainSubtitle}
        value={previousGlassDamage?.selected}
        children={
          <PreviousGlassDamageQuestionFields
            onClick={onClickRadioBtn}
            isGoNextClicked={isGoNextClicked}
            updateTrackingErrors={updateTrackingErrors}
          />
        }
        onChange={handleOnChangeRadioDrawer}
      />
      <ErrorsForInput
        errors={selectedError}
        updateTrackingErrors={updateTrackingErrors}
      />
    </div>
  );
};
