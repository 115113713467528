import { documentEngineHttp } from '../../http/documentEngineHttp';
import { downloadOffer as downloadOfferRoute, getUrl } from '../constants';

export const downloadOffer = async (businessId: string): Promise<Buffer> => {
  try {
    const { path } = downloadOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);
    const data = await documentEngineHttp<Buffer>(url, {
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw error;
  }
};
