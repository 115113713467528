import { useReducer } from 'react';

type Fields<T> = {
  name: T;
  blur: boolean;
  focus: boolean;
  touched: boolean;
};

type ACTIONTYPE<T> =
  | { type: 'blur'; payload: T }
  | { type: 'focus'; payload: T }
  | { type: 'touched'; payload: T };

type UseTouchedReturn<T> = {
  state: Fields<T>[];
  dispatch: React.Dispatch<ACTIONTYPE<T>>;
  isTouchedElement: (element: T) => boolean;
  isFocusedElement: (element: T) => boolean;
  isBlurElement: (element: T) => boolean;
  someWasTouched: boolean;
};

export function useTouched<ElementName>(
  elements: ElementName[] = []
): UseTouchedReturn<ElementName> {
  type ReducerState = Fields<ElementName>[];

  const fields: Fields<ElementName>[] = elements.map(element => ({
    name: element,
    blur: false,
    focus: false,
    touched: false,
  }));

  const initialState: ReducerState = fields;

  const reducer = (
    state: ReducerState,
    action: ACTIONTYPE<ElementName>
  ): ReducerState => {
    switch (action.type) {
      case 'blur':
        return state.map(field => {
          if (field.name === action.payload) {
            field.blur = true;
            field.focus = false;
            field.touched = true;
            return field;
          }

          field.blur = false;
          return field;
        });
      case 'focus':
        return state.map(field => {
          if (field.name === action.payload) {
            field.focus = true;
            field.blur = false;
            return field;
          }
          field.focus = false;
          return field;
        });
      case 'touched':
        return state.map(field => {
          if (field.name === action.payload) {
            field.touched = true;
            return field;
          }
          return field;
        });
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const isTouchedElement = (element: ElementName): boolean =>
    state.find(field => field.name === element)?.touched;

  const isFocusedElement = (element: ElementName): boolean =>
    state.find(field => field.name === element)?.focus;

  const isBlurElement = (element: ElementName): boolean =>
    state.find(field => field.name === element)?.blur;

  const someWasTouched = state.some(element => element.touched);

  return {
    state,
    dispatch,
    isTouchedElement,
    isFocusedElement,
    isBlurElement,
    someWasTouched,
  };
}
