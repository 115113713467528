import { isValidPostalCode } from 'commons';
import { useEffect, useMemo, useState } from 'react';
import translations from '../../../constants/translations';
import { useForm } from '../../../store/useForm';
import { clearZipCodeOptions, setCity } from '../addressSlice';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAddressData = () => {
  const {
    dispatch,
    streetName,
    streetNameError,
    streetNumber,
    streetNumberError,
    isLoadingZipCode,
    zipCode,
    zipCodeOptions,
    zipCodeError,
    city,
    isAddressStepValid,
    isAddressCorrected,
    isLoadingCustomerAddress,
    oldAddressData,
  } = useForm();

  const values = {
    zipCode,
    city,
    streetName,
    streetNumber,
  };

  /**
   * OE is providing a list of addresses (street + city), but
   * our app needs to suggest cities and streets independently.
   */

  const [suggestions, setSuggestions] = useState({
    cities: [],
    streets: [],
  });

  useEffect(() => {
    const suggestion = zipCodeOptions.reduce(
      (agg, curr) => {
        if (!!curr.city && !agg.cities.includes(curr.city)) {
          agg.cities.push(curr.city);
        }
        return agg;
      },
      {
        cities: [] as string[],
        streets: [] as string[],
      }
    );
    setSuggestions(suggestion);
  }, [zipCodeOptions, zipCodeOptions.length]);

  const errors = useMemo(() => {
    const streetNameValid = !streetNameError;

    const zipCodeValid = !zipCode || isValidPostalCode(zipCode);

    const cityValid =
      !suggestions.cities.length ||
      (!!city?.length && suggestions.cities.includes(city));

    return {
      streetName: !streetNameValid ? streetNameError : undefined,

      streetNumber: streetNumberError || undefined,

      zipCode:
        zipCodeError ??
        (!zipCodeValid ? translations.step4.zipCodeErrorInvalid : undefined),

      address: !(
        streetNameValid &&
        zipCodeValid &&
        cityValid &&
        isAddressStepValid
      ),
    };
  }, [
    streetNameError,
    streetNumberError,
    zipCode,
    zipCodeError,
    city,
    isAddressStepValid,
    suggestions,
  ]);
  // UseEffect for selecting city automatically
  useEffect(() => {
    const [suggestedCity] = suggestions.cities;
    if (!suggestedCity && !city) {
      dispatch(setCity(undefined));
    } else if (!zipCode) {
      dispatch(clearZipCodeOptions());
    } else if (
      suggestions.cities.length === 1 &&
      city !== suggestedCity &&
      zipCodeError == undefined
    ) {
      dispatch(setCity(''));
      dispatch(setCity(suggestedCity));
    }
  }, [!!suggestions.cities, suggestions, zipCodeOptions.length, city]);

  return {
    dispatch,
    errors,
    isLoadingZipCode,
    isLoadingCustomerAddress,
    isAddressCorrected,
    suggestions,
    values,
    oldAddressData,
  };
};
