import { AxiosError } from 'axios';
import { ErrorInfo } from '..';
import { addressValidationError } from './addressValidation.helper';
import { getErrorCode, isAxiosError } from './errors.helper';

export const getErrorInfo = (error: unknown): ErrorInfo => {
  if (isAxiosError(error)) {
    const axiosErr = error as AxiosError;

    if (axiosErr?.response?.data?.parsableDetails?.code) {
      const message = axiosErr?.response?.data?.parsableDetails?.code;
      const status = axiosErr?.response?.status ?? 400;
      return {
        message,
        status,
      };
    }

    if (axiosErr.response?.data?.meldungen) {
      const [firstErr] = axiosErr.response.data.meldungen;

      const message = firstErr?.text || axiosErr.message;
      let status = 500;
      switch (firstErr?.code) {
        case 'OPERATION_ERROR':
          break;

        default:
          const [statusStr] = message.match(/([0-9]{3,3})/) ?? ['500'];
          status = getErrorCode(Number(axiosErr.code ?? statusStr), status);
      }

      return {
        message,
        status,
      };
    }

    let message = axiosErr.message;
    let status = 500;
    switch (axiosErr.code) {
      case 'ENOTFOUND':
        message = 'You need to be connected to a VPN';
        status = 412;
        break;

      default:
        const [statusStr] = axiosErr.message.match(/([0-9]{3,3})/) ?? ['500'];
        status = getErrorCode(Number(axiosErr.code ?? statusStr), status);
    }

    return {
      message,
      status,
    };
  }

  const addressValidationResult = addressValidationError(error);
  if (addressValidationResult.isError) {
    return addressValidationResult.error;
  }

  if (error instanceof Error) {
    const [statusStr] = error.message.match(/([0-9]{3,3})/) ?? ['500'];

    return {
      message: error.message,
      status: getErrorCode(Number(statusStr), 500),
    };
  }

  if (typeof error === 'string') {
    const [statusStr] = error.match(/([0-9]{3,3})/) ?? ['500'];

    return {
      message: error,
      status: getErrorCode(Number(statusStr), 500),
    };
  }

  return {
    message: 'Something wrong happened',
    status: 500,
  };
};
