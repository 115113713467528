import { RootState } from '../../types';
import { getRisikoOrtFromOffer } from './generics';
import { floorTypeMapping } from 'commons';

export const getLocationHomeFromOffer = (
  state: RootState
): string | undefined => getRisikoOrtFromOffer(state)?.lageDerEingangstuer;

export const isHomeLocationUpdated = (state: RootState): boolean => {
  const offerValue = getRisikoOrtFromOffer(state)?.lageDerEingangstuer;
  return floorTypeMapping[state.homeLocation.form.typeOfFloor] !== offerValue;
};
