import { yearFirstFormat } from '../../../helpers/dates/dates';
import { RootState } from '../../types';
import { getSelectedVariantFromOffer } from './generics';

export const getInsuranceDateFromOffer = (
  state: RootState
): string | undefined => {
  const selectedVariant = getSelectedVariantFromOffer(state);
  return selectedVariant?.versicherungsbeginn;
};

export const isInsuranceStartDateUpdated = (state: RootState): boolean => {
  const offerValue = getSelectedVariantFromOffer(state)?.versicherungsbeginn;

  const selectedValue = state.insuranceDate.form.insuranceStartDate;
  const formattedValue = yearFirstFormat(selectedValue, 'YYYY-MM-DD');

  return formattedValue !== offerValue;
};
