import { useLocation } from 'react-router-dom';
import { routes } from '../router';

type UseStepsReturn = {
  currentPath: string;
  currentStep: number;
  numberOfSteps: number;
  stepName: string;
};

export const useSteps = (): UseStepsReturn => {
  const location = useLocation();

  const currentStep =
    routes.findIndex(route => route.path === location.pathname) ?? 0;

  const stepName =
    routes.find(route => route.path === location.pathname)?.name ?? '';

  const numberOfSteps = routes.length;

  const currentPath =
    routes.find(route => route.path === location.pathname)?.path ?? '';

  return {
    currentPath,
    currentStep,
    numberOfSteps,
    stepName,
  };
};
