import React from 'react';
import './InsuranceSummary.scss';

import Card from '@eg/elements/Card';
import translations from '../../../../constants/translations';
import { useAppSelector } from '../../../../store/hooks';
import { dateFirstFormat } from '../../../../helpers/dates/dates';
import { useForm } from '../../../../store/useForm';
import { getSelectedVariantFromOffer } from '../../../../store/offerSlice';
import { getOfferFeedbackSelector } from '../../../../store/offerSlice';

export const InsuranceSummary = (): JSX.Element => {
  const state = useForm();
  const { bonus, paymentMethod, premium, tax } = useAppSelector(
    getOfferFeedbackSelector
  );

  const variant = useAppSelector(getSelectedVariantFromOffer);

  return (
    <div className="feedback">
      <h2 className="feedback__title">
        {translations.step13.insuranceSummaryTitle}
      </h2>
      <Card>
        <ul className="feedback-list">
          <li key={translations.step13.insuranceSummaryList.tariff.label}>
            <dl className="feedback-list__element">
              <dt
                className="feedback-list__term"
                data-testid="insurance-summary-tariff-label"
              >
                {translations.step13.insuranceSummaryList.tariff.label}
              </dt>
              <dd
                className="feedback-list__description"
                data-testid="insurance-summary-tariff-content"
              >
                {translations.step13.insuranceSummaryList.tariff.text(
                  variant?.tarifvariante,
                  bonus
                )}
              </dd>
            </dl>
          </li>
          <li
            key={translations.step13.insuranceSummaryList.insuranceDate.label}
          >
            <dl className="feedback-list__element">
              <dt
                className="feedback-list__term"
                data-testid="insurance-summary-date-label"
              >
                {translations.step13.insuranceSummaryList.insuranceDate.label}
              </dt>
              <dd
                className="feedback-list__description"
                data-testid="insurance-summary-date-content"
              >
                {dateFirstFormat(state?.insuranceStartDate)}
              </dd>
            </dl>
          </li>
          <li key={translations.step13.insuranceSummaryList.contribution.label}>
            <dl className="feedback-list__element">
              <dt
                className="feedback-list__term"
                data-testid="insurance-summary-contribution-label"
              >
                {translations.step13.insuranceSummaryList.contribution.label}
              </dt>
              <dd
                className="feedback-list__description"
                data-testid="insurance-summary-contribution-content"
              >
                {translations.step13.insuranceSummaryList.contribution.text(
                  premium,
                  paymentMethod,
                  tax
                )}
              </dd>
            </dl>
          </li>
        </ul>
      </Card>
    </div>
  );
};
