import { spcsHttp } from '../../http/spcsHttp';
import {
  agencyRoles,
  createAgency as createAgencyRoute,
  getUrl,
} from '../constants';
import {
  CreateAgencyParams,
  CreateAgencyRequest,
  CreateAgencyResponse,
} from './types';
import { ChannelType } from '../../hausrat/types';

export const createAgency = async ({
  businessId,
  details,
}: CreateAgencyParams): Promise<CreateAgencyResponse> => {
  try {
    const { method, path } = createAgencyRoute;
    const { channelType, agentNumber, oeNr } = details;

    const url = getUrl(path).replace(':businessId', businessId);
    const payload: CreateAgencyRequest = {
      roles: agencyRoles,
      provision: 100,
    };

    if (
      channelType === ChannelType.AGENT_BROKER ||
      channelType === ChannelType.ERGO_PAGE
    ) {
      payload.oeNummer = oeNr;
    } else if (channelType === ChannelType.COOP_SALES_PARTNER) {
      payload.vermittlernummer = agentNumber;
    }
    const response = await spcsHttp<CreateAgencyResponse>(url, {
      method,
      payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
