import React from 'react';
import type { FloorType } from 'commons';
import type { HomeLocationRadioButtonsProps } from './HomeLocationOptionsTypes';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import { typesOfFloorData } from './HomeLocationOptionsConstants';

const HomeLocationOptions = ({
  onClick,
  checked,
}: HomeLocationRadioButtonsProps): JSX.Element => {
  return (
    <section className="home-location__options">
      {typesOfFloorData.map(type => {
        return (
          <RadioButton
            key={type.value}
            inline
            text={type.text}
            onClick={(value): void => onClick(value as FloorType)}
            value={type.value}
            checked={checked === type.value}
          />
        );
      })}
    </section>
  );
};

export default HomeLocationOptions;
