import React, { useMemo, useState, useEffect } from 'react';
import DateInput, { DateInputValue } from '@eg/elements/DateInput';
import ControlWithHint from '@eg/elements/ControlWithHint';

import { DateErrors } from '@eg/elements/utils/validation/date';
import { useForm } from '../../../../../../store/useForm';
import { addDates } from '../../../../../../helpers/dates/dates';
import { setDateOfStart } from '../../../../insuranceDateSlice';
import './InsuranceDateInput.scss';
import translations from '../../../../../../constants/translations';
import { trackFrontendError } from '../../../../../../utils/errorHandler';
import { useInputMode } from '../../../../../../hooks/useInputMode';

type DateError = Record<'error', keyof DateErrors>;
type DateOfBirthState = Partial<DateError> & { display: boolean };

const InsuranceDateInput = (): JSX.Element => {
  const { setInputMode } = useInputMode('.ee_date-input .ee_input');
  const { dispatch, typeOfDate, insuranceStartDate } = useForm();
  const [inputErrors, setInputError] = useState<DateOfBirthState>({
    display: false,
  });

  useEffect(() => {
    setInputMode('numeric');
  }, [setInputMode]);

  const inputErrorMessage = useMemo(() => {
    const dateErrorMap: Partial<Record<keyof DateErrors, string>> = {
      badInput: translations.step5.errorMessageBadInput,
      valueMissing: translations.step5.errorMessageValueMissing,
      rangeOverflow: translations.step5.errorMessageRangeOverflow,
      rangeUnderflow: translations.step5.errorMessageRangeUnderflow,
    };

    if (inputErrors.display) {
      return dateErrorMap[inputErrors.error];
    }
  }, [inputErrors]);

  useEffect(() => {
    if (inputErrorMessage) {
      trackFrontendError({ errorMessages: inputErrorMessage });
    }
  }, [inputErrorMessage]);

  const displayErrors = (): void => {
    setInputError(state => ({ ...state, display: true }));
  };

  const handleOnChangeDateInput = (
    value: DateInputValue,
    error: DateErrors
  ): void => {
    if (typeOfDate === 'anotherDate') {
      dispatch(setDateOfStart(value));
    }

    if (!value.day && !value.month && !value.year) {
      setInputError({ display: false });
    } else if (error) {
      const { valid, ...errorObject } = error;
      setInputError(state => ({
        ...state,
        error: Object.keys(errorObject)[0] as keyof DateErrors,
      }));
    }
  };

  const minDateOfDateInput = (): Date => {
    return addDates({ datesToAdd: 0 });
  };

  const maxDateOfDateInput = (): Date => {
    return addDates({ datesToAdd: 364 });
  };

  return (
    <div
      data-testid="insurance-date-input"
      className="insurance-date-input reset-input-artifacts"
    >
      <p className="insurance-date-input__label">
        {translations.step5.insuranceDateInputLabel}
      </p>
      <ControlWithHint error={inputErrorMessage}>
        <DateInput
          className="insurance-date-input__input"
          autoTab
          value={insuranceStartDate}
          onChange={handleOnChangeDateInput}
          onBlur={displayErrors}
          minDate={minDateOfDateInput()}
          maxDate={maxDateOfDateInput()}
          error={!!inputErrors?.error}
        />
      </ControlWithHint>
    </div>
  );
};

export default InsuranceDateInput;
