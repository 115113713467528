import React, { useEffect } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import translations from '../../../../constants/translations';
import { trackFrontendError } from '../../../../utils/errorHandler';

type ConsultationWeiverCheckboxProps = {
  consultationWeiver: boolean;
  showError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dataTestid?: string;
};

const ConsultationWeiverCheckbox = ({
  consultationWeiver,
  showError,
  onChange,
}: ConsultationWeiverCheckboxProps): JSX.Element => {
  useEffect(() => {
    if (showError) {
      trackFrontendError({
        errorMessages: translations.step12.checkbox.error,
      });
    }
  }, [showError]);
  return (
    <ControlWithHint
      error={showError ? translations.step12.checkbox.error : false}
    >
      <Checkbox
        verticalAlign="top"
        label={translations.step12.checkbox.label}
        checked={consultationWeiver}
        onChange={onChange}
        data-testid="consultation-weiver-checkbox"
        name="consultation-weiver"
      />
    </ControlWithHint>
  );
};

export default ConsultationWeiverCheckbox;
