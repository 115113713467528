import { retrieveTrackingData } from '../../storage/storage';
import {
  removeConversionType,
  removeMiscellaneous,
  setTariffOptions,
  updateInsuranceStart,
  updateAndHashUserAddress,
  updateUserBirthday,
  updateGender,
  setCalculatedValue,
  pushTrackingData,
  setConversionType,
  setMiscellaneousError,
  updateAndHashProfileInfo,
} from './tracking.helpers';
import {
  TrackingElement,
  TrackingClickOptions,
  TrackingContent,
  TrackingEvents,
  TrackingGender,
  TrackingErrorDetails,
} from './TrackingTypes';

export const trackClickEvent = (
  clickedElement: TrackingElement,
  options?: TrackingClickOptions
): void => {
  const currentState = retrieveTrackingData();
  if (!currentState) {
    return;
  }

  let trackingObject: TrackingContent = { ...currentState };

  trackingObject['event'] = TrackingEvents.GENERAL_CLICK;
  trackingObject['eventdetails'] = {
    clickType: 'other',
    clickedElement: clickedElement,
  };

  // conversionType exception
  removeConversionType(trackingObject);
  removeMiscellaneous(trackingObject);

  if (options) {
    const { productAttrs, userProfile, transaction } = options;

    if (productAttrs) {
      const { tariffOptions, insuranceStart } = productAttrs;
      if (tariffOptions) {
        trackingObject = setTariffOptions(trackingObject, tariffOptions);
      }

      if (insuranceStart) {
        trackingObject = updateInsuranceStart(trackingObject, insuranceStart);
      }
    }

    if (userProfile) {
      const { attributes, profileInfo } = userProfile;
      if (attributes) {
        const { birthday, gender, address } = attributes;
        if (address) {
          const { zipCode, streetName, streetNumber, city } = address;
          trackingObject = updateAndHashUserAddress(trackingObject, {
            zipCode,
            streetName,
            streetNumber,
            city,
          });
        }

        if (birthday) {
          trackingObject = updateUserBirthday(trackingObject, birthday);
        }

        if (gender) {
          trackingObject = updateGender(
            trackingObject,
            gender as TrackingGender
          );
        }

        if (profileInfo) {
          trackingObject = updateAndHashProfileInfo(
            trackingObject,
            profileInfo
          );
        }
      }
    }

    if (transaction) {
      const { calculatedValue, conversionType } = transaction;
      if (calculatedValue) {
        trackingObject = setCalculatedValue(trackingObject, calculatedValue);
      }

      if (conversionType) {
        trackingObject = setConversionType(trackingObject, conversionType);
      }
    }
  }

  pushTrackingData(trackingObject);
};

export const trackError = (errorDetails: TrackingErrorDetails): void => {
  const currentState = retrieveTrackingData();
  if (!currentState) {
    return;
  }

  let trackingObject: TrackingContent = { ...currentState };

  trackingObject.event = TrackingEvents.GENERAL_CLICK;
  trackingObject.eventdetails = {
    clickType: 'other',
  };

  trackingObject = setMiscellaneousError(trackingObject, errorDetails);
  pushTrackingData(trackingObject);
};
