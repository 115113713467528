import { trackClickEvent } from '../../../api/tracking';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { typesOfDates } from '../components/InsuranceDateOptions/helpers/constants';
import { TypesOfDate } from '../InsuranceDateTypes';
import {
  addDaysFromTodayFormatted,
  firstDayOfTheNextMonthFormatted,
} from '../components/InsuranceDateOptions/helpers/dates';
import { TrackingElementPage5 } from '../TrackingElements';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onRButtonsTracking: (value: TypesOfDate) => void;
  onModalTracking: () => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();
  const {
    productAttrs: { tariffOptions },
  } = trackingOptions;
  const onRButtonsTracking = (option: TypesOfDate): void => {
    const { trackingElement: element } = typesOfDates[option];

    let date = '0';

    switch (option) {
      case 'tomorrow':
        date = addDaysFromTodayFormatted(1);
        break;
      case 'nextMonth':
        date = firstDayOfTheNextMonthFormatted();
        break;
    }

    trackClickEvent(element, {
      ...trackingOptions,
      productAttrs: { insuranceStart: date, tariffOptions },
    });
  };

  const onModalTracking = (): void => {
    const element = TrackingElementPage5.INFOICON;
    trackClickEvent(element, trackingOptions);
  };

  return {
    trackingOptions,
    onRButtonsTracking,
    onModalTracking,
  };
};

export default useClickTracking;
