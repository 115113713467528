import React from 'react';
import { typesOfHouseData } from './LivingPlaceOptionsConstants';

import Grid from '../../../../components/Grid/Grid';
import GridElement from '../../../../components/Grid/components/GridElement/GridElement';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import type { LivingPlaceRadioOptionsProps } from './LivingPlaceOptionsTypes';
import { LivingPlace } from 'commons';
import './LivingPlaceOptions.scss';

const LivingPlaceOptions = ({
  onClick,
  checked,
}: LivingPlaceRadioOptionsProps): JSX.Element => {
  return (
    <Grid className="living-place-options">
      <>
        {typesOfHouseData.map(type => {
          return (
            <GridElement
              className={`radio-button--${type.value}`}
              key={type.value}
              responsive={[{ viewport: 'l', colSize: 6 }]}
            >
              <RadioButton
                icon={<type.icon width={50} height={50} />}
                text={type.text}
                onClick={(value): void => onClick(value as LivingPlace)}
                value={type.value}
                checked={checked === type.value}
              />
            </GridElement>
          );
        })}
      </>
    </Grid>
  );
};

export default LivingPlaceOptions;
