export enum trackingPageNames {
  livingPlacePageName = 'BasisdatenWohnsituation',
  homeLocationPageName = 'BasisdatenWohngeschoss',
  homeSquareMetersPageName = 'BasisdatenWohnflaeche',
  addressPageName = 'BasisdatenWohnort',
  addressVerificationFailedPageName = 'AddressVerificationFailed',
  insuranceDatePageName = 'BasisdatenVersBeginn',
  birthDatePageName = 'BasisdatenGebDatum',
  insuranceCoveragePageName = 'Berechnungsergebnis',
  additionalQuestionsPageName = 'Vorversicherung',
  personalDataPageName = 'Personendaten',
  contractReviewPageName = 'PruefenUndSenden',
  bankDetailsPageName = 'Bankdaten',
  termsOfServicePageName = 'PruefenUndSendenIDD',
  feedbackPageName = 'Abschluss Feedback',
}
