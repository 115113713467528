import { hausratHttp } from '../../http/hausratHttp';
import { InsuranceCompany } from '../../insuranceCompanies/types';
import { BankData } from '../../spcs/payments/types';
import { SubmitOfferResponse } from '../../submitEngine/submitOffer/types';
import {
  acceptConditions as acceptConditionsRoute,
  additionalQuestions as additionalQuestionsRoute,
  bankDetails as bankDetailsRoute,
  birthDate as birthDateRoute,
  createOffer as createOfferRoute,
  customerAddress as customerAddressRoute,
  downloadOffer as downloadOfferRoute,
  floorType as floorTypeRoute,
  getBankDataFromIban as getBankDataFromIbanRoute,
  getCitiesFromAddress as getCitiesFromAddressRoute,
  getUrl,
  insuranceCoverage as insuranceCoverageRoute,
  insuranceGlasField as insuranceGlasFieldRoute,
  insuranceStartDate as insuranceStartDateRoute,
  livingPlace as livingPlaceRoute,
  personalData as personalDataRoute,
  previousInsuranceCompanies as previousInsuranceCompaniesRoute,
  squareMeters as squareMetersRoute,
  submitOffer as submitOfferRoute,
  validateOffer as validateOfferRoute,
} from '../constants';
import {
  AdditionalQuestionsForm,
  Address,
  BirthDateParams,
  BirthDateResponse,
  CreateOfferResponse,
  DownloadOrSubmitOfferErrorResponse,
  InsuranceCoverage,
  InsuranceVariant,
  AgencyDetails,
  PersonalData,
  UpdateOfferResponse,
} from '../types';

// Screen 0
export const createOffer = async (
  businessId?: string,
  agencyDetails?: AgencyDetails
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = createOfferRoute;
    const response = await hausratHttp<CreateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        agencyDetails,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 1
export const livingPlace = async (
  businessId: string,
  value: string
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = livingPlaceRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        livingPlace: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 2
export const floorType = async (
  businessId: string,
  value: string
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = floorTypeRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        floorType: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 3
export const squareMeters = async (
  businessId: string,
  value: string
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = squareMetersRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        squareMeters: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 4
export const getCitiesFromAddress = async (
  businessId: string,
  personId: string,
  value: Address
): Promise<Address[]> => {
  try {
    const { method, path } = getCitiesFromAddressRoute;
    const url = getUrl(path);

    const payload = {
      businessId,
      personId,
      ...value,
    };

    const response = await hausratHttp<Address[]>(url, {
      method,
      payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const customerAddress = async (
  businessId: string,
  personId: string,
  value: Address
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = customerAddressRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        personId,
        address: value,
      },
    });
    return response;
  } catch (error) {
    if (error.response.data) {
      throw error.response.data;
    }

    throw error;
  }
};

// Screen 5
export const insuranceStartDate = async (
  businessId: string,
  variants: InsuranceVariant[]
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = insuranceStartDateRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        variants,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 6
export const birthDate = async ({
  businessId,
  dateOfBirth,
  personId,
}: BirthDateParams): Promise<BirthDateResponse> => {
  try {
    const { method, path } = birthDateRoute;
    const response = await hausratHttp<BirthDateResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        dateOfBirth,
        personId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 7
export const insuranceCoverage = async (
  businessId: string,
  value: InsuranceCoverage
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = insuranceCoverageRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        ...value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

type InsuranceGlassValue = {
  variantIds: string[];
  selected: boolean;
};

export const insuranceGlasField = async (
  businessId: string,
  value: InsuranceGlassValue
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = insuranceGlasFieldRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        ...value,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 8
export const additionalQuestions = async (
  businessId: string,
  value: AdditionalQuestionsForm
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = additionalQuestionsRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        additionalQuestions: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const previousInsuranceCompanies = async (
  payload: string
): Promise<InsuranceCompany[]> => {
  try {
    const { method, path } = previousInsuranceCompaniesRoute;
    const response = await hausratHttp<InsuranceCompany[]>(getUrl(path), {
      method,
      payload: { name: payload },
    });
    return response.map(company => ({
      ...company,
      vuNr: company.vuNr.substring(0, 4),
    }));
  } catch (error) {
    error;
  }
};

// Screen 9
export const personalData = async (
  businessId: string,
  personId: string,
  value: PersonalData
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = personalDataRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        personalData: value,
        personId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 11
export const getBankDataFromIban = async (
  businessId: string,
  personId: string,
  value: string,
  paymentId: string
): Promise<BankData> => {
  try {
    const { method, path } = getBankDataFromIbanRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':personId', personId);
    const payload = {
      businessId,
      personId,
      iban: value,
      paymentId: paymentId,
    };
    const response = await hausratHttp<BankData>(url, {
      method,
      payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bankDetails = async (
  businessId: string,
  value: string
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = bankDetailsRoute;
    const url = getUrl(path);
    const response = await hausratHttp<UpdateOfferResponse>(url, {
      method,
      payload: {
        businessId,
        id: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Screen 12
// submit offer
export const submitOffer = async (
  businessId: string
): Promise<SubmitOfferResponse> => {
  try {
    const { method, path } = submitOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);
    const response = await hausratHttp<SubmitOfferResponse>(url, {
      method,
      payload: {
        businessId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadOffer = async (businessId: string): Promise<Blob> => {
  try {
    const { method, path } = downloadOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);
    const response = await hausratHttp<Blob>(url, {
      method,
      payload: {
        businessId,
      },
      responseType: 'arraybuffer',
    });
    return response;
  } catch (error) {
    console.log('DEBUG Hausrat API download Offer', error);
    throw error;
  }
};

export const acceptConditions = async (
  businessId: string,
  value: boolean
): Promise<UpdateOfferResponse> => {
  try {
    const { method, path } = acceptConditionsRoute;
    const response = await hausratHttp<UpdateOfferResponse>(getUrl(path), {
      method,
      payload: {
        businessId,
        value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const validateOffer = async (
  businessId: string
): Promise<DownloadOrSubmitOfferErrorResponse> => {
  try {
    const { method, path } = validateOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);
    const response = await hausratHttp<DownloadOrSubmitOfferErrorResponse>(
      url,
      {
        method,
        payload: {
          businessId,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
