import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { trackClickEvent } from '../../../api/tracking';
import { TrackingElementPage4 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onModalTracking: () => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onModalTracking = (): void => {
    const element = TrackingElementPage4.INFO_ICON_HEADER;

    trackClickEvent(element, trackingOptions);
  };

  return { trackingOptions, onModalTracking };
};

export default useClickTracking;
