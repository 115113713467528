import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdditionalQuestionsForm } from 'commons/apis/hausrat/types';
import {
  additionalQuestions,
  previousInsuranceCompanies,
} from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import { RootState } from '../../store/types';
import type { AdditionalQuestionsState } from './AdditionalQuestionsTypes';
import { reducers } from './utils/reducers';
import {
  initProductModule,
  optionsSelected,
  updateProductModule,
} from '../step7/insuranceCoverageSlice';
import { InsuranceCompany } from 'commons/apis/insuranceCompanies/types';
import { storeAdditionalQuestionsTouched } from '../../storage/storage';
import { saveOffer } from '../../store/offerSlice';
import { Angebot } from 'commons/apis/offerEngine';

type InsuranceCompanySuggestionsKeys =
  keyof AdditionalQuestionsState['suggestions'];

const initialState: AdditionalQuestionsState = {
  isLoading: false,
  isValidated: false,
  form: {
    previousInsurance: {
      companyName: '',
      endDate: {},
    },
    previousHouseholdDamage: {},
    previousGlassInsurance: {
      endDate: {},
    },
    previousGlassDamage: {},
    hasElectronicOrMechatronicLocks: {},
  },
  suggestions: {
    previousInsuranceCompanies: [],
    previousGlassInsuranceCompanies: [],
  },
};

const removeGlassFields = (
  state: AdditionalQuestionsState
): AdditionalQuestionsState => {
  const { form } = state;
  const {
    previousGlassDamage,
    previousGlassInsurance,
    ...remainingFormFields
  } = form;
  return { ...state, form: remainingFormFields };
};

export const additionalQuestionsFetchValidation = createAsyncThunk(
  'additionalQuestions/validation',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    try {
      const response = await additionalQuestions(
        state.app.businessId,
        state.additionalQuestions.form
      );

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      dispatch(setStepValidated(8));

      storeAdditionalQuestionsTouched(true);

      return response;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const additionalQuestionsFetchCompanyNames = createAsyncThunk(
  'additionalQuestions/companyNames',
  async (payload: {
    target: InsuranceCompanySuggestionsKeys;
    value: string;
  }) => {
    try {
      const response = (await previousInsuranceCompanies(payload.value)) ?? [];
      return { target: payload.target, suggestions: response };
    } catch (error) {
      throw Error(error);
    }
  }
);

const additionalQuestionsSlice = createSlice({
  name: 'additionalQuestions',
  initialState,
  reducers: {
    ...reducers,
    setElectronicOrMechatronicLocks: ({ form }, action) => {
      form.hasElectronicOrMechatronicLocks.selected = action.payload;
    },
    setInsuranceCompanyVuNR: ({ form }, action) => {
      form.previousInsurance.vuNr = action.payload;
    },
    setGlassInsuranceCompanyVuNR: ({ form }, action) => {
      form.previousGlassInsurance.vuNr = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      additionalQuestionsFetchCompanyNames.fulfilled,
      (state, action) => {
        const { target, suggestions } = action.payload;

        state.suggestions[target] = suggestions;
      }
    );
    builder.addCase(initProductModule, (state, action) => {
      const isGlasInModules = !!action.payload.find(
        module => module === 'glass'
      );
      if (!isGlasInModules) {
        return removeGlassFields(state);
      }
    });
    builder.addCase(updateProductModule, (state, action) => {
      const { form } = state;
      const formHasGlassFields = Object.keys(form).includes(
        'previousGlassInsurance'
      );
      const moduleIsGlass = action.payload === 'glass';
      if (moduleIsGlass) {
        if (formHasGlassFields) {
          return removeGlassFields(state);
        }

        const updatedState = {
          ...state,
          form: {
            ...state.form,
            previousGlassInsurance: {
              endDate: {},
            },
            previousGlassDamage: {},
          },
        };

        return updatedState;
      }
    });
    builder.addCase(optionsSelected.fulfilled, (state, action) => {
      const { payload, meta } = action;
      if (meta.arg?.value === 'Best' || meta.arg?.value === 'Smart') {
        const selectedVariant = payload.angebot.versicherungen.find(
          variant => variant.ausgewaehlt === true
        );
        const contracts = payload.angebot.vertraege.filter(
          contract =>
            contract.struktur.versicherungen.toLowerCase() ===
            selectedVariant.versicherungsId.toLowerCase()
        );
        const isGlasSelected = contracts.some(
          c => c.vertragsId === 'HRG' && c.vereinbart
        );
        if (!isGlasSelected) {
          return removeGlassFields(state);
        }

        const updatedState = {
          ...state,
          form: {
            ...state.form,
            previousGlassInsurance: {
              endDate: {},
            },
            previousGlassDamage: {},
          },
        };

        return updatedState;
      }
    });
    builder.addCase(additionalQuestionsFetchValidation.pending, state => {
      state.isLoading = true;
      state.isValidated = false;
    });
    builder.addCase(additionalQuestionsFetchValidation.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(additionalQuestionsFetchValidation.rejected, state => {
      state.isLoading = false;
      state.isValidated = false;
    });
  },
});

export const {
  setPreviousHouseholdInsurance,
  setPreviousHouseholdInsuranceCompany,
  setPreviousHouseholdInsuranceTerminator,
  setPreviousHouseholdInsuranceEndDate,
  setPreviousHouseholdInsurancePolicyNumber,
  setPreviousGlassInsurance,
  setPreviousGlassInsuranceCompany,
  setPreviousGlassInsuranceTerminator,
  setPreviousGlassInsuranceEndDate,
  setPreviousGlassInsurancePolicyNumber,
  setPreviousHouseholdDamage,
  setPreviousHouseholdDamageNumber,
  setPreviousHouseholdDamageCost,
  setPreviousHouseholdDamageYear,
  setPreviousHouseholdDamageType,
  setPreviousGlassDamage,
  setPreviousGlassDamageNumber,
  setPreviousGlassDamageCost,
  setPreviousGlassDamageYear,
  setPreviousGlassDamageType,
  setElectronicOrMechatronicLocks,
  setInsuranceCompanyVuNR,
  setGlassInsuranceCompanyVuNR,
} = additionalQuestionsSlice.actions;

export const additionalQuestionsSelector = (
  state: RootState
): AdditionalQuestionsForm => state.additionalQuestions.form;

export const insuranceCompaniesSuggestionsSelector = (
  state: RootState
): Record<InsuranceCompanySuggestionsKeys, InsuranceCompany[]> =>
  state.additionalQuestions.suggestions;

export const selectIsLoading = (state: RootState): boolean =>
  state.additionalQuestions.isLoading;

export default additionalQuestionsSlice.reducer;
