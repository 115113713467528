import React from 'react';
import ConsultationWeiverCheckbox from '../ConsultationWeiverCheckbox';
import translation from '../../../../constants/translations';

type ConsultationNoteProps = {
  consultationWeiver: boolean;
  showError: boolean;
  onChangeConsultationWeiver: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

const ConsultationNote = ({
  consultationWeiver,
  showError,
  onChangeConsultationWeiver,
}: ConsultationNoteProps): JSX.Element => {
  return (
    <div className="consultation-note">
      <div className="consultation-note__info">
        <h3 className="consultation-note__title">
          {translation.step12.note.title}
        </h3>
        {translation.step12.note.body.map(bodyText => {
          return <p key={bodyText}>{bodyText}</p>;
        })}
      </div>
      <div className="consultation-note__checkbox">
        <ConsultationWeiverCheckbox
          consultationWeiver={consultationWeiver}
          showError={showError}
          onChange={onChangeConsultationWeiver}
        />
      </div>
    </div>
  );
};

export default ConsultationNote;
