import { ReactNode } from 'react';

// values taken from manifest.json
export enum AemAssetsKeys {
  OE_NUMBER = 'OENumber',
  PRODUCT_TABLE = 'productTable',
  FEEDBACK_FRAGMENT_OFFER = 'feedbackFragmentOffer',
  PDF_BENEFITS_DOWNLOAD = 'pdfDownload',
  DATA_SECURITY_TOOLTIP = 'securityPersonalData',
  BROKER_MODUS = 'brokerModus',
  SHORT_NAME = 'shortName',
  PRODUCT_GROUP = 'productGroup',
  LIVING_PLACE_PAGE = 'livingPlacePageName',
  HOME_LOCATION_PAGE = 'homeLocationPageName',
  HOME_SQUARE_METERS_PAGE = 'homeSquareMetersPageName',
  ADDRESS_PAGE = 'addressPageName',
  ADDRESS_FAIL_PAGE = 'addressVerificationFailedPageName',
  INSURANCE_DATE_PAGE = 'insuranceDatePageName',
  BIRTH_DATE_PAGE = 'birthDatePageName',
  INSURANCE_COVERAGE_PAGE = 'insuranceCoveragePageName',
  ADDITIONAL_QUESTIONS_PAGE = 'additionalQuestionsPageName',
  PERSONAL_DATA_PAGE = 'personalDataPageName',
  CONTRACT_REVIEW_PAGE = 'contractReviewPageName',
  BANK_DETAILS_PAGE = 'bankDetailsPageName',
  TERMS_OF_SERVICE_PAGE = 'termsOfServicePageName',
  FEEDBACK_PAGE = 'feedbackPageName',
}

export type AemWindow = {
  envUrl: string;
  aemAssets: AemAssets;
  loadFetchedAemJs: (content: string) => string;
  isEditMode: boolean;
};

export type AemAssets = {
  [key in AemAssetsKeys]?: string;
};

export type TrackingConstants = {
  shortName: string;
  productGroup: string;
  brokerModus: boolean;
};

export type AemDownloadLinkProps = {
  fragmentName: AemAssetsKeys;
  children: ReactNode;
};
