import React from 'react';
import translations from '../../../../constants/translations';

const LivingSpaceInfo = (): JSX.Element => {
  return (
    <div data-testid="living-space-info">
      <h3 data-testid="living-space-title">{translations.step3.modalTitle}</h3>
      <p data-testid="living-space-p1">{translations.step3.modalSubtitle}</p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li>{translations.step3.livingAreaType1}</li>
        <li>{translations.step3.livingAreaType2}</li>
      </ul>
      <p data-testid="living-space-p2">{translations.step3.excludedList}</p>
      <ul className="modal-list" data-testid="modal-list-2">
        <li>{translations.step3.basement}</li>
        <li>{translations.step3.balcony}</li>
        <li>{translations.step3.loggias}</li>
        <li>{translations.step3.terrace}</li>
        <li>{translations.step3.stairs}</li>
      </ul>
    </div>
  );
};

export default LivingSpaceInfo;
