import React from 'react';
import { useSelector } from 'react-redux';
import FormFooter from '@eg/elements/FormFooter';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import { InsuranceCoverageOptions } from './InsuranceCoverageOptions';
import { useForm } from '../../store/useForm';
import { useAppSelector } from '../../store/hooks';
import translations from '../../constants/translations';
import { useTrackingOptions } from '../../api/tracking/hooks/useTrackingData';
import './InsuranceCoverage.scss';
import {
  insuranceCoverageFetchValidation,
  isBikeModulePartiallyFilledSelector,
  optionsSelected,
  removeError,
  selectIsLoading,
  setError,
} from './insuranceCoverageSlice';
import useStepNavigation from '../../hooks/useStepNavigation';
import { getInsuranceCoverageFields } from '../../store/offerSlice';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import useClickTracking from './hooks/useClickTracking';
import { trackFrontendError } from '../../utils/errorHandler';

const InsuranceCoverage = (): JSX.Element => {
  const isError = useAppSelector(getGenericErrorSelector);
  const { step7 } = translations;
  const { goNext, goPrev } = useStepNavigation();
  const { dispatch, isInsuranceCoverageValid } = useForm();
  const { onClickTracking, onClickInfoIcon } = useClickTracking();
  const trackingOptions = useTrackingOptions();

  const isLoading = useSelector(selectIsLoading);
  const fields = useSelector(getInsuranceCoverageFields);
  const isBikeModulePartiallyFilled = useSelector(
    isBikeModulePartiallyFilledSelector
  );

  const handleContinue = (): void => {
    if (isBikeModulePartiallyFilled) {
      const errorMessages = step7.bikeModule.coverageNotSelectedError;
      trackFrontendError({ errorMessages });
      dispatch(
        setError({
          type: 'bikeModulePartialFilling',
          message: errorMessages,
        })
      );
      return;
    }

    dispatch(insuranceCoverageFetchValidation())
      .unwrap()
      .then(() => goNext());
  };

  const handleBackButton = (): void => {
    goPrev();
  };

  const handleRemoveBikeCoverageError = (id: string): void => {
    if (
      (isBikeModulePartiallyFilled && id === 'unchecked-ebike-module') ||
      id === 'fahrradkaskoUndMobilitätsschutz' ||
      id === 'fahrraddiebstahl'
    ) {
      dispatch(removeError('bikeModulePartialFilling'));
    }
  };

  const handleChangeOption = (
    id: string,
    selected: string | boolean,
    value?: number
  ): void => {
    handleRemoveBikeCoverageError(id);

    dispatch(optionsSelected({ id, selected, value }))
      .then(() => {
        onClickTracking(id, selected, value);
      })
      .catch(() => {
        onClickTracking(id, selected, value);
      });
  };

  return (
    <FormSection className="insurance-coverage">
      <FormSectionHeader
        title={step7.pageTitle}
        subtitle={step7.pageSubtitle}
      />

      <FormSectionBody className="form-section-body--small-padding">
        <InsuranceCoverageOptions
          fields={fields}
          handleChangeOption={handleChangeOption}
          isLoading={isLoading}
          onOpenInfoIcon={onClickInfoIcon}
        />
      </FormSectionBody>

      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBackButton}
          disableContinue={isLoading || !isInsuranceCoverageValid}
          isLoading={isLoading}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default InsuranceCoverage;
