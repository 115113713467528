import React from 'react';
import type { FormSectionFooterProps } from './FormSectionFooterType';
import './FormSectionFooter.scss';

const FormSectionFooter = ({
  children,
}: FormSectionFooterProps): JSX.Element => {
  return <footer className="form-section-footer">{children}</footer>;
};

export default FormSectionFooter;
