import React from 'react';
import Radio from '../../../../../components/ERadio/ERadio';
import { useForm } from '../../../../../store/useForm';
import { setPreviousHouseholdDamageNumber } from '../../../additionalQuestionsSlice';
import { ErrorsForInput } from '../../../ErrorsForInput';
import MoreThanOneDamageFields from '../../MoreThanOneDamageFields';
import OneDamageHouseholdFields from './OneDamageHouseholdFields';
import { DamageNumber } from 'commons/apis/hausrat/types';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { previousHouseholdDamageValidations } from '../../../utils/validations/previousHouseholdDamage';
import { getErrorMessage } from '../../../utils/validations/validators';
import {
  PreviousDamageKey,
  PreviousDamageQuestionFieldsProps,
} from '../../../AdditionalQuestionsTypes';
import translations from '../../../../../constants/translations';

interface Props extends PreviousDamageQuestionFieldsProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (id: string) => void;
}

export const PreviousDamageQuestionFields = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const { previousHouseholdDamage, dispatch } = useForm();
  const validations = useFormValidations(
    previousHouseholdDamage,
    previousHouseholdDamageValidations
  );

  const displayError = (field: PreviousDamageKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = translations.step8.pleaseSelect;
    }
    if (error) {
      updateTrackingErrors(error);
    }
    return error;
  };
  const damageNumberError = displayError('number')
    ? [displayError('number')]
    : undefined;

  const onChangeRadioBtn = (
    value: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const damageNumber = value.target.value as DamageNumber;
    dispatch(setPreviousHouseholdDamageNumber(damageNumber));

    if (onClick) {
      const isOne = damageNumber === 'one';
      const elementKey = isOne
        ? 'RBUTTON_DAMAGE_EQUAL1'
        : 'RBUTTON_DAMAGE_SIZE1';
      onClick(elementKey);
    }
  };

  return (
    <div
      className="additional-question__content additional-question__card"
      data-testid="additional-question-previous-damage"
    >
      <div className="additional-question-radio-group">
        <p>{translations.step8.damageAmountTitle}</p>
        <p>{translations.step8.damageAmountQuestion}</p>
        <Radio
          value="one"
          label="1"
          checked={previousHouseholdDamage.number === 'one'}
          onChange={onChangeRadioBtn}
        />
        <Radio
          value="moreThanOne"
          label={translations.step8.moreThanOne}
          checked={previousHouseholdDamage.number === 'moreThanOne'}
          onChange={onChangeRadioBtn}
        />
      </div>
      <ErrorsForInput
        errors={damageNumberError}
        updateTrackingErrors={updateTrackingErrors}
      />
      {previousHouseholdDamage.number === 'moreThanOne' && (
        <MoreThanOneDamageFields updateTrackingErrors={updateTrackingErrors} />
      )}

      {previousHouseholdDamage.number === 'one' && (
        <OneDamageHouseholdFields
          isGoNextClicked={isGoNextClicked}
          updateTrackingErrors={updateTrackingErrors}
        />
      )}
    </div>
  );
};
