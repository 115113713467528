import { ChannelType, AgencyDetails } from 'commons/apis/hausrat/types';
import {
  getActionNumber,
  getAgentNumberFromMarketingEvent,
  getOenrFromQueryParam,
  getOeNumberFromAdhopPages,
  isAdhopPage,
  isPartnerCooperationPage,
  isSitecorePage,
} from './getAgencyDetailsHelpers';
import { DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES } from 'commons/apis/spcs/constants';

export const getChannelType = (): ChannelType => {
  if (isPartnerCooperationPage()) {
    return ChannelType.COOP_SALES_PARTNER;
  } else if (isAdhopPage() || isSitecorePage()) {
    return ChannelType.AGENT_BROKER;
    //todo do we secure makler mode by accepting query param channelType=makler (like PHV and Unfall)?
    // } else if (makler?) {
    //   return ChannelType.MAKLER;
  } else {
    return ChannelType.ERGO_PAGE;
  }
};

export const getOeNumber = (): string => {
  if (getChannelType() === ChannelType.ERGO_PAGE) {
    return DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES;
  } else {
    return getOenrFromQueryParam() || getOeNumberFromAdhopPages() || '';
  }
};

export const getAgencyValues = (): AgencyDetails => {
  return {
    channelType: getChannelType(),
    oeNr: getOeNumber(),
    agentNumber: getAgentNumberFromMarketingEvent() || '',
    actionNumber: getActionNumber(),
  };
};
