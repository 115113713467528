import React from 'react';
import EEPrice from '@eg/elements/Price';
import type { PriceProps } from './Price.d';

import './Price.scss';

const Price = ({
  value,
  size,
  suffix,
  unstyled = false,
  'data-testid': dataTestId,
  withSpace = false,
}: PriceProps): JSX.Element => (
  <div className={`Price ${withSpace ? 'Price--with-space' : ''}`}>
    <EEPrice
      value={value}
      size={size}
      suffix={suffix}
      unstyled={unstyled}
      data-testid={dataTestId}
    />
  </div>
);

export default Price;
