import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';
import translations from '../../../../constants/translations';

import './PersonalDataIntroText.scss';

interface Props {
  onOpened?: () => void;
}

const PersonalDataIntroText = ({ onOpened }: Props): JSX.Element => {
  const handleToggleOpen = (isOpen: boolean): void => {
    if (onOpened && isOpen) {
      onOpened();
    }
  };

  return (
    <div className="personal-data-intro">
      <p className="personal-data-intro__text">
        {translations.step9.pageSubtitle}
      </p>
      <div className="personal-data-intro__tooltip">
        <p className="personal-data-intro__tooltip-text">
          {translations.step9.subTitleToolTip}
        </p>
        <TooltipIcon isModal onToggledOpen={handleToggleOpen}>
          <h3>{translations.step9.subTitleToolTip}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: translations.step9.subTitleToolTipInfo,
            }}
          />
        </TooltipIcon>
      </div>
    </div>
  );
};

export default PersonalDataIntroText;
