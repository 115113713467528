export const step13 = {
  headerTitle: (name: string): string => `Vielen Dank, ${name}!`,
  headerSubtitle:
    'Ihr Vertragsabschluss war erfolgreich. Sie haben eine gute Entscheidung für Ihre Zukunft getroffen. Herzlichen Glückwunsch!',
  insuranceSummaryMessage:
    'Mit ERGO haben Sie ab sofort einen zuverlässigen Partner an Ihrer Seite. Sie müssen nichts weiter tun. ERGO kümmert sich um alles Weitere. Sie erhalten in wenigen Tagen Ihre Vertragsunterlagen per Post, sobald Ihr Vertragsabschluss bearbeitet wurde.',
  insuranceSummaryTitle: 'Ihre ERGO Hausratversicherung',
  insuranceSummaryList: {
    tariff: {
      label: 'Ihr Tarif',
      text: (variant, bonus: string | number | undefined): string =>
        `${variant} ${bonus ? `inkl. ${bonus} % Startbonus` : ''}`,
    },
    insuranceDate: {
      label: 'Ihr Versicherungsbeginn',
    },
    contribution: {
      label: 'Ihr Beitrag',
      text: (
        monthly: string | number,
        paymentMethod: string,
        insuranceTax: string
      ): string =>
        `${monthly} € ${paymentMethod} (inkl. Versicherungssteuer von zurzeit ${insuranceTax} %)`,
    },
  },
};
