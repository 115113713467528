import React, { useMemo } from 'react';

import type {
  GridElementResponsive,
  GridElementProps,
} from './GridElementTypes';

const responsiveDefault: GridElementResponsive[] = [
  {
    viewport: 'xs',
    colSize: 12,
  },
];

const GridElement = ({
  className,
  responsive = [],
  children,
}: GridElementProps): JSX.Element => {
  const colSizeResponsiveString = useMemo(() => {
    return responsiveDefault
      .concat(responsive)
      .reduce(
        (prev, curr) =>
          `${prev} esc_col-${curr.viewport}-${curr.colSize} ${
            curr.offset ? `esc_col-${curr.viewport}--offset-${curr.offset}` : ''
          }`,
        ''
      );
  }, [responsive]);

  const classNames = useMemo(() => {
    return `esc_col ${colSizeResponsiveString} ${className || ''}`;
  }, [className, colSizeResponsiveString]);

  return <div className={classNames}>{children}</div>;
};

export default GridElement;
