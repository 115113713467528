import React, { useCallback, useRef, useEffect } from 'react';
import FormFooter from '@eg/elements/FormFooter';
import Input from '@eg/elements/Input';
import LayoutBox from '@eg/elements/LayoutBox';
import FormSection from '../../components/FormSection/FormSection';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import InfoMsg from './components/InfoMsg/InfoMsg';
import ErrorMsg from './components/ErrorMsg/ErrorMsg';
import SuccessMsg from './components/SuccessMsg/SuccessMsg';
import LivingSpaceInfo from './components/LivingSpaceInfo/LivingSpaceInfo';
import { useForm } from '../../store/useForm';
import { MAX_SQUARE_METERS, MIN_SQUARE_METERS, NumberPages } from 'commons';
import { debounce } from '../../helpers/debounce/debounce';
import {
  homeSquareMetersFetchSumInsured,
  homeSquareMetersFetchValidation,
  setSizeInSM,
} from './HomeSquareMetersSlice';
import './HomeSquareMeters.scss';
import { isValidSquareMeters } from 'commons';
import translations from '../../constants/translations';
import { useAppSelector } from '../../store/hooks';
import {
  getInsuredAmountFromOffer,
  offerExistSelector,
} from '../../store/offerSlice';
import useStepNavigation from '../../hooks/useStepNavigation';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import useClickTracking from './hooks/useClickTracking';
import { isHomeSquareMetersUpdated } from '../../store/offerSlice';
import { blockedCharactersOnInput } from '../../utils';

export const HomeSquareMeters = (): JSX.Element => {
  const homeSizeRef = useRef(null);
  const {
    dispatch,
    sizeInSM,
    typeOfHouse,
    isLoadingSquareMeters,
    isLoadingSumInsured,
  } = useForm();
  const { goNext, goPrev } = useStepNavigation();
  const sumInsured = useAppSelector(getInsuredAmountFromOffer);
  const offerExist = useAppSelector(offerExistSelector);
  const isValidInput = isValidSquareMeters(sizeInSM);
  const hasError = !isValidInput && sizeInSM !== 0;
  const normalizeValue = sizeInSM > 0 ? sizeInSM : '';
  const disableContinue = !isValidInput || isLoadingSumInsured;
  const showInfoMessage = normalizeValue === '';
  const isError = useAppSelector(getGenericErrorSelector);
  const { trackingOptions, onModalTracking } = useClickTracking();
  const isFormModified = useAppSelector(isHomeSquareMetersUpdated);

  useEffect(() => {
    if (!offerExist) {
      dispatch(homeSquareMetersFetchSumInsured());
    }
  }, [dispatch, offerExist]);

  const handleCTA = useCallback(() => {
    if (isFormModified) {
      dispatch(homeSquareMetersFetchValidation())
        .unwrap()
        .then(() => {
          goNext();
        })
        .catch(error => console.error(error));
    } else {
      goNext();
    }
  }, [dispatch, isFormModified]);

  const handleCancel = useCallback(() => {
    typeOfHouse === 'singleFamilyHouse'
      ? goNext(NumberPages.LIVING_PLACE)
      : goPrev();
  }, [typeOfHouse]);

  const debounceFetchSumInsured = React.useMemo(
    () =>
      debounce(() => {
        dispatch(homeSquareMetersFetchSumInsured());
      }, 400),
    [dispatch]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      const getValidNumber = Number.isNaN(value) ? 0 : value;
      if (value < 0) {
        return;
      }

      dispatch(setSizeInSM(value));
      debounceFetchSumInsured(getValidNumber);
    },
    [debounceFetchSumInsured, dispatch]
  );

  const handleHeaderModal = (): void => {
    onModalTracking('header');
  };

  const handleInfoModal = (): void => {
    onModalTracking('info');
  };

  return (
    <FormSection className="form-section--home-size">
      <FormSectionHeader
        withBox
        showTooltip
        title={translations.step3.pageTitle}
        subtitle={[
          translations.step3.pageSubtitle1,
          <strong key="subTBoldL">{translations.step3.pageSubtitle2}</strong>,
          translations.step3.pageSubtitle3,
        ]}
        toolTipComponent={<LivingSpaceInfo />}
        onOpened={handleHeaderModal}
      />
      <FormSectionBody>
        <LayoutBox direction="column" ref={homeSizeRef}>
          <div className="homeSquareMeters">
            <Input
              name="inputSizeHome"
              min={MIN_SQUARE_METERS}
              max={MAX_SQUARE_METERS}
              adornmentRight="qm"
              value={normalizeValue}
              onChange={handleChange}
              alt={translations.step3.inputHelper}
              autoFocus
              inputMode="numeric"
              type="text"
              data-testid="inputSizeHome"
              onKeyDown={blockedCharactersOnInput}
              onKeyPress={blockedCharactersOnInput}
            />
          </div>
          <>
            {showInfoMessage && (
              <InfoMsg
                minValue={MIN_SQUARE_METERS.toString()}
                maxValue={MAX_SQUARE_METERS.toString()}
              />
            )}
            {hasError && <ErrorMsg value={sizeInSM} />}
            {!hasError && isValidInput && (
              <SuccessMsg
                value={sumInsured}
                isLoading={isLoadingSumInsured}
                onOpened={handleInfoModal}
              />
            )}
          </>
        </LayoutBox>
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleCTA}
          onClickCancel={handleCancel}
          disableContinue={disableContinue}
          disableCancel={false}
          isLoading={isLoadingSquareMeters}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};
