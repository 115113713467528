import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Angebot } from 'commons/apis/offerEngine';
import { Person } from 'commons/apis/spcs/persons/types';
import { storeOffer } from '../../helpers/stateInit';
import { createOffer } from '../../services/offerService/offerService';
import {
  retrieveBusinessId,
  retrievePersonId,
  retrieveReachedFeedback,
  storeBusinessId,
  storePersonId,
  storeReachedFeedback,
} from '../../storage/storage';
import { RootState } from '../types';
import { saveOffer, savePerson } from '../offerSlice';
import { AppState } from './types';
import { getAgencyValues } from '../getAgencyDetails';

const initialState: AppState = {
  businessId: retrieveBusinessId(),
  personId: retrievePersonId(),
  paymentId: '',
  isPendingBusinessId: false,
  isRejectedBusinessId: false,
  stepsValidated: [],
  discount: 13,
  reachedFeedback: retrieveReachedFeedback(),
  genericError: false,
};

export const getInitialOffer = createAsyncThunk(
  'app/getBusinessId',
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setGenericError(false));
      const state = getState() as RootState;
      const { businessId } = state.app;

      const agencyValues = getAgencyValues();
      const response = await createOffer(businessId, agencyValues);

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));

        const newBusinessId = response.angebot.businessId;
        if (newBusinessId) {
          storeBusinessId(response.angebot.businessId);
        }
      }

      if (response.person) {
        dispatch(savePerson(response.person as Person));

        const newPersonId = response.person.personId;
        if (newPersonId) {
          storePersonId(response?.person.personId);
        }
      }
      return response;
    } catch (error) {
      dispatch(setGenericError(true));
      throw Error(error);
    }
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setGenericError: (state, action: PayloadAction<boolean>) => {
      state.genericError = action.payload;
    },
    setStepValidated: (state, action: PayloadAction<number>) => {
      if (!state.stepsValidated.includes(action.payload)) {
        state.stepsValidated.push(action.payload);
      }
    },
    setDiscount: (state, action: PayloadAction<number>) => {
      state.discount = action.payload;
    },
    setReachedFeedback: (state, action: PayloadAction<boolean>) => {
      state.reachedFeedback = action.payload;
      storeReachedFeedback(action.payload);
    },
    setPaymentId: (state, action: PayloadAction<string>) => {
      state.paymentId = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getInitialOffer.pending, state => {
      state.isPendingBusinessId = true;
    });
    builder.addCase(getInitialOffer.fulfilled, (state, action) => {
      storeOffer(action.payload?.angebot, action.payload?.person);

      state.isPendingBusinessId = false;
      state.businessId =
        action.payload?.angebot?.businessId ?? state.businessId;

      state.isRejectedBusinessId = false;
      state.personId = action.payload?.person?.personId ?? state.personId;
    });
    builder.addCase(getInitialOffer.rejected, state => {
      state.isPendingBusinessId = false;
      state.isRejectedBusinessId = true;
    });
  },
});

export const {
  setStepValidated,
  setDiscount,
  setReachedFeedback,
  setPaymentId,
  setGenericError,
} = appSlice.actions;
