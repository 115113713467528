import React from 'react';
import translations from '../../../../constants/translations';

const SumInsuredInfo = (): JSX.Element => {
  return (
    <div data-testid="sum-insured-info">
      <h3 data-testid="sum-insured-title">
        {translations.step3.successMessageModalTitle}
      </h3>
      <p
        data-testid="sum-insured-p1"
        dangerouslySetInnerHTML={{
          __html: translations.step3.agreedSum,
        }}
      />
      <p
        data-testid="sum-insured-p2"
        dangerouslySetInnerHTML={{
          __html: translations.step3.damageNotice,
        }}
      />
      <p
        data-testid="sum-insured-p3"
        dangerouslySetInnerHTML={{
          __html: translations.step3.propotionCompensation,
        }}
      />
      <p
        data-testid="sum-insured-p4"
        dangerouslySetInnerHTML={{
          __html: translations.step3.smallTip,
        }}
      />
    </div>
  );
};

export default SumInsuredInfo;
