import { spcsHttp } from '../../http/spcsHttp';
import {
  createPerson as createPersonRoute,
  updatePerson as updatePersonRoute,
  readPerson as readPersonRoute,
  getUrl,
} from '../constants';
import {
  CreatePersonParams,
  UpdatePersonParams,
  PersonResponse,
  ReadPersonParams,
} from './types';

export const createPerson = async ({
  businessId,
}: CreatePersonParams): Promise<PersonResponse> => {
  try {
    const { method, path } = createPersonRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const payload = {
      addresses: null,
      familyName: null,
      firstName: null,
      placeOfBirth: null,
      dateOfBirth: null,
    };

    const response = await spcsHttp<PersonResponse>(url, {
      method,
      payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const readPerson = async ({
  businessId,
}: ReadPersonParams): Promise<PersonResponse> => {
  try {
    const { method, path } = readPersonRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const response = await spcsHttp<PersonResponse>(url, {
      method,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePerson = async ({
  businessId,
  personId,
  payload,
}: UpdatePersonParams): Promise<PersonResponse> => {
  try {
    const { method, path } = updatePersonRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':personId', personId);

    const response = await spcsHttp<PersonResponse>(url, {
      method,
      payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
