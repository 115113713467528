import React, { RefObject } from 'react';
import ExistingDoorLocksQuestion from './questions/furtherDetails/ExistingDoorLocksQuestion/ExistingDoorLocksQuestion';
import { PreviousDamageQuestion } from './questions/previousHouseholdInsurance/PreviousDamageQuestion/PreviousDamageQuestion';
import { PreviousGlassDamageQuestion } from './questions/previousGlassInsurance/PreviousGlassDamageQuestion/PreviousGlassDamageQuestion';
import { PreviousGlassInsuranceQuestion } from './questions/previousGlassInsurance/PreviousGlassInsuranceQuestion/PreviousGlassInsuranceQuestion';
import { PreviousInsuranceQuestion } from './questions/previousHouseholdInsurance/PreviousInsuranceQuestion/PreviousInsuranceQuestion';
import translations from '../../constants/translations';
import { AdditionalQuestionsErrorProps } from './AdditionalQuestionsTypes';
import { getInsuranceCoverageFromOffer } from '../../store/offerSlice';
import { useSelector } from 'react-redux';

interface Props extends AdditionalQuestionsErrorProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (id: string) => void;
  householdInsurance: RefObject<HTMLDivElement>;
  householdDamage: RefObject<HTMLDivElement>;
  glassInsurance: RefObject<HTMLDivElement>;
  glassDamage: RefObject<HTMLDivElement>;
  existingDoorLock: RefObject<HTMLDivElement>;
}

const AdditionalQuestionsOptions = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
  householdInsurance,
  householdDamage,
  glassInsurance,
  glassDamage,
  existingDoorLock,
}: Props): JSX.Element => {
  const { productModules } = useSelector(getInsuranceCoverageFromOffer);

  const isProductWithGlassSelected = productModules?.includes('glass');

  const onClickHandler = (id: string): void => {
    onClick && onClick(id);
  };

  return (
    <>
      <div className="questions-section">
        <h3 className="questions-section__title">
          {translations.step8.householdInsuranceSectionTitle}
        </h3>
        <div className="question-category" ref={householdInsurance}>
          <PreviousInsuranceQuestion
            onClick={onClickHandler}
            isGoNextClicked={isGoNextClicked}
            updateTrackingErrors={updateTrackingErrors}
          />
        </div>
        <hr className="divider" />
        <div className="question-category" ref={householdDamage}>
          <PreviousDamageQuestion
            onClick={onClickHandler}
            isGoNextClicked={isGoNextClicked}
            updateTrackingErrors={updateTrackingErrors}
          />
        </div>
      </div>

      {isProductWithGlassSelected && (
        <div className="questions-section">
          <h3 className="questions-section__title">
            {translations.step8.glassInsuranceSectionTitle}
          </h3>
          <div className="question-category" ref={glassInsurance}>
            <PreviousGlassInsuranceQuestion
              onClick={onClickHandler}
              isGoNextClicked={isGoNextClicked}
              updateTrackingErrors={updateTrackingErrors}
            />
          </div>
          <hr className="divider" />
          <div className="question-category" ref={glassDamage}>
            <PreviousGlassDamageQuestion
              onClick={onClickHandler}
              isGoNextClicked={isGoNextClicked}
              updateTrackingErrors={updateTrackingErrors}
            />
          </div>
        </div>
      )}

      <div className="questions-section">
        <h3 className="questions-section__title">
          {translations.step8.existingDoorLocksSectionTitle}
        </h3>
        <div className="question-category" ref={existingDoorLock}>
          <ExistingDoorLocksQuestion
            onClick={onClickHandler}
            isGoNextClicked={isGoNextClicked}
            updateTrackingErrors={updateTrackingErrors}
          />
        </div>
      </div>
    </>
  );
};

export default AdditionalQuestionsOptions;
