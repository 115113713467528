import { setGenericError } from '../store/appSlice';
import { store } from '../store';
import { trackError } from '../api/tracking';
import { getTrackingErrorDetails } from '../api/tracking/tracking.helpers';

export interface ParticularErrorFlags {
  error?: any;
  errorMessages?: string;
  setGenericErrorRequired?: boolean;
  throwNewErrorRequired?: boolean;
  particularThrowError?: string | any;
}

export const handleServiceError = ({
  error,
  setGenericErrorRequired = true,
  throwNewErrorRequired = true,
  particularThrowError,
}: ParticularErrorFlags): void => {
  const { errorMessages, errorType } = getTrackingErrorDetails(error);

  trackError({ errorMessages, errorType });

  if (setGenericErrorRequired) {
    store.dispatch(setGenericError(true));
  }

  if (throwNewErrorRequired) {
    throw Error(error);
  } else {
    throw particularThrowError;
  }

  /** Important: these throwNewErrorRequired, particularThrowError props are required due on OfferService for some particular cases are not ready to thrown the Error(error) and some FE functions are expecting particular values in case of fails. The same about setGenericErrorRequired, sometimes when the service fails it's not necessary */
};

export const trackFrontendError = ({
  errorMessages,
}: ParticularErrorFlags): void => {
  const errorType = 'FormFieldError';
  trackError({ errorMessages, errorType });
};
