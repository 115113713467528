import React from 'react';
import { Size } from '@eg/elements/Price';
import translations from '../../constants/translations';
import './RateHeader.scss';
import type { RateHeaderProps } from './RateHeader.d';
import Price from '../Price/Price';

const RateHeader = ({
  title,
  subtitle,
  price,
  priceSuffix = translations.step7.dataFile.premiumUnits.monthly,
}: RateHeaderProps): JSX.Element => {
  return (
    <section className="rate__wrapper">
      <div className="rate__head">
        <h6 className="rate__title" data-testid="title-rate-header">
          {title}
        </h6>
        {subtitle && (
          <span
            className="rate__subtitle"
            aria-label="banner"
            data-testid="subtitle-rate-header"
          >
            {subtitle}
          </span>
        )}
      </div>
      <Price
        value={price}
        size={Size.Medium}
        suffix={priceSuffix}
        data-testid="price-rate-header"
      />
    </section>
  );
};

export default RateHeader;
