import { hausratApi } from 'commons';

export const NODE_ENV = window.NODE_ENV || process.env.NODE_ENV || 'production';

export const CONFIG_BACKEND_API_BASE_URL =
  window.CONFIG_BACKEND_API_BASE_URL ||
  process.env.REACT_APP_BACKEND_API_BASE_URL;

/*
 hack to set a configs in the commons folder
 the logic regarding env variables should be in the frontend folder
*/
hausratApi.frontendConfig.config = {
  CONFIG_BACKEND_API_BASE_URL,
};
