import React from 'react';

import type { ProgressBarProps } from './ProgressBar.d';
import './ProgressBar.scss';

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  max = 100,
  value = 0,
  dataTestId = 'ee-progressbar',
}: ProgressBarProps) => {
  if (isNaN(max)) {
    max = 100;
  }

  if (isNaN(value) || !value) {
    value = 0;
  }
  const absValue = Math.abs(value);
  const normalizedValue = absValue > max ? max : absValue;
  return (
    <div className="progressbar-container" data-testid={`${dataTestId}`}>
      <progress max={max} value={normalizedValue}></progress>
    </div>
  );
};
