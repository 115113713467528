import React from 'react';
import ErgoAutoComplete from '@eg/elements/Autocomplete';
import './styles.scss';

type Props = {
  ['data-testid']: string;
  errors?: string[];
  label: string;
  onChange: (value: string) => void;
  options: string[];
  placeholder?: string;
  readonly?: boolean;
  value: string;
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  name?: string;
};

const Autocomplete = ({
  errors,
  label,
  onChange,
  options,
  placeholder,
  readonly = false,
  value,
  onBlur,
  onFocus,
  ...rest
}: Props): JSX.Element => {
  const isValid = !errors?.length;

  return (
    <div className={`Autocomplete ${!isValid ? 'Autocomplete--error' : ''}`}>
      <span className="Autocomplete__label Autocomplete__label--col">
        {label}
      </span>

      <div className="Autocomplete__field">
        <ErgoAutoComplete
          data-testid={rest['data-testid']}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readonly}
          suggestions={options}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          name="name"
        />

        {!isValid && (
          <div className="Autocomplete__errors">
            {errors.map(error => (
              <span key={error} className="Autocomplete__error">
                {error}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Autocomplete;
