import React from 'react';
import translations from '../../../../constants/translations';

const InsuranceDateInfo = (props: { isZurs: boolean }): JSX.Element => {
  return (
    <div data-testid="insurance-date-info">
      <h3>{translations.step5.insuranceDateInfoTitle}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.step5.insuranceDateInfoP1,
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.step5.insuranceDateInfoP2,
        }}
      ></p>
      {props.isZurs && (
        <p
          dangerouslySetInnerHTML={{
            __html: translations.step5.insuranceDateInfoP3,
          }}
        ></p>
      )}
    </div>
  );
};

export default InsuranceDateInfo;
