import React, { ChangeEvent, useEffect, useRef } from 'react';
import FormFooter from '@eg/elements/FormFooter';
import RadioGroup from '../../components/ERadioGroup/ERadioGroup';
import Radio from '../../components/ERadio/ERadio';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import PersonalDataIntroText from './components/PersonalDataIntroText/PersonalDataIntroText';
import PersonalDataInformation from './components/PersonalDataInformation/PersonalDataInformation';
import ContactInformation from './components/ContactInformation/ContactInformation';
import { dateOfBirthFormatted } from '../step6/dateOfBirthSlice';
import useTelephoneNumber from './components/hooks/useTelephoneNumber';
import useAddressConfirmation from './components/hooks/useAddressConfirmation';
import useGenderChoice from './components/hooks/useGenderChoice';
import useNameData from './components/hooks/useNameData';
import useEmailValidation from './components/hooks/useEmailValidation';
import translations from '../../constants/translations';
import './PersonalData.scss';
import { useForm } from '../../store/useForm';
import {
  personalDataFetchValidation,
  getPersonalDataValidationError,
  setValidationError,
} from './personalDataSlice';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useAppSelector } from '../../store/hooks';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import { isPersonalDataUpdated } from '../../store/offerSlice';
import useClickTracking from './hooks/useClickTracking';
import { Gender } from './PersonalDataEnums';
import { trackFrontendError } from '../../utils/errorHandler';

type Change = ChangeEvent<HTMLInputElement>;

export const PersonalData = (): JSX.Element => {
  const { dispatch, isLoadingPersonalData } = useForm();
  const isError = useAppSelector(getGenericErrorSelector);
  const form = useForm();
  const { goNext, goPrev } = useStepNavigation();
  const isFormModified = useAppSelector(isPersonalDataUpdated);
  const {
    errorMessage: emailErrorMessage,
    email,
    handleOnBlurEmailValidation,
    handleOnChangeEmail,
    setEmailErrorMessage,
    onSubmitHandleEmailValidation,
  } = useEmailValidation(form);

  const { zipCode, city, streetName, streetNumber } = useForm();
  const dateOfBirth = useAppSelector(dateOfBirthFormatted);
  const {
    handleOnChangeSurname,
    handleOnChangeFirstName,
    handleValidationFirstName,
    handleValidationSurname,
    lastNameErrorMessage,
    firstNameErrorMessage,
    setFirstNameErrorMessage,
    setLastNameErrorMessage,
    firstName,
    lastname,
  } = useNameData(form);

  const {
    handleOnChangeGender,
    genderErrorMessage,
    setGenderErrorMessage,
    gender,
  } = useGenderChoice(form);

  const {
    handleCheckboxAddress,
    addressConfirmation,
    checkBoxErrorMessage,
    setAddressConfirmationErrorMessage,
  } = useAddressConfirmation(form);
  const validatedErrorMessage = useAppSelector(getPersonalDataValidationError);
  const {
    handleChangeTelephone,
    handleChangePrefix,
    handleTelephoneValidation,
    prefixNumber,
    telephoneNumber,
    errorMessageTelephone,
    setErrorMessageTelephone,
  } = useTelephoneNumber(form);

  // Form field refs
  const genderSelect = useRef<HTMLDivElement>(null);
  const firstNameInput = useRef<HTMLDivElement>(null);
  const lastNameInput = useRef<HTMLDivElement>(null);
  const addressConfirmationCheckbox = useRef<HTMLDivElement>(null);
  const emailInput = useRef<HTMLDivElement>(null);

  const { trackingOptions, onClickTracking } = useClickTracking();

  const hasAddress = !!streetName && !!zipCode && !!streetNumber && !!city;
  const hasAllRequiredFields =
    !errorMessageTelephone &&
    !!firstName &&
    !firstNameErrorMessage &&
    !!lastname &&
    !lastNameErrorMessage &&
    !!email &&
    !emailErrorMessage &&
    !!dateOfBirth &&
    !!addressConfirmation &&
    !!gender;

  const handleContinue = (): void => {
    dispatch(setValidationError(''));
    if (isFormModified || !hasAllRequiredFields) {
      if (!email) {
        const errorMessage = translations.step9.emailAddressMissing;
        setEmailErrorMessage(errorMessage);
        trackFrontendError({ errorMessages: errorMessage });
        emailInput.current.scrollIntoView();
      }
      if (!addressConfirmation) {
        const errorMessage = translations.step9.checkBoxErrorMessage;
        setAddressConfirmationErrorMessage(errorMessage);
        trackFrontendError({ errorMessages: errorMessage });
        addressConfirmationCheckbox.current.scrollIntoView();
      }
      if (!lastname) {
        const errorMessage = translations.step9.lastNameErrorMessage;
        setLastNameErrorMessage(errorMessage);
        trackFrontendError({ errorMessages: errorMessage });
        lastNameInput.current.scrollIntoView();
      }
      if (!firstName) {
        const errorMessage = translations.step9.firstNameErrorMessage;
        setFirstNameErrorMessage(errorMessage);
        trackFrontendError({ errorMessages: errorMessage });
        firstNameInput.current.scrollIntoView();
      }
      if (!gender) {
        const errorMessage = translations.step9.genderErrorMessage;
        setGenderErrorMessage(errorMessage);
        trackFrontendError({ errorMessages: errorMessage });
        genderSelect.current.scrollIntoView();
      }
      if (!dateOfBirth) {
        trackFrontendError({
          errorMessages: translations.step9.dateOfBirthErrorMessage,
        });
      }
      if (!hasAddress) {
        trackFrontendError({
          errorMessages: translations.step9.addressErrorMessage,
        });
      }
      if (errorMessageTelephone) {
        trackFrontendError({
          errorMessages: errorMessageTelephone,
        });
      }
      if (emailErrorMessage) {
        trackFrontendError({
          errorMessages: emailErrorMessage,
        });
      }
      if (hasAllRequiredFields == true) {
        dispatch(personalDataFetchValidation())
          .unwrap()
          .then(() => goNext());
      }
    } else {
      goNext();
    }
  };

  useEffect(() => {
    if (validatedErrorMessage) {
      if (validatedErrorMessage === 'person.telephone.number.invalid') {
        setErrorMessageTelephone(translations.step9.telephoneInvalid);
      } else if (validatedErrorMessage === 'person.email.email.invalid') {
        setEmailErrorMessage(translations.step9.emailAddressInvalid);
      } else {
        setErrorMessageTelephone('');
        setEmailErrorMessage('');
      }
    }
    if (email !== '') {
      onSubmitHandleEmailValidation();
    }
  }, [validatedErrorMessage]);

  const handleBackButton = (): void => {
    goPrev();
  };

  const onOpenHeaderTooltip = (): void => onClickTracking('INFOICON_PRIVACY');
  const onOpenEmailTooltip = (): void => onClickTracking('INFOICON_EMAIL');

  const onClickCheckBoxAddress = (event: Change): void => {
    const { target } = event;
    const { value } = target;

    const active = 'CHECKBOX_POSTAL_ADDRESS_ACTIVE';
    const inactive = 'CHECKBOX_POSTAL_ADDRESS_INACTIVE';

    onClickTracking(value ? inactive : active);
    handleCheckboxAddress(event);
  };

  return (
    <FormSection className="personal-data">
      <FormSectionHeader
        className="form-section-header"
        title={translations.step9.pageTitle}
      />
      <FormSectionBody>
        <PersonalDataIntroText onOpened={onOpenHeaderTooltip} />
        <h3 className="personal-data--header" data-testid="personal-data-title">
          {translations.step9.personDataTitle}
        </h3>
        <div className="gender-container" ref={genderSelect}>
          <RadioGroup
            label={translations.step9.gender}
            name="anrede"
            error={genderErrorMessage}
          >
            <Radio
              className="gender-container--radioSalutation"
              value={Gender.MR}
              onChange={handleOnChangeGender}
              checked={gender === Gender.MR}
              label={translations.step9.radioButtonMale}
              data-testid="personal-data-salutation-male"
              id="personal-data-salutation-male"
            />
            <Radio
              value={Gender.MS}
              className="gender-container--radioSalutation"
              onChange={handleOnChangeGender}
              checked={gender === Gender.MS}
              label={translations.step9.radioButtonFemale}
              data-testid="personal-data-salutation-female"
              id="personal-data-salutation-female"
            />
          </RadioGroup>
        </div>
        <PersonalDataInformation
          firstNameInputRef={firstNameInput}
          lastNameInputRef={lastNameInput}
          addressCheckboxRef={addressConfirmationCheckbox}
          handleOnChangeFirstName={handleOnChangeFirstName}
          handleOnChangeSurname={handleOnChangeSurname}
          handleValidationFirstName={handleValidationFirstName}
          handleValidationSurname={handleValidationSurname}
          firstNameErrorMessage={firstNameErrorMessage}
          lastNameErrorMessage={lastNameErrorMessage}
          firstName={firstName}
          lastname={lastname}
          handleCheckboxAddress={onClickCheckBoxAddress}
          checkBoxErrorMessage={checkBoxErrorMessage}
          addressConfirmation={addressConfirmation}
          zipCode={zipCode}
          streetName={streetName}
          streetNumber={streetNumber}
          city={city}
          dateOfBirth={dateOfBirth}
          hasAddress={hasAddress}
        />
        <ContactInformation
          handleOnChangeEmail={handleOnChangeEmail}
          handleOnBlurEmailValidation={handleOnBlurEmailValidation}
          handleChangeTelephone={handleChangeTelephone}
          handleTelephoneValidation={handleTelephoneValidation}
          handleChangePrefix={handleChangePrefix}
          errorMessage={emailErrorMessage}
          errorMessageTelephone={errorMessageTelephone}
          prefixNumber={prefixNumber}
          telephoneNumber={telephoneNumber}
          email={email}
          emailInputRef={emailInput}
          onOpened={onOpenEmailTooltip}
        />
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBackButton}
          disableContinue={false}
          isLoading={isLoadingPersonalData}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default PersonalData;
