export const URLs = {
  livingPlaceType: '/living-place-type',
  birthDate: '/birth-date',
  customerAddress: '/customer-address',
  zipCode: '/customer-address/zip-code',
  additionalQuestions: '/additional-questions',
  livingPlace: '/',
  homeLocation: '/home-location',
  homeSquareMeters: '/home-square-meters',
  address: '/address',
  insuranceStartDate: '/insurance-start-date',
  insuranceCoverage: '/insurance-coverage',
  feedback: '/feedback',
  bankDetails: '/bank-details',
  personalData: '/personal-data',
  termsOfService: '/tos',
  contractReview: '/contract-review',
} as const;
