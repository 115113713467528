import { Bankdatum } from 'commons/apis/spcs/persons/types';
import { RootState } from '../../types';
import { getPersonSelector } from './generics';
import { normalizeIbanValue } from 'commons';

export const getBankDetailsFromPerson = (
  state: RootState
): Bankdatum | undefined => {
  const { payments } = getPersonSelector(state) ?? {};
  const [bankData] = payments?.bankdata ?? [];
  return bankData;
};

export const isBankDetailsUpdated = (state: RootState): boolean => {
  const { payments } = getPersonSelector(state) ?? {};
  const [bankData] = payments?.bankdata ?? [];
  return bankData?.iban !== normalizeIbanValue(state.bankDetails.form.iban);
};
