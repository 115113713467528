import React, { useEffect } from 'react';
import FormFooter from '@eg/elements/FormFooter';
import '@eg/elements/styles/ergo-one/styles.css';
import { useForm } from '../../store/useForm';
import FormSection from '../../components/FormSection/FormSection';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import InsuranceDateOptions from './components/InsuranceDateOptions/InsuranceDateOptions';
import { TypesOfDate } from './InsuranceDateTypes';
import './InsuranceDate.scss';
import { typesOfDates as typesOfDatesOptions } from './components/InsuranceDateOptions/helpers/constants';
import {
  insuranceDateFetchValidation,
  setTypeOfDate,
} from './insuranceDateSlice';
import InsuranceDateInfo from './components/InsuranceDateInfo/InsuranceDateInfo';
import translations from '../../constants/translations';
import { useAppSelector } from '../../store/hooks';
import { isZursZoneSelector } from '../../store/offerSlice';
import useStepNavigation from '../../hooks/useStepNavigation';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import useClickTracking from './hooks/useClickTracking';
import { isInsuranceStartDateUpdated } from '../../store/offerSlice';

const InsuranceDate = (): JSX.Element => {
  const isError = useAppSelector(getGenericErrorSelector);
  const isFormModified = useAppSelector(isInsuranceStartDateUpdated);
  const { goNext, goPrev } = useStepNavigation();
  const {
    typeOfDate,
    isInsuranceDateStepValid,
    dispatch,
    isLoadingInsuranceDate,
    insuranceStartDate,
  } = useForm();
  const { trackingOptions, onRButtonsTracking, onModalTracking } =
    useClickTracking();

  useEffect(() => {
    if (!typeOfDate) {
      dispatch(setTypeOfDate(typesOfDatesOptions.tomorrow.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfDate]);

  const handleContinue = (): void => {
    if (isFormModified) {
      dispatch(insuranceDateFetchValidation())
        .unwrap()
        .then(() => goNext());
    } else {
      goNext();
    }
  };

  const handleBack = (): void => {
    goPrev();
  };

  const handleOptionSelected = (typeOfDateSelected: TypesOfDate): void => {
    if (typeOfDateSelected === typeOfDate) {
      return;
    }
    onRButtonsTracking(typeOfDateSelected);
    dispatch(setTypeOfDate(typeOfDateSelected));
  };

  const isZurs = useAppSelector(isZursZoneSelector);

  const disableContinue = (): boolean => {
    if (
      !typeOfDate ||
      insuranceStartDate.day === undefined ||
      insuranceStartDate.month === undefined ||
      insuranceStartDate.year === undefined
    ) {
      return true;
    }

    return !isInsuranceDateStepValid;
  };

  return (
    <FormSection className="insurance-date">
      <FormSectionHeader
        title={translations.step5.pageTitle}
        showTooltip
        toolTipComponent={<InsuranceDateInfo isZurs={isZurs} />}
        onOpened={onModalTracking}
      />
      <FormSectionBody>
        <InsuranceDateOptions
          typeOfDateSelected={typeOfDate}
          onSelectOption={handleOptionSelected}
        />
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBack}
          extraComponent={null}
          disableContinue={disableContinue()}
          isLoading={isLoadingInsuranceDate}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default InsuranceDate;
