import { trackClickEvent } from '../../../api/tracking';

import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { TrackingElementPage6 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onModalTracking: () => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onModalTracking = (): void => {
    const element = TrackingElementPage6.INFOICON;
    trackClickEvent(element, trackingOptions);
  };

  return {
    trackingOptions,
    onModalTracking,
  };
};

export default useClickTracking;
