export const step5 = {
  pageTitle: 'Wann soll Ihre Versicherung beginnen?',
  errorMessageBadInput: 'Bitte geben Sie ein gültiges Datum ein.',
  errorMessageValueMissing:
    'Bitte geben Sie das vollständige Datum ein (Format TT MM JJJJ).',
  errorMessageRangeUnderflow:
    'Der Versicherungsbeginn kann nicht in der Vergangenheit liegen. Und höchstens 364 Tage in der Zukunft.',
  errorMessageRangeOverflow:
    'Der Versicherungsbeginn kann höchstens 364 Tage in der Zukunft liegen.',
  insuranceDateInputLabel: 'Ihr gewünschter Versicherungsbeginn',
  insuranceDateInfoTitle: 'Versicherungsbeginn',
  insuranceDateInfoP1:
    'Sie können die Versicherung <strong>höchstens ein Jahr im Voraus</strong> abschließen.',
  insuranceDateInfoP2:
    'Der Versicherungsschutz beginnt <strong>am vereinbarten Datum um 0 Uhr</strong>, wenn Sie den Versicherungsschein erhalten und den ersten Beitrag rechtzeitig gezahlt haben.',
  insuranceDateInfoP3:
    '<strong>Weitere Naturgefahren</strong>: Der Versicherungsschutz dagegen beginnt frühestens <strong>einen Monat nach der Antragsstellung</strong>. Wurde ein späterer Versicherungsbeginn vereinbart, gilt dieser. Diese Regelung entfällt, wenn weitere Naturgefahren bisher über einen anderen Vertrag versichert waren, an den dieser direkt anschließt.',
  constantTomorrowLabel: 'Morgen',
  constantTomorrowSubtitle: 'Dienstag',
  constantNextMonthLabel: 'Nächsten Monat',
  constantNextMonthSubtitle: 'Sonntag',
  constantAnotherDateLabel: 'Anderes Datum',
  constantAnotherDateSubtitle: 'Wählen Sie Ihren Wunschtermin',
};
