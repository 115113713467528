import { URLs } from 'commons';
import { retrieveTrackingData } from '../../storage/storage';
import aemIntegration from '../aemIntegration';
import {
  isConversionTypedPage,
  pushTrackingData,
  removeMiscellaneous,
  removeEventDetails,
  setCalculatedValue,
  setTariffOptions,
  setTotalTransaction,
  updateConversionType,
  UpdateConversionTypeOptions,
  updateGender,
  updateInsuranceStart,
  updateAndHashUserAddress,
  updateUserBirthday,
  updateAndHashProfileInfo,
} from './tracking.helpers';
import {
  PageLoadedContext,
  TrackingContent,
  TrackingEvents,
  TrackingGender,
} from './TrackingTypes';

export const trackPageLoad = async (
  location: string,
  context?: PageLoadedContext
): Promise<void> => {
  const currentState = retrieveTrackingData();

  if (!currentState) {
    return;
  }

  let trackingObject: TrackingContent = { ...currentState };

  // Event
  trackingObject.event = TrackingEvents.PAGE_LOAD;

  removeMiscellaneous(trackingObject);
  removeEventDetails(trackingObject);

  const {
    birthday,
    calculatedValue,
    gender,
    insuranceStart,
    tariffOptions1,
    zipCode,
    isAddressCorrected,
    isAddressValidationFailed,
    streetName,
    streetNumber,
    city,
    email,
    firstName,
    lastname,
    prefixNumber,
    telephoneNumber,
  } = context;

  // page.pageInfo.pageName
  const { shortName } = aemIntegration.getTrackingConstants();
  const pageName = aemIntegration.getTrackingPageName(
    location,
    isAddressValidationFailed
  );
  trackingObject.page.pageInfo.pageName = `${shortName}:${pageName}`;

  // product[n].attributes.tariffOptions1
  if (tariffOptions1) {
    trackingObject = setTariffOptions(trackingObject, tariffOptions1);
  }

  // transaction.attributes.calculatedValue
  if (calculatedValue) {
    trackingObject = setCalculatedValue(trackingObject, calculatedValue);
  }

  if (zipCode) {
    trackingObject = updateAndHashUserAddress(trackingObject, {
      zipCode,
      streetName,
      streetNumber,
      city,
    });
  }

  if (email || firstName || lastname || prefixNumber || telephoneNumber) {
    trackingObject = updateAndHashProfileInfo(trackingObject, {
      email,
      firstName,
      lastname,
      prefixNumber,
      telephoneNumber,
    });
  }

  if (insuranceStart) {
    trackingObject = updateInsuranceStart(trackingObject, insuranceStart);
  }

  if (birthday) {
    trackingObject = updateUserBirthday(trackingObject, birthday);
  }

  if (gender) {
    trackingObject = updateGender(trackingObject, gender as TrackingGender);
  }

  // conversionType exceptions
  const gotConversionType = isConversionTypedPage(location);
  const conversionTypeOptions: UpdateConversionTypeOptions = {
    gotConversionType,
    location,
    address: { isAddressCorrected, isAddressValidationFailed },
  };
  trackingObject = updateConversionType(trackingObject, conversionTypeOptions);

  if (location === URLs.feedback && calculatedValue) {
    trackingObject = setTotalTransaction(trackingObject, calculatedValue);
  }

  if (location === URLs.feedback && calculatedValue) {
    trackingObject = setTotalTransaction(trackingObject, calculatedValue);
  }

  pushTrackingData(trackingObject);
};
