import React, { useCallback } from 'react';
import FormFooter from '@eg/elements/FormFooter';
import '@eg/elements/styles/ergo-one/styles.css';
import './HomeLocation.scss';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useForm } from '../../store/useForm';
import FormSection from '../../components/FormSection/FormSection';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import HomeLocationOptions from './components/HomeLocationOptions/HomeLocationOptions';
import type { FloorType } from 'commons';
import {
  homeLocationFetchValidation,
  setTypeOfFloor,
  selectIsLoading,
} from './homeLocationSlice';
import translations from '../../constants/translations';
import { useAppSelector } from '../../store/hooks';
import { getGenericErrorSelector } from '../../store/appSlice';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import useClickTracking from './hooks/useClickTracking';

import { isHomeLocationUpdated } from '../../store/offerSlice';

const HomeLocation = (): JSX.Element => {
  const { typeOfFloor, dispatch } = useForm();
  const isLoading = useAppSelector(selectIsLoading);
  const { goNext, goPrev } = useStepNavigation();
  const isError = useAppSelector(getGenericErrorSelector);
  const { trackingOptions, onRButtonsTracking } = useClickTracking();
  const isFormModified = useAppSelector(isHomeLocationUpdated);
  const handleContinue = useCallback(() => {
    if (isFormModified) {
      dispatch(homeLocationFetchValidation())
        .unwrap()
        .then(() => goNext());
    } else {
      goNext();
    }
  }, [dispatch, isFormModified]);

  const handleBack = (): void => {
    goPrev();
  };

  const handleClick = (typeOfFloorValue: FloorType): void => {
    dispatch(setTypeOfFloor(typeOfFloorValue));
    onRButtonsTracking(typeOfFloorValue);
  };

  return (
    <FormSection className="home-location">
      <FormSectionHeader title={translations.step2.pageTitle} />
      <FormSectionBody>
        <HomeLocationOptions checked={typeOfFloor} onClick={handleClick} />
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBack}
          extraComponent={null}
          disableContinue={!typeOfFloor}
          isLoading={isLoading}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default HomeLocation;
