import React from 'react';
import translations from '../../../constants/translations';

const AdditionalQuestionsInfo = (): JSX.Element => {
  return (
    <div data-testid="additional-questions-info">
      <h3 data-testid="additional-questions-info-title">
        {translations.step8.modalTitle}
      </h3>
      <p
        data-testid="additional-questions-info-p1"
        dangerouslySetInnerHTML={{
          __html: translations.step8.subInfoTitle1,
        }}
      />
      <p
        data-testid="additional-questions-info-p2"
        dangerouslySetInnerHTML={{
          __html: translations.step8.subInfoTitle2,
        }}
      />
      <p
        data-testid="additional-questions-info-p3"
        dangerouslySetInnerHTML={{
          __html: translations.step8.subInfoTitle3,
        }}
      />
      <p
        data-testid="additional-questions-info-p4"
        dangerouslySetInnerHTML={{
          __html: translations.step8.subInfoTitle4,
        }}
      />
    </div>
  );
};

export default AdditionalQuestionsInfo;
