import React from 'react';
import Button from '@eg/elements/Button';
import { trackClickEvent } from '../../api/tracking';
import { EButtonProps } from './EButton.d';

/** ERGO elements' button wrapped with tracking */
const EButton = ({
  trackingOptions,
  onClick,
  children,
  className,
  colorScheme,
  'data-testid': dataTestId,
  disabled,
  iconRight,
  loading,
  name,
  size,
  variant,
  iconLeft,
}: React.PropsWithChildren<EButtonProps>): JSX.Element => {
  const trackedOnClick = (): void => {
    const { element, options } = trackingOptions;
    trackClickEvent(element, options);
    if (onClick) {
      return onClick();
    }
  };

  return (
    <Button
      className={className}
      colorScheme={colorScheme}
      data-testid={dataTestId}
      disabled={disabled}
      iconRight={iconRight}
      loading={loading}
      name={name}
      onClick={trackingOptions ? trackedOnClick : onClick}
      size={size}
      variant={variant}
    >
      {iconLeft} {children}
    </Button>
  );
};

export default EButton;
