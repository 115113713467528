import { PaymentMethods } from 'commons/apis/offerEngine';
import { useEffect, useRef } from 'react';
import { trackClickEvent } from '../../../api/tracking';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import {
  getExcess,
  getPaymentMethod,
  transformPaymentMethodsToPaymentMethodTYpe,
} from '../../../api/tracking/tracking.data.helpers';
import {
  getTrackingBooleanValue,
  updateTariffOptions,
} from '../../../api/tracking/tracking.helpers';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import {
  TrackingElementPage7 as Clickable,
  TrackingIDs,
  trackingMapPage7,
} from '../TrackingElements';
import { InfoIcons } from '../TrackingTypes';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onClickTracking: (
    id: string,
    selected: string | boolean,
    value: string | number
  ) => void;
  onClickInfoIcon: (key: InfoIcons) => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();
  const {
    productAttrs: { tariffOptions: tariffs, insuranceStart },
    transaction: { calculatedValue },
  } = trackingOptions;

  const { transaction } = trackingOptions;
  const prevCountRef = useRef() as any;
  const prevTarrifRef = useRef() as any;
  useEffect(() => {
    prevCountRef.current = calculatedValue;
    prevTarrifRef.current = tariffs;
  }, [calculatedValue]);

  const onClickTracking = (
    id: string,
    selected: string | boolean,
    value: string | number
  ): void => {
    let element: Clickable;
    let firstOption: Clickable;
    let secondOption: Clickable;
    let trackingValue: string | number;
    let tariffOptions: string = prevTarrifRef.current;
    const key = trackingMapPage7.get(id as TrackingIDs);
    if (!key) {
      return;
    }
    switch (id) {
      case 'Product Selector':
        firstOption = Clickable.RBUTTON_BEST;
        secondOption = Clickable.RBUTTON_SMART;
        trackingValue = value;
        element = value === 'Best' ? firstOption : secondOption;
        if (value !== 'Best') {
          tariffOptions = updateTariffOptions(tariffOptions, 'UnbG', 'NA');
        } else {
          tariffOptions = updateTariffOptions(tariffOptions, 'EDieb', 'J');
        }
        tariffOptions = updateTariffOptions(tariffOptions, key, value);
        break;
      case 'simpleTheft':
        firstOption = Clickable.CHECKBOX_SIMPLE_THEFT_ACTIVE;
        secondOption = Clickable.CHECKBOX_SIMPLE_THEFT_INACTIVE;
        trackingValue = getTrackingBooleanValue(selected as boolean);

        element = selected ? firstOption : secondOption;
        tariffOptions = updateTariffOptions(tariffOptions, key, trackingValue);

        break;
      case 'houseAndFlat':
        firstOption = Clickable.CHECKBOX_HOUSE_AND_FLAT_ACTIVE;
        secondOption = Clickable.CHECKBOX_HOUSE_AND_FLAT_INACTIVE;
        trackingValue = getTrackingBooleanValue(selected as boolean);

        element = selected ? firstOption : secondOption;
        tariffOptions = updateTariffOptions(tariffOptions, key, trackingValue);

        break;
      case 'otherNaturalHazards':
        firstOption = Clickable.CHECKBOX_NATURAL_HAZARDS_ACTIVE;
        secondOption = Clickable.CHECKBOX_NATURAL_HAZARDS_INACTIVE;
        trackingValue = getTrackingBooleanValue(selected as boolean);
        element = selected ? firstOption : secondOption;
        tariffOptions = updateTariffOptions(tariffOptions, key, trackingValue);
        if (selected && !tariffOptions.includes('Smart')) {
          tariffOptions = updateTariffOptions(tariffOptions, 'UnbG', 'N');
        } else {
          tariffOptions = updateTariffOptions(tariffOptions, 'UnbG', 'NA');
        }
        break;
      case 'unnamedPerils':
        firstOption = Clickable.CHECKBOX_UNNAMED_DANGER_ACTIVE;
        secondOption = Clickable.CHECKBOX_UNNAMED_DANGER_INACTIVE;
        trackingValue = getTrackingBooleanValue(selected as boolean);
        element = selected ? firstOption : secondOption;
        tariffOptions = updateTariffOptions(tariffOptions, key, trackingValue);

        break;
      case 'glass':
        firstOption = Clickable.CHECKBOX_GLASS_ACTIVE;
        secondOption = Clickable.CHECKBOX_GLASS_INACTIVE;
        trackingValue = getTrackingBooleanValue(selected as boolean);
        element = selected ? firstOption : secondOption;
        tariffOptions = updateTariffOptions(tariffOptions, key, trackingValue);
        break;

      case 'bike-module':
        firstOption = Clickable.CHECKBOX_BICYCLE_ACTIVE;
        secondOption = Clickable.CHECKBOX_BICYCLE_INACTIVE;
        // Updating insured amount
        trackingValue = selected ? value : 'Ohne';
        element = selected ? firstOption : secondOption;
        tariffOptions = updateTariffOptions(tariffOptions, key, trackingValue);
        // Updating coinsurance type
        tariffOptions = updateTariffOptions(
          tariffOptions,
          trackingMapPage7.get(TrackingIDs.fahrradkaskoUndMobilitätsschutz),
          'NA'
        );
        break;
      case 'fahrradkaskoUndMobilitätsschutz':
        element = selected
          ? Clickable.RBUTTON_BICYCLE_INSURANCE
          : Clickable.DROPDOWN_BICYCLE_COINSURANCE;
        tariffOptions = selected
          ? updateTariffOptions(tariffOptions, key, 'FaKa')
          : updateTariffOptions(tariffOptions, key, 'NA');
        tariffOptions = updateTariffOptions(
          tariffOptions,
          trackingMapPage7.get(TrackingIDs.bikeModule),
          value
        );
        break;
      case 'fahrraddiebstahl':
        element = Clickable.RBUTTON_BICYCLE_THEFT;
        tariffOptions = selected
          ? updateTariffOptions(tariffOptions, key, 'FaDie')
          : updateTariffOptions(tariffOptions, key, 'NA');
        tariffOptions = updateTariffOptions(
          tariffOptions,
          trackingMapPage7.get(TrackingIDs.bikeModule),
          value
        );

        break;
      case 'bike-amount':
        element = Clickable.DROPDOWN_BICYCLE_COINSURANCE;
        tariffOptions = updateTariffOptions(
          tariffOptions,
          trackingMapPage7.get(TrackingIDs.bikeModule),
          value
        );
        break;

      case 'deductible-amount':
        element = Clickable.DROPDOWN_EXCESS;
        const tariffOption = getExcess(value as string);
        tariffOptions = updateTariffOptions(tariffOptions, key, tariffOption);

        break;
      case 'payment-method':
        element = Clickable.DROPDOWN_PAYMENT_METHOD;
        const method = transformPaymentMethodsToPaymentMethodTYpe(
          selected as PaymentMethods
        );
        const paymentMethod = getPaymentMethod(method);
        tariffOptions = updateTariffOptions(tariffOptions, key, paymentMethod);
        break;
      case 'contract-term':
        element = Clickable.DROPDOWN_CONTRACT_TERM;
        const contractTerm = `${selected}J`;
        tariffOptions = updateTariffOptions(tariffOptions, key, contractTerm);
        break;
    }

    if (id === 'bike-module' || id === 'bike-amount') {
      trackClickEvent(element, {
        ...trackingOptions,
        productAttrs: { tariffOptions, insuranceStart },
      });
    } else if (prevCountRef.current !== calculatedValue) {
      transaction.calculatedValue = prevCountRef.current;
      trackClickEvent(element, {
        ...trackingOptions,
        transaction: {
          ...transaction,
        },
        productAttrs: { tariffOptions, insuranceStart },
      });
    }
  };

  const onClickInfoIcon = (key: InfoIcons): void => {
    let element: Clickable = Clickable.INFOICON_USP_SUM_INSURANCE;

    switch (key) {
      case InfoIcons.SUM_INSURANCE:
        element = Clickable.INFOICON_USP_SUM_INSURANCE;
        break;
      case InfoIcons.GROSS_NEGLIGENCE:
        element = Clickable.INFOICON_USP_GROSS_NEGLIGENCE;
        break;
      case InfoIcons.SIMPLE_THEFT_INCLUDED:
        element = Clickable.INFOICON_USP_SIMPLE_THEFT;
        break;
      case InfoIcons.LINK_ALL_SERVICES:
        element = Clickable.LINK_ALL_SERVICES;
        break;
      case InfoIcons.SIMPLE_THEFT:
        element = Clickable.INFOICON_SIMPLE_THEFT;
        break;
      case InfoIcons.HOUSE_AND_FLAT:
        element = Clickable.INFOICON_HOUSE_AND_FLAT;
        break;
      case InfoIcons.NATURAL_HAZARDS:
        element = Clickable.INFOICON_NATURAL_HAZARDS;
        break;
      case InfoIcons.UNNAMED_DANGER:
        element = Clickable.INFOICON_UNNAMED_DANGER;
        break;
      case InfoIcons.GLASS:
        element = Clickable.INFOICON_GLASS;
        break;
      case InfoIcons.BICYCLE:
        element = Clickable.INFOICON_BICYCLE;
        break;
      case InfoIcons.BICYCLE_INSURANCE_TYPE:
        element = Clickable.INFOICON_BICYCLE_INSURANCE_TYPE;
        break;
      case InfoIcons.EXCESS:
        element = Clickable.INFOICON_EXCESS;
        break;
      case InfoIcons.PAYMENT_METHOD:
        element = Clickable.INFOICON_PAYMENT_METHOD;
        break;
      case InfoIcons.CONTRACT_TERM:
        element = Clickable.INFOICON_CONTRACT_TERM;
        break;
    }

    trackClickEvent(element, { ...trackingOptions });
  };

  return {
    trackingOptions,
    onClickTracking,
    onClickInfoIcon,
  };
};

export default useClickTracking;
