import { LivingPlace } from 'commons';

import { trackClickEvent } from '../../../api/tracking';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { updateTariffOptions } from '../../../api/tracking/tracking.helpers';
import {
  TrackingClickOptions,
  TrackingLivingType,
} from '../../../api/tracking/TrackingTypes';
import { TrackingElementPage1 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onRButtonsTracking: (value: LivingPlace) => void;
  onModalLinkTracking: () => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();
  const {
    productAttrs: { tariffOptions },
  } = trackingOptions;

  const onRadioButtonsClick = (eventValue: LivingPlace): void => {
    const divisor = '=';
    const { 0: key } = TrackingLivingType.APARTMENT.split(divisor);
    let element: TrackingElementPage1;
    let value: string;
    let updatedTariffs = tariffOptions;

    switch (eventValue) {
      case 'apartment':
        value = TrackingLivingType.APARTMENT.split(divisor)[1];
        element = TrackingElementPage1.RBUTTON_APARTMENT;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
      case 'singleFamilyHouse':
        value = TrackingLivingType.SINGLE.split(divisor)[1];
        element = TrackingElementPage1.RBUTTON_SINGLE;
        updatedTariffs = updateTariffOptions(tariffOptions, key, value);
        break;
    }

    trackClickEvent(element, {
      productAttrs: { tariffOptions: updatedTariffs },
    });
  };

  const onModalClick = (): void => {
    trackClickEvent(TrackingElementPage1.LINK, trackingOptions);
  };

  return {
    trackingOptions,
    onRButtonsTracking: onRadioButtonsClick,
    onModalLinkTracking: onModalClick,
  };
};

export default useClickTracking;
