import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FloorType } from 'commons';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import { floorType } from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import { RootState } from '../../store/types';
import { saveOffer, savePerson } from '../../store/offerSlice';
import type { HomeLocationState } from './HomeLocationTypes';

const initialState: HomeLocationState = {
  isValidated: false,
  isLoading: false,
  form: {
    typeOfFloor: undefined,
  },
};

export const homeLocationFetchValidation = createAsyncThunk(
  'homeLocation/validation',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const response = await floorType(
        state.app.businessId,
        state.homeLocation.form.typeOfFloor
      );

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      dispatch(setStepValidated(2));
    } catch (error) {
      console.error('error', error);
      throw Error(error);
    }
  }
);

export const homeLocationSlice = createSlice({
  name: 'homeLocation',
  initialState,
  reducers: {
    setTypeOfFloor: (
      state: HomeLocationState,
      action: PayloadAction<FloorType>
    ) => {
      state.form.typeOfFloor = action.payload;
    },
    setValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(homeLocationFetchValidation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(homeLocationFetchValidation.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(homeLocationFetchValidation.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
    });
  },
});

export const { setTypeOfFloor, setValidated: setFloorTypeValid } =
  homeLocationSlice.actions;

export const selectTypeOfFloor = (state: RootState): FloorType =>
  state.homeLocation.form.typeOfFloor;

export const selectIsLoading = (state: RootState): boolean =>
  state.homeLocation.isLoading;

export default homeLocationSlice.reducer;
