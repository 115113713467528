import React from 'react';
import translations from '../../../../../constants/translations';

export const ExistingDoorLocksQuestionInfo = (): JSX.Element => {
  return (
    <div>
      <h3>{translations.step8.existingDoorLocksQuestionInfo.title}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.step8.existingDoorLocksQuestionInfo.paragraph1,
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.step8.existingDoorLocksQuestionInfo.paragraph2,
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.step8.existingDoorLocksQuestionInfo.paragraph3,
        }}
      ></p>
      <p>{translations.step8.existingDoorLocksQuestionInfo.paragraph4}</p>
    </div>
  );
};
