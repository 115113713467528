import './AccountOwnerName.scss';
import React from 'react';
import translations from '../../../../constants/translations';

type Props = {
  firstName: string;
  lastName: string;
};

const AccountOwnerName = ({ firstName, lastName }: Props): JSX.Element => {
  const nameNotProvided = firstName.trim() === '' || lastName.trim() === '';
  const nameClass = nameNotProvided
    ? 'account-owner__name--error'
    : 'account-owner__name';
  return (
    <div className="account-owner">
      <p className="account-owner__description">
        {translations.step11.accountOwnerDescription}
      </p>
      <span className={nameClass}>
        {firstName.trim() === '' || lastName.trim() === ''
          ? translations.step11.noNameProvided
          : `${firstName} ${lastName}`}
      </span>
    </div>
  );
};

export default AccountOwnerName;
