import React, { useEffect, useState } from 'react';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useSteps } from '../../hooks/useSteps';
import { useForm } from '../../store/useForm';
import {
  updateLivingPlace,
  setTypeOfHouse,
  selectIsLoading,
} from './livingPlaceSlice';
import { setTypeOfFloor } from '../step2/homeLocationSlice';
import { LivingPlace as LivingPlaceType, NumberPages } from 'commons';
import FormFooter from '@eg/elements/FormFooter';
import FormSection from '../../components/FormSection/FormSection';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import LivingPlaceOptions from './components/LivingPlaceOptions/LivingPlaceOptions';
import ContactModal from './components/ContactModal/ContactModal';
import FooterInformation from './components/FooterInformation/FooterInformation';
import './LivingPlace.scss';
import translations from '../../constants/translations';
import { useAppSelector } from '../../store/hooks';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import useClickTracking from './hooks/useClickTracking';
import { isLivingPlaceUpdated } from '../../store/offerSlice';

export const LivingPlace = (): JSX.Element => {
  const { currentStep } = useSteps();
  const { goNext } = useStepNavigation();
  const [isModalOpen, setModalOpen] = useState(() => false);
  const { typeOfHouse, dispatch } = useForm();
  const isLoading = useAppSelector(selectIsLoading);
  const { trackingOptions, onRButtonsTracking, onModalLinkTracking } =
    useClickTracking();
  const modifiedForm = useAppSelector(isLivingPlaceUpdated);
  const isError = useAppSelector(getGenericErrorSelector);

  useEffect(() => {
    if (currentStep !== 1) {
      goNext(NumberPages.LIVING_PLACE);
    }
  }, []);

  const handleClick = (value: LivingPlaceType): void => {
    if (value === 'singleFamilyHouse') {
      dispatch(setTypeOfFloor(null));
    }

    dispatch(setTypeOfHouse(value));
    onRButtonsTracking(value);
  };

  const navigate = (): void => {
    if (typeOfHouse === 'singleFamilyHouse') {
      dispatch(setTypeOfFloor(null));
      goNext(NumberPages.HOME_SQUARE_METERS);
    } else {
      goNext();
    }
  };

  const handleContinue = (): void => {
    if (modifiedForm) {
      dispatch(updateLivingPlace())
        .unwrap()
        .then(() => {
          navigate();
        });
    } else {
      navigate();
    }
  };

  const handleModal = (): void => {
    setModalOpen(!isModalOpen);
  };

  const handleFooterLink = (): void => {
    setModalOpen(true);
    onModalLinkTracking();
  };

  return (
    <FormSection className="form-section--living-situation">
      <FormSectionHeader
        title={translations.step1.pageTitle}
        subtitle={translations.step1.pageSubTitle}
      />
      <FormSectionBody>
        <LivingPlaceOptions checked={typeOfHouse} onClick={handleClick} />
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          onClickContinue={handleContinue}
          extraComponent={<FooterInformation onClick={handleFooterLink} />}
          disableContinue={!typeOfHouse}
          isLoading={isLoading}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ContactModal open={isModalOpen} onClose={handleModal} />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};
