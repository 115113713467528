import './AddressMessage.scss';
import React from 'react';
import translations from '../../../../constants/translations';

const AddressMessage = (): JSX.Element => {
  return (
    <div data-testid="unknown-address-message" className="address-message">
      <span>
        <b>{translations.step4.addressCorrectedHint}:</b>{' '}
        {translations.step4.addressCorrected}
      </span>
    </div>
  );
};

export default AddressMessage;
