import { ServerRoute } from '../../../types';

export const createOffer: ServerRoute = {
  method: 'POST',
  path: '/offer',
};

// Screen 1
export const livingPlace: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/livingPlaceType`,
};

// Screen 2
export const floorType: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/floorType`,
};

// Screen 3
export const squareMeters: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/squareMeters`,
};

// Screen 4
export const customerAddress: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/customerAddress`,
};

export const getCitiesFromAddress: ServerRoute = {
  method: 'POST',
  path: `${customerAddress.path}/address`,
};

// Screen 5
export const insuranceStartDate: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/insuranceStartDate`,
};

// Screen 6
export const birthDate: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/birthDate`,
};

//Screen 7
export const insuranceCoverage: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/insuranceCoverage`,
};

export const insuranceGlasField: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/insuranceCoverage/glas`,
};

// Screen 8
export const additionalQuestions: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/additionalQuestions`,
};

export const previousInsuranceCompanies: ServerRoute = {
  method: 'POST',
  path: `${createOffer.path}/previousInsuranceCompanies`,
};

//Screen 9
export const personalData: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/personalData`,
};

// Screen 11
export const bankDetails: ServerRoute = {
  method: 'POST',
  path: `${createOffer.path}/bankDetails`,
};

export const getBankDataFromIban: ServerRoute = {
  method: 'POST',
  path: `${bankDetails.path}/:businessId/person/:personId/bankdata`,
};

//Screen 12
export const submitOffer: ServerRoute = {
  method: 'POST',
  path: '/submit/:businessId',
};

//Screen 12
export const downloadOffer: ServerRoute = {
  method: 'GET',
  path: '/download/:businessId',
};

//Screen 12
export const acceptConditions: ServerRoute = {
  method: 'PUT',
  path: `${createOffer.path}/accept`,
};
//Screen 12
export const validateOffer: ServerRoute = {
  method: 'GET',
  path: `${createOffer.path}/validate/:businessId`,
};

type Config = {
  CONFIG_BACKEND_API_BASE_URL: string;
};

class FrontendConfig {
  public config: Config = {} as Config;
}

export const frontendConfig = new FrontendConfig();

export const getUrl = (path: string): string =>
  `${frontendConfig.config?.CONFIG_BACKEND_API_BASE_URL}${path}`;
