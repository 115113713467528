import {
  BicycleAndEbikeCoInsuredCoverType,
  BicycleAndEbikeCoInsuredType,
  CoverageModuleType,
  CoverageProductVariantType,
  DeductibleAmountType,
  PaymentMethodType,
} from '../../../pages/step7/InsuranceCoverageTypes';
import {
  getPropFromAngebot,
  paymentMethodMapping,
  productModuleMapping,
} from '../../../pages/step7/utils';
import { RootState } from '../../types';
import {
  getOfferSelector,
  getSelectedVariantFromOffer,
  getTariffsFromOffer,
} from './generics';
import { commonOffer } from 'commons';
import { formatDecimals } from '../../../utils/index';

type InsuranceCoverageFromOffer = {
  coverageProductVariant?: CoverageProductVariantType;
  productModules?: CoverageModuleType[];
  paymentMethod?: PaymentMethodType;
  deductibleAmount?: DeductibleAmountType;
  contractPeriod: string;
  bicycleAndEbikeCoInsuredAmount?: BicycleAndEbikeCoInsuredType;
  bicycleAndEbikeCoInsuredCover?: BicycleAndEbikeCoInsuredCoverType;
};
export const getInsuranceCoverageFromOffer = (
  state: RootState
): InsuranceCoverageFromOffer => {
  const selectedVariant = getSelectedVariantFromOffer(state);
  const coverageProductVariant =
    selectedVariant?.versicherungsId?.toLowerCase() as
      | CoverageProductVariantType
      | undefined;

  const tariffs = getTariffsFromOffer(coverageProductVariant)(state);

  const availableModules = [
    'simpleTheft',
    'houseAndFlat',
    'glass',
    'otherNaturalHazards',
    'unnamedPerils',
    'bicycleAndEbike',
  ];
  const rawProductModules = tariffs.filter(
    tariff =>
      availableModules.some(
        m => getPropFromAngebot(m) === tariff.leistungsvereinbarungsId
      ) && tariff.vereinbart === true
  );
  const productModules = rawProductModules
    .map((tariff): CoverageModuleType => {
      const [productModule] = (Object.entries(productModuleMapping).find(
        ([, value]) => value === tariff.leistungsvereinbarungsId
      ) ?? []) as [CoverageModuleType, string];
      return productModule;
    })
    // Filter any undefined that could be present
    .filter(pm => !!pm);

  const rawPaymentMethod = selectedVariant?.zahlweise;
  const [paymentMethod] = (Object.entries(paymentMethodMapping).find(
    ([, value]) => value === rawPaymentMethod
  ) ?? []) as [PaymentMethodType | undefined, string | undefined];

  const deductibleAmount = tariffs.find(
    tariff =>
      tariff.leistungsvereinbarungsId ===
      getPropFromAngebot('deductible-amount')
  )?.selbstbehalt as DeductibleAmountType | undefined;

  const contractPeriod = selectedVariant?.laufzeit ?? '5';

  const bikeCoverage = tariffs.find(
    tariff =>
      (tariff.leistungsvereinbarungsId ===
        getPropFromAngebot('fahrradkaskoUndMobilitätsschutz') ||
        tariff.leistungsvereinbarungsId ===
          getPropFromAngebot('fahrraddiebstahl')) &&
      tariff.vereinbart === true
  );

  const bicycleAndEbikeCoInsuredAmount = bikeCoverage?.versicherungssumme as
    | BicycleAndEbikeCoInsuredType
    | undefined;

  let bicycleAndEbikeCoInsuredCover:
    | BicycleAndEbikeCoInsuredCoverType
    | undefined;
  if (
    bikeCoverage?.leistungsvereinbarungsId ===
    productModuleMapping.fahrradkaskoUndMobilitätsschutz
  ) {
    bicycleAndEbikeCoInsuredCover = 'fahrradkaskoUndMobilitätsschutz';
  } else if (
    bikeCoverage?.leistungsvereinbarungsId ===
    productModuleMapping.fahrraddiebstahl
  ) {
    bicycleAndEbikeCoInsuredCover = 'fahrraddiebstahl';
  }

  if (bicycleAndEbikeCoInsuredCover) {
    productModules.push('bicycleAndEbike');
  }

  return {
    coverageProductVariant,
    productModules,
    paymentMethod,
    deductibleAmount,
    contractPeriod,
    bicycleAndEbikeCoInsuredAmount,
    bicycleAndEbikeCoInsuredCover,
  };
};

export const getOfferSumInsuredSelector = (
  state: RootState
): number | undefined => {
  const variantSelected = getSelectedVariantFromOffer(state);
  return variantSelected?.versicherungssumme;
};

export const getBonusFromOffer = (state: RootState): number | undefined => {
  const discounts = getOfferSelector(state)?.zuschlaegeNachlaesse ?? [];
  const bonusOptions = discounts.filter(
    v => v.bezeichnung === 'Startbonus Hausrat'
  );

  if (!bonusOptions.length) {
    return undefined;
  }

  const selectedVariant = getSelectedVariantFromOffer(state);
  const bonus = bonusOptions.find(
    option =>
      option.struktur?.versicherungen.toLowerCase() ===
        selectedVariant?.versicherungsId?.toLocaleLowerCase() &&
      option.vereinbart
  );

  return bonus?.value;
};

type GetPremiumFromOfferReturn = {
  taxes: string;
  netPremium: string;
  grossPremium: string;
  paymentMethod: string;
  glassTax: string;
  housePremium: string;
  glassPremium: string;
};
export const getPremiumFromOffer = (
  state: RootState
): GetPremiumFromOfferReturn | undefined => {
  const offer = getOfferSelector(state);
  const selectedVariant = getSelectedVariantFromOffer(state);

  if (!selectedVariant) {
    return undefined;
  }

  const contributions = (offer?.beitraege ?? []).filter(
    contribution =>
      contribution.struktur.versicherungen === selectedVariant.versicherungsId
  );
  const totalContract = contributions.find(
    contribution => !contribution.struktur.vertraege
  );
  const housePremium = contributions.find(
    contribution => contribution.struktur.vertraege === 'HRH'
  );
  const glassPremium = contributions.find(
    contribution => contribution.struktur.vertraege === 'HRG'
  );
  const contract =
    totalContract ??
    contributions.reduce((aggr, curr) => {
      if (!aggr) {
        return curr;
      }

      return {
        ...aggr,
        bruttobeitragNachZahlweise:
          aggr.bruttobeitragNachZahlweise + curr.bruttobeitragNachZahlweise,
        nettobeitragNachZahlweise:
          aggr.bruttobeitragNachZahlweise + curr.nettobeitragNachZahlweise,
      };
    }, undefined);

  if (!contract) {
    return undefined;
  }
  const paymentMethodMapping = {
    MONATLICH: 'monatlich',
    VIERTELJAEHRLICH: 'vierteljährlich',
    JAEHRLICH: 'jährlich',
    HALBJAEHRLICH: 'halbjährlich',
  };
  const paymentMethod = paymentMethodMapping[selectedVariant.zahlweise];
  return {
    taxes: formatDecimals(commonOffer.tax),
    netPremium: formatDecimals(contract.nettobeitragNachZahlweise),
    grossPremium: formatDecimals(contract.bruttobeitragNachZahlweise),
    paymentMethod,
    glassTax: formatDecimals(commonOffer.glassTax),
    housePremium: formatDecimals(housePremium?.bruttobeitragNachZahlweise),
    glassPremium: glassPremium
      ? formatDecimals(glassPremium?.bruttobeitragNachZahlweise)
      : '',
  };
};
