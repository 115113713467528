import './ConfirmationModal.scss';
import React from 'react';

import PhoneIcon from '@eg/elements/Icons/PhoneIcon';
import Modal from '@eg/elements/Modal';
import EButton from '../../../../components/EButton/EButton';
import type { ConfirmationModalProps } from './ConfirmationModalTypes';
import translations from '../../../../constants/translations';
import { TrackingElementPage4 } from '../../TrackingElements';
import { getChannelType } from '../../../../store/getAgencyDetails';
import { ChannelType } from 'commons/apis/hausrat/types';

export const ConfirmationModal = ({
  open,
  onClose,
  zipCode,
  city,
  streetName,
  streetNumber,
  onCall,
  zursValue,
  errors,
  trackingOptions,
}: ConfirmationModalProps): JSX.Element => {
  const fullAddress = `${streetName} ${streetNumber}, ${zipCode} ${city}`;
  const isAgentPage = getChannelType() === ChannelType.AGENT_BROKER;
  const isRiskZoneInvalid =
    zursValue === 'ZUERS_GK_9' || zursValue === 'ZUERS_GK_0';
  const streetNumberErrorExists = !!errors?.streetNumber;

  const showTelephoneButton = (): boolean => {
    if (isAgentPage) {
      return false;
    }
    if (isRiskZoneInvalid || streetNumberErrorExists) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={open}
      useEscKeyToDismiss
      dismissible
      onDismiss={onClose}
      extendedWidth
      backdropDismissesModal
    >
      <div data-testid="address-modal">
        <h3>{translations.step4.modalTitle}</h3>
        <p>{translations.step4.modalp1}</p>
        <p className="address-modal__description">
          {translations.step4.modalDescription}
        </p>
        <b>{fullAddress}</b>
        <p>{translations.step4.modalp2}</p>
        <p>{translations.step4.modalp3(isAgentPage)}</p>
        <div className="button-wrapper">
          <EButton
            className="button-wrapper__confirmation"
            onClick={onClose}
            variant="tertiary"
            size="large"
            colorScheme="regular"
            data-testid="submit-button-modal"
            trackingOptions={{
              element: TrackingElementPage4.BUTTON_CORRECT_INPUT,
              options: trackingOptions,
            }}
          >
            {translations.step4.button1}
          </EButton>
          {showTelephoneButton() && (
            <EButton
              className="button-wrapper__contact"
              iconLeft={<PhoneIcon />}
              onClick={onCall}
              variant="primary"
              size="large"
              colorScheme="regular"
              data-testid="telephone-button-modal"
              trackingOptions={{
                element: TrackingElementPage4.BUTTON_TELEPHONE,
                options: trackingOptions,
              }}
            >
              {translations.step4.button2}
            </EButton>
          )}
        </div>
      </div>
    </Modal>
  );
};
