import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const Glass = (): JSX.Element => {
  return (
    <div data-testid="glass-info">
      <h3>{step7.glass.title}</h3>
      <p
        data-testid="glass-p1"
        dangerouslySetInnerHTML={{
          __html: step7.glass.paragraph1,
        }}
      ></p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li>{step7.glass.listOptions1.a}</li>
        <li>{step7.glass.listOptions1.b}</li>
        <li>{step7.glass.listOptions1.c}</li>
        <li>{step7.glass.listOptions1.d}</li>
        <li>{step7.glass.listOptions1.e}</li>
        <li>{step7.glass.listOptions1.f}</li>
      </ul>
      <p
        data-testid="glass-p2"
        dangerouslySetInnerHTML={{
          __html: step7.glass.paragraph2,
        }}
      ></p>
      <ul className="modal-list" data-testid="modal-list-2">
        <li>{step7.glass.listOptions2.a}</li>
        <li>{step7.glass.listOptions2.b}</li>
        <li>{step7.glass.listOptions2.c}</li>
        <li>{step7.glass.listOptions2.d}</li>
        <li>{step7.glass.listOptions2.e}</li>
        <li>{step7.glass.listOptions2.f}</li>
      </ul>
      <p data-testid="glass-p3">{step7.glass.paragraph3}</p>
      <ul className="modal-list" data-testid="modal-list-3">
        <li>{step7.glass.listOptions3.a}</li>
        <li>{step7.glass.listOptions3.b}</li>
        <li>{step7.glass.listOptions3.c}</li>
        <li>{step7.glass.listOptions3.d}</li>
      </ul>
      <p data-testid="glass-p4">{step7.glass.paragraph4}</p>
    </div>
  );
};

export default Glass;
