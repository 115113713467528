import { commonOffer } from 'commons';
import { RootState } from '../../types';
import { getBonusFromOffer, getPremiumFromOffer } from './step7';
import { formatDecimals } from '../../../utils/index';

type GetOfferFeedbackSelector = {
  paymentMethod?: string;
  premium?: string;
  bonus?: number;
  tax?: string;
};
export const getOfferFeedbackSelector = (
  state: RootState
): GetOfferFeedbackSelector => {
  const bonus = getBonusFromOffer(state);
  const premium = getPremiumFromOffer(state);

  return {
    paymentMethod: premium?.paymentMethod,
    premium: premium?.grossPremium,
    bonus,
    tax: formatDecimals(commonOffer.tax),
  };
};
