import { offerEngineHttp } from '../../http/offerEngineHttp';
import {
  getUrl,
  createRole as createRoleRoute,
  updateRole as updateRoleRoute,
} from '../constants';
import {
  CreateRoleParams,
  CreateRoleResponse,
  UpdateRoleParams,
  UpdateRoleResponse,
} from './types';

export const createRole = async ({
  businessId,
  payload,
}: CreateRoleParams): Promise<CreateRoleResponse> => {
  try {
    const { method, path } = createRoleRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await offerEngineHttp<CreateRoleResponse>(url, {
      method,
      payload,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateRole = async ({
  businessId,
  payload,
}: UpdateRoleParams): Promise<UpdateRoleResponse> => {
  try {
    const { method, path } = updateRoleRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await offerEngineHttp<UpdateRoleResponse>(url, {
      method,
      payload,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
