import { rest } from 'msw';
import { Handler } from '../../../types';
import type { Role } from './types';
import { createRole, getUrl } from '../constants';

export const createRoleHandler = (
  rest[createRole.method.toLowerCase()] as Handler
)(getUrl(createRole.path), (req, res, ctx) => {
  const { businessId } = req.params;
  const [{ personId, rollentyp }] = req.body as Role[];

  return res(
    ctx.status(201),
    ctx.json({
      angebot: {
        businessId,
        rollen: [
          {
            personId,
            rollentyp,
            reference: {
              personId,
            },
          },
        ],
      },
    })
  );
});
