import React from 'react';

import type { TextInputProps } from './TextInputTypes';
import './TextInput.scss';

export const TextInput = ({
  label,
  id,
  placeholder,
  className = '',
  display = 'inline',
  errors,
  onChange,
  onBlur,
  onFocus,
  value = '',
  type = 'text',
  name,
  onKeyDown,
  onPaste,
}: TextInputProps): JSX.Element => {
  const labelDisplay = `input-text__label--${display}`;
  const labelClassName = className ? `${className}__label` : '';
  const labelError = !!errors ? 'is-error__label' : '';
  const labelClasses = `${labelDisplay} ${labelClassName} ${labelError}`.trim();

  const inputDisplay = `input-text__input--${display}`;
  const inputClassName = className ? `${className}__input` : '';
  const inputError = !!errors ? 'is-error__input' : '';
  const inputClasses = `${inputDisplay} ${inputClassName} ${inputError}`.trim();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (type === 'number' && /([^0-9])/g.test(value)) {
      event.preventDefault();
      return;
    }

    onChange?.(event);
  };

  if (value === null) {
    value = '';
  }

  return (
    <div className="input-text">
      <div className={`input-text--${display} ${className}`}>
        {label && (
          <label
            aria-label={`${id}-text`}
            htmlFor={id}
            className={labelClasses}
          >
            {label}
          </label>
        )}
        <div className="input-wrapper">
          <input
            name={name}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            placeholder={placeholder}
            aria-label={`${id}-input`}
            data-testid={id}
            type={type === 'email' ? 'email' : 'text'}
            id={id}
            className={inputClasses}
            inputMode={type === 'number' ? 'numeric' : type}
          />
          {(errors ?? []).map(error => (
            <span className="input-wrapper__error-message" key={error}>
              {error}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
