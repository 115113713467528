import { DateInputValue } from '@eg/elements/DateInput';
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Angebot, Versicherungen } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import {
  addDates,
  dateFormatted,
  dateInputValueToDate,
  dateToDateInputValue,
  differenceBetweenDates,
} from '../../helpers/dates/dates';
import { isEmptyObject } from '../../helpers/objects/objects';
import { insuranceStartDate } from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import { RootState } from '../../store/types';
import {
  getVariantsFromOffer,
  saveOffer,
  savePerson,
} from '../../store/offerSlice';
import type { InsuranceDateState, TypesOfDate } from './InsuranceDateTypes';
import { typesOfDatesValues } from './components/InsuranceDateOptions/helpers/constants';
import { insuranceDateOfStartByTypeOfDate } from './components/InsuranceDateOptions/helpers/dates';
import { InsuranceVariant } from 'commons/apis/hausrat/types';

const initialState: InsuranceDateState = {
  isValidated: false,
  isLoading: false,
  error: '',
  form: {
    typeOfDate: undefined,
    insuranceStartDate: {} as DateInputValue,
  },
};

const getVariantsPayload = (
  variants: Versicherungen[],
  startDate: string
): InsuranceVariant[] => {
  return variants.map(({ versicherungsId }) => ({
    variantId: versicherungsId,
    startDate: startDate,
  }));
};

export const insuranceDateFetchValidation = createAsyncThunk(
  'insuranceDate/validation',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const variants = getVariantsFromOffer()(state);
    try {
      const response = await insuranceStartDate(
        state.app.businessId,
        getVariantsPayload(variants, startDateOfInsuranceFormatted(state))
      );

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      dispatch(setStepValidated(5));

      return response;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const insuranceDateSlice = createSlice({
  name: 'insuranceDate',
  initialState,
  reducers: {
    setTypeOfDate: (state, action: PayloadAction<TypesOfDate>) => {
      state.form.typeOfDate = action.payload;

      const newDateOfStart = insuranceDateOfStartByTypeOfDate(action.payload);
      state.form.insuranceStartDate = newDateOfStart
        ? dateToDateInputValue(newDateOfStart)
        : ({} as DateInputValue);
    },
    setDateOfStart: (state, action: PayloadAction<DateInputValue>) => {
      if (state.form.typeOfDate) {
        state.form.insuranceStartDate = action.payload;
      }
    },
    setValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(insuranceDateFetchValidation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(insuranceDateFetchValidation.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(insuranceDateFetchValidation.rejected, (state, action) => {
      state.isLoading = false;
      state.isValidated = false;
      state.error = action.error.message;
    });
  },
});

export const {
  setTypeOfDate,
  setDateOfStart,
  setValidated: setInsuranceDateValid,
} = insuranceDateSlice.actions;

export const startDateOfInsuranceFormatted = ({
  insuranceDate: { form },
}: RootState): string | null => {
  const { insuranceStartDate } = form;
  if (isEmptyObject(insuranceStartDate)) {
    return null;
  }

  return dateFormatted(dateInputValueToDate(insuranceStartDate));
};

export const isTypeOfDateValid = ({
  insuranceDate: { form },
}: RootState): boolean => {
  const { insuranceStartDate, typeOfDate } = form;
  return (
    typesOfDatesValues.includes(typeOfDate) &&
    !isEmptyObject(insuranceStartDate)
  );
};

export const isDateOfStartValid = ({
  insuranceDate: { form },
}: RootState): boolean => {
  const { insuranceStartDate } = form;
  if (isEmptyObject(insuranceStartDate)) {
    return false;
  }

  if (dateInputValueToDate(insuranceStartDate)) {
    const difference = differenceBetweenDates({
      startDate: addDates({ datesToAdd: 0, resetTime: true }),
      endDate: dateInputValueToDate(insuranceStartDate),
      resetTime: true,
    });

    return !!(difference.days > 0 && difference.days <= 364);
  }
};

export const isInsuranceDateValid = (state: RootState): boolean => {
  return isTypeOfDateValid(state) && isDateOfStartValid(state);
};

export const selectIsLoading = (state: RootState): boolean =>
  state.insuranceDate.isLoading;

export default insuranceDateSlice.reducer;
