const PRICE_BY_SQUARE_METERS = 650;

export const MIN_SQUARE_METERS = 10;
export const MAX_SQUARE_METERS = 384;

export function isValidSquareMeters(num: number): boolean {
  return isValidSquareMetersMinWise(num) && isValidSquareMetersMaxWise(num);
}

export function isValidSquareMetersMinWise(num: number): boolean {
  return num >= MIN_SQUARE_METERS;
}

export function isValidSquareMetersMaxWise(num: number): boolean {
  return num <= MAX_SQUARE_METERS;
}

export function getSumInsured(value: number): number {
  return value * PRICE_BY_SQUARE_METERS;
}
