import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { LivingPlace } from 'commons';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import { livingPlace } from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import { RootState } from '../../store/types';
import { saveOffer, savePerson } from '../../store/offerSlice';
import type { LivingPlaceState } from './LivingPlaceTypes';

const initialState: LivingPlaceState = {
  isValidated: false,
  isLoading: false,
  form: {
    typeOfHouse: undefined,
  },
};

export const updateLivingPlace = createAsyncThunk(
  'livingPlace/update',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const { businessId } = state.app;
      const response = await livingPlace(
        businessId,
        state.livingPlace.form.typeOfHouse
      );
      dispatch(setStepValidated(1));

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }
    } catch (error) {
      console.error('error', error);
      throw Error(error);
    }
  }
);

export const livingPlaceSlice = createSlice({
  name: 'livingPlace',
  initialState,
  reducers: {
    setTypeOfHouse: (state, action: PayloadAction<LivingPlace>) => {
      state.form.typeOfHouse = action.payload;
    },
    setValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateLivingPlace.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateLivingPlace.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(updateLivingPlace.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
    });
  },
});

export const { setTypeOfHouse, setValidated: setTypeOfHouseValid } =
  livingPlaceSlice.actions;

export const selectIsLoading = (state: RootState): boolean =>
  state.livingPlace.isLoading;

export default livingPlaceSlice.reducer;
