import React from 'react';
import EButton from '../../../components/EButton/EButton';
import translations from '../../../constants/translations';
import './ComparativeServicesButtonRender.scss';

const ComparativeServicesButtonRender = ({ useClick }): JSX.Element => (
  <div className="card-section__footer">
    <EButton variant="text-link" onClick={useClick}>
      {translations.step7.aemIntegrationLink}
    </EButton>
  </div>
);

export default ComparativeServicesButtonRender;
