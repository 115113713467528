import { submitEngineHttp } from '../../http/submitEngineHttp';
import { submitOffer as submitOfferRoute, getUrl } from '../constants';
import { SubmitOfferResponse } from './types';

export const submitOffer = async (
  businessId: string
): Promise<SubmitOfferResponse> => {
  try {
    const { method, path } = submitOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await submitEngineHttp<SubmitOfferResponse>(url, {
      method,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
