import { AddressErrors, ValidateAddress, ValidateAddressRules } from '../types';

export const isValidPostalCode = (zipCode: string | number): boolean =>
  zipCode.toString().length === ValidateAddressRules.postalCodeLength;

export const isValidStreetName = (streetName: string): boolean =>
  streetName.length >= ValidateAddressRules.streetNameMinLength &&
  streetName.length <= ValidateAddressRules.streetNameMaxlength;

export const isLessThanOne = (streetNumber: string): boolean =>
  streetNumber < '1';

export const isValidStreetNumberLength = (streetNumber: string): boolean =>
  streetNumber.length >= ValidateAddressRules.streetNumberMinLength &&
  streetNumber.length <= ValidateAddressRules.streetNumberMaxlength;

export const isValidStreetNumber = (streetNumber: string): boolean =>
  isValidStreetNumberLength(streetNumber);

export const validateAddress: ValidateAddress = ({
  streetName,
  streetNumber,
  zipCode,
}) => {
  const validation = {
    isValid: false,
    errors: [],
  };

  if (!isValidStreetName(streetName)) {
    validation.errors.push(AddressErrors.streetNameLength);
  }

  if (!isValidStreetNumberLength(streetNumber)) {
    validation.errors.push(AddressErrors.streetNumberLength);
  }

  if (!isValidStreetNumber(streetNumber)) {
    validation.errors.push(AddressErrors.streetNumber);
  }

  if (!isValidPostalCode(zipCode)) {
    validation.errors.push(AddressErrors.postalCode);
  }

  validation.isValid = !validation.errors.length;

  return validation;
};

export const errorInAddressErrors = (
  errors: AddressErrors | AddressErrors[] | string
): boolean => {
  if (Array.isArray(errors)) {
    return errors.some(error => !!AddressErrors[error]);
  }

  return !!AddressErrors[errors];
};
