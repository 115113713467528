export const step6 = {
  pageTitle: 'Geben Sie Ihr Geburtsdatum ein.',
  pageSubtitle1:
    'Wenn Sie bei Versicherungsbeginn jünger als 36 Jahre sind, erhalten Sie als Startbonus einen Nachlass von 13 %.',
  errorMessageBadInput: 'Bitte geben Sie ein gültiges Datum ein.',
  errorMessageValueMissing:
    'Bitte geben Sie das vollständige Datum ein (Format TT MM JJJJ).',
  errorMessageRangeOverflow:
    'Sie müssen bei Vertragsabschluss mindestens 18 Jahre alt sein.',
  errorMessageRangeUnderflow: 'Bitte geben Sie ein gültiges Datum ein.',
  promotionMessageCorrectAge:
    'Sie erhalten als Startbonus <strong>13 % Nachlass</strong>.',
  modalTitle: 'Startbonus für junge Leute',
  modalSubtitle:
    'Sie erhalten als Startbonus einen Nachlass von <strong>13 %</strong> auf den Gesamtbeitrag, wenn Sie bei Beginn des Vertrages Ihr 36. Lebensjahr noch nicht vollendet haben. Der Startbonus entfällt zur nächsten Jahresfälligkeit, nachdem Sie Ihr 36. Lebensjahr vollendet haben.',
};
