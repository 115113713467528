export const step11 = {
  title: 'Geben Sie Ihre Bankverbindung an.',
  subtitle:
    'Für einen Online-Abschluss benötigt der Versicherer ein SEPA-Lastschriftmandat.',

  tooltipTitle: 'Bank Details',
  tooltipMsg:
    'Ihre IBAN finden Sie auf der Vorderseite Ihrer Bankkarte oder auf Ihrem Kontoauszug.',

  authorizationText: `Ich ermächtige die ERGO Versicherung AG, ERGO-Platz 1, 
  40477 Düsseldorf (Gläubiger-ID DE05ZZZ00000012101), 
  Zahlungen von meinem Konto mittels Lastschrift einzuziehen.`,

  authorizationText2: `Zugleich weise ich mein Kreditinstitut an, diese auf mein 
  Konto gezogenen Lastschriften einzulösen. 
  Der SEPA-Basislastschrift-Einzug wird mir spätestens 5 
  Kalendertage im Voraus unter Angabe der weiteren Fälligkeitstermine angekündigt.`,

  noteTitle: 'Hinweis:',
  noteText: `Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, 
  die Erstattung des belasteten Betrags verlangen. 
  Dabei gelten die mit meinem Kreditinstitut vereinbarten Bedingungen.`,
  directDebitText: 'Hinweise zur SEPA-Lastschrift',
  directDebitTextModalTitle: 'Lastschrift',
  directDebitTextModalText: `Sollte bereits ein Mandat für die oben genannte Kontoverbindung
    bestehen, bin ich damit einverstanden, dass das von mir bereits
    erteilte SEPA-Lastschriftmandat auch für den Einzug der Beiträge für
    diesen Versicherungsvertrag genutzt wird.`,

  accountOwnerDescription: 'Kontoinhaber',
  noNameProvided: 'Name nicht angegeben',
  ibanLabel: 'IBAN',
  ibanPlaceholder: 'DE00 0000 0000 0000 0000 00',
  ibanErrorInvalid:
    'Diese IBAN ist nicht korrekt. Bitte korrigieren Sie Ihre Angabe.',
  ibanErrorSepa:
    'Bitte geben Sie eine IBAN von einer Bank aus dem SEPA-Zahlungsraum an.',
  ibanSuccessMsg:
    'Ihre Kontoverbindung wurde erfolgreich geprüft. Die zugehörige BIC haben wir für Sie automatisch ergänzt.',
  bicLabel: 'BIC',
  bankLabel: 'Geldinstitut',
  bankMsg:
    'Ihre Kontoverbindung wurde erfolgreich geprüft. Die zugehörige BIC wurde automatisch ergänzt.',
};
