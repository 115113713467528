import { AgentWindow } from '../types';
import { TrackingEvents } from '../api/tracking/TrackingTypes';
import {
  DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES,
  DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES,
} from 'commons/apis/spcs/constants';

export const MARKETING_ACTION_NUMBER_KEY = 'actionNumber';
export const MARKETING_AGENT_NUMBER_KEY = 'agentNumber';
export const MARKETING_SALES_COOKIES_KEY = 'salesCookies';
export const ACTION_NUMBER_COOKIE_KEY = 'aktionNr';
export const OE_NUMBER_QUERY_KEY = 'adh_oenr';

export const getOenrFromQueryParam = (): string | null => {
  const searchString = window.location.search;
  if (searchString) {
    const params = new URLSearchParams(searchString);
    const oeNumber = params.get(OE_NUMBER_QUERY_KEY);
    return oeNumber ? oeNumber : null;
  }
  return null;
};

export const getMarketingEventProperty = (property: string): string | null => {
  const { appEventData } = window as AgentWindow;
  if (appEventData) {
    const maretingEvent = appEventData.find(
      e => e.event === TrackingEvents.MARKETING
    );
    return maretingEvent?.miscellaneous.marketing[property] ?? null;
  }
  return null;
};

export const getActionNumberFromCookie = (): string | null => {
  const allCookiesString = document.cookie;
  const cookieList: string[] = allCookiesString.split(';');
  const actionNrCookie: string | undefined = cookieList.find(
    (element: string) => element.includes(ACTION_NUMBER_COOKIE_KEY)
  );
  if (actionNrCookie) {
    const [, actionNr]: string[] = actionNrCookie.split('=');
    const decodedActionNumber = decodeURIComponent(actionNr);
    if (decodedActionNumber.length > 4) {
      return decodedActionNumber.substring(0, 4);
    }
  }
  return null;
};

export const getActionNumberFromGlobalVariable = (): string | null => {
  const aktionNumberValueFromGlobalVariable =
    (window as any).defaultAktionNr || '';
  const [extractedActualActionNumber] =
    aktionNumberValueFromGlobalVariable.split(',');
  if (extractedActualActionNumber) {
    return extractedActualActionNumber;
  }
  return null;
};

export const getActionNumberFromMarketingEvent = (): string | null => {
  return null;
  //todo EHAUS-1197 - due to caching problems in "MarketingLogicReady" event - we cannot identify action number correctly
  // return getMarketingEventProperty(MARKETING_ACTION_NUMBER_KEY);
};

export const getAgentNumberFromMarketingEvent = (): string | null => {
  return null;
  //todo EHAUS-1197 - due to caching problems in "MarketingLogicReady" event - we cannot identify agent number correctly
  // return getMarketingEventProperty(MARKETING_AGENT_NUMBER_KEY);
};

export const getOeNumberFromAdhopPages = (): string | null => {
  const { agencyData } = window as AgentWindow;
  return agencyData && agencyData.oenrNumber ? agencyData.oenrNumber : null;
};

export const getActionNumber = (): string => {
  //todo EHAUS-1197 -due to caching problems in "MarketingLogicReady" event - we cannot identify aktionNr from marketing event correctly
  // const marketingActionNumber = getActionNumberFromMarketingEvent();
  // if (marketingActionNumber) {
  //   return marketingActionNumber;
  // }
  const cookiesActionNumber = getActionNumberFromCookie();
  if (cookiesActionNumber) {
    return cookiesActionNumber;
  }

  const actionNumberFromGlobalVariable = getActionNumberFromGlobalVariable();
  if (actionNumberFromGlobalVariable) {
    return actionNumberFromGlobalVariable;
  }

  if (isAdhopPage() || isSitecorePage()) {
    return DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES;
  }
  return DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES;
};

export const isPartnerCooperationPage = (): boolean => {
  return false;
  //todo EHAUS-1197 - due to caching problems in "MarketingLogicReady" event - we cannot identify cooperation sales case correctly
  // !!getMarketingEventProperty(MARKETING_SALES_COOKIES_KEY);
};
export const isAdhopPage = (): boolean => !!getOeNumberFromAdhopPages();
export const isSitecorePage = (): boolean => !!getOenrFromQueryParam();
