import { trackClickEvent } from '../../../api/tracking';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import {
  TrackingClickOptions,
  TrackingConversionTypes,
} from '../../../api/tracking/TrackingTypes';
import { TrackingElementPage11 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onOpenInfoIcon: (id: string) => void;
  onVerificationStart: () => void;
  onVerificationSuccess: () => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onOpenInfoIcon = (id: string): void => {
    const element = TrackingElementPage11[id];
    trackClickEvent(element, trackingOptions);
  };

  const onVerificationStart = (): void => {
    const element = TrackingElementPage11.VERIFICATION_START;
    const { transaction } = trackingOptions;
    trackClickEvent(element, {
      ...trackingOptions,
      transaction: {
        ...transaction,
        conversionType: TrackingConversionTypes.IBAN_VERIFICATION_START,
      },
    });
  };

  const onVerificationSuccess = (): void => {
    const element = TrackingElementPage11.VERIFICATION_SUCCESS;
    const { transaction } = trackingOptions;
    trackClickEvent(element, {
      ...trackingOptions,
      transaction: {
        ...transaction,
        conversionType: TrackingConversionTypes.IBAN_VERIFICATION_SUCCESS,
      },
    });
  };

  return {
    trackingOptions,
    onOpenInfoIcon,
    onVerificationStart,
    onVerificationSuccess,
  };
};

export default useClickTracking;
