import * as agencies from './agencies';
import * as constants from './constants';
import * as document from './document';
import * as payments from './payments';
import * as persons from './persons';
import * as validation from './validation';

const { getUrl, ...endpoints } = constants;

const spcsApi = {
  agencies,
  document,
  endpoints,
  payments,
  persons,
  validation,
};

export default spcsApi;
