// https://sep-prx.local.ergodirekt.de/browse/EHAUS-313
// It is not possible to write special characters (except "-" and ".") and numbers in the street field
import React from 'react';

import { TextInput } from '../../../../components/TextInput/TextInput';
import { validateInputRegex } from '../../../../helpers/inputs/inputs';
import { StreetInfoInputProps } from './StreetInfoInputTypes';
import translations from '../../../../constants/translations';

import './StreetInfoInput.scss';
import { useForm } from '../../../../store/useForm';
import { validateStreetName, validateStreetNumber } from '../../addressSlice';

export const StreetInfoInputs = ({
  onChangeStreetName,
  onChangeStreetNumber,
  streetName,
  streetNameError,
  streetNumber,
  streetNumberError,
}: StreetInfoInputProps): JSX.Element => {
  const { dispatch } = useForm();
  const streetNameRegExpPattern = /^[A-Za-z-zÀ-ž-0-9 .-]*$/;
  const streetNumberRegExpPattern = /^[A-Za-z-0-9 /.-]*$/;

  const handleChangeStreetName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (validateInputRegex(e.target.value, /[^a-zA-ZäöüÄÖÜß \.\-]/g)) {
      e.preventDefault();
      return;
    }

    if (validateInputRegex(e.target.value, streetNameRegExpPattern)) {
      onChangeStreetName(e.target.value);
    }
  };

  const handleOnChangeStreetNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (validateInputRegex(e.target.value, streetNumberRegExpPattern)) {
      onChangeStreetNumber(e.target.value);
    }
  };

  const handleOnBlurStreetName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(validateStreetName(e.target.value));
  };

  const handleOnBlurStreetNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(validateStreetNumber(e.target.value));
  };

  return (
    <div className="street-info">
      <div className="street-info__name">
        <TextInput
          data-testid="street-name-input"
          display="col"
          errors={streetNameError ? [streetNameError] : undefined}
          id="street-name-input"
          label={translations.step4.streetNameLabel}
          onChange={handleChangeStreetName}
          onBlur={handleOnBlurStreetName}
          value={streetName}
          placeholder={translations.step4.streetNamePlaceholder}
        />
      </div>

      <div className="street-info__number">
        <TextInput
          display="col"
          id="street-number-input"
          label={translations.step4.streetNumberLabel}
          onChange={handleOnChangeStreetNumber}
          onBlur={handleOnBlurStreetNumber}
          placeholder={translations.step4.streetNumberPlaceholder}
          value={streetNumber}
          errors={streetNumberError ? [streetNumberError] : undefined}
        />
      </div>
    </div>
  );
};
