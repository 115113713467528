import { FloorType, LivingPlace } from 'commons';
import { PaymentMethods } from 'commons/apis/offerEngine';
import {
  BicycleAndEbikeCoInsuredType,
  CoverageModuleType,
  DeductibleAmountType,
  PaymentMethodType,
} from '../../pages/step7/InsuranceCoverageTypes';
import { InsuranceCoverageFields } from '../../store/offerSlice';
import { round } from '../../utils';
import {
  TrackingBoolean,
  TrackingFloor,
  TrackingGender,
  TrackingLivingType,
  TrackingSize,
} from './TrackingTypes';
import { Gender } from '../../pages/step9/PersonalDataEnums';

export const getLivingTypeValue = (typeOfHouse: LivingPlace): string => {
  const isApartment = typeOfHouse === 'apartment';
  return TrackingLivingType[isApartment ? 'APARTMENT' : 'SINGLE'];
};
export const getLivingType = (
  totalString: string,
  typeOfHouse: LivingPlace
): string => {
  if (!typeOfHouse) {
    return totalString;
  }

  const value = getLivingTypeValue(typeOfHouse);

  return `${totalString}|${value}`;
};

export const getLivingLocationValue = (typeOfFloor: FloorType): string => {
  let value: TrackingFloor;

  switch (typeOfFloor) {
    case 'basement':
      value = TrackingFloor.CELLAR;
      break;
    case 'groundFloor':
      value = TrackingFloor.GROUNDFLOOR;
      break;
    case 'firstFloor':
      value = TrackingFloor.FLOOR_1ST;
      break;
    case 'secondFloor':
      value = TrackingFloor.FLOOR_2ND;
      break;
    case 'otherFloor':
      value = TrackingFloor.FLOOR_3RD_ABOVE;
      break;
    default:
      value = TrackingFloor.CELLAR;
      break;
  }

  return value;
};

export const getLivingLocation = (
  totalString: string,
  typeOfFloor: FloorType
): string => {
  if (!typeOfFloor) {
    return totalString;
  }

  const value = getLivingLocationValue(typeOfFloor);

  return `${totalString}|${value}`;
};

export const getTrackingSizeInSM = (size: number): string =>
  `${TrackingSize.SM}=${size}`;

export const getSquareMeters = (
  totalString: string,
  sizeInSM: number
): string => {
  if (!sizeInSM) {
    return totalString;
  }

  const value = getTrackingSizeInSM(sizeInSM);
  return `${totalString}|${value}`;
};

export const getTariffData = (
  totalString: string,
  label: string,
  value: string
): string => {
  return `${totalString}|${label}=${value}`;
};

export const getProductsData = (
  totalString: string,
  productArray: CoverageModuleType[],
  fields: InsuranceCoverageFields,
  bikeAmount?: BicycleAndEbikeCoInsuredType,
  tariff?: string
): string => {
  const { services } = fields;
  const products = [
    { label: 'EDieb', product: 'simpleTheft', checked: services[0]?.checked },
    {
      label: 'Schutzb',
      product: 'houseAndFlat',
      checked: services[1]?.checked,
    },
    { label: 'Glas', product: 'glass', checked: services[2]?.checked },
    {
      label: 'NatG',
      product: 'otherNaturalHazards',
      checked: services[3]?.checked,
    },
    { label: 'UnbG', product: 'unnamedPerils', checked: services[4]?.checked },
    { label: 'Fahrrad', product: 'bicycleAndEbike' },
  ];

  let result = totalString;
  for (let i = 0, len = products.length; i < len; i++) {
    const { label, product, checked } = products[i];
    result = getProductModuleData(
      result,
      label,
      checked,
      product as CoverageModuleType,
      productArray,
      bikeAmount,
      tariff
    );
  }
  return result;
};

export const getProductModuleData = (
  totalString: string,
  label: string,
  checked: boolean,
  moduleType: CoverageModuleType,
  products: CoverageModuleType[],
  bikeAmount?: BicycleAndEbikeCoInsuredType,
  tariff?: string
): string => {
  const condition = products.includes(moduleType);

  const natGCondition = products.includes('otherNaturalHazards');

  let value = '';
  // For Best simpleTheft is always enables
  if (moduleType === 'simpleTheft' && tariff === 'Best') {
    value = TrackingBoolean['J'];
  }
  if (moduleType === 'bicycleAndEbike') {
    value = condition ? bikeAmount.toString() : 'Ohne';
  } else if (moduleType === 'unnamedPerils' && (!natGCondition || !checked)) {
    value = TrackingBoolean['NA'];
    // For best if Weitere Naturgefahren is selected Unbenannte Gefahren should be N
    if (tariff === 'Best' && natGCondition && !checked) {
      value = TrackingBoolean['NO'];
    }
  } else {
    value = TrackingBoolean[checked ? 'YES' : 'NO'];
  }

  return `${totalString}|${label}=${value}`;
};

export const getBikeInsuranceCoverageData = (
  totalString: string,
  bikeOption: string
): string => {
  const label = 'FaKaDie';
  let value = '';

  if (!bikeOption) {
    value = 'NA';
  } else {
    value = bikeOption === 'fahrraddiebstahl' ? 'FaDie' : 'FaKa';
  }

  return `${totalString}|${label}=${value}`;
};

export const getPaymentMethod = (paymentMethod: PaymentMethodType): string => {
  let value = '1';

  switch (paymentMethod) {
    case 'annual':
      value = `${value}/1`;
      break;
    case 'half-year':
      value = `${value}/2`;
      break;
    case 'quarterly':
      value = `${value}/4`;
      break;
    case 'monthly':
      value = `${value}/12`;
      break;
  }

  return value;
};

export const getPaymentMethodData = (
  totalString: string,
  paymentMethod: PaymentMethodType
): string => {
  const label = 'Zahlung';
  const value = getPaymentMethod(paymentMethod);

  return `${totalString}|${label}=${value}`;
};

export const getContractPeriodData = (
  totalString: string,
  contractPeriod: string
): string => {
  const label = 'Dauer';
  const value = `${contractPeriod}J`;
  return `${totalString}|${label}=${value}`;
};

export const getExcess = (deductibleAmount: string): string => {
  let value = '';
  switch (deductibleAmount) {
    case 'KEINE_SELBSTBETEILIGUNG':
    case 'zero':
      value = 'Ohne';
      break;
    case 'EUR_300_FEST':
    case 'threeHundred':
      value = '300';
      break;
    case 'EUR_300_FLEXI':
    case 'flex300':
      value = '300flex';
      break;
    default:
      value = 'Ohne';
  }

  return value;
};

export const getDeductibleAmount = (
  totalString: string,
  deductibleAmount: string | DeductibleAmountType
): string => {
  const label = 'SB';
  const value = getExcess(deductibleAmount);

  return `${totalString}|${label}=${value}`;
};

export const transformPaymentMethodsToPaymentMethodTYpe = (
  method: PaymentMethods
): PaymentMethodType => {
  switch (method) {
    case 'JAEHRLICH':
      return 'annual';
    case 'HALBJAEHRLICH':
      return 'half-year';
    case 'VIERTELJAEHRLICH':
      return 'quarterly';
    case 'MONATLICH':
      return 'monthly';
    default:
      return 'monthly';
  }
};

export const getCalculatedValue = (
  price: string,
  method: PaymentMethodType | PaymentMethods
): number => {
  if (!price) {
    return 0;
  }

  let factor = 12;

  switch (method) {
    case 'monthly':
    case 'MONATLICH':
      factor = 12;
      break;
    case 'quarterly':
    case 'VIERTELJAEHRLICH':
      factor = 4;
      break;
    case 'half-year':
    case 'HALBJAEHRLICH':
      factor = 2;
      break;
    case 'annual':
    case 'JAEHRLICH':
      factor = 1;
      break;
  }

  const formattedFloat = parseFloat(price.replace(/,/g, '.'));
  return round(formattedFloat * factor);
};

export const getTrackedGender = (gender: string | Gender): TrackingGender => {
  switch (gender) {
    case Gender.MS:
      return TrackingGender.FEMALE;
    case Gender.MR:
      return TrackingGender.MALE;
    default:
      return TrackingGender.UNKNOWN;
  }
};
