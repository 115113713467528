import React, { useEffect } from 'react';
import './Address.scss';
import FormFooter from '@eg/elements/FormFooter';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import translations from '../../constants/translations';
import {
  setStreetName,
  setZipCode,
  setStreetNumber,
  setCity,
  fetchCityFromAddress,
  addressFetchValidation,
  isZursValueSelector,
  resetZursCode,
  resetStreetNameErrors,
  setDisabledContinue,
  setModalOpen,
  getModalState,
  getContinueState,
  selectIsValidated,
  setFormValidationStatus,
} from './addressSlice';
import { AddressTooltipInfo } from './components/AddressTooltipInfo/AddressTooltipInfo';
import { ConfirmationModal } from './components/ConfirmationModal/ConfirmationModal';
import { StreetInfoInputs } from './components/StreetInfoInput/StreetInfoInput';
import { ZipCodeInput } from './components/ZipCodeInput/ZipCodeInput';
import { useAddressData } from './hooks';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useAppSelector } from '../../store/hooks';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import AddressMessage from './components/AddressMessage/AddressMessage';
import useClickTracking from './hooks/useClickTracking';
import { isAddressUpdated } from '../../store/offerSlice';
import { useLocation } from 'react-router-dom';
import { trackPageLoad } from '../../api/tracking';
import { ERGO_PHONE_NUMBER } from '../../constants/branding';
import { useForm } from '../../store/useForm';

let getCityDebounce: NodeJS.Timeout;

export const Address = (): JSX.Element => {
  const { pathname } = useLocation();

  const isError = useAppSelector(getGenericErrorSelector);
  const isFormModified = useAppSelector(isAddressUpdated);
  const isValidated = useAppSelector(selectIsValidated);
  const zursValue = useAppSelector(isZursValueSelector);
  const isModalOpen = useAppSelector(getModalState);
  const isDisabledContinue = useAppSelector(getContinueState);
  const { city } = useForm();
  const { goNext, goPrev } = useStepNavigation();
  const {
    dispatch,
    isLoadingZipCode,
    isLoadingCustomerAddress,
    isAddressCorrected,
    suggestions,
    errors,
    values,
    oldAddressData,
  } = useAddressData();

  const { trackingOptions, onModalTracking } = useClickTracking();

  const handleOnChangeStreetName = (value: string): void => {
    dispatch(resetZursCode());
    dispatch(setStreetName(value));
  };

  const handleOnChangeStreetNumber = (value: string): void => {
    dispatch(setFormValidationStatus(false));
    dispatch(resetZursCode());
    dispatch(setStreetNumber(value));
  };

  const handleOnChangeZipCode = (value: string): void => {
    dispatch(setZipCode(value));
  };

  const handleOnChangeCity = (value: string): void => {
    if (!errors.address) {
      dispatch(setDisabledContinue(false));
    }
    dispatch(resetStreetNameErrors());
    dispatch(resetZursCode());
    dispatch(setCity(value));
  };

  const handleContinue = async (): Promise<void> => {
    if (isAddressCorrected) {
      dispatch(setFormValidationStatus(false));
    }
    if (isFormModified && !!errors) {
      await dispatch(addressFetchValidation());
    }
  };

  const handleBack = (): void => {
    goPrev();
  };

  const handleModal = (): void => {
    dispatch(setModalOpen(!isModalOpen));
  };

  // Conditions for redirection are a side effect of a state update
  // So it needs to be executed here instead of the submit event handler
  useEffect(() => {
    if (!isAddressCorrected && isValidated) {
      goNext();
      dispatch(setFormValidationStatus(false));
    }
  }, [isAddressCorrected, isValidated, goNext, dispatch, isFormModified]);

  useEffect(() => {
    if (isModalOpen) {
      trackPageLoad(pathname, { isAddressValidationFailed: true });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (city) {
      return;
    }
    if (
      !errors.zipCode &&
      values.zipCode &&
      values.streetName &&
      isFormModified
    ) {
      dispatch(resetZursCode());
      clearTimeout(getCityDebounce);
      getCityDebounce = setTimeout(async () => {
        await dispatch(fetchCityFromAddress());
      }, 300);
    }
  }, [
    dispatch,
    errors.zipCode,
    values.streetName,
    values.zipCode,
    isFormModified,
  ]);

  useEffect(() => {
    if (isAddressCorrected && zursValue !== 'ZUERS_GK_0') {
      setModalOpen(false);
      trackPageLoad(pathname, { isAddressCorrected: true });
    }
  }, [isAddressCorrected, pathname, zursValue]);

  const onCall = (): void => {
    window.open(`tel:${ERGO_PHONE_NUMBER}`);
  };

  useEffect(() => {
    if (
      !values.streetName?.length ||
      !values.streetNumber?.length ||
      !values.zipCode?.length ||
      isLoadingZipCode ||
      isLoadingCustomerAddress ||
      errors.address ||
      zursValue === 'ZUERS_GK_0'
    ) {
      dispatch(setDisabledContinue(true));
    } else {
      dispatch(setDisabledContinue(false));
    }
  }, [
    dispatch,
    errors.address,
    errors.zipCode,
    isAddressCorrected,
    isLoadingCustomerAddress,
    isLoadingZipCode,
    values,
    values.streetName,
    values.streetNumber,
    values.zipCode,
    zursValue,
  ]);

  return (
    <FormSection className="address">
      <FormSectionHeader
        title={translations.step4.pageTitle}
        subtitle={translations.step4.pageSubtitle}
        showTooltip
        toolTipComponent={<AddressTooltipInfo />}
        onOpened={onModalTracking}
      />

      <FormSectionBody>
        {!!isAddressCorrected && <AddressMessage />}

        <StreetInfoInputs
          onChangeStreetName={handleOnChangeStreetName}
          onChangeStreetNumber={handleOnChangeStreetNumber}
          streetName={values.streetName}
          streetNameError={errors.streetName}
          streetNumber={values.streetNumber}
          streetNumberError={errors.streetNumber}
        />

        <ZipCodeInput
          zipCode={values.zipCode}
          zipCodeError={errors.zipCode}
          city={values.city}
          cityOptions={suggestions.cities.length > 1 ? suggestions.cities : []}
          onChangeZipCode={handleOnChangeZipCode}
          onChangeCity={handleOnChangeCity}
          isLoading={isLoadingZipCode}
        />
      </FormSectionBody>

      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBack}
          disableContinue={isDisabledContinue}
          isLoading={isLoadingCustomerAddress}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>

      <FormFooter />

      <ConfirmationModal
        isButtonDisabled={!errors.address}
        zipCode={oldAddressData.zipCode}
        city={oldAddressData.city}
        streetName={oldAddressData.streetName}
        streetNumber={oldAddressData.streetNumber}
        open={isModalOpen}
        onClose={handleModal}
        onCall={onCall}
        zursValue={zursValue}
        errors={errors}
        trackingOptions={trackingOptions}
      />

      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};
