import React from 'react';
import DetailsList from './components/DetailsList/DetailsList';
import FormFooter from '@eg/elements/FormFooter';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import translations from '../../constants/translations';
import useStepNavigation from '../../hooks/useStepNavigation';
import { getGenericErrorSelector } from '../../store/appSlice';
import { useAppSelector } from '../../store/hooks';
import useClickTracking from './hooks/useClickTracking';

const ContractReview = (): JSX.Element => {
  const isError = useAppSelector(getGenericErrorSelector);
  const { goNext, goPrev } = useStepNavigation();
  const { trackingOptions, onOpenAccordion, onClickLink } = useClickTracking();

  const handleContinue = (): void => {
    goNext();
  };

  const handleBack = (): void => {
    goPrev();
  };

  return (
    <FormSection>
      <FormSectionHeader
        withBox
        title={translations.step10.pageTitle}
        subtitle={translations.step10.pageSubtitle}
      />
      <FormSectionBody>
        <DetailsList
          onOpenAccordion={onOpenAccordion}
          onClickLink={onClickLink}
        />
      </FormSectionBody>

      <FormSectionBody>
        <h5 className="footer-notes__notice-title-text">
          {translations.step10.noteTitle}
        </h5>

        <p className="footer-notes__notice-text">
          {translations.step10.noteText}
        </p>
      </FormSectionBody>

      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBack}
          disableContinue={null}
          disableCancel={false}
          isLoading={null}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>

      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default ContractReview;
