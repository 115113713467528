export const getMapKey = <T>(
  value: string,
  mapObject: T
): keyof T | undefined => {
  if (!value || !mapObject) {
    return undefined;
  }

  const entry = Object.entries(mapObject).find(([, val]) => val === value);
  if (!entry) {
    return undefined;
  }

  return entry[0] as keyof T;
};

export const livingPlaceTypeMapping = {
  apartment: 'HAUPTWOHNUNG_IN_MEHRFAMILIENHAUS',
  singleFamilyHouse: 'HAUPTWOHNUNG_IN_EINFAMILIENHAUS',
};

export const getLivingPlaceFromMapping = (
  houseType: string
): keyof typeof livingPlaceTypeMapping =>
  getMapKey<typeof livingPlaceTypeMapping>(houseType, livingPlaceTypeMapping);

export const floorTypeMapping = {
  basement: 'KELLERGESCHOSS',
  groundFloor: 'ERDGESCHOSS',
  firstFloor: 'EINGANG_1_OG',
  secondFloor: 'EINGANG_2_OG',
  otherFloor: 'EINGANG_3_OG_ODER_DARUEBER',
};

export const getFloorTypeFromMapping = (
  floorType: string
): keyof typeof floorTypeMapping =>
  getMapKey<typeof floorTypeMapping>(floorType, floorTypeMapping);

export const policyTerminatorMapping = {
  policyHolder: 'GEKUENDIGT_DURCH_KUNDEN',
  insurer: 'GEKUENDIGT_DURCH_VERSICHERER',
};

export const contractTypeMapping = {
  household: 'HAUSRAT',
  glass: 'HAUSHALT_GLAS',
};

export const getContractTypeFromMapping = (
  contractType: string
): keyof typeof contractTypeMapping =>
  getMapKey<typeof contractTypeMapping>(contractType, contractTypeMapping);

export const deductibleAmountMapping = {
  flex300: 'EUR_300_FLEXI',
  zero: 'KEINE_SELBSTBETEILIGUNG',
  threeHundred: 'EUR_300_FEST',
};

export const getDeductibleAmountFromMapping = (
  deductibleAmount: string
): keyof typeof deductibleAmountMapping =>
  getMapKey<typeof deductibleAmountMapping>(
    deductibleAmount,
    deductibleAmountMapping
  );

export const damageTypeMapping = {
  burglary: 'EINBRUCHDIEBSTAHL',
  fire: 'FEUER',
  glassBreakage: 'GLASBRUCH',
  other: 'SONSTIGE',
  otherNaturalHazards: 'WEITERE_NATURGEFAHREN',
  robbery: 'RAUB',
  storm: 'STURM_ODER_HAGEL',
  tapWater: 'LEITUNGSWASSER',
  vandalism: 'VANDALISMUS',
};
