import { useSelector } from 'react-redux';
import { useAppDispatch } from './hooks';
import type { LivingPlaceFormTypes } from '../pages/step1/LivingPlaceTypes';
import type { HomeLocationFormTypes } from '../pages/step2/HomeLocationTypes';
import type { HomeSizeFormTypes } from '../pages/step3/HomeSquareMetersTypes';
import type { InsuranceDateFormTypes } from '../pages/step5/InsuranceDateTypes';
import type { DateOfBirthFormTypes } from '../pages/step6/DateOfBirthTypes';
import type { AddressFormTypes } from '../pages/step4/AddressTypes';
import {
  isLoadingSumInsuredSelector,
  selectIsLoading as selectIsLoadingStep3,
} from '../pages/step3/HomeSquareMetersSlice';
import {
  getCityError,
  getStreetNameError,
  getZipCodeError,
  addressIsCorrected,
  selectIsLoading as selectIsLoadingStep4,
  selectIsLoadingZipCode,
  getStreetNumberError,
  selectOldAddressData,
} from '../pages/step4/addressSlice';
import {
  isInsuranceDateValid,
  selectIsLoading as selectIsLoadingStep5,
} from '../pages/step5/insuranceDateSlice';
import {
  isAddressValid,
  selectIsValidated,
  zipCodeOptionsSelector,
} from '../pages/step4/addressSlice';
import { isInsuranceCoverageValidSelector } from '../pages/step7/insuranceCoverageSlice';
import type { RootState } from './types';
import { BankDetailsFormTypes } from '../pages/step11/BankDetailsTypes';
import { PersonalDataForm } from '../pages/step9/PersonalDataTypes';
import { AdditionalQuestionsForm } from 'commons/apis/hausrat/types';
import {
  isLoadingDateOfBirthSelector,
  isDateOfBirthValidSelector,
} from '../pages/step6/dateOfBirthSlice';
import { isLoadingPersonalDataSelector } from '../pages/step9/personalDataSlice';
import type { TermsOfServiceForm } from '../pages/step12/TermsOfServiceTypes';
import { InsuranceCoverageState } from '../pages/step7/InsuranceCoverageTypes';

/**
 * This returns uses type UseFormType, but since it returns an 'any' we let TS infeers typing
 * TODO: Fix UseFormType type composition.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useForm = () => {
  const livingPlaceForm = useSelector<RootState, LivingPlaceFormTypes>(
    state => state.livingPlace.form
  );

  const homeLocationForm = useSelector<RootState, HomeLocationFormTypes>(
    state => state.homeLocation.form
  );

  const homeSquareMetersForm = useSelector<RootState, HomeSizeFormTypes>(
    state => state.homeSquareMeters.form
  );

  const addressForm = useSelector<RootState, AddressFormTypes>(
    state => state.address.form
  );

  const insuranceDateForm = useSelector<RootState, InsuranceDateFormTypes>(
    state => state.insuranceDate.form
  );

  const dateOfBirthForm = useSelector<RootState, DateOfBirthFormTypes>(
    state => state.dateOfBirth.form
  );

  const bankDetailsForm = useSelector<RootState, BankDetailsFormTypes>(
    state => state.bankDetails.form
  );

  const personalDataForm = useSelector<RootState, PersonalDataForm>(
    state => state.personalData.form
  );

  const insuranceCoverageForm = useSelector<
    RootState,
    InsuranceCoverageState['form']
  >(state => state.insuranceCoverage.form);

  const additionalQuestions = useSelector<RootState, AdditionalQuestionsForm>(
    state => state.additionalQuestions.form
  );

  const compulsoryDetailsForm = useSelector<RootState, TermsOfServiceForm>(
    state => state.termsOfService.form
  );

  const isInsuranceDateStepValid = useSelector(isInsuranceDateValid);
  const streetNameError = useSelector(getStreetNameError);
  const streetNumberError = useSelector(getStreetNumberError);
  const zipCodeError = useSelector(getZipCodeError);
  const cityError = useSelector(getCityError);
  const isAddressStepValid = useSelector(isAddressValid);
  const zipCodeOptions = useSelector(zipCodeOptionsSelector);
  const isAddressCorrected = useSelector(addressIsCorrected);
  const oldAddressData = useSelector(selectOldAddressData);

  const isLoadingSquareMeters = useSelector(selectIsLoadingStep3);
  const isLoadingZipCode = useSelector(selectIsLoadingZipCode);
  const isLoadingCustomerAddress = useSelector(selectIsLoadingStep4);
  const isLoadingInsuranceDate = useSelector(selectIsLoadingStep5);
  const isValidatedAddress = useSelector(selectIsValidated);

  const isInsuranceCoverageValid = useSelector(
    isInsuranceCoverageValidSelector
  );

  const isLoadingDateOfBirth = useSelector(isLoadingDateOfBirthSelector);
  const isLoadingPersonalData = useSelector(isLoadingPersonalDataSelector);
  const isDateOfBirthValid = useSelector(isDateOfBirthValidSelector);
  const isLoadingSumInsured = useSelector(isLoadingSumInsuredSelector);

  return {
    ...livingPlaceForm,
    ...homeLocationForm,
    ...homeSquareMetersForm,
    ...addressForm,
    ...insuranceDateForm,
    ...dateOfBirthForm,
    ...insuranceCoverageForm,
    ...bankDetailsForm,
    ...additionalQuestions,
    ...personalDataForm,
    ...compulsoryDetailsForm,
    isInsuranceDateStepValid,
    isLoadingSquareMeters,
    isLoadingZipCode,
    isLoadingCustomerAddress,
    streetNameError,
    streetNumberError,
    zipCodeError,
    cityError,
    isAddressStepValid,
    isLoadingInsuranceDate,
    isLoadingDateOfBirth,
    isDateOfBirthValid,
    isLoadingPersonalData,
    zipCodeOptions,
    isValidatedAddress,
    isInsuranceCoverageValid,
    isLoadingSumInsured,
    isAddressCorrected,
    oldAddressData,
    dispatch: useAppDispatch(),
  };
};
