/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';

const SENSITIVE_FIELDS = [
  // IddInitDto -> IddTextVar
  'wert',

  //  SpcsAddress
  'anrede',
  'hausnummer',
  'hausNummer',
  'ort',
  'postleitzahl',
  'hausnummerZusatz',
  'strasse',
  'adressZusatz',
  'streetName',
  'buildingNumber',
  'buildingNumberAddition',
  'street',
  'streetNumber',
  'additionalAddressInformation',
  'postalCode',
  'city',
  'additionalCityInformation',
  'country',
  'country',
  'adresse',
  'areaCode',

  // SpcsBankData
  'iban',
  'bic',
  'financialInstitutionName',
  'city',
  'mrn',
  'accountOwner',
  'rufnummer',

  // Personal data
  'salutation',
  'academicTitle',
  'firstName',
  'familyName',
  'dateOfBirth',
  'geburtsdatum',
  'geburtsdatumVN',
  'placeOfBirth',
  'nationality',
  'email',
  'lastName',
  'password',
  'name',
  'vorname',
  'nachname',
  'privat',
  'geschäftlich',
  'writtenOfferEmail',
  'versandmail',
  'vertriebskanal',

  //Telephone data
  'baseNumber',
  'internationalAreaCode',
  'number',
  'dialNumber',
  'nummer',

  // Agency data:
  'dienststelle',
  'gesellschaft',
  'provisionssplit',

  // OE
  'versandAdresseId',

  // Paris
  'internationaleVorwahl',
  'vorwahl',

  // OE Hausrat
  'plz',
  'land',
  'vuNr',
  'vsScheinNr',

  // Address Validation Service
  'houseNumber',
  'zipCode',
];

const hashString = '*********';

const replaceAllConfidentialFieldsValuesToHash = (
  dataCopy: Record<string, any>
): Record<string, any> => {
  Object.entries(dataCopy).forEach(([key, value]) => {
    if (!value) {
      dataCopy[key] = String(value);
    } else if (typeof value === 'object') {
      dataCopy[key] = replaceAllConfidentialFieldsValuesToHash(value);
    } else if (SENSITIVE_FIELDS.includes(key)) {
      dataCopy[key] = hashString;
    }
  });
  return dataCopy;
};

const hashConfidentialData = (data: any): any => {
  try {
    if (!data) {
      return data;
    }
    if (typeof data === 'string') {
      try {
        return replaceAllConfidentialFieldsValuesToHash(JSON.parse(data));
      } catch (e) {
        return data;
      }
    }
    if (typeof data === 'object') {
      return replaceAllConfidentialFieldsValuesToHash(cloneDeep(data));
    }
  } catch (e) {
    console.error(e);
  }
  return data;
};

export const ConfidentialDataHelper = {
  hashConfidentialData,
  hashString,
};
