import { spcsHttp } from '../../http/spcsHttp';
import {
  deleteBankData as deleteBankDataRoute,
  getBankDataFromIban as getBankDataRoute,
  getUrl,
} from '../constants';
import {
  BankData,
  CreateBankDataResponse,
  GetBankDataParams,
  DeleteBankDetailsResponse,
} from './types';

export const getBankDataFromIban = async ({
  businessId,
  iban,
  mrn,
  personId,
}: GetBankDataParams): Promise<BankData> => {
  try {
    const { method, path } = getBankDataRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':personId', personId);

    const response = await spcsHttp<CreateBankDataResponse>(url, {
      method,
      payload: {
        iban,
        mrn,
      },
    });

    const [person] = response.data.persons;
    const [payment] = person?.payments.bankdata;

    if (payment) {
      return payment;
    }

    throw new Error('Invalid response from spcs');
  } catch (error) {
    throw error;
  }
};

export const deleteBankData = async ({
  businessId,
  personId,
  paymentId,
}: GetBankDataParams): Promise<DeleteBankDetailsResponse> => {
  try {
    const { method, path } = deleteBankDataRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':personId', personId)
      .replace(':paymentId', paymentId);

    const response = await spcsHttp<DeleteBankDetailsResponse>(url, {
      method,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
