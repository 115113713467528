import React from 'react';

import Select from '@eg/elements/components/Select';
import Input from '@eg/elements/Input';

import type { DropdownProps } from './DropDown.d';
import './DropDown.scss';
import translations from '../../constants/translations';

const DropDown = ({
  id,
  value,
  className = '',
  options,
  label,
  display = 'col',
  onChange,
  selectOptionLabel = translations.commons.pleaseSelect,
  placeholder,
  readonly = false,
  errors,
  dataTestId = 'dropdown',
}: DropdownProps): JSX.Element => {
  const hasErrors = errors?.length;
  const displayClassName = `dropdown--${display}`;

  const handleOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    let value = event.target.value;

    if (value === 'default') {
      value = undefined;
    }

    onChange(value);
  };

  return (
    <div
      className={`dropdown ${displayClassName} ${className}`}
      data-testid={dataTestId}
    >
      <div className="dropdown__labelWrapper">
        <label
          className="dropdown__label"
          aria-label={`${id}-select`}
          htmlFor={id}
          data-testid={`${dataTestId}-label`}
        >
          {label}
        </label>
      </div>

      <div className="dropdown__selectWrapper">
        {readonly && (
          <Input
            className="dropdown__input"
            id={id}
            readOnly={true}
            value={value}
            placeholder={placeholder}
            type="text"
            data-testid={`${dataTestId}-textbox`}
          />
        )}

        {!readonly && (
          <Select
            className="dropdown__select"
            id={id}
            defaultValue={value}
            onChange={handleOnChange}
            error={hasErrors}
            placeholder={placeholder}
            data-testid={`${dataTestId}-select`}
          >
            {
              <>
                <option hidden value="default">
                  {selectOptionLabel}
                </option>
                {options?.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </>
            }
          </Select>
        )}

        {hasErrors && (
          <ul className="dropdown__errors" data-testid={`${dataTestId}-errors`}>
            {errors.map(error => (
              <li key={error} className="dropdown__error">
                {error}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropDown;
