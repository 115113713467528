import type { BicycleAndEbikeCoInsuredCoverType as BikeCover } from '../pages/step7/InsuranceCoverageTypes';
import { formatDecimals } from '../utils';

export const step10 = {
  pageTitle: 'Sie haben es fast geschafft.',
  pageSubtitle:
    'Bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.',
  buttonChange: 'Angaben ändern',
  noteTitle: 'Hinweis:',
  noteText:
    'Im nächsten Schritt sind noch Angaben zu Ihrer Bankverbindung nötig.',
  formCategoryNameTariff: 'Tarif und Beitrag',
  formSubCategoryNameTariffType: 'Gewählter Tarif:',
  formSubCategoryNameSum: 'Versicherungssumme:',
  formSubCategoryNameModules: 'Gewählte Leistungsbausteine:',
  formSubCategoryNameExcess: 'Selbstbeteiligung:',
  formSubCategoryContentExcessNone: 'Keine Selbstbeteiligung',
  formSubCategoryContentExcess300: '300,00 €',
  formSubCategoryContentExcess300flex: '300,00 € flexibel',
  formSubCategoryNameStartDate: 'Versicherungsbeginn:',
  formSubCategoryNameContractTerm: 'Vertragslaufzeit:',
  formSubCategoryContentContractTermOneYear: '1 Jahr',
  formSubCategoryContentContractTermTwoYears: '2 Jahre',
  formSubCategoryContentContractTermThreeYears: '3 Jahre',
  formSubCategoryContentContractTermFourYears: '4 Jahre',
  formSubCategoryContentContractTermFiveYears: '5 Jahre',
  formSubCategoryContentContractTermSixYears: '6 Jahr',
  formSubCategoryContentContractTermSevenYears: '7 Jahre',
  formSubCategoryContentContractTermEightYears: '8 Jahre',
  formSubCategoryContentContractTermNineYears: '9 Jahre',
  formSubCategoryContentContractTermTenYears: '10 Jahre',
  formSubCategoryNameContribution: 'Ihr Beitrag:',
  formSubCategoryContentContribution: (percent: string): string =>
    `(inkl. Versicherungssteuer von zurzeit ${percent} %)`,
  formSubCategoryTotalDueContribution: 'Ihr zu zahlender Beitrag:',
  formSubCategoryTotalDueContent: '(inkl. Versicherungssteuer)',
  formSubCategoryHouseDueContribution: 'Beitrag Hausratversicherung:',
  formSubCategoryGlassDueContribution: 'Beitrag Glasversicherung:',
  formCategoryNameAddress: 'Persönliche Daten',
  formSubCategoryNameClientGender: 'Anrede',
  formSubCategoryNameClientFirstName: 'Vorname',
  formSubCategoryNameClientSurname: 'Nachname',
  formSubCategoryNameClientAddress: 'Adresse',
  formSubCategoryNameClientBirth: 'Geburtsdatum',
  formCategoryNameHouse: 'Angaben zu Ihrer Wohnung',
  formSubCategoryNameHouseType: 'Art der Wohnung:',
  formSubCategoryNameFloorType: 'Lage der Wohnung:',
  formSubCategoryNameSize: 'Wohnfläche:',
  formSubCategoryNameMandatory:
    'Ich bestätige, dass meine oben angegebene Postanschrift auch die des versicherten Objekts ist.',
  formSubCategoryNameEmail: 'E-Mail-Adresse',
  formSubCategoryNameTelephone: 'Telefon',
  formCategoryNamePrevious: 'Vorversicherung und Vorschäden',
  formCategoryNamePreviousInsuranceHeadline: 'Hausratversicherung',
  formCategoryNamePreviousGlassInsuranceHeadline: 'Glasversicherung',
  formSubCategoryNameLastYears: 'Vorversicherung Hausratversicherung:',
  formSubCategoryNameCompanyName: 'Name der Gesellschaft:',
  formSubCategoryNamePolicyNumber: 'Versicherungsscheinnummer:',
  formSubCategoryNamePolicyTerminated: 'Gekündigt von:',
  formSubCategoryNameExpiration: 'Vertragsablauf:',
  formSubCategoryNameDamageFiveYears: 'Vorschäden Hausratversicherung',
  formSubCategoryNameDamageArt: 'Schadenart:',
  formSubCategoryNameDamageAmount: 'Schadenhöhe:',
  formSubCategoryNameDamageYear: 'Schadenjahr:',
  formSubCategoryNameLocks: 'Vorhandene Türschlösser',
  constantHouseTypeCopiesApartment: 'Wohnung in einem Mehrfamilienhaus',
  constantHouseTypeCopiesSingleHouse: 'In einem Einfamilienhaus',
  constantFloorTypeCopiesBasemeant: 'Keller bzw. Souterrain',
  constantFloorTypeCopiesGroundFloor: 'Erdgeschoss bzw. Hochparterre',
  constantFloorTypeCopiesFirstFloor: '1. Obergeschoss',
  constantFloorTypeCopiesSecondFloor: '2. Obergeschoss',
  constantFloorTypeCopiesOtherFloor: '3. Obergeschoss oder darüber',
  constantPreviousContractTermitedByCopiesPolicyHolder: 'Versicherungsnehmer',
  constantPreviousContractTermitedByCopiesInsurer: 'Versicherer',
  constantProductModulesCopiesSimpleTheft: 'Einfacher Diebstahl bis 10.000 €',
  constantProductModulesCopiesHouseAndFlat:
    'Haus- und Wohnungsschutzbrief bis 3.000 €',
  constantProductModulesCopiesGlass: 'Glasversicherung',
  formPreinsuranceGlassInsurance: 'Vorversicherung Glasversicherung',
  formPreviousInsuraneGlassDamage: 'Vorschäden Glasversicherung',
  constantProductModulesCopiesOtherNaturalHazards:
    'Weitere Naturgefahren (Selbstbeteiligung 500 €)',
  constantProductModulesCopiesUnnamedPerils:
    'Unbenannte Gefahren (Selbstbeteiligung 300 €)',
  constantProductModulesCopiesBicycleAndEbike: (
    type: BikeCover,
    ammount: number
  ): string => {
    return `${
      type === 'fahrraddiebstahl'
        ? 'Fahrraddiebstahl bis'
        : 'Fahrradkasko und Mobilitätsschutz bis'
    } ${formatDecimals(ammount)} €`;
  },
  constantTarifTypeCopies: (bonus: string, tariff: string): string =>
    `ERGO Hausratversicherung ${tariff}${bonus.length ? ` ${bonus}` : ''}`,
  constantTarifTypeSubLabelCopies: (tariff: string): string =>
    tariff === 'Best'
      ? 'Schäden durch grobe Fahrlässigkeit'
      : 'Schäden durch grobe Fahrlässigkeit bis 10.000 €',
  bonusText: ' inkl. 13 % Startbonus',
  constantElectric: 'Elektronische oder mechatronische Türschlösser',
};
