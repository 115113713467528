import DownloadIcon from '@eg/elements/Icons/DownloadIcon';
import SelectRow from '@eg/elements/SelectRow';
import { AemAssetsKeys } from 'commons/apis/aemIntegration/types/index';
import React from 'react';
import AemDownloadLink from '../../api/aemIntegration/AemDownloadLink';
import EButton from '../../components/EButton/EButton';
import translations from '../../constants/translations';

import { useForm } from '../../store/useForm';
import {
  ContractPeriodType,
  DeductibleAmountType,
  PaymentMethodType,
} from './InsuranceCoverageTypes';
import {
  setContractPeriod,
  setDeductibleAmount,
  setPaymentMethod,
} from './insuranceCoverageSlice';
import { ContractTerm } from './modals/ContractTerm';
import { Excess } from './modals/Excess';
import { PaymentMethod } from './modals/PaymentMethod';
import { InsuranceCoverageFields } from '../../store/offerSlice';
import { InfoIcons } from './TrackingTypes';
import useClickTracking from './hooks/useClickTracking';
import { TrackingElementPage7 } from './TrackingElements';

type Props = {
  fields: InsuranceCoverageFields;
  handleChangeOption: (
    id: string,
    selected: string | boolean,
    value?: number | string
  ) => void;
  onModalOpen: (modalId: InfoIcons) => void;
};

const InsuranceCoveragePayment = ({
  fields,
  handleChangeOption,
  onModalOpen,
}: Props): JSX.Element => {
  const { dispatch } = useForm();
  const { trackingOptions } = useClickTracking();
  const onPaymentChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name } = event.target;
    const value = event.target.value as PaymentMethodType;
    dispatch(setPaymentMethod(value));
    handleChangeOption(name, value);
  };

  const onDeductableAmountChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name } = event.target;
    const value = event.target.value as DeductibleAmountType;
    dispatch(setDeductibleAmount(value));
    handleChangeOption(name, true, value);
  };

  const onContractTermChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name } = event.target;
    const value = event.target.value as ContractPeriodType;
    dispatch(setContractPeriod(value));
    handleChangeOption(name, value);
  };

  return (
    <div className="insurance-coverage__payment">
      {fields.contractTerms.map(field => {
        let Tooltip: JSX.Element | undefined;
        let onChange:
          | ((event: React.ChangeEvent<HTMLInputElement>) => void)
          | undefined;

        switch (field.name) {
          case 'contract-term':
            Tooltip = ContractTerm({ onOpen: onModalOpen });
            onChange = onContractTermChangeHandler;
            break;

          case 'deductible-amount':
            Tooltip = Excess({ onOpen: onModalOpen });
            onChange = onDeductableAmountChangeHandler;
            break;

          case 'payment-method':
            Tooltip = PaymentMethod({ onOpen: onModalOpen });
            onChange = onPaymentChangeHandler;
            break;
          default:
        }

        return (
          <SelectRow
            key={field.name}
            disabled={field.disabled}
            label={field.label}
            name={field.name}
            onChange={onChange}
            required={field.mandatory}
            tooltip={Tooltip}
            value={field.value ?? undefined}
          >
            {field.options.map(option => (
              <option
                value={option.value}
                key={`${field.name}-${option.value}`}
              >
                {option.label}
              </option>
            ))}
          </SelectRow>
        );
      })}

      <AemDownloadLink fragmentName={AemAssetsKeys.PDF_BENEFITS_DOWNLOAD}>
        <EButton
          variant="text-link"
          iconLeft={<DownloadIcon />}
          className="download-link"
          trackingOptions={{
            element: TrackingElementPage7.LINK_DOWNLOAD_PDF,
            options: trackingOptions,
          }}
        >
          {translations.step7.downloadPdf}
        </EButton>
      </AemDownloadLink>
    </div>
  );
};

export default InsuranceCoveragePayment;
