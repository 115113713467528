import { validateHasExistingDoorLocksPayload } from 'commons';
import translations from '../../../../constants/translations';
import { ValidatorFunction } from '../../../../store/types';
import { generateInvalidField, generateValidField } from './validators';

export const existingDoorLocksErrors: Record<'selected', string> = {
  selected: translations.step8.existingDoorLocksSelectedError,
};

type ExistingDoorLocksErrors = typeof existingDoorLocksErrors;
type DoorLocksErrorsKey = keyof ExistingDoorLocksErrors;

export const existingDoorLocksValidations: Record<
  DoorLocksErrorsKey,
  ValidatorFunction<boolean>
> = {
  selected: (value: boolean) => {
    return validateHasExistingDoorLocksPayload({ selected: value })
      ? generateValidField()
      : generateInvalidField(existingDoorLocksErrors, 'selected');
  },
};
