import { InsuranceEndDate } from '../apis/hausrat/types';

export const isUndefined = (field: unknown): boolean =>
  typeof field === 'undefined';
export const isNotUndefined = (field: unknown): boolean => !isUndefined(field);

export const isString = (field: unknown): boolean => typeof field === 'string';
export const isNumber = (field: unknown): boolean => typeof field === 'number';

export const hasLength = (field: string | []): boolean => field?.length > 0;

export const getLowerOrEqualThanValidator =
  (field: string | number) =>
  (amount: number): boolean =>
    Number(field) === amount || Number(field) < amount;

export const isValidDateInputValue = (
  objectDate: InsuranceEndDate
): boolean => {
  const isValidDay = (day: string): boolean => {
    const dayInNumber = Number(day);

    if (typeof dayInNumber !== 'number') {
      return false;
    }

    return dayInNumber > 0 && dayInNumber < 32;
  };

  const isValidMonth = (month: string): boolean => {
    const monthInNumber = Number(month);
    if (typeof monthInNumber !== 'number') {
      return false;
    }
    return monthInNumber > 0 && monthInNumber < 13;
  };

  const isValidYear = (year: string): boolean => {
    return year?.length === 4;
  };
  return (
    isValidDay(objectDate?.day) &&
    isValidMonth(objectDate?.month) &&
    isValidYear(objectDate?.year)
  );
};
