import React from 'react';
import type { FooterInformationProps } from './FooterInformationTypes';
import translations from '../../../../constants/translations';

const FooterInformation = ({
  onClick,
}: FooterInformationProps): JSX.Element => {
  return (
    <footer className="esc_container esc_box">
      <p className="footer__text">{translations.step1.footerText}</p>
      <button
        className="button-reset esc_text-link"
        data-testid="open-apartment-type-modal"
        onClick={(): void => onClick(true)}
      >
        {translations.step1.moreInfo}
      </button>
    </footer>
  );
};

export default FooterInformation;
