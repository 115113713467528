import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const OtherDangers = (): JSX.Element => {
  return (
    <div data-testid="other-dangers-info">
      <h3 data-testid="other-dangers-title">{step7.otherDangers.title}</h3>
      <p
        data-testid="other-dangers-p1"
        dangerouslySetInnerHTML={{
          __html: step7.otherDangers.paragraph1,
        }}
      ></p>
      <p
        data-testid="other-dangers-p2"
        dangerouslySetInnerHTML={{
          __html: step7.otherDangers.paragraph2,
        }}
      ></p>
      <p
        data-testid="other-dangers-p3"
        dangerouslySetInnerHTML={{
          __html: step7.otherDangers.paragraph3,
        }}
      ></p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li>{step7.otherDangers.listOptions1.a}</li>
        <li>{step7.otherDangers.listOptions1.b}</li>
      </ul>
      <p
        data-testid="other-dangers-p4"
        dangerouslySetInnerHTML={{
          __html: step7.otherDangers.paragraph4,
        }}
      ></p>
      <p
        data-testid="other-dangers-p5"
        dangerouslySetInnerHTML={{
          __html: step7.otherDangers.paragraph5,
        }}
      ></p>
      <p data-testid="other-dangers-p6">{step7.otherDangers.paragraph6}</p>
    </div>
  );
};

export default OtherDangers;
