export const countries = [
  {
    name: 'Afghanistan',
    code: '+93',
    continent: 'AS',
    country_code: 'AF',
  },
  {
    name: 'Aland Islands',
    code: '+358',
    continent: 'EU',
    country_code: 'AX',
  },
  {
    name: 'Albanien',
    code: '+355',
    continent: 'EU',
    country_code: 'AL',
  },
  {
    name: 'Algeria',
    code: '+213',
    continent: 'AF',
    country_code: 'DZ',
  },
  {
    name: 'AmericanSamoa',
    code: '+1684',
    continent: 'OC',
    country_code: 'AS',
  },
  {
    name: 'Andorra',
    code: '+376',
    continent: 'EU',
    country_code: 'AD',
  },
  {
    name: 'Angola',
    code: '+244',
    continent: 'AF',
    country_code: 'AO',
  },
  {
    name: 'Anguilla',
    code: '+1264',
    continent: 'NA',
    country_code: 'AI',
  },
  {
    name: 'Antarctica',
    code: '+672',
    continent: 'not_found',
    country_code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    code: '+1268',
    continent: 'NA',
    country_code: 'AG',
  },
  {
    name: 'Argentina',
    code: '+54',
    continent: 'SA',
    country_code: 'AR',
  },
  {
    name: 'Armenia',
    code: '+374',
    continent: 'AS',
    country_code: 'AM',
  },
  {
    name: 'Aruba',
    code: '+297',
    continent: 'NA',
    country_code: 'AW',
  },
  {
    name: 'Australia',
    code: '+61',
    continent: 'OC',
    country_code: 'AU',
  },
  {
    name: 'Österreich',
    code: '+43',
    continent: 'EU',
    country_code: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: '+994',
    continent: 'AS',
    country_code: 'AZ',
  },
  {
    name: 'Bahamas',
    code: '+1242',
    continent: 'NA',
    country_code: 'BS',
  },
  {
    name: 'Bahrain',
    code: '+973',
    continent: 'AS',
    country_code: 'BH',
  },
  {
    name: 'Bangladesh',
    code: '+880',
    continent: 'AS',
    country_code: 'BD',
  },
  {
    name: 'Barbados',
    code: '+1246',
    continent: 'NA',
    country_code: 'BB',
  },
  {
    name: 'Belarus',
    code: '+375',
    continent: 'EU',
    country_code: 'BY',
  },
  {
    name: 'Belgien',
    code: '+32',
    continent: 'EU',
    country_code: 'BE',
  },
  {
    name: 'Belize',
    code: '+501',
    continent: 'NA',
    country_code: 'BZ',
  },
  {
    name: 'Benin',
    code: '+229',
    continent: 'AF',
    country_code: 'BJ',
  },
  {
    name: 'Bermuda',
    code: '+1441',
    continent: 'NA',
    country_code: 'BM',
  },
  {
    name: 'Bhutan',
    code: '+975',
    continent: 'AS',
    country_code: 'BT',
  },
  {
    name: 'Bolivia, Plurinational State of',
    code: '+591',
    continent: 'SA',
    country_code: 'BO',
  },
  {
    name: 'Bosnien und Herzegowina',
    code: '+387',
    continent: 'EU',
    country_code: 'BA',
  },
  {
    name: 'Botswana',
    code: '+267',
    continent: 'AF',
    country_code: 'BW',
  },
  {
    name: 'Brazil',
    code: '+55',
    continent: 'SA',
    country_code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    code: '+246',
    continent: 'AS',
    country_code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    code: '+673',
    continent: 'AS',
    country_code: 'BN',
  },
  {
    name: 'Bulgarien',
    code: '+359',
    continent: 'EU',
    country_code: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: '+226',
    continent: 'AF',
    country_code: 'BF',
  },
  {
    name: 'Burundi',
    code: '+257',
    continent: 'AF',
    country_code: 'BI',
  },
  {
    name: 'Cambodia',
    code: '+855',
    continent: 'AS',
    country_code: 'KH',
  },
  {
    name: 'Cameroon',
    code: '+237',
    continent: 'AF',
    country_code: 'CM',
  },
  {
    name: 'Canada',
    code: '+1',
    continent: 'NA',
    country_code: 'CA',
  },
  {
    name: 'Cape Verde',
    code: '+238',
    continent: 'AF',
    country_code: 'CV',
  },
  {
    name: 'Cayman Islands',
    code: '+ 345',
    continent: 'NA',
    country_code: 'KY',
  },
  {
    name: 'Central African Republic',
    code: '+236',
    continent: 'AF',
    country_code: 'CF',
  },
  {
    name: 'Chad',
    code: '+235',
    continent: 'AF',
    country_code: 'TD',
  },
  {
    name: 'Chile',
    code: '+56',
    continent: 'SA',
    country_code: 'CL',
  },
  {
    name: 'China',
    code: '+86',
    continent: 'AS',
    country_code: 'CN',
  },
  {
    name: 'Christmas Island',
    code: '+61',
    continent: 'AS',
    country_code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: '+61',
    continent: 'AS',
    country_code: 'CC',
  },
  {
    name: 'Colombia',
    code: '+57',
    continent: 'SA',
    country_code: 'CO',
  },
  {
    name: 'Comoros',
    code: '+269',
    continent: 'AF',
    country_code: 'KM',
  },
  {
    name: 'Congo',
    code: '+242',
    continent: 'AF',
    country_code: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    code: '+243',
    continent: 'AF',
    country_code: 'CD',
  },
  {
    name: 'Cook Islands',
    code: '+682',
    continent: 'OC',
    country_code: 'CK',
  },
  {
    name: 'Costa Rica',
    code: '+506',
    continent: 'NA',
    country_code: 'CR',
  },
  {
    name: "Cote d'Ivoire",
    code: '+225',
    continent: 'AF',
    country_code: 'CI',
  },
  {
    name: 'Kroatien',
    code: '+385',
    continent: 'EU',
    country_code: 'HR',
  },
  {
    name: 'Cuba',
    code: '+53',
    continent: 'NA',
    country_code: 'CU',
  },
  {
    name: 'Cyprus',
    code: '+357',
    continent: 'AS',
    country_code: 'CY',
  },
  {
    name: 'Tschechien',
    code: '+420',
    continent: 'EU',
    country_code: 'CZ',
  },
  {
    name: 'Dänemark',
    code: '+45',
    continent: 'EU',
    country_code: 'DK',
  },
  {
    name: 'Djibouti',
    code: '+253',
    continent: 'AF',
    country_code: 'DJ',
  },
  {
    name: 'Dominica',
    code: '+1767',
    continent: 'NA',
    country_code: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: '+1849',
    continent: 'NA',
    country_code: 'DO',
  },
  {
    name: 'Ecuador',
    code: '+593',
    continent: 'SA',
    country_code: 'EC',
  },
  {
    name: 'Egypt',
    code: '+20',
    continent: 'AF',
    country_code: 'EG',
  },
  {
    name: 'El Salvador',
    code: '+503',
    continent: 'NA',
    country_code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: '+240',
    continent: 'AF',
    country_code: 'GQ',
  },
  {
    name: 'Eritrea',
    code: '+291',
    continent: 'AF',
    country_code: 'ER',
  },
  {
    name: 'Estland',
    code: '+372',
    continent: 'EU',
    country_code: 'EE',
  },
  {
    name: 'Ethiopia',
    code: '+251',
    continent: 'AF',
    country_code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: '+500',
    continent: 'SA',
    country_code: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: '+298',
    continent: 'EU',
    country_code: 'FO',
  },
  {
    name: 'Fiji',
    code: '+679',
    continent: 'OC',
    country_code: 'FJ',
  },
  {
    name: 'Finnland',
    code: '+358',
    continent: 'EU',
    country_code: 'FI',
  },
  {
    name: 'Frankreich',
    code: '+33',
    continent: 'EU',
    country_code: 'FR',
  },
  {
    name: 'French Guiana',
    code: '+594',
    continent: 'SA',
    country_code: 'GF',
  },
  {
    name: 'French Polynesia',
    code: '+689',
    continent: 'OC',
    country_code: 'PF',
  },
  {
    name: 'Gabon',
    code: '+241',
    continent: 'AF',
    country_code: 'GA',
  },
  {
    name: 'Gambia',
    code: '+220',
    continent: 'AF',
    country_code: 'GM',
  },
  {
    name: 'Georgia',
    code: '+995',
    continent: 'AS',
    country_code: 'GE',
  },
  {
    name: 'Deutschland',
    code: '+49',
    continent: 'EU',
    country_code: 'DE',
  },
  {
    name: 'Ghana',
    code: '+233',
    continent: 'AF',
    country_code: 'GH',
  },
  {
    name: 'Gibraltar',
    code: '+350',
    continent: 'EU',
    country_code: 'GI',
  },
  {
    name: 'Griechenland',
    code: '+30',
    continent: 'EU',
    country_code: 'GR',
  },
  {
    name: 'Greenland',
    code: '+299',
    continent: 'NA',
    country_code: 'GL',
  },
  {
    name: 'Grenada',
    code: '+1473',
    continent: 'NA',
    country_code: 'GD',
  },
  {
    name: 'Guadeloupe',
    code: '+590',
    continent: 'NA',
    country_code: 'GP',
  },
  {
    name: 'Guam',
    code: '+1671',
    continent: 'OC',
    country_code: 'GU',
  },
  {
    name: 'Guatemala',
    code: '+502',
    continent: 'NA',
    country_code: 'GT',
  },
  {
    name: 'Guernsey',
    code: '+44',
    continent: 'EU',
    country_code: 'GG',
  },
  {
    name: 'Guinea',
    code: '+224',
    continent: 'AF',
    country_code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    code: '+245',
    continent: 'AF',
    country_code: 'GW',
  },
  {
    name: 'Guyana',
    code: '+595',
    continent: 'SA',
    country_code: 'GY',
  },
  {
    name: 'Haiti',
    code: '+509',
    continent: 'NA',
    country_code: 'HT',
  },
  {
    name: 'Vatikanstadt',
    code: '+379',
    continent: 'not_found',
    country_code: 'VA',
  },
  {
    name: 'Honduras',
    code: '+504',
    continent: 'NA',
    country_code: 'HN',
  },
  {
    name: 'Hong Kong',
    code: '+852',
    continent: 'AS',
    country_code: 'HK',
  },
  {
    name: 'Ungarn',
    code: '+36',
    continent: 'EU',
    country_code: 'HU',
  },
  {
    name: 'Island',
    code: '+354',
    continent: 'EU',
    country_code: 'IS',
  },
  {
    name: 'India',
    code: '+91',
    continent: 'AS',
    country_code: 'IN',
  },
  {
    name: 'Indonesia',
    code: '+62',
    continent: 'AS',
    country_code: 'ID',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    code: '+98',
    continent: 'AS',
    country_code: 'IR',
  },
  {
    name: 'Iraq',
    code: '+964',
    continent: 'AS',
    country_code: 'IQ',
  },
  {
    name: 'Irland',
    code: '+353',
    continent: 'EU',
    country_code: 'IE',
  },
  {
    name: 'Isle of Man',
    code: '+44',
    continent: 'EU',
    country_code: 'IM',
  },
  {
    name: 'Israel',
    code: '+972',
    continent: 'AS',
    country_code: 'IL',
  },
  {
    name: 'Italien',
    code: '+39',
    continent: 'EU',
    country_code: 'IT',
  },
  {
    name: 'Jamaica',
    code: '+1876',
    continent: 'NA',
    country_code: 'JM',
  },
  {
    name: 'Japan',
    code: '+81',
    continent: 'AS',
    country_code: 'JP',
  },
  {
    name: 'Jersey',
    code: '+44',
    continent: 'EU',
    country_code: 'JE',
  },
  {
    name: 'Jordan',
    code: '+962',
    continent: 'AS',
    country_code: 'JO',
  },
  {
    name: 'Kasachstan',
    code: '+77',
    continent: 'AS',
    country_code: 'KZ',
  },
  {
    name: 'Kenya',
    code: '+254',
    continent: 'AF',
    country_code: 'KE',
  },
  {
    name: 'Kiribati',
    code: '+686',
    continent: 'OC',
    country_code: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    code: '+850',
    continent: 'AS',
    country_code: 'KP',
  },
  {
    name: 'Korea, Republic of South Korea',
    code: '+82',
    continent: 'AS',
    country_code: 'KR',
  },
  {
    name: 'Kuwait',
    code: '+965',
    continent: 'AS',
    country_code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: '+996',
    continent: 'AS',
    country_code: 'KG',
  },
  {
    name: 'Laos',
    code: '+856',
    continent: 'AS',
    country_code: 'LA',
  },
  {
    name: 'Lettland',
    code: '+371',
    continent: 'EU',
    country_code: 'LV',
  },
  {
    name: 'Lebanon',
    code: '+961',
    continent: 'AS',
    country_code: 'LB',
  },
  {
    name: 'Lesotho',
    code: '+266',
    continent: 'AF',
    country_code: 'LS',
  },
  {
    name: 'Liberia',
    code: '+231',
    continent: 'AF',
    country_code: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: '+218',
    continent: 'AF',
    country_code: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: '+423',
    continent: 'EU',
    country_code: 'LI',
  },
  {
    name: 'Litauen',
    code: '+370',
    continent: 'EU',
    country_code: 'LT',
  },
  {
    name: 'Luxemburg',
    code: '+352',
    continent: 'EU',
    country_code: 'LU',
  },
  {
    name: 'Macao',
    code: '+853',
    continent: 'AS',
    country_code: 'MO',
  },
  {
    name: 'Mazedonien',
    code: '+389',
    continent: 'EU',
    country_code: 'MK',
  },
  {
    name: 'Madagascar',
    code: '+261',
    continent: 'AF',
    country_code: 'MG',
  },
  {
    name: 'Malawi',
    code: '+265',
    continent: 'AF',
    country_code: 'MW',
  },
  {
    name: 'Malaysia',
    code: '+60',
    continent: 'AS',
    country_code: 'MY',
  },
  {
    name: 'Maldives',
    code: '+960',
    continent: 'AS',
    country_code: 'MV',
  },
  {
    name: 'Mali',
    code: '+223',
    continent: 'AF',
    country_code: 'ML',
  },
  {
    name: 'Malta',
    code: '+356',
    continent: 'EU',
    country_code: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: '+692',
    continent: 'OC',
    country_code: 'MH',
  },
  {
    name: 'Martinique',
    code: '+596',
    continent: 'NA',
    country_code: 'MQ',
  },
  {
    name: 'Mauritania',
    code: '+222',
    continent: 'AF',
    country_code: 'MR',
  },
  {
    name: 'Mauritius',
    code: '+230',
    continent: 'AF',
    country_code: 'MU',
  },
  {
    name: 'Mayotte',
    code: '+262',
    continent: 'AF',
    country_code: 'YT',
  },
  {
    name: 'Mexico',
    code: '+52',
    continent: 'NA',
    country_code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    code: '+691',
    continent: 'OC',
    country_code: 'FM',
  },
  {
    name: 'Moldau',
    code: '+373',
    continent: 'EU',
    country_code: 'MD',
  },
  {
    name: 'Monaco',
    code: '+377',
    continent: 'EU',
    country_code: 'MC',
  },
  {
    name: 'Mongolia',
    code: '+976',
    continent: 'AS',
    country_code: 'MN',
  },
  {
    name: 'Montenegro',
    code: '+382',
    continent: 'EU',
    country_code: 'ME',
  },
  {
    name: 'Montserrat',
    code: '+1664',
    continent: 'NA',
    country_code: 'MS',
  },
  {
    name: 'Morocco',
    code: '+212',
    continent: 'AF',
    country_code: 'MA',
  },
  {
    name: 'Mozambique',
    code: '+258',
    continent: 'AF',
    country_code: 'MZ',
  },
  {
    name: 'Myanmar',
    code: '+95',
    continent: 'AS',
    country_code: 'MM',
  },
  {
    name: 'Namibia',
    code: '+264',
    continent: 'AF',
    country_code: 'NA',
  },
  {
    name: 'Nauru',
    code: '+674',
    continent: 'OC',
    country_code: 'NR',
  },
  {
    name: 'Nepal',
    code: '+977',
    continent: 'AS',
    country_code: 'NP',
  },
  {
    name: 'Niederlande',
    code: '+31',
    continent: 'EU',
    country_code: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    code: '+599',
    continent: 'not_found',
    country_code: 'AN',
  },
  {
    name: 'New Caledonia',
    code: '+687',
    continent: 'OC',
    country_code: 'NC',
  },
  {
    name: 'New Zealand',
    code: '+64',
    continent: 'OC',
    country_code: 'NZ',
  },
  {
    name: 'Nicaragua',
    code: '+505',
    continent: 'NA',
    country_code: 'NI',
  },
  {
    name: 'Niger',
    code: '+227',
    continent: 'AF',
    country_code: 'NE',
  },
  {
    name: 'Nigeria',
    code: '+234',
    continent: 'AF',
    country_code: 'NG',
  },
  {
    name: 'Niue',
    code: '+683',
    continent: 'OC',
    country_code: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: '+672',
    continent: 'OC',
    country_code: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    code: '+1670',
    continent: 'OC',
    country_code: 'MP',
  },
  {
    name: 'Norwegen',
    code: '+47',
    continent: 'EU',
    country_code: 'NO',
  },
  {
    name: 'Oman',
    code: '+968',
    continent: 'AS',
    country_code: 'OM',
  },
  {
    name: 'Pakistan',
    code: '+92',
    continent: 'AS',
    country_code: 'PK',
  },
  {
    name: 'Palau',
    code: '+680',
    continent: 'OC',
    country_code: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: '+970',
    continent: 'AS',
    country_code: 'PS',
  },
  {
    name: 'Panama',
    code: '+507',
    continent: 'NA',
    country_code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: '+675',
    continent: 'OC',
    country_code: 'PG',
  },
  {
    name: 'Paraguay',
    code: '+595',
    continent: 'SA',
    country_code: 'PY',
  },
  {
    name: 'Peru',
    code: '+51',
    continent: 'SA',
    country_code: 'PE',
  },
  {
    name: 'Philippines',
    code: '+63',
    continent: 'AS',
    country_code: 'PH',
  },
  {
    name: 'Pitcairn',
    code: '+872',
    continent: 'not_found',
    country_code: 'PN',
  },
  {
    name: 'Polen',
    code: '+48',
    continent: 'EU',
    country_code: 'PL',
  },
  {
    name: 'Portugal',
    code: '+351',
    continent: 'EU',
    country_code: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: '+1939',
    continent: 'NA',
    country_code: 'PR',
  },
  {
    name: 'Qatar',
    code: '+974',
    continent: 'AS',
    country_code: 'QA',
  },
  {
    name: 'Rumänien',
    code: '+40',
    continent: 'EU',
    country_code: 'RO',
  },
  {
    name: 'Russland',
    code: '+7',
    continent: 'EU',
    country_code: 'RU',
  },
  {
    name: 'Rwanda',
    code: '+250',
    continent: 'AF',
    country_code: 'RW',
  },
  {
    name: 'Reunion',
    code: '+262',
    continent: 'AF',
    country_code: 'RE',
  },
  {
    name: 'Saint Barthelemy',
    code: '+590',
    continent: 'NA',
    country_code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    code: '+290',
    continent: 'AF',
    country_code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: '+1869',
    continent: 'NA',
    country_code: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: '+1758',
    continent: 'NA',
    country_code: 'LC',
  },
  {
    name: 'Saint Martin',
    code: '+590',
    continent: 'NA',
    country_code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: '+508',
    continent: 'NA',
    country_code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: '+1784',
    continent: 'NA',
    country_code: 'VC',
  },
  {
    name: 'Samoa',
    code: '+685',
    continent: 'OC',
    country_code: 'WS',
  },
  {
    name: 'San Marino',
    code: '+378',
    continent: 'EU',
    country_code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    code: '+239',
    continent: 'AF',
    country_code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: '+966',
    continent: 'AS',
    country_code: 'SA',
  },
  {
    name: 'Senegal',
    code: '+221',
    continent: 'AF',
    country_code: 'SN',
  },
  {
    name: 'Serbien',
    code: '+381',
    continent: 'EU',
    country_code: 'RS',
  },
  {
    name: 'Seychelles',
    code: '+248',
    continent: 'AF',
    country_code: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: '+232',
    continent: 'AF',
    country_code: 'SL',
  },
  {
    name: 'Singapore',
    code: '+65',
    continent: 'AS',
    country_code: 'SG',
  },
  {
    name: 'Slowakei',
    code: '+421',
    continent: 'EU',
    country_code: 'SK',
  },
  {
    name: 'Slowenien',
    code: '+386',
    continent: 'EU',
    country_code: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: '+677',
    continent: 'OC',
    country_code: 'SB',
  },
  {
    name: 'Somalia',
    code: '+252',
    continent: 'AF',
    country_code: 'SO',
  },
  {
    name: 'South Africa',
    code: '+27',
    continent: 'AF',
    country_code: 'ZA',
  },
  {
    name: 'South Sudan',
    code: '+211',
    continent: 'AF',
    country_code: 'SS',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: '+500',
    continent: 'SA',
    country_code: 'GS',
  },
  {
    name: 'Spanien',
    code: '+34',
    continent: 'EU',
    country_code: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: '+94',
    continent: 'AS',
    country_code: 'LK',
  },
  {
    name: 'Sudan',
    code: '+249',
    continent: 'AF',
    country_code: 'SD',
  },
  {
    name: 'Suriname',
    code: '+597',
    continent: 'SA',
    country_code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: '+47',
    continent: 'EU',
    country_code: 'SJ',
  },
  {
    name: 'Swaziland',
    code: '+268',
    continent: 'AF',
    country_code: 'SZ',
  },
  {
    name: 'Schweden',
    code: '+46',
    continent: 'EU',
    country_code: 'SE',
  },
  {
    name: 'Schweiz',
    code: '+41',
    continent: 'EU',
    country_code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    code: '+963',
    continent: 'AS',
    country_code: 'SY',
  },
  {
    name: 'Taiwan',
    code: '+886',
    continent: 'AS',
    country_code: 'TW',
  },
  {
    name: 'Tajikistan',
    code: '+992',
    continent: 'AS',
    country_code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    code: '+255',
    continent: 'AF',
    country_code: 'TZ',
  },
  {
    name: 'Thailand',
    code: '+66',
    continent: 'AS',
    country_code: 'TH',
  },
  {
    name: 'Timor-Leste',
    code: '+670',
    continent: 'not_found',
    country_code: 'TL',
  },
  {
    name: 'Togo',
    code: '+228',
    continent: 'AF',
    country_code: 'TG',
  },
  {
    name: 'Tokelau',
    code: '+690',
    continent: 'OC',
    country_code: 'TK',
  },
  {
    name: 'Tonga',
    code: '+676',
    continent: 'OC',
    country_code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: '+1868',
    continent: 'NA',
    country_code: 'TT',
  },
  {
    name: 'Tunisia',
    code: '+216',
    continent: 'AF',
    country_code: 'TN',
  },
  {
    name: 'Türkei',
    code: '+90',
    continent: 'AS',
    country_code: 'TR',
  },
  {
    name: 'Turkmenistan',
    code: '+993',
    continent: 'AS',
    country_code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: '+1649',
    continent: 'NA',
    country_code: 'TC',
  },
  {
    name: 'Tuvalu',
    code: '+688',
    continent: 'OC',
    country_code: 'TV',
  },
  {
    name: 'Uganda',
    code: '+256',
    continent: 'AF',
    country_code: 'UG',
  },
  {
    name: 'Ukraine',
    code: '+380',
    continent: 'EU',
    country_code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: '+971',
    continent: 'AS',
    country_code: 'AE',
  },
  {
    name: 'Vereinigtes Königreich',
    code: '+44',
    continent: 'EU',
    country_code: 'GB',
  },
  {
    name: 'United States',
    code: '+1',
    continent: 'NA',
    country_code: 'US',
  },
  {
    name: 'Uruguay',
    code: '+598',
    continent: 'SA',
    country_code: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: '+998',
    continent: 'AS',
    country_code: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: '+678',
    continent: 'OC',
    country_code: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    code: '+58',
    continent: 'SA',
    country_code: 'VE',
  },
  {
    name: 'Vietnam',
    code: '+84',
    continent: 'AS',
    country_code: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    code: '+1284',
    continent: 'NA',
    country_code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: '+1340',
    continent: 'NA',
    country_code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    code: '+681',
    continent: 'OC',
    country_code: 'WF',
  },
  {
    name: 'Yemen',
    code: '+967',
    continent: 'AS',
    country_code: 'YE',
  },
  {
    name: 'Zambia',
    code: '+260',
    continent: 'AF',
    country_code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: '+263',
    continent: 'AF',
    country_code: 'ZW',
  },
];
