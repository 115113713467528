import { MAX_SQUARE_METERS, MIN_SQUARE_METERS } from 'commons';

export const step3 = {
  pageTitle: 'Wie groß ist Ihre Wohnung?',
  pageSubtitle1: 'Bitte geben Sie Ihre Wohnfläche',
  pageSubtitle2: ' in vollen Quadratmetern',
  pageSubtitle3:
    ' (qm) an, damit Ihre Versicherungssumme genau ermittelt werden kann.',
  inputHelper: 'Bitte geben Sie Ihre Wohnfläche an.',
  maxValueText: `Online können Sie maximal ${MAX_SQUARE_METERS} qm Wohnfläche versichern. Bei größeren Wohnflächen kontaktieren Sie bitte einen ERGO Berater in Ihrer Nähe.`,

  minValueText: `Bitte geben Sie eine Wohnfläche von mindestens ${MIN_SQUARE_METERS} qm ein.`,
  infoMsg: (
    min: string,
    max: string
  ): string => `Online können Wohnflächen von ${min} bis ${max} qm versichert
werden.`,
  modalTitle: 'Wohnfläche',
  modalSubtitle:
    'Die Wohnfläche ist die Grundfläche der Wohnung einschließlich:',
  livingAreaType1: 'Wintergarten',
  livingAreaType2: 'Ausgebauter Räume im Dachgeschoss',
  excludedList: 'Nicht zur Wohnfläche gehören:',
  basement: 'Kellerräume',
  balcony: 'Balkone',
  loggias: 'Loggien',
  terrace: 'Terrassen',
  stairs: 'Treppen',
  sumInsured: 'Ihre Versicherungssumme beträgt ',
  successMessageModalTitle: 'Versicherungssumme',
  agreedSum:
    'Die vereinbarte Versicherungssumme soll dem <strong>Neuwert</strong> Ihres Hausrats (Versicherungswert) entsprechen. Für die Berechnung wurde ein Wert von 650 €/qm Wohnfläche angesetzt. Das entspricht dem Wert einer <strong>normalen Haushaltsausstattung.</strong>',
  damageNotice:
    'Bei einem Schaden bekommen Sie <strong>höchstens</strong> die vereinbarte Versicherungssumme. Dafür <strong>prüft ERGO nicht</strong>, ob diese ausreicht (<strong>Unterversicherungsverzicht</strong>).',
  propotionCompensation:
    '<strong>Unterversichert</strong> ist Ihr Hausrat, wenn am Tag des Schadens die Versicherungssumme <strong>niedriger</strong> ist als der Neuwert des Hausrats. Dann werden Ihnen Schäden i. d. R. <strong>nur anteilig</strong> ersetzt.',
  smallTip:
    '<strong>Tipp:</strong> Wenn Sie eine <strong>höhere Versicherungssumme</strong> vereinbaren wollen, wenden Sie sich bitte an einen ERGO Berater in Ihrer Nähe.',
};
