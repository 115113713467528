import {
  validateCompanyNameField,
  validateCompanyVuNr,
  validateDateField,
  validatePolicyNumberField,
  validatePolicyTerminatorField,
  validateSelectedField,
  validateDateYearField,
  validateYearIsNotOlderThanFiveYearsAgo,
} from 'commons';

import {
  InsuranceEndDate,
  PreviousInsurance,
} from 'commons/apis/hausrat/types';
import { InsuranceCompany } from 'commons/apis/insuranceCompanies/types';
import translations from '../../../../constants/translations';
import {
  InvalidField,
  ValidatorFunction,
  ValidField,
} from '../../../../store/types';
import { PreviousInsuranceKey } from '../../AdditionalQuestionsTypes';
import { generateInvalidField, generateValidField } from './validators';

export const previousInsuranceErrors = {
  selected: translations.step8.pleaseSelectPreviousInsuranceRadio,
  companyName: translations.step8.companyNameError,
  companyNameNoEntry: translations.step8.companyNameNoEntryError,
  vuNr: '',
  policyNumber: translations.step8.policyNumberError,
  policyTerminatedBy: translations.step8.policyTerminatorError,
  endDate: translations.step8.endDateError,
  endDateNotWithinTheLast5Years:
    translations.step8.endDateNotWithinTheLast5YearsError,
  endDateInvalid: translations.step8.endDateInvalidError,
};

export type PreviousInsuranceError = typeof previousInsuranceErrors;
export type PreviousInsuranceErrorKey = keyof PreviousInsuranceError;

export const previousInsuranceValidations = (
  suggestions: InsuranceCompany[]
): Record<
  PreviousInsuranceKey,
  ValidatorFunction<PreviousInsurance[PreviousInsuranceKey]>
> => ({
  selected: (value: boolean | undefined): ValidField | InvalidField => {
    return validateSelectedField(value)
      ? generateValidField()
      : generateInvalidField(previousInsuranceErrors, 'selected');
  },
  companyName: (value: string): ValidField | InvalidField => {
    let result: ValidField | InvalidField = generateInvalidField(
      previousInsuranceErrors,
      'companyName'
    );

    // Alternate logic
    if (validateCompanyNameField(value, suggestions)) {
      result = generateValidField();
    }

    if (!value.length) {
      result = generateInvalidField(
        previousInsuranceErrors,
        'companyNameNoEntry'
      );
    }
    return result;
  },
  vuNr: (value: string): ValidField | InvalidField => {
    return validateCompanyVuNr(value)
      ? generateValidField()
      : generateInvalidField(previousInsuranceErrors, 'vuNr');
  },
  policyNumber: (value: string): ValidField | InvalidField => {
    return validatePolicyNumberField(value)
      ? generateValidField()
      : generateInvalidField(previousInsuranceErrors, 'policyNumber');
  },
  policyTerminatedBy: (value: string): ValidField | InvalidField => {
    return validatePolicyTerminatorField(value)
      ? generateValidField()
      : generateInvalidField(previousInsuranceErrors, 'policyTerminatedBy');
  },
  endDate: (value: InsuranceEndDate): ValidField | InvalidField => {
    let validationResult: ValidField | InvalidField = generateValidField();

    if (!validateYearIsNotOlderThanFiveYearsAgo(value)) {
      validationResult = generateInvalidField(
        previousInsuranceErrors,
        'endDateNotWithinTheLast5Years'
      );
    }

    if (!validateDateField(value)) {
      validationResult = generateInvalidField(
        previousInsuranceErrors,
        'endDate'
      );
    }

    if (!validateDateYearField(value)) {
      validationResult = generateInvalidField(
        previousInsuranceErrors,
        'endDateInvalid'
      );
    }

    return validationResult;
  },
});
