import { isValidDateObject } from '../utils';

/**
 * This function calculates if the user is older than `ageRequired` based on
 * its `birthday`.
 *
 * @example
 * // Returns true
 * validateBirthDate('20/Nov/1987', 18);
 */
export const validateBirthDate = (
  birthday = '',
  ageRequired = 18,
  comparisionDate = new Date(Date.now())
): boolean => {
  // Create Date object
  const birthDate = new Date(birthday);

  // Verify it's a valid object
  if (!isValidDateObject(birthDate) || !isValidDateObject(comparisionDate)) {
    return false;
  }

  let age = comparisionDate.getFullYear() - birthDate.getFullYear();
  const months = comparisionDate.getMonth() - birthDate.getMonth();

  if (
    months < 0 ||
    (months === 0 && comparisionDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age >= ageRequired;
};
