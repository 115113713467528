import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  removeLastTrackedPage,
  retrieveLastTrackedPage,
  storeLastTrackedPage,
} from '../../../storage/storage';
import { useForm } from '../../../store/useForm';
import { TrackingHook } from './TrackingHooksTypes';
import { initTracking, trackPageLoad } from '../../tracking';

import { useTrackingData } from './useTrackingData';

import { getStep } from '../tracking.helpers';
import { useAppSelector } from '../../../store/hooks';
import { isAppLoadingSelector } from '../../../store/appSlice';

export const useTracking = (): Partial<TrackingHook> => {
  const location = useLocation();

  const { typeOfFloor } = useForm();

  const trackingData = useTrackingData();

  const [trackerReady, setTrackerReady] = useState(false);

  const isLoading = useAppSelector(isAppLoadingSelector);

  useEffect(() => {
    removeLastTrackedPage();
  }, []);

  useEffect(() => {
    if (trackerReady && !isLoading) {
      const lastTrackedPage = retrieveLastTrackedPage();
      const { pathname } = location;

      const isTrackablePage = lastTrackedPage !== pathname;

      if (isTrackablePage) {
        trackPageLoad(pathname, trackingData);
        storeLastTrackedPage(pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerReady, location.pathname, isLoading]);

  const initializeTracking = async (
    businessId: string,
    pathName: string
  ): Promise<void> => {
    await initTracking(pathName, businessId);
    setTrackerReady(true);
  };

  const updateTrackingStep = (): number => {
    const step = getStep(location.pathname);
    return typeOfFloor ? step : step - 1;
  };

  return {
    initializeTracking,
    setTrackerReady,
    updateTrackingStep,
  };
};
