import ControlWithHint from '@eg/elements/ControlWithHint';
import DateInput, { DateInputValue } from '@eg/elements/DateInput';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import { DateErrors } from '@eg/elements/utils/validation/date';
import React, { useMemo, useState, useEffect } from 'react';
import translations from '../../../constants/translations';
import {
  dateInputValueToDate,
  isBetween18and35yearsOld,
} from '../../../helpers/dates/dates';
import { useForm } from '../../../store/useForm';
import { setDateOfBirth, setDateOfBirthValid } from '../dateOfBirthSlice';
import type { DateOfBirthProps } from '../DateOfBirthTypes';
import './DateOfBirthDateInput.scss';
import { trackFrontendError } from '../../../utils/errorHandler';
import { useInputMode } from '../../../hooks/useInputMode';
import moment from 'moment';

type DateError = Record<'error', keyof DateErrors>;
type DateOfBirthState = Partial<DateError> & { display: boolean };

const DateOfBirthDateInput = ({
  dataTestId,
  isDateOfBirthValid,
}: DateOfBirthProps): JSX.Element => {
  const { setInputMode } = useInputMode('.ee_date-input .ee_input');

  useEffect(() => {
    setInputMode('numeric');
  }, [setInputMode]);

  const { dispatch, dateOfBirth, insuranceStartDate } = useForm();

  const [inputErrors, setInputError] = useState<DateOfBirthState>({
    display: false,
  });

  const inputErrorMessage = useMemo(() => {
    const dateErrorMap: Partial<Record<keyof DateErrors, string>> = {
      badInput: translations.step6.errorMessageBadInput,
      valueMissing: translations.step6.errorMessageValueMissing,
      rangeOverflow: translations.step6.errorMessageRangeOverflow,
      rangeUnderflow: translations.step6.errorMessageRangeUnderflow,
    };

    if (inputErrors.display) {
      return dateErrorMap[inputErrors.error];
    }
  }, [inputErrors]);

  useEffect(() => {
    if (inputErrorMessage) {
      trackFrontendError({ errorMessages: inputErrorMessage });
    }
  }, [inputErrorMessage]);

  const minimumDateAllowed = new Date('01-01-1900');
  const maxDateAllowed = moment().subtract('18', 'y').toDate();

  const displayErrors = (): void => {
    setInputError(state => ({ ...state, display: true }));
  };

  const dateValueIsInvalid = (value: DateInputValue): boolean => {
    return (
      value?.day === '00' || value?.month === '00' || value?.year === '0000'
    );
  };

  const handleOnChangeDateInput = (
    value: DateInputValue,
    error: DateErrors
  ): void => {
    dispatch(setDateOfBirth(value));
    if (error) {
      const { valid, ...errorObject } = error;
      setInputError(state => ({
        ...state,
        error: Object.keys(errorObject)[0] as keyof DateErrors,
      }));
    }
    if (!error?.valid) {
      dispatch(setDateOfBirthValid(false));
      if (
        (!error?.valueMissing && !error?.badInput) ||
        dateValueIsInvalid(value)
      ) {
        displayErrors();
      }
    } else {
      dispatch(setDateOfBirthValid(true));
    }
  };

  const hasPromotion = isBetween18and35yearsOld(
    dateOfBirth,
    dateInputValueToDate(insuranceStartDate)
  );

  return (
    <div
      data-testid={dataTestId}
      className="select-date-input reset-input-artifacts"
    >
      <ControlWithHint error={inputErrorMessage}>
        <DateInput
          className="select-date-input__input"
          autoTab
          value={dateOfBirth}
          maxDate={maxDateAllowed}
          minDate={minimumDateAllowed}
          onChange={handleOnChangeDateInput}
          onBlur={displayErrors}
          error={!isDateOfBirthValid}
        />
      </ControlWithHint>
      {isDateOfBirthValid && hasPromotion && (
        <div className="select-date-input__notification">
          <CheckColorIcon className="select-date-input__notification_icon" />
          <span
            dangerouslySetInnerHTML={{
              __html: translations.step6.promotionMessageCorrectAge,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DateOfBirthDateInput;
