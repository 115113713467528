export const step1 = {
  pageTitle: 'Wie wohnen Sie?',
  pageSubTitle:
    'Bei ERGO bekommen Sie online den passenden Versicherungsschutz für Ihre ständig bewohnte Hauptwohnung.',
  contactInfoModalTitle: 'Wohnungsart',
  contactInfoModalSubTitle:
    'Online können Sie nur für ständig bewohnte <strong>Hauptwohnungen</strong> in Deutschland Versicherungsschutz abschließen.',
  listTitle:
    'Sie möchten den Hausrat einer der folgenden Immobilien absichern?',
  list1: 'Zweit- oder Ferienwohnung',
  list2: 'Wochenend- oder Jagdhaus',
  list3: 'Vorübergehend unbewohnte Immobilie',
  listClosure: 'Dann lassen Sie sich telefonisch oder persönlich beraten.',
  modalButton: ' Berater kontaktieren',
  moreInfo: 'Mehr erfahren',
  footerText:
    'Sie möchten eine andere Wohnungsart absichern, z. B. eine Zweitwohnung, ein Ferienhaus o. Ä.?',
  apartmentText: 'Wohnung in einem Mehrfamilienhaus',
  singleHouse: 'In einem Einfamilienhaus',
};
