import * as constants from './constants';
import * as offers from './downloadOffer';

const { getUrl, ...endpoints } = constants;

const documentEngine = {
  endpoints,
  offers,
};

export default documentEngine;
