import { rest } from 'msw';
import { mockedOffer } from '../../../mocks/offer';
import { Handler } from '../../../types';
import { Address as SuggestedAddress } from '../../addressValidation/types';
import { CreateAgencyResponse } from '../../spcs/agencies/types';
import { BankData } from '../../spcs/payments/types';
import { PersonResponse } from '../../spcs/persons/types';
import { SubmitOfferResponse } from '../../submitEngine/submitOffer/types';
import {
  additionalQuestions,
  bankDetails,
  birthDate,
  createOffer,
  customerAddress,
  floorType,
  getBankDataFromIban,
  getUrl,
  insuranceStartDate,
  livingPlace,
  squareMeters,
  insuranceCoverage,
  personalData,
  getCitiesFromAddress,
  submitOffer,
  downloadOffer,
  acceptConditions,
  previousInsuranceCompanies,
  validateOffer,
} from '../constants';
import {
  BirthDateParams,
  BirthDateResponse,
  UpdateOfferResponse,
} from '../types';

const createOfferHandler = (rest[createOffer.method.toLowerCase()] as Handler)(
  getUrl(createOffer.path),
  (req, res, ctx) => {
    const businessId = '08d098a0s98d0as98d09a8s0d98as0';

    const offerResponse = {
      angebot: {
        ...mockedOffer,
        businessId,
      },
    };
    const agencyResponse: CreateAgencyResponse = {
      responseDetails: {},
      contextDetails: {
        businessId,
      },
      data: {
        agencies: [
          {
            agencyId: 'agencyIdMocked',
          },
        ],
      },
    };

    const personResponse: PersonResponse = {
      responseDetails: {},
      contextDetails: {
        businessId,
      },
      data: {
        persons: [
          {
            personId: 'personIdMocked',
          },
        ],
      },
    };

    return res(
      ctx.status(201),
      ctx.json({
        ...offerResponse,
        agency: agencyResponse.data.agencies[0],
        person: personResponse.data.persons[0],
      })
    );
  }
);

const livingPlaceHandler = (rest[livingPlace.method.toLowerCase()] as Handler)(
  getUrl(livingPlace.path),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          businessId: (req.body as any).businessId,
        },
      } as UpdateOfferResponse)
    )
);

const floorTypeHandler = (rest[floorType.method.toLowerCase()] as Handler)(
  getUrl(floorType.path),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          businessId: (req.body as any).businessId,
        },
      } as UpdateOfferResponse)
    )
);

const squareMetersHandler = (
  rest[squareMeters.method.toLowerCase()] as Handler
)(getUrl(squareMeters.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as UpdateOfferResponse)
  )
);

const getCitiesFromAddressHandler = (
  rest[getCitiesFromAddress.method.toLowerCase()] as Handler
)(getUrl(getCitiesFromAddress.path), (req, res, ctx) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { businessId, personId, ...address } = req.body;

  if (address.zipCode === '01234') {
    return res(ctx.status(200), ctx.json([]));
  }

  return res(
    ctx.status(200),
    ctx.json([
      {
        city: 'Berlin',
        street: 'Alexander-Abusch-Str.',
        zipCode: '12619',
        houseNumber: '',
      },
    ] as SuggestedAddress[])
  );
});

const customerAddressHandler = (
  rest[customerAddress.method.toLowerCase()] as Handler
)(getUrl(customerAddress.path), (req, res, ctx) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { address } = req.body;

  if (address.streetName === 'Alex') {
    return res(ctx.status(400), ctx.json({ errors: ['streetName'] }));
  }

  if (address.streetName === 'Alexanderpl') {
    return res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          businessId: (req.body as any).businessId,
          risikoOrt: {
            ...mockedOffer.risikoOrt,
            zuersGefaehrdungsklasse: 'ZUERS_GK_1',
          },
        },
        correctedAddress: {
          ...address,
          streetName: 'Alexanderplatz',
        },
      })
    );
  }

  return res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as UpdateOfferResponse)
  );
});

const insuranceStartDateHandler = (
  rest[insuranceStartDate.method.toLowerCase()] as Handler
)(getUrl(insuranceStartDate.path), (req, res, ctx) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(req.body as any).variants || !(req.body as any).variants.length) {
    return res(ctx.status(400));
  }

  return res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as UpdateOfferResponse)
  );
});

const birthDateHandler = (rest[birthDate.method.toLowerCase()] as Handler)(
  getUrl(birthDate.path),
  (req, res, ctx) => {
    const { personId, dateOfBirth } = req.body as BirthDateParams;
    return res(
      ctx.status(200),
      ctx.json({
        person: {
          personId,
          dateOfBirth,
        },
      } as BirthDateResponse)
    );
  }
);

const insuranceCoverageHandler = (
  rest[insuranceCoverage.method.toLowerCase()] as Handler
)(getUrl(insuranceCoverage.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as UpdateOfferResponse)
  )
);

const additionalQuestionsHandler = (
  rest[additionalQuestions.method.toLowerCase()] as Handler
)(getUrl(additionalQuestions.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    })
  )
);

const previousInsuranceCompaniesHandler = (
  rest[previousInsuranceCompanies.method.toLocaleLowerCase()] as Handler
)(getUrl(previousInsuranceCompanies.path), async (req, res, ctx) => {
  const { name } = req.body as { name: string };
  const { versicherungen: response } = await import(
    '../../insuranceCompanies/services/utils/companiesOkResponse.json'
  );
  const data = response.filter(el =>
    /*
     * To work arounde includes' case sensitiviness,
     * both the target and the search substring are changed to lowercase
     */
    el.name.toLowerCase().includes(name.toLowerCase())
  );

  return res(ctx.status(200), ctx.json(data));
});

const personalDataHandler = (
  rest[personalData.method.toLowerCase()] as Handler
)(getUrl(personalData.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as UpdateOfferResponse)
  )
);

const getBankDataFromIbanHandler = (
  rest[getBankDataFromIban.method.toLowerCase()] as Handler
)(getUrl(getBankDataFromIban.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      bic: 'INGDDEFFXXX',
      city: 'Frankfurt am Main',
      financialInstitutionName: 'ING-DiBa Frankfurt am Main',
      iban: 'DE02500105170137075030',
      id: 'f2068bcc-63aa-40d3-9ef1-8f44935b8b37',
      mrn: null,
    } as BankData)
  )
);

const bankDetailsHandler = (rest[bankDetails.method.toLowerCase()] as Handler)(
  getUrl(bankDetails.path),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          businessId: (req.body as any).businessId,
        },
      } as UpdateOfferResponse)
    )
);

const submitOfferHandler = (rest[submitOffer.method.toLowerCase()] as Handler)(
  getUrl(submitOffer.path),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          businessId: (req.body as any).businessId,
        },
      } as unknown as SubmitOfferResponse)
    )
);

const downloadOfferHandler = (
  rest[downloadOffer.method.toLowerCase()] as Handler
)(getUrl(downloadOffer.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: req.params.businessId,
      },
    } as Record<string, unknown>)
  )
);

const acceptConditionsHandler = (
  rest[acceptConditions.method.toLowerCase()] as Handler
)(getUrl(acceptConditions.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as Record<string, unknown>)
  )
);
const validateOfferHandler = (
  rest[validateOffer.method.toLowerCase()] as Handler
)(getUrl(validateOffer.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        ...mockedOffer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        businessId: (req.body as any).businessId,
      },
    } as Record<string, unknown>)
  )
);

const hausratHandlers = [
  additionalQuestionsHandler,
  createOfferHandler,
  livingPlaceHandler,
  floorTypeHandler,
  squareMetersHandler,
  getCitiesFromAddressHandler,
  customerAddressHandler,
  insuranceStartDateHandler,
  birthDateHandler,
  insuranceCoverageHandler,
  personalDataHandler,
  getBankDataFromIbanHandler,
  bankDetailsHandler,
  submitOfferHandler,
  previousInsuranceCompaniesHandler,
  downloadOfferHandler,
  validateOfferHandler,
  acceptConditionsHandler,
];

export default hausratHandlers;
