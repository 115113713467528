import { RootState } from '../../types';
import { getPersonSelector } from './generics';
import { formatTelephoneNumber, extractCountryCode } from '../../../utils';

type PersonalData = {
  salutation?: string;
  firstName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
};
export const getPersonalDataFromPerson = (state: RootState): PersonalData => {
  const {
    salutation,
    firstName,
    familyName,
    emailAddresses,
    telephoneNumbers,
  } = getPersonSelector(state) ?? {};
  const [email] = emailAddresses ?? [];
  const [phone] = telephoneNumbers ?? [];

  return {
    salutation,
    firstName,
    familyName,
    email: email?.email,
    phoneNumber: phone?.number,
  };
};

export const isPersonalDataUpdated = (state): boolean => {
  const {
    salutation,
    firstName,
    familyName,
    emailAddresses,
    telephoneNumbers,
  } = getPersonSelector(state) ?? {};
  const [email] = emailAddresses ?? [];
  const [phone] = telephoneNumbers ?? [];
  const {
    firstName: formFirstName,
    lastname: formFamilyName,
    email: formEmail,
    telephoneNumber: formTelephoneNumber,
    gender: formSalutation,
    prefixNumber: formPrefix,
  } = state.personalData.form;
  const prefixFromOffer = extractCountryCode(phone?.number);

  return !(
    salutation === formSalutation &&
    firstName === formFirstName &&
    familyName === formFamilyName &&
    email?.email === formEmail &&
    formatTelephoneNumber(phone?.number) ==
      formatTelephoneNumber(formTelephoneNumber) &&
    prefixFromOffer === formPrefix
  );
};
