import { rest } from 'msw';
import { Handler } from '../../../types';
import { createPerson, getUrl, readPerson, updatePerson } from '../constants';
import { Person } from './types';

export const PERSON_ID = 'd3ebe39f-938b-4ca9-8a07-588395bb414e';

export const createPersonHandler = (
  rest[createPerson.method.toLowerCase()] as Handler
)(getUrl(createPerson.path), (req, res, ctx) => {
  const { businessId } = req.params;

  return res(
    ctx.status(201),
    ctx.json({
      responseDetails: {
        apiVersion: '0.46.0',
        serviceVersion: '0.37.0',
        responseTime: '2022-06-21T12:08:41.994Z',
      },
      contextDetails: {
        businessId,
        shortcode: 'H3RE57D',
        creationTime: '2021-06-09T15:35:49.666Z',
        lastModificationTime: '2021-06-15T14:43:32.96Z',
        expirationTime: '2021-12-16T15:35:49.666Z',
      },
      data: {
        persons: [
          {
            personId: PERSON_ID,
            salutation: null,
            academicTitle: null,
            firstName: null,
            familyName: null,
            dateOfBirth: null,
            placeOfBirth: null,
            nationality: null,
            addresses: [],
            telephoneNumbers: [],
            emailAddresses: [],
            partnerReferences: [],
            payments: {
              bankdata: [],
            },
            advertiseConsent: {
              telephonyConsent: null,
              emailConsent: null,
            },
            lastModified: true,
          },
        ],
      },
    })
  );
});

export const readPersonHandler = (
  rest[readPerson.method.toLowerCase()] as Handler
)(getUrl(readPerson.path), (req, res, ctx) => {
  const { businessId } = req.params;

  return res(
    ctx.status(201),
    ctx.json({
      responseDetails: {
        apiVersion: '0.46.0',
        serviceVersion: '0.37.0',
        responseTime: '2022-06-21T12:08:41.994Z',
      },
      contextDetails: {
        businessId,
        shortcode: 'H3RE57D',
        creationTime: '2021-06-09T15:35:49.666Z',
        lastModificationTime: '2021-06-15T14:43:32.96Z',
        expirationTime: '2021-12-16T15:35:49.666Z',
      },
      data: {
        persons: [
          {
            personId: PERSON_ID,
            salutation: null,
            academicTitle: null,
            firstName: null,
            familyName: null,
            dateOfBirth: null,
            placeOfBirth: null,
            nationality: null,
            addresses: [],
            telephoneNumbers: [],
            emailAddresses: [],
            partnerReferences: [],
            payments: {
              bankdata: [],
            },
            advertiseConsent: {
              telephonyConsent: null,
              emailConsent: null,
            },
            lastModified: true,
          },
        ],
      },
    })
  );
});

export const updatePersonHandler = (
  rest[updatePerson.method.toLowerCase()] as Handler
)(getUrl(updatePerson.path), (req, res, ctx) => {
  const { dateOfBirth } = req.body as Partial<Person>;
  const { businessId, personId } = req.params;

  return res(
    ctx.status(201),
    ctx.json({
      responseDetails: {
        apiVersion: '0.46.0',
        serviceVersion: '0.37.0',
        responseTime: '2022-06-21T12:08:41.994Z',
      },
      contextDetails: {
        businessId,
        shortcode: 'H3RE57D',
        creationTime: '2021-06-09T15:35:49.666Z',
        lastModificationTime: '2021-06-15T14:43:32.96Z',
        expirationTime: '2021-12-16T15:35:49.666Z',
      },
      data: {
        persons: [
          {
            personId,
            salutation: 'MR',
            academicTitle: 'PROFESSOR_DOCTOR',
            firstName: 'Frank',
            familyName: 'Medina',
            dateOfBirth: dateOfBirth,
            placeOfBirth: 'München',
            nationality: 'DE',
            addresses: [],
            telephoneNumbers: [],
            emailAddresses: [],
            partnerReferences: [],
            payments: {
              bankdata: [],
            },
            advertiseConsent: {
              telephonyConsent: null,
              emailConsent: null,
            },
            lastModified: true,
          },
        ],
      },
    })
  );
});
