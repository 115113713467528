import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';

import type { FormSectionHeaderProps } from './FormSectionHeaderTypes';
import './FormSectionHeader.scss';
import { useAppSelector } from '../../../../store/hooks';

import { useMemo } from 'react';
import { getOfferFeedbackSelector } from '../../../../store/offerSlice';

const FormSectionHeader = ({
  title,
  subtitle,
  toolTipComponent = null,
  showTooltip = false,
  withBox = false,
  onOpened,
  className,
}: FormSectionHeaderProps): JSX.Element => {
  const { premium } = useAppSelector(getOfferFeedbackSelector);
  const headerClass = useMemo(() => {
    const classes = ['form-section-header'];
    if (className) {
      classes.push(className);
    }

    const withPrice = '--with-price';
    const hasPrice = !!premium ? withPrice : '';
    if (hasPrice) {
      classes.push(withPrice);
    }

    const escBox = 'esc_box';
    const hasBox = withBox ? escBox : '';
    if (hasBox) {
      classes.push(escBox);
    }

    return classes.join(' ');
  }, [premium, withBox, className]);

  const handleToggleOpen = (isOpen: boolean): void => {
    if (onOpened && isOpen) {
      onOpened();
    }
  };

  return (
    <div className={headerClass}>
      <h2 className="form-section-header__title">{title}</h2>
      <p className="form-section-header__subtitle">{subtitle}</p>
      {showTooltip && (
        <TooltipIcon
          isModal
          data-testid="tooltip-header"
          onToggledOpen={handleToggleOpen}
        >
          {toolTipComponent}
        </TooltipIcon>
      )}
    </div>
  );
};

export default FormSectionHeader;
