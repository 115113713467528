import React from 'react';
import Modal from '@eg/elements/Modal';
import EButton from '../EButton/EButton';
import translations from '../../constants/translations';
import { setGenericError } from '../../store/appSlice';
import { useForm } from '../../store/useForm';

type ModalErrorProps = {
  isShowing: boolean;
  subTitle?: string;
};

export const ModalErrors = ({
  isShowing,
  subTitle,
}: React.PropsWithChildren<ModalErrorProps>): JSX.Element => {
  const { dispatch } = useForm();

  const handleModalError = (): void => {
    dispatch(setGenericError(!isShowing));
  };

  return (
    <Modal
      open={isShowing}
      dismissible
      onDismiss={handleModalError}
      backdropDismissesModal
    >
      <div data-testid="generic-error-type-modal">
        <h3 className="modal-title">{translations.modalError.title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: subTitle || translations.modalError.infoSubTitle,
          }}
        />
        <div className="button-wrapper">
          <EButton
            disabled={false}
            className="button-wrapper__confirmation"
            onClick={handleModalError}
            variant="tertiary"
            size="large"
            colorScheme="regular"
            data-testid="submit-button-modal"
          >
            {translations.modalError.button}
          </EButton>
        </div>
      </div>
    </Modal>
  );
};
