import React from 'react';
import Button from '@eg/elements/Button';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import './stepButton.scss';
import { StepButtonProps } from './StepButton.d';
import { StepButton as EStepButton } from '@eg/elements/StepButton';
import { trackClickEvent } from '../../api/tracking';
const StepButton = ({
  step,
  text,
  subText = '',
  loading = false,
  isDone = false,
  disabled = true,
  size = 'auto',
  onClick,
  name,
  trackingOptions,
}: StepButtonProps): JSX.Element => {
  const trackedOnClick = (): void => {
    const { element, options } = trackingOptions;
    trackClickEvent(element, options);

    return onClick();
  };

  if (isDone) {
    return (
      <Button
        iconRight={<CheckIcon />}
        onClick={onClick}
        disabled={disabled}
        size={size}
        variant="secondary"
        name={name}
        loading={loading}
      >
        {step} {text ? ` | ${text}` : ''}
      </Button>
    );
  }
  return (
    <EStepButton
      step={step}
      text={text}
      subText={subText}
      disabled={disabled}
      onClick={trackingOptions ? trackedOnClick : onClick}
      loading={loading}
    />
  );
};

export default StepButton;
