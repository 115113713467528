import React from 'react';
import translations from '../../../constants/translations';

const MoreThanOneDamageFields = ({ updateTrackingErrors }): JSX.Element => {
  updateTrackingErrors(translations.step8.moreThanOneDamageError);

  return (
    <div>
      <p className="error-text">{translations.step8.moreThanOneDamageError}</p>
    </div>
  );
};

export default MoreThanOneDamageFields;
