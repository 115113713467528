export const step7 = {
  pageTitle: 'Wählen Sie Ihren Versicherungsschutz.',
  pageSubtitle: 'Sichern Sie Ihr Hab und Gut finanziell ab.',
  bikeOptionsTitle:
    ' Sichern Sie auch alle Fahrräder und E-Bikes ab, die zu Ihrem Haushalt gehören.',
  rowLabel1: 'Zahlungsweise',
  rowLabel2: 'Selbstbeteiligung',
  rowLabel3: 'Vertragslaufzeit',
  bikeTheftModal: {
    title: 'Fahrraddiebstahl',
    paragraph1: `Mit dem Baustein „Fahrraddiebstahl“ 
      sind abgeschlossene Fahrräder sowie nicht versicherungspflichtige E-Bikes (Pedelecs) gegen <strong>einfachen Diebstahl</strong> versichert. 
      Zubehör und Fahrradanhänger sind mitversichert. Der Versicherungsschutz besteht weltweit.`,
    paragraph2: `ERGO zahlt bei einem Diebstahl maximal die gewählte
  Entschädigungsleistung
  <strong>
    für jedes gestohlene Fahrrad des versicherten Haushalts.
  </strong>`,
    paragraph3: `<strong>Tipp:</strong> Wählen Sie die Entschädigungsleistung so, dass
  sie dem Wert des <strong>teuersten Fahrrads in Ihrem Haushalt</strong>
  entspricht.`,
    paragraph4: 'Diese Regelung gilt auch für:',
    paragraph5: '<strong>Fahrradkasko und Mobilitätsschutz:</strong>',
    paragraph6: `Mit der Fahrradkasko besteht Versicherungsschutz, wenn Fahrräder sowie
    nicht versicherungspflichtige E-Bikes (Pedelecs) durch ein
    <strong>unvorhergesehenes Ereignis zerstört oder beschädigt</strong>
    werden. Das gilt z. B. bei Unfall, Vandalismus oder bei Bruchschäden
    durch An- oder Zusammenstoß.`,
    paragraph7:
      'Dasselbe gilt, wenn versicherte Fahrräder und E-Bikes (Pedelecs) durch <strong>Diebstahl</strong> abhandenkommen.',
    paragraph8: 'Mitversichert sind auch:',
    listOption1:
      'Schäden an der <strong>Elektronik</strong> des E-Bikes (Pedelecs)',
    listOption2:
      '<strong>Akkuschutz,</strong> wenn die Akkuleistung nach 3 Jahren weniger als 50 % beträgt',
    listOption3: `Kosten zur Beseitigung von
    <strong>Material-, Konstruktions- und Fabrikationsfehlern,</strong>
    sofern nicht der Hersteller oder der Lieferant nach den Garantie- oder
    Gewährleistungsbestimmungen haftet.`,
    paragraph9:
      'Mit dem <strong>Mobilitätsschutz</strong> erhalten Sie zusätzlich eine Kostenerstattung, wenn eine Fahrt aufgrund einer Panne nicht möglich ist.',
    paragraph10: `ERGO erstattet die Kosten für die von Ihnen selbst organisierten
    Leistungen, und zwar <strong>maximal 500 € pro Ereignis und 1.000 € pro Jahr.</strong>`,
    paragraph11:
      'Einzelheiten finden Sie in den Versicherungsbedingungen (KT2021HR).',
  },
  contractTerm: {
    title: 'Vertragslaufzeit',
    paragraph1: '<strong>1 Jahr:</strong>',
    paragraph2: `Der Vertrag <strong>verlängert sich</strong> nach Ablauf der Vertragsdauer
    stillschweigend von Jahr zu Jahr, wenn dem anderen Vertragspartner nicht
    spätestens 3 Monate vor dem jeweiligen Ablauf eine Kündigung zugegangen
    ist.`,
    paragraph3: '<strong>3 Jahre:</strong>',
    paragraph4: `Entscheiden Sie sich für eine <strong>3-jährige Vertragsdauer,</strong>
    erhalten Sie <strong>10 % Nachlass</strong> auf Ihren Nettobeitrag.`,
  },
  customerSelection: {
    paragraph1:
      'ERGO zahlt bei einem Schaden maximal die gewählte Entschädigungsleistung <strong>für jedes Fahrrad des versicherten Haushalts.</strong>',
    paragraph2:
      ' <strong>Tipp:</strong> Wählen Sie die Entschädigungsleistung so, dass sie dem Wert des teuersten Fahrrads in Ihrem Haushalt entspricht',
  },
  excess: {
    title: 'Selbstbeteiligung',
    paragraph1:
      'Durch eine zusätzlich vereinbarte Selbstbeteiligung im Schadensfall <strong>reduzieren Sie den Beitrag</strong> für Ihre Hausratversicherung.',
    paragraph2: `Vereinbaren Sie eine Selbstbeteiligung, <strong>kürzt</strong> der
    Versicherer die bedingungsgemäße Entschädigung je Schadensfall um den
    vereinbarten Betrag. Die Selbstbeteiligung gilt für alle vereinbarten
    Gefahren – außer für die Bausteine „Weitere Naturgefahren“ und „Unbenannte
    Gefahren“ mit separater, obligatorischer Selbstbeteiligung. Für alle
    sonstigen vereinbarten Bausteine (z. B. Fahrraddiebstahl) sowie für die
    Glasversicherung gilt die Selbstbeteiligung nicht.`,
    paragraph3: '<strong>a. Feste Selbstbeteiligung</strong>',
    paragraph4:
      'Im Schadensfall rechnet der Versicherer <strong>immer</strong> die mit Ihnen vereinbarte Selbstbeteiligung an.',
    paragraph5:
      '<strong>10 % Nachlass</strong> für die Vereinbarung einer Selbstbeteiligung von <strong>300 €</strong>',
    paragraph6: '<strong>b. Flexible Selbstbeteiligung</strong>',
    paragraph7: `Nach jedem schadensfreien Versicherungsjahr <strong>reduziert</strong>
    sich die Selbstbeteiligung um <strong>ein Fünftel</strong> des
    ursprünglichen Werts. Nach dem
    <strong>5. schadensfreien Versicherungsjahr</strong> in Folge wird bei der
    nächsten Schadensregulierung die Selbstbeteiligung
    <strong>nicht abgezogen.</strong>`,
    paragraph8: `Wenn der Versicherer im Schadensfall eine Zahlung geleistet hat, gilt
    wieder die ursprünglich vereinbarte Selbstbeteiligung. Die Berechnung der
    schadensfreien Zeit beginnt von Neuem.`,
    paragraph9: `<strong>8 % Nachlass</strong> für die Vereinbarung einer Selbstbeteiligung
    von <strong>300 € flexibel</strong>`,
  },
  glass: {
    title: 'Glasversicherung',
    paragraph1: `Mit der Glasversicherung versichern Sie Bruchschäden an
    <strong>Scheiben aus Glas oder Kunststoff</strong>, z. B. an Ihrem
    versicherten Gebäude oder Ihrer Wohnung:`,
    listOptions1: {
      a: 'Fenster und Türen',
      b: 'Balkone, Loggien, Terrassen, Veranden',
      c: 'Wintergärten, Wetterschutzvorbauten',
      d: 'Duschkabinen',
      e: 'Sonnenkollektoren, Fotovoltaikanlagen',
      f: 'Scheiben von privaten Gewächshäusern und Nebengebäuden (max. 25 qm Grundfläche)',
    },
    paragraph2:
      'Bruchschäden an <strong>Scheiben des Mobiliars</strong>, z. B. an:',
    listOptions2: {
      a: 'Bildern',
      b: 'Schränken, Vitrinen',
      c: 'Stand-, Wand- und Schrankspiegeln',
      d: 'Glasplatten',
      e: 'Glasscheiben und Sichtfenstern von Mikrowellen-, Elektro- und Gasgeräten',
      f: 'Aquarien und Terrarien',
    },
    paragraph3: 'Mitversichert sind auch:',
    listOptions3: {
      a: 'Glaskeramik-Kochflächen',
      b: 'Platten aus Naturstein, die nicht fest mit dem Gebäude verbunden sind, z. B. eine Tischplatte aus Marmor',
      c: 'Blind gewordene Mehrscheiben-Isolierverglasungen bis zu 5.000 € je Versicherungsfall',
      d: 'Künstlerisch bearbeitete Glasscheiben, Glasspiegel oder Glasplatten bis zu 5.000 € je Versicherungsfall',
    },
    paragraph4:
      'Einzelheiten finden Sie in den Versicherungsbedingungen (KT2021HR).',
  },
  grossNegligence: {
    title: 'Grobe Fahrlässigkeit',
    paragraph1: `Grob fahrlässiges Verhalten kann dazu führen, dass bei einem Schaden die
    Leistung <strong>gekürzt wird</strong> oder
    <strong>ganz wegfällt.</strong>`,
    paragraph2: `Bei ERGO sind grundsätzlich auch Schäden versichert, die durch grob
    fahrlässiges Verhalten entstehen. Also z. B. Brandschäden durch
    unbeaufsichtigt brennende Kerzen.`,
    paragraph3: `Im <strong>Tarif „Smart“</strong> sind Schäden durch grobe
    Fahrlässigkeit <strong>bis 10.000 € versichert.</strong> Bei höheren
    Schäden kann die Leistung gekürzt werden.`,
    paragraph4: `<strong>Tipp:</strong> Entscheiden Sie sich für den Tarif
    <strong>„Best“</strong>. Dann sind solche Schäden
    <strong>bis zur Versicherungssumme versichert</strong> – ohne mögliche
    Kürzung.`,
    paragraph5: `<strong>Wichtig:</strong> Ausgenommen von den genannten Regelungen sind
    Schäden durch Verletzung von Obliegenheiten und Gefahrerhöhungen.`,
  },
  houseAndFlat: {
    title: 'Haus- und Wohnungsschutzbrief',
    paragraph1: `Informieren Sie den Versicherer <strong>unverzüglich</strong> über den
    Notfall. Dann organisiert er in den folgenden Notfällen
    <strong>die notwendigen Fachleute</strong> und zahlt jeweils
    <strong>bis zu 3.000 €</strong> für den Einsatz:`,
    listOptions1: {
      a: 'Schlüsseldienst',
      b: 'Rohrreinigung',
      c: 'Psychologische Betreuung, z. B. nach einem Brand oder Einbruch',
      d: 'Rückreise aus dem Urlaub, z. B. nach einem Einbruch',
      e: 'Unterbringung von Kindern und Haustieren im Notfall',
      f: 'Sanitär-, (Not-)Heizungs-, Elektroinstallationsservice',
      g: 'Schädlingsbekämpfung',
      h: 'Nestentfernung (Bienen, Wespen, Hornissen)',
      i: 'Sicherheitsberatung nach einem Einbruch',
      j: 'Wiederherstellung privater Dateien, z. B. nach einem Brand',
      k: 'Dokumentendepot',
    },
    paragraph2: `Wenn Sie die Organisation selbst übernehmen, zahlt der Versicherer <strong>bis
    zu 500 €.<strong/>`,
    paragraph3:
      'Einzelheiten finden Sie in den Versicherungsbedingungen (KT2021HR).',
  },
  otherDangers: {
    title: 'Unbenannte Gefahren',
    paragraph1: `Mit diesem Baustein ist die
    <strong>unvorhersehbare Beschädigung oder Zerstörung</strong>
    versicherter Sachen abgedeckt.`,
    paragraph2: `Dabei besteht immer eine <strong>Selbstbeteiligung von 300 €</strong> je
    Schadensfall. Pro Jahr zahlt ERGO <strong>höchstens 2,5 Mio. €</strong>
    (Jahreshöchstentschädigung).`,
    paragraph3:
      '<strong>Beispiele für Schäden durch unbenannte Gefahren:</strong>',
    listOptions1: {
      a: 'Wind unter Windstärke 7 beschädigt Ihren Hausrat.',
      b: 'Der an der Wand befestigte Fernseher fällt herunter und funktioniert nicht mehr.',
    },
    paragraph4: `<strong>Ausgeschlossen</strong> sind Schäden, die in den Tarifen und den
    Bausteinen versichert sind, sowie deren Ausschlüsse. Also z. B. Schäden
    durch Feuer, Leitungswasser, Rohrbruch und Frost, Sturm und Hagel,
    Einbruchdiebstahl, Raub, Vandalismus nach Einbruch oder Raub, Diebstahl
    sowie weitere Naturgefahren. Ebenfalls ausgeschlossen sind die dort
    genannten Ausschlusstatbestände (z. B. Schäden durch Sturmflut).`,
    paragraph5: `Weitere Ausschlüsse sind z. B. Abnutzung, Verschleiß, übermäßige
    Beanspruchung, Alterung oder Kontamination (z. B. Verseuchung).`,
    paragraph6:
      'Einzelheiten finden Sie in den Versicherungsbedingungen (KT2021HR).',
  },
  otherNaturalHazards: {
    title: 'Weitere Naturgefahren',
    paragraph1: `Sichern Sie unbedingt Schäden durch weitere Naturgefahren ab. Denn
    Experten warnen: In den nächsten Jahrzehnten werden
    <strong>extreme Wetterlagen noch mehr zunehmen.</strong>`,
    paragraph2: 'Versichert sind die finanziellen Folgen von Schäden durch:',
    listOptions1: {
      a: 'Überschwemmungen',
      b: 'Rückstau',
      c: 'Erdbeben',
      d: 'Erdsenkung',
      e: 'Erdrutsche',
      f: 'Schneedruck',
      g: 'Lawinen',
      h: 'Vulkanausbruch',
    },
    paragraph3: `Die <strong>Wartezeit</strong> beträgt <strong>einen Monat,</strong> die
    <strong>Selbstbeteiligung 500 €.</strong> Sie ist unabhängig von
    möglichen anderen Selbstbeteiligungen in der Hausratversicherung.`,
    paragraph4:
      'Einzelheiten finden Sie in den Versicherungsbedingungen (KT2021HR).',
  },
  paymentMethod: {
    title: 'Zahlungsweise',
    paragraph1:
      'Je nach Zahlungsweise enthält Ihr Beitrag diese Ratenzahlungszuschläge:',
    listOptions1: {
      a: 'Monatlich: 6 %',
      b: 'Vierteljährlich: 5 %',
      c: 'Halbjährlich: 3 %',
      d: 'Jährlich: kein Zuschlag',
    },
    paragraph2:
      'Die monatliche Zahlungsweise ist nur im Lastschriftverfahren möglich.',
  },
  simpleTheftIncluded: {
    title: 'Einfacher Diebstahl',
    paragraph1: 'Versicherungsschutz besteht auch für Diebstahl:',
    listOptions1: {
      a: 'am Arbeitsplatz',
      b: 'innerhalb Europas aus verschlossenen Kraftfahrzeugen, daran angekoppelten Anhängern oder aus Wohnmobilen',
      c: 'aus einem verschlossenen Wasserfahrzeug',
      d: 'aus einer Schiffskabine, einem Zug oder Reisebus',
      e: 'von Krankenfahrstühlen, Gehhilfen (z. B. Rollatoren) und Kinderwagen',
      f: 'aus dem Krankenzimmer',
      g: 'von Gartenmöbeln, Gartengeräten (auch Mährobotern), Garteninventar und Grillgeräten',
      h: 'von Antennenanlagen, Markisen, Klimageräten, Gefahrenmeldeanlagen, Smarthome-Anlagen und Ladegeräten für Elektrofahrzeuge (Wallboxen, Ladesäulen)',
      i: 'von Wäsche und Bekleidung vom versicherten Grundstück',
      j: 'von Waschmaschinen, Wäschetrocknern oder Wäsche und Bekleidung aus Gemeinschaftsräumen',
      k: 'außerhalb der Wohnung (inkl. Trickdiebstahl) bis 500 €',
      l: 'Trickdiebstahl in Ihrer Wohnung',
    },
    paragraph2:
      'Einzelheiten finden Sie in den Versicherungsbedingungen (KT2021HR).',
  },
  sumInsured: {
    title: 'Versicherungssumme:',
    paragraph1: `Die vereinbarte Versicherungssumme soll dem <strong>Neuwert</strong>
    Ihres Hausrats (Versicherungswert) entsprechen. Für die Berechnung
    wurden 650 €/qm Wohnfläche angesetzt. Das entspricht dem Wert einer
    <strong>normalen Haushaltsausstattung.</strong>`,
    paragraph2: `Bei einem Schaden bekommen Sie <strong>höchstens</strong> die
    vereinbarte Versicherungssumme. Dafür <strong>prüft ERGO nicht,</strong>
    ob die Versicherungssumme ausreicht (
    <strong>Unterversicherungsverzicht</strong>).`,
    paragraph3: `<strong>Unterversichert</strong> ist Ihr Hausrat, wenn am Tag des
    Schadens die Versicherungssumme <strong>niedriger</strong> ist als der
    Neuwert des Hausrats. Dann werden Ihnen Schäden i. d. R.
    <strong>nur anteilig</strong> ersetzt.`,
    paragraph4: `<strong>Tipp:</strong> Wenn Sie eine höhere Versicherungssumme
    vereinbaren wollen, wenden Sie sich bitte an einen ERGO Berater in Ihrer
    Nähe.`,
  },
  dataFile: {
    smartSpecialFeatures: {
      sumInsuredSmart: {
        label: 'Versicherungssumme: 52.000€',
      },
      grossNegligenceSmart: {
        label: 'Grobe Fahrlässigkeit 10.000 €',
        subLabel: 'Keine Entschädigungskürzung bei Schäden bis 10.000 €',
      },
    },
    bestSpecialFeatures: {
      sumInsuredBest: {
        label: 'Versicherungssumme: 52.000€',
      },
      grossNegligenceBest: {
        label: 'Grobe Fahrlässigkeit 100%',
        subLabel:
          'Keine Entschädigungskürzung bei Schäden bis zur Versicherungssumme',
      },
      simpleTheftIncludedBest: {
        label: 'Einfacher Diebstahl inklusive',
        subLabel: 'Absicherung von Diebstahlschäden bis 10.000 €',
      },
    },
    benefitModules: {
      simpleTheft: {
        label: 'Einfacher Diebstahl',
        subLabel: 'bei Diebstahlschäden bis 10.000 €',
      },
      houseAndFlat: {
        label: 'Haus- und Wohnungsschutzbrie',
        subLabel: 'bei Rohrbruch, Heizungsausfall oder Schlüsselverlust',
      },
      glass: {
        label: 'Glasversicherung',
        subLabel: 'bei Glasbruchschäden',
      },
      otherNaturalHazards: {
        label: 'Weitere Naturgefahren',
        subLabel: 'bei extremen Unwettern',
      },
      unnamedPerils: {
        label: 'Unbenannte Gefahren',
        subLabel: 'nur in Verbindung mit dem Baustein „Weitere Naturgefahren“',
      },
      bicycleAndEbike: {
        label: 'Fahrrad- und E-Bike-Schutz',
        subLabel:
          'bei Diebstahl bzw. Beschädigung von Fahrrädern und E-Bikes (Pedelecs)',
      },
    },
    paymentMethodsOptionsLabels: {
      firstOptionLabel: 'Monatlich',
      secondOptionLabel: 'Vierteljährlich',
      thirdOptionLabel: 'Halbjährlich',
      fourthOptionLabel: 'Jährlich',
    },
    premiumUnits: {
      monthly: 'mtl.',
      quaterly: 'viertelj.',
      halfYearly: 'halbj.',
      annual: 'jährl.',
    },
    deductibleOptionsLabels: {
      firstOptionLabel: '0,00€',
      secondOptionLabel: '300,00€',
      thirdOptionLabel: '300,00€ flexibel',
    },
    contractPeriodOptionsLabels: {
      firstOptionLabel: '5 Jahre',
      secondOptionLabel: '1 Jahr',
    },
  },
  bikeModule: {
    coverageNotSelectedError: 'Bitte treffen Sie eine Auswahl',
  },
  downloadPdf: 'Leistungsübersicht als PDF herunterladen',
  powerfulHeading: 'Leistungsstarke Absicherung',
  extraProtection: 'Topschutz mit vielen Extras',
  aemIntegrationLink: 'Alle Leistungen im Vergleich',
};
