import { setupWorker, SetupWorkerApi } from 'msw';
import handlers from './handlers';

let worker: SetupWorkerApi | undefined;

const getClientMocks = (): SetupWorkerApi => {
  if (!worker) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    worker = setupWorker(...handlers);
  }

  return worker;
};

export default getClientMocks;
