import React, { useEffect } from 'react';
import './Feedback.scss';
import FormSection from '../../components/FormSection/FormSection';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import { InsuranceSummary } from './components/InsuranceSummary/InsuranceSummary';
import translations from '../../constants/translations';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import {
  reachedFeedbackSelector,
  setReachedFeedback,
} from '../../store/appSlice';
import AemFragment from '../../api/aemIntegration/AemFragment';
import { AemAssetsKeys } from 'commons';

const FeedbackMessage = (): JSX.Element => (
  <p
    className="feedback-message"
    data-testid="feedback-message"
    dangerouslySetInnerHTML={{
      __html: translations.step13.insuranceSummaryMessage,
    }}
  />
);

export const Feedback = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const feedbackReached = useAppSelector(reachedFeedbackSelector);

  const disableBack = (): void => {
    window.history.forward();
  };

  const timeout = setTimeout('disableBack()', 0);
  timeout;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  window.onunload = function () {
    null;
  };

  useEffect(() => {
    if (!feedbackReached) {
      dispatch(setReachedFeedback(true));
    } else {
      localStorage.clear();
      sessionStorage.clear();
      disableBack();
    }
    clearTimeout(timeout);
  }, [dispatch, feedbackReached, timeout]);

  return (
    <FormSection>
      <FormSectionBody>
        <FeedbackMessage />
        <InsuranceSummary />
        <AemFragment
          fragmentName={AemAssetsKeys.FEEDBACK_FRAGMENT_OFFER}
          shouldUseIframe={false}
        />
      </FormSectionBody>
    </FormSection>
  );
};
