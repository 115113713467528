import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpMethod } from '../../types';
import { createInstance } from './commons';

const getAuth = (): string => {
  const { DOCUMENTENGINE_USER, DOCUMENTENGINE_PASSWORD } = process.env;

  const token = `${DOCUMENTENGINE_USER}:${DOCUMENTENGINE_PASSWORD}`;

  return `Basic ${Buffer.from(token).toString('base64')}`;
};

type HttpOptions = {
  method?: HttpMethod;
  payload?: Record<string, unknown> | Record<string, unknown>[];
};

let fetch: AxiosInstance | undefined;
export const documentEngineHttp = async <T = unknown>(
  url: string,
  options?: HttpOptions & AxiosRequestConfig
): Promise<T> => {
  try {
    if (!fetch) {
      fetch = createInstance(getAuth());
    }

    const { method = 'GET', payload, ...config } = options ?? {};

    const { data, ...response } = await fetch[method.toLowerCase()](url, {
      ...config,
      data: payload,
    });
    if (!/^2[0-9]{2,2}/.test(response.status.toString())) {
      throw new Error(response.statusText);
    }
    return data as T;
  } catch (error) {
    throw error;
  }
};
