import React from 'react';
import { useForm } from '../../../../../store/useForm';
import { RadioDrawer } from '../../../../../components/RadioDrawer/RadioDrawer';
import { setPreviousHouseholdDamage } from '../../../additionalQuestionsSlice';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { PreviousDamageQuestionFields } from './PreviousDamageQuestionFields';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { previousHouseholdDamageValidations } from '../../../utils/validations/previousHouseholdDamage';
import { getErrorMessage } from '../../../utils/validations/validators';
import {
  PreviousDamageKey,
  PreviousDamageQuestionProps,
} from '../../../AdditionalQuestionsTypes';
import translations from '../../../../../constants/translations';

interface Props extends PreviousDamageQuestionProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (id: string) => void;
}

export const PreviousDamageQuestion = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const { previousHouseholdDamage, dispatch } = useForm();
  const validations = useFormValidations(
    previousHouseholdDamage,
    previousHouseholdDamageValidations
  );

  const displayError = (field: PreviousDamageKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = translations.step8.pleaseSelectPreviousInsuranceDamageRadio;
    }
    if (error) {
      updateTrackingErrors(error);
    }
    return error;
  };
  const selectedError = displayError('selected')
    ? [displayError('selected')]
    : undefined;

  const handleOnChangeRadioDrawer = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const value = target.value === 'true';
    dispatch(setPreviousHouseholdDamage(value));

    if (onClick) {
      const trulyRadioBtn = 'RBUTTON_DAMAGE_YES';
      const falsyRadioBtn = 'RBUTTON_DAMAGE_NO';
      onClick(value ? trulyRadioBtn : falsyRadioBtn);
    }
  };

  const onClickRadioBtn = (id: string): void => {
    onClick && onClick(id);
  };

  return (
    <div className="additional-question">
      <RadioDrawer
        title={translations.step8.previousDamageInLast5Years}
        subtitle={translations.step8.previousDamageSubtitle}
        value={previousHouseholdDamage.selected}
        onChange={handleOnChangeRadioDrawer}
        data-testid="previous-damage-question"
      >
        <PreviousDamageQuestionFields
          isGoNextClicked={isGoNextClicked}
          onClick={onClickRadioBtn}
          updateTrackingErrors={updateTrackingErrors}
        />
      </RadioDrawer>

      <ErrorsForInput
        errors={selectedError}
        updateTrackingErrors={updateTrackingErrors}
      />
    </div>
  );
};
