import {
  beautifyIban,
  normalizeIban,
  isValidIban,
} from '@eg/elements/utils/validation/iban';

export const validateIban = (iban: string): boolean => {
  return isValidIban(iban);
};

export const normalizeIbanValue = (iban: string): string => {
  return normalizeIban(iban);
};

export const beautifyIbanValue = (iban: string): string => {
  return beautifyIban(iban);
};
