import * as constants from './constants';
import * as offers from './submitOffer';

const { getUrl, ...endpoints } = constants;

const submitEngineApi = {
  endpoints,
  offers,
};

export default submitEngineApi;
