import { useEffect, useState } from 'react';

type InputModeType =
  | 'none'
  | 'text'
  | 'decimal'
  | 'numeric'
  | 'tel'
  | 'search'
  | 'email'
  | 'url';

type UseInputMode = (cssSelector: string) => {
  setInputMode: (mode: InputModeType) => void;
};

export const useInputMode: UseInputMode = cssSelector => {
  const [inputMode, setInputMode] = useState<string | undefined>();

  useEffect(() => {
    if (inputMode) {
      const inputs = document.querySelectorAll(cssSelector);
      for (const input of inputs) {
        input.setAttribute('inputmode', inputMode);
      }
    }
  }, [cssSelector, inputMode]);

  return {
    setInputMode,
  };
};
