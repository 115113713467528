import React, { useRef } from 'react';
import Card from '@eg/elements/Card';
import { Size } from '@eg/elements/Price';
import { TooltipIcon } from '@eg/elements/TooltipIcon';

import './SuccessMsg.scss';
import SumInsuredInfo from '../SumInsuredInfo/SumInsuredInfo';
import type { TypeSuccessMsg } from './SuccessMsgTypes';
import translations from '../../../../constants/translations';
import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import Price from '../../../../components/Price/Price';

const SuccessMsg = ({
  value,
  isLoading = false,
  onOpened,
}: TypeSuccessMsg): JSX.Element => {
  const toolTipRef = useRef(null);
  return (
    <div className="success">
      <Card padding="small">
        <div className="success__card">
          <CheckColorIcon />
          <span className="success__message" data-testid="success-msg">
            {translations.step3.sumInsured}
            {isLoading ? (
              <LoaderIcon />
            ) : (
              <Price value={value} size={Size.Small} unstyled />
            )}
          </span>
          <TooltipIcon
            isModal
            ref={toolTipRef}
            onToggledOpen={(isOpen: boolean): void => isOpen && onOpened()}
          >
            <span ref={toolTipRef} data-testid="tooltip-icon-msg">
              <SumInsuredInfo />
            </span>
          </TooltipIcon>
        </div>
      </Card>
    </div>
  );
};

export default SuccessMsg;
