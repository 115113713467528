import { useState } from 'react';

import { setAddressConfirmation } from '../../personalDataSlice';
import {
  useAddressConfirmationTypes,
  PersonalDataForm,
} from '../../PersonalDataTypes';
import { useAppDispatch } from '../../../../store/hooks';
import translations from '../../../../constants/translations';

const useAddressConfirmation = (
  form: PersonalDataForm
): useAddressConfirmationTypes => {
  const { addressConfirmation } = form;
  const dispatch = useAppDispatch();
  const [checkBoxErrorMessage, setAddressConfirmationErrorMessage] =
    useState('');
  const handleCheckboxAddress = (event): void => {
    const checked = event.target.checked;
    if (checked) {
      dispatch(setAddressConfirmation('Bestätigte Adresse'));
      setAddressConfirmationErrorMessage('');
    } else {
      dispatch(setAddressConfirmation(''));
      setAddressConfirmationErrorMessage(
        translations.step9.checkBoxErrorMessage
      );
    }
  };

  const handleCheckboxAddressConfirmationValidation = (): void => {
    setAddressConfirmationErrorMessage(
      !addressConfirmation ? translations.step9.checkBoxErrorMessage : ''
    );
  };

  return {
    addressConfirmation,
    handleCheckboxAddress,
    handleCheckboxAddressConfirmationValidation,
    setAddressConfirmationErrorMessage,
    checkBoxErrorMessage,
  };
};

export default useAddressConfirmation;
