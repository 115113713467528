import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as AxiosLogger from 'axios-logger';
import { ConfidentialDataHelper } from '../../utils/logsHelper/confidential-data.helper';

const globalLoggerOptions = {
  headers: false,
};

const errorLoggerOptions = {
  ...globalLoggerOptions,
  params: false,
};

const requestLoggerOptions = {
  ...globalLoggerOptions,
  data: true,
};

const successResponseLoggerOptions = {
  ...globalLoggerOptions,
  data: false,
};

export const createInstance = (
  authorization?: string | null,
  config?: AxiosRequestConfig
): AxiosInstance => {
  AxiosLogger.setGlobalConfig(globalLoggerOptions);

  const axiosInstance = axios.create({
    ...config,
    headers: {
      ...config?.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(authorization && { Authorization: authorization }),
    },
  });
  axiosInstance.interceptors.request.use(
    request => {
      const maskedRequest = {
        ...request,
        data: JSON.stringify(
          ConfidentialDataHelper.hashConfidentialData(request.data)
        ),
      };
      AxiosLogger.requestLogger(maskedRequest, requestLoggerOptions);
      return request;
    },
    error => {
      return AxiosLogger.errorLogger(error, errorLoggerOptions);
    }
  );

  axiosInstance.interceptors.response.use(
    response => {
      return AxiosLogger.responseLogger(response, successResponseLoggerOptions);
    },
    error => {
      return AxiosLogger.errorLogger(error, errorLoggerOptions);
    }
  );

  return axiosInstance;
};
