import { rest } from 'msw';
import { Handler } from '../../../types';
import { createDocument, getUrl } from '../constants';
import { CreateDocumentResponse } from './types';

export const createDocumentHandler = (
  rest[createDocument.method.toLowerCase()] as Handler
)(getUrl(createDocument.path), (req, res, ctx) => {
  const { businessId } = req.params;

  return res(
    ctx.status(201),
    ctx.json({
      contextDetails: {
        businessId,
      },
      data: {},
      responseDetails: {},
    } as CreateDocumentResponse)
  );
});
