import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const HouseAndFlat = (): JSX.Element => {
  return (
    <div data-testid="house-flat-info">
      <h3 data-testid="house-flat-title">{step7.houseAndFlat.title}</h3>
      <p
        data-testid="house-flat-p1"
        dangerouslySetInnerHTML={{
          __html: step7.houseAndFlat.paragraph1,
        }}
      ></p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li>{step7.houseAndFlat.listOptions1.a}</li>
        <li>{step7.houseAndFlat.listOptions1.b}</li>
        <li>{step7.houseAndFlat.listOptions1.c}</li>
        <li>{step7.houseAndFlat.listOptions1.d}</li>
        <li>{step7.houseAndFlat.listOptions1.e}</li>
        <li>{step7.houseAndFlat.listOptions1.f}</li>
        <li>{step7.houseAndFlat.listOptions1.g}</li>
        <li>{step7.houseAndFlat.listOptions1.h}</li>
        <li>{step7.houseAndFlat.listOptions1.i}</li>
        <li>{step7.houseAndFlat.listOptions1.j}</li>
        <li>{step7.houseAndFlat.listOptions1.k}</li>
      </ul>
      <p
        data-testid="house-flat-p2"
        dangerouslySetInnerHTML={{
          __html: step7.houseAndFlat.paragraph2,
        }}
      ></p>
      <p data-testid="house-flat-p3">{step7.houseAndFlat.paragraph3}</p>
    </div>
  );
};

export default HouseAndFlat;
