import buildInfo from '../../build-info.json';
import { URLs } from 'commons';
import { routes } from '../../router';
import { storeTrackingData } from '../../storage/storage';
import { NODE_ENV } from '../../config';
import { SHA256 } from 'crypto-js';

import {
  TrackingBoolean,
  TrackingContent,
  TrackingConversionTypes,
  TrackingGender,
  TrackingUser,
  TrackingErrorDetails,
  TrackingWindow,
  TrackingClickUserProfileAddress,
  TrackingClickUserProfileInfo,
} from './TrackingTypes';

export const getStep = (location: string): number => {
  const route = routes.find(item => location === item.path);

  if (!route) {
    return 1;
  }

  return routes.indexOf(route) + 1;
};

export const getTrackingDate = (): string => `OTRhr2022|${buildInfo.buildDate}`;

export const isConversionTypedPage = (location: string): boolean => {
  const conversionTypedPages: string[] = [
    URLs.livingPlaceType,
    URLs.address,
    URLs.insuranceStartDate,
    URLs.insuranceCoverage,
    URLs.personalData,
    URLs.contractReview,
    URLs.bankDetails,
    URLs.feedback,
  ];

  return conversionTypedPages.includes(location);
};

export const removeConversionType = (
  trackingObject: TrackingContent
): TrackingContent => {
  delete trackingObject.transaction.attributes?.conversionType;
  return trackingObject;
};

export const removeMiscellaneous = (
  trackingObject: TrackingContent
): TrackingContent => {
  delete trackingObject.miscellaneous;
  return trackingObject;
};

export const removeEventDetails = (
  trackingObject: TrackingContent
): TrackingContent => {
  delete trackingObject.eventdetails;
  return trackingObject;
};

export interface UpdateConversionTypeOptions {
  gotConversionType: boolean;
  location: string;
  address?: {
    isAddressCorrected: boolean;
    isAddressValidationFailed: boolean;
  };
}

export const updateConversionType = (
  trackingObject: TrackingContent,
  options: UpdateConversionTypeOptions
): TrackingContent => {
  const { gotConversionType, location, address } = options;

  if (!gotConversionType) {
    return removeConversionType(trackingObject);
  }

  if (!trackingObject.transaction.attributes) {
    const { ...rest } = trackingObject.transaction;
    trackingObject.transaction = {
      attributes: {},
      ...rest,
    };
  }

  let conversionType: TrackingConversionTypes =
    TrackingConversionTypes.SALE_FUNNEL_START;

  switch (location) {
    case URLs.insuranceCoverage:
      conversionType = TrackingConversionTypes.SALE_FUNNEL_CALCULATION;
      break;
    case URLs.address:
      const { isAddressCorrected, isAddressValidationFailed } = address;
      if (isAddressCorrected) {
        conversionType = TrackingConversionTypes.ADDRESS_CORRECTED;
      }
      if (isAddressValidationFailed) {
        conversionType = TrackingConversionTypes.ADDRESS_VERIFICATION_FAILED;
      }
      if (!isAddressCorrected && !isAddressValidationFailed) {
        conversionType = null;
      }
      break;
    case URLs.insuranceStartDate:
      conversionType = TrackingConversionTypes.ADDRESS_SUCCESS;
      break;
    case URLs.personalData:
      conversionType = TrackingConversionTypes.SALE_FUNNEL_PERSONAL_DATA;
      break;
    case URLs.contractReview:
      conversionType = TrackingConversionTypes.SALE_FUNNEL_FINAL_CHECK;
      break;
    case URLs.bankDetails:
      conversionType = TrackingConversionTypes.SALE_FUNNEL_BANK_DATA;
      break;
    case URLs.feedback:
      conversionType = TrackingConversionTypes.SALE;
      break;
    default:
      break;
  }

  if (!conversionType) {
    if (trackingObject.transaction.attributes.conversionType) {
      delete trackingObject.transaction.attributes.conversionType;
    }
    return trackingObject;
  }

  const { ...attrs } = trackingObject.transaction.attributes;
  trackingObject.transaction.attributes = { ...attrs, conversionType };

  return trackingObject;
};

export const setTariffOptions = (
  trackingObject: TrackingContent,
  data: string
): TrackingContent => {
  trackingObject.product[0].attributes.tariffOptions1 = data;
  return trackingObject;
};

export const getTariffOptions = (
  trackingObject: TrackingContent,
  step: number
): string => {
  if (!trackingObject.product) {
    console.error('No product available in trackingObject', trackingObject);
    return '';
  }

  const { product: products } = trackingObject;
  const [product] = products;

  const { attributes } = product;
  const { tariffOptions1 = '' } = attributes;
  const options = tariffOptions1?.split('|');
  return options?.filter((_, key) => key < step).join('|');
};

export const updateTariffOptions = (
  original: string,
  key: string,
  value: string | number
): string => {
  const divisor = '|';
  const originalArray = original.split(divisor);
  const newValue = `${key}=${value}`;

  if (!originalArray.find(item => item.includes(key))) {
    return [...originalArray, newValue].join(divisor);
  }

  const updatedArray = originalArray.map(item =>
    item.includes(key) ? newValue : item
  );

  return updatedArray.join(divisor);
};

export const getTrackingBooleanValue = (selected: boolean): TrackingBoolean =>
  TrackingBoolean[selected ? 'YES' : 'NO'];

export const updateInsuranceStart = (
  trackingObject: TrackingContent,
  value: string
): TrackingContent => {
  if (!trackingObject.product) {
    console.error('No product available in trackingObject', trackingObject);
    return trackingObject;
  }

  if (value === '0') {
    delete trackingObject.product[0].attributes.insuranceStart;
    return trackingObject;
  }

  trackingObject.product[0].attributes.insuranceStart = value;
  return trackingObject;
};

export const updateAndHashUserAddress = (
  trackingObject: TrackingContent,
  adressInfo: Required<TrackingClickUserProfileAddress>
): TrackingContent => {
  const { zipCode, streetName, streetNumber, city } = adressInfo;
  const address = {
    ...(Boolean(zipCode) && { postalCode: zipCode }),
    ...((Boolean(streetName) || Boolean(streetNumber)) && {
      line1: hashData(`${streetName + streetNumber}`),
    }),
    ...(Boolean(city) && { city: hashData(adressInfo.city) }),
  };

  if (trackingObject.hasOwnProperty('user')) {
    const { user: users, ...rest } = trackingObject;
    const [user] = users;

    user.profile.address = address;

    return { ...rest, user: [user] };
  }

  const userItem: TrackingUser = {
    profile: {
      address: address,
    },
  };
  trackingObject['user'] = [];
  trackingObject.user.push(userItem);

  return trackingObject;
};

export const updateAndHashProfileInfo = (
  trackingObject: TrackingContent,
  profileInfo: TrackingClickUserProfileInfo
): TrackingContent => {
  const { prefixNumber, telephoneNumber, email, firstName, lastname } =
    profileInfo;
  const phoneNumber =
    prefixNumber && telephoneNumber
      ? prefixNumber + preparePhoneNumberForHashing(telephoneNumber)
      : undefined;

  const preparedProfileInfo = {
    ...(email && { email: hashData(email) }),
    ...(phoneNumber && {
      telephone: hashData(phoneNumber.substring(1)),
      telephoneE164: hashData(phoneNumber),
    }),
    ...(firstName && {
      firstName: hashData(firstName),
    }),
    ...(lastname && { lastName: hashData(lastname) }),
  };

  if (trackingObject.hasOwnProperty('user')) {
    const { user: users, ...rest } = trackingObject;
    const [user] = users;

    user.profile.profileInfo = preparedProfileInfo;

    return { ...rest, user: [user] };
  }

  const userItem: TrackingUser = {
    profile: {
      profileInfo: preparedProfileInfo,
    },
  };
  trackingObject['user'] = [];
  trackingObject.user.push(userItem);

  return trackingObject;
};

export const updateUserBirthday = (
  trackingObject: TrackingContent,
  //todo year of birth should be a number
  yearOfBirth: string
): TrackingContent => {
  if (!trackingObject?.user || trackingObject.user.length === 0) {
    console.error('No user available in trackingObject', trackingObject);
    return trackingObject;
  }

  trackingObject.user[0].profile = {
    ...trackingObject.user[0].profile,
    attributes: {
      ...trackingObject.user[0].profile.attributes,
      birthday: yearOfBirth,
    },
  };

  return trackingObject;
};

export const updateGender = (
  trackingObject: TrackingContent,
  gender: TrackingGender
): TrackingContent => {
  if (
    !trackingObject.user ||
    !trackingObject.user[0] ||
    !trackingObject.user[0].profile ||
    !trackingObject.user[0].profile.attributes
  ) {
    return trackingObject;
  }

  trackingObject.user[0].profile.attributes.gender = gender;
  return trackingObject;
};

export const setCalculatedValue = (
  trackingObject: TrackingContent,
  calculatedValue: number
): TrackingContent => {
  trackingObject.transaction.attributes = {
    ...trackingObject.transaction.attributes,
    calculatedValue,
  };

  return trackingObject;
};

export const setMiscellaneousError = (
  trackingObject: TrackingContent,
  errorDetails: TrackingErrorDetails
): TrackingContent => {
  const { errorMessages, errorType } = errorDetails;
  const errors = {
    errorMessages,
    errorType,
    errorUrl: window.location.href,
    errorFlag: true,
  };

  trackingObject.miscellaneous = { errors: errors };
  return trackingObject;
};
export const setConversionType = (
  trackingObject: TrackingContent,
  conversionType: TrackingConversionTypes
): TrackingContent => {
  if (!trackingObject.transaction) {
    return trackingObject;
  }

  const { transaction } = trackingObject;
  const { attributes, ...rest } = transaction;

  trackingObject.transaction = {
    ...rest,
    attributes: { ...attributes, conversionType },
  };

  return trackingObject;
};

export const pushTrackingData = (data: TrackingContent): void => {
  // init carbon data layer array if not already present
  const trackingWindow = window as unknown as TrackingWindow;
  trackingWindow.appEventData = trackingWindow.appEventData || [];

  if (NODE_ENV === 'development') {
    console.log({ data });
  }

  trackingWindow.appEventData.push(data);
  storeTrackingData(data);
};

export const setTotalTransaction = (
  trackingObject: TrackingContent,
  total: number
): TrackingContent => {
  trackingObject.transaction = {
    ...trackingObject.transaction,
    total: {
      transactionTotal: total,
    },
  };

  return trackingObject;
};

export const getTrackingErrorDetails = (error: any): TrackingErrorDetails => {
  let errorMessages = 'An unexpected error occurred';
  let errorType = '400';
  const gotErrorMessage = error.hasOwnProperty('message');
  if (gotErrorMessage) {
    const { message: genericErrorMsg, response } = error;
    if (response) {
      const { message, status: code } = error.response.data;
      errorType = code;
      errorMessages = message;
    } else {
      errorMessages = genericErrorMsg;
    }
  }
  return { errorMessages, errorType };
};

const USER_REPLACEMENT_REGEX = {
  phone: /^(0|49|\+49)+|[^0-9]+/g,
  hash: /\s/g,
};

export const hashData = (dataToHash: string): string => {
  const preparedDataToHash = dataToHash
    .toLowerCase()
    .replace(USER_REPLACEMENT_REGEX.hash, '');

  return SHA256(preparedDataToHash).toString();
};

export const preparePhoneNumberForHashing = (phoneNumber: string): string =>
  phoneNumber.replace(USER_REPLACEMENT_REGEX.phone, '');
