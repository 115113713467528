import {
  glassDamageCostValidator,
  isValidYear,
  validateDamageNumberField,
  validateGlassDamageTypeField,
  validateSelectedField,
} from 'commons';
import {
  DamageNumber,
  GlassDamage,
  GlassPreviousDamage,
} from 'commons/apis/hausrat/types';
import translations from '../../../../constants/translations';
import { ValidatorFunction } from '../../../../store/types';
import { PreviousDamageKey } from '../../AdditionalQuestionsTypes';
import { generateValidField, generateInvalidField } from './validators';

export const previousGlassDamageErrors: Record<PreviousDamageKey, string> = {
  selected: translations.step8.companyNameError,
  number: translations.step8.damageAmountError,
  type: translations.step8.damageTypeError,
  cost: translations.step8.damageCostError,
  year: translations.step8.damageYearError,
};

const costAmountError = {
  cost: translations.step8.moreThanOneDamageError,
};

export const previousGlassDamageValidations: Record<
  PreviousDamageKey,
  ValidatorFunction<GlassPreviousDamage[PreviousDamageKey]>
> = {
  selected: (value: boolean) => {
    return validateSelectedField(value)
      ? generateValidField()
      : generateInvalidField(previousGlassDamageErrors, 'selected');
  },
  number: (value: DamageNumber) => {
    return validateDamageNumberField(value)
      ? generateValidField()
      : generateInvalidField(previousGlassDamageErrors, 'number');
  },
  cost: (value: number) => {
    const isValid = glassDamageCostValidator(value);
    if (isValid) {
      return generateValidField();
    }
    const errorCollection =
      typeof value === undefined ? previousGlassDamageErrors : costAmountError;
    return generateInvalidField(errorCollection, 'cost');
  },
  type: (value: GlassDamage) => {
    return validateGlassDamageTypeField(value)
      ? generateValidField()
      : generateInvalidField(previousGlassDamageErrors, 'type');
  },
  year: (value: number) => {
    const isValid = isValidYear(value);
    return isValid
      ? generateValidField()
      : generateInvalidField(previousGlassDamageErrors, 'year');
  },
};
