import {
  PreviousInsurance,
  HouseholdPreviousDamage,
  GlassPreviousDamage,
  AdditionalQuestionsForm,
  hasDoorLocks,
  DamageNumber,
  GlassDamage,
  InsuranceEndDate,
  HouseholdDamage,
} from '../apis/hausrat/types';
import { InsuranceCompany } from '../apis/insuranceCompanies/types';
import {
  MAX_ALLOWED_GLASS_DAMAGE_COST,
  MAX_ALLOWED_HOUSEHOLD_DAMAGE_COST,
  MAX_YEARS_PREVIOUS_INSURANCE_ALLOWED,
} from '../constants';
import { addYears, dateInputValueToDate, isDateOlderThan } from '../utils';
import {
  getLowerOrEqualThanValidator,
  hasLength,
  isNotUndefined,
  isNumber,
  isString,
  isValidDateInputValue,
} from './base.validator';

export const householdDamageCostValidator = (value: number): boolean => {
  const isLowerOrEqualThan = getLowerOrEqualThanValidator(value);
  return (
    isNotUndefined(value) &&
    isLowerOrEqualThan(MAX_ALLOWED_HOUSEHOLD_DAMAGE_COST)
  );
};

export const glassDamageCostValidator = (value: number): boolean => {
  const isLowerOrEqualThan = getLowerOrEqualThanValidator(value);
  return (
    isNotUndefined(value) && isLowerOrEqualThan(MAX_ALLOWED_GLASS_DAMAGE_COST)
  );
};

export const previousGlassInsuranceCompanyValidator = (
  value: string,
  suggestions?: InsuranceCompany[]
): boolean => {
  return (
    isNotUndefined(value) &&
    isString(value) &&
    (!value.length || suggestions?.some(c => c.name === value))
  );
};

export const isValidYear = (year: number): boolean => {
  return isNumber(year) && year?.toString().length === 4;
};

export const validateSelectedField = (field: boolean): boolean => {
  return isNotUndefined(field);
};

export const validateCompanyIsInSuggestions = (
  name: string,
  suggestions: InsuranceCompany[]
): boolean => !name.length || suggestions.some(c => c.name === name);

export const validateCompanyNameField = (
  value: string,
  suggestions: InsuranceCompany[]
): boolean => {
  return (
    isNotUndefined(value) &&
    isString(value) &&
    validateCompanyIsInSuggestions(value, suggestions)
  );
};

export const validateCompanyVuNr = (field: string): boolean => {
  return isNotUndefined(field) && isString(field);
};

export const validateDamageNumberField = (field: DamageNumber): boolean => {
  return isNotUndefined(field);
};

export const validateHouseholdDamageTypeField = (
  field: HouseholdDamage
): boolean => {
  return isNotUndefined(field);
};

export const validateGlassDamageTypeField = (field: GlassDamage): boolean => {
  return isNotUndefined(field);
};

export const validatePolicyNumberField = (field: string): boolean => {
  return hasLength(field);
};

export const validatePolicyTerminatorField = (field: string): boolean => {
  return isNotUndefined(field);
};

export const validateDateField = (field: InsuranceEndDate): boolean => {
  return isValidDateInputValue(field);
};

export const validateDateYearField = (field: InsuranceEndDate): boolean => {
  return field?.year !== '0000';
};

export const validateYearIsNotOlderThanFiveYearsAgo = (
  field: InsuranceEndDate
): boolean => {
  if (!isValidDateInputValue(field)) {
    return false;
  }
  const startDate = addYears({
    yearsToAdd: -MAX_YEARS_PREVIOUS_INSURANCE_ALLOWED,
  });

  const dateToCheck = dateInputValueToDate(field);

  return !isDateOlderThan({
    startDate,
    dateToCheck,
    resetTime: true,
  });
};

export const validatePreviousInsuranceFields = (
  value: PreviousInsurance & { suggestions: InsuranceCompany[] }
): boolean => {
  const {
    selected,
    companyName,
    suggestions,
    vuNr,
    policyNumber,
    policyTerminatedBy,
    endDate,
  } = value;
  if (selected === false) {
    return true;
  }
  return (
    validateSelectedField(selected) &&
    validateCompanyNameField(companyName, suggestions) &&
    validateCompanyVuNr(vuNr) &&
    validatePolicyNumberField(policyNumber) &&
    validatePolicyTerminatorField(policyTerminatedBy) &&
    validateDateField(endDate) &&
    validateDateYearField(endDate) &&
    validateYearIsNotOlderThanFiveYearsAgo(endDate)
  );
};

export const validatePreviousHouseholdDamageFields = (
  value: HouseholdPreviousDamage
): boolean => {
  const { selected, number, cost, year, type } = value;
  if (selected === false) {
    return true;
  }
  return (
    validateSelectedField(selected) &&
    validateDamageNumberField(number) &&
    householdDamageCostValidator(cost) &&
    isValidYear(year) &&
    isNotUndefined(type)
  );
};

export const validatePreviousGlassDamageFields = (
  value: GlassPreviousDamage
): boolean => {
  const { selected, number, cost, year, type } = value;
  if (selected === false) {
    return true;
  }
  return (
    validateSelectedField(selected) &&
    validateDamageNumberField(number) &&
    glassDamageCostValidator(cost) &&
    isValidYear(year) &&
    validateGlassDamageTypeField(type)
  );
};

export const validateHasExistingDoorLocksPayload = (
  data: hasDoorLocks
): boolean => {
  return isNotUndefined(data.selected);
};

export const validateAdditionalQuestionsPayload = (
  data: AdditionalQuestionsForm,
  suggestions: InsuranceCompany[]
): boolean => {
  const validationsMap = {
    previousInsurance: (fields: PreviousInsurance) =>
      validatePreviousInsuranceFields({ ...fields, suggestions }),
    previousHouseholdDamage: validatePreviousHouseholdDamageFields,
    previousGlassInsurance: (fields: PreviousInsurance) =>
      validatePreviousInsuranceFields({ ...fields, suggestions }),
    previousGlassDamage: validatePreviousGlassDamageFields,
    hasElectronicOrMechatronicLocks: validateHasExistingDoorLocksPayload,
  };

  let payloadIsValid = true;
  for (const [key, value] of Object.entries(data)) {
    const validationResult = validationsMap[key](value);
    if (!validationResult) {
      payloadIsValid = false;
      break;
    }
  }

  return payloadIsValid;
};
