import React, { useState } from 'react';
import SelectRow from '@eg/elements/SelectRow';
import { CountryCodeProps } from './CountryCodes.d';
import { countries } from '../../constants/countryPrefixes';

const createPrefixes = (fieldName: string): JSX.Element[] => {
  const fieldOptions: JSX.Element[] = [];
  if (countries) {
    countries
      .filter(country => country.continent === 'EU')
      .forEach(country => {
        fieldOptions.push(
          <option key={`${fieldName}-${country.name}`} value={country.code}>
            {country.name} {country.code}
          </option>
        );
      });
  }
  return fieldOptions;
};

const CountryCode: React.FC<CountryCodeProps> = ({ code, onChange }) => {
  const [country, setCountry] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setCountry(value);

    onChange?.(event);
  };

  const getDefaultCode = (): string => {
    const defaultCountry = countries.filter(el => el.code === '+49');
    return defaultCountry[0].code;
  };

  return (
    <SelectRow
      id="prefix"
      name={country}
      label=""
      data-component-id="country codes"
      onChange={handleChange}
      value={code || getDefaultCode()}
    >
      {createPrefixes('prefixes')}
    </SelectRow>
  );
};

export default CountryCode;
