export enum TrackingElementPage8 {
  RBUTTON_PRE_INSURANCE_YES = 'RButton_HRVorversicherungJa',
  RBUTTON_PRE_INSURANCE_NO = 'RButton_HRVorversicherungNein',
  RBUTTON_DAMAGE_YES = 'RButton_HRSchaedenJa',
  RBUTTON_DAMAGE_NO = 'RButton_HRSchaedenNein',
  RBUTTON_DAMAGE_EQUAL1 = 'RButton_HRSchaedenGleich1',
  RBUTTON_DAMAGE_SIZE1 = 'RButton_HRSchaedenGroesser1',
  RBUTTON_GEK_VN = 'RButton_HRGekVN',
  RBUTTON_GEK_VERS = 'RButton_HRGekVers',
  RBUTTON_PRE_GLAS_INSURANCE_YES = 'RButton_GlasVorversicherungJa',
  RBUTTON_PRE_GLAS_INSURANCE_NO = 'RButton_GlasVorversicherungNein',
  RBUTTON_DAMAGE_GLAS_YES = 'RButton_GlasSchaedenJa',
  RBUTTON_DAMAGE_GLAS_NO = 'RButton_GlasSchaedenNein',
  RBUTTON_DAMAGE_GLAS_EQUAL1 = 'RButton_GlasSchaedenGleich1',
  RBUTTON_DAMAGE_GLAS_SIZE1 = 'RButton_GlasSchaedenGroesser1',
  RBUTTON_GEK_GLAS_VN = 'RButton_GlasGekVN',
  RBUTTON_GEK_GLAS_VERS = 'RButton_GlasGekVers',
  RBUTTON_DOOR_LOCKS_YES = 'RButton_TuerschloesserJa',
  RBUTTON_DOOR_LOCKS_NO = 'RButton_TuerschloesserNein',
  INFOICON_DOOR_LOCKS = 'InfoIcon_Tuerschloesser',
  BUTTON_CONTACT_CONSULTANT = 'Button_BeraterKontaktieren',
}
