import { spcsHttp } from '../../http/spcsHttp';
import { getUrl, validateAddressRoute } from '../constants';
import { ValidateAddressType } from '../payments/types';

type ValidateAddressReturnType = {
  status: 'VALID' | 'INVALID';
  errorMessage?: string;
};

export const validateAddress = async ({
  businessId,
  personId,
  addressId,
}): Promise<ValidateAddressReturnType> => {
  try {
    const { method, path } = validateAddressRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':personId', personId)
      .replace(':addressId', addressId);
    const response = await spcsHttp<ValidateAddressType>(url, {
      method,
    });

    const {
      data: {
        validationResult: {
          pdbResult,
          parisResult,
          pdbErrorMessage,
          parisErrorMessage,
        },
      },
    } = response;

    if (pdbResult === 'VALID' && parisResult === 'VALID') {
      return {
        status: 'VALID',
      };
    }

    return {
      status: 'INVALID',
      errorMessage: pdbErrorMessage || parisErrorMessage,
    };
  } catch (error) {
    return {
      status: 'INVALID',
      errorMessage: error,
    };
  }
};
