import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';
import translations from '../../../constants/translations';
import { ModalProps } from './types';
import { InfoIcons } from '../TrackingTypes';

const { step7 } = translations;
const content = (
  <div data-testid="contract-term-info">
    <h3 data-testid="contract-term-title">{step7.contractTerm.title}</h3>
    <p
      data-testid="contract-term-p1"
      dangerouslySetInnerHTML={{
        __html: step7.contractTerm.paragraph1,
      }}
    ></p>
    <p
      data-testid="contract-term-p2"
      dangerouslySetInnerHTML={{
        __html: step7.contractTerm.paragraph2,
      }}
    ></p>
    <p
      data-testid="contract-term-p3"
      dangerouslySetInnerHTML={{
        __html: step7.contractTerm.paragraph3,
      }}
    ></p>
    <p
      data-testid="contract-term-p4"
      dangerouslySetInnerHTML={{
        __html: step7.contractTerm.paragraph4,
      }}
    ></p>
  </div>
);

const ContractTerm = ({ onOpen }: ModalProps): JSX.Element => {
  const onToggleHandler = (isOpen: boolean): void =>
    isOpen && onOpen(InfoIcons.CONTRACT_TERM);
  return (
    <TooltipIcon isModal onToggledOpen={onToggleHandler}>
      {content}
    </TooltipIcon>
  );
};

export { ContractTerm };
