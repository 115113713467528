import translations from '../../../constants/translations';

export const houseTypeCopies = {
  apartment: translations.step10.constantHouseTypeCopiesApartment,
  singleFamilyHouse: translations.step10.constantHouseTypeCopiesSingleHouse,
};

export const floorTypeCopies = {
  basement: translations.step10.constantFloorTypeCopiesBasemeant,
  groundFloor: translations.step10.constantFloorTypeCopiesGroundFloor,
  firstFloor: translations.step10.constantFloorTypeCopiesFirstFloor,
  secondFloor: translations.step10.constantFloorTypeCopiesSecondFloor,
  otherFloor: translations.step10.constantFloorTypeCopiesOtherFloor,
};

export const previousContractTermitedByCopies = {
  policyHolder:
    translations.step10.constantPreviousContractTermitedByCopiesPolicyHolder,
  insurer: translations.step10.constantPreviousContractTermitedByCopiesInsurer,
};

export const productModulesCopies = {
  simpleTheft: translations.step10.constantProductModulesCopiesSimpleTheft,
  houseAndFlat: translations.step10.constantProductModulesCopiesHouseAndFlat,
  glass: translations.step10.constantProductModulesCopiesGlass,
  otherNaturalHazards:
    translations.step10.constantProductModulesCopiesOtherNaturalHazards,
  unnamedPerils: translations.step10.constantProductModulesCopiesUnnamedPerils,
  bicycleAndEbike:
    translations.step10.constantProductModulesCopiesBicycleAndEbike,
};

export const tariffTypeCopies = {
  tariffTypes: translations.step10.constantTarifTypeCopies,
};

export const deductibleAmountCopies = {
  zero: translations.step10.formSubCategoryContentExcessNone,
  threeHundred: translations.step10.formSubCategoryContentExcess300,
  flex300: translations.step10.formSubCategoryContentExcess300flex,
};

export const contractPeriodCopies = {
  '1': translations.step10.formSubCategoryContentContractTermOneYear,
  '2': translations.step10.formSubCategoryContentContractTermTwoYears,
  '3': translations.step10.formSubCategoryContentContractTermThreeYears,
  '4': translations.step10.formSubCategoryContentContractTermFourYears,
  '5': translations.step10.formSubCategoryContentContractTermFiveYears,
  '6': translations.step10.formSubCategoryContentContractTermSixYears,
  '7': translations.step10.formSubCategoryContentContractTermSevenYears,
  '8': translations.step10.formSubCategoryContentContractTermEightYears,
  '9': translations.step10.formSubCategoryContentContractTermNineYears,
  '10': translations.step10.formSubCategoryContentContractTermTenYears,
};

export const damageTypeCopies = {
  fire: translations.step8.fire,
  burglary: translations.step8.burglary,
  robbery: translations.step8.robbery,
  tapWater: translations.step8.tapWater,
  storm: translations.step8.storm,
  vandalism: translations.step8.vandalism,
  otherNaturalHazards: translations.step8.otherNaturalHazards,
  other: translations.step8.other,
};

export const damageTypeGlassCopies = {
  glassBreakage: translations.step8.glassBreakage,
  other: translations.step8.other,
};
