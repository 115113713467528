import React from 'react';
import { useSelector } from 'react-redux';
import { RadioDrawer } from '../../../../../components/RadioDrawer/RadioDrawer';
import translations from '../../../../../constants/translations';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { useForm } from '../../../../../store/useForm';
import {
  insuranceCompaniesSuggestionsSelector,
  setPreviousGlassInsurance,
} from '../../../additionalQuestionsSlice';
import {
  PreviousGlassInsuranceQuestionProps,
  PreviousInsuranceKey,
} from '../../../AdditionalQuestionsTypes';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { previousGlassInsuranceValidations } from '../../../utils/validations/previousGlassInsurance';
import { getErrorMessage } from '../../../utils/validations/validators';
import { PreviousGlassInsuranceQuestionFields } from './PreviousGlassInsuranceQuestionFields';

interface Props extends PreviousGlassInsuranceQuestionProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (error: string) => void;
}
export const PreviousGlassInsuranceQuestion = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const suggestions = useSelector(insuranceCompaniesSuggestionsSelector);
  const { previousGlassInsurance, dispatch } = useForm();
  const validations = useFormValidations(
    previousGlassInsurance,
    previousGlassInsuranceValidations(
      suggestions.previousGlassInsuranceCompanies
    )
  );

  const displayError = (field: PreviousInsuranceKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = translations.step8.pleaseSelectPreviousGlassInsuranceRadio;
    }
    if (error) {
      updateTrackingErrors(error);
    }
    return error;
  };

  const selectedError = displayError('selected')
    ? [displayError('selected')]
    : undefined;

  const handleOnChangeRadioDrawer = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const value = target.value === 'true';
    dispatch(setPreviousGlassInsurance(value));

    if (onClick) {
      const trulyRadioBtn = 'RBUTTON_PRE_GLAS_INSURANCE_YES';
      const falsyRadioBtn = 'RBUTTON_PRE_GLAS_INSURANCE_NO';
      onClick(value ? trulyRadioBtn : falsyRadioBtn);
    }
  };

  const onClickRadioBtn = (id: string): void => {
    onClick && onClick(id);
  };

  const subtitle = (
    <p
      dangerouslySetInnerHTML={{
        __html: translations.step8.previousGlassDamageSubtitle,
      }}
    ></p>
  );
  return (
    <div className="additional-question">
      <RadioDrawer
        title={translations.step8.previousInsurancesInLast5Years}
        subtitle={subtitle}
        value={previousGlassInsurance?.selected}
        children={
          <PreviousGlassInsuranceQuestionFields
            onClick={onClickRadioBtn}
            isGoNextClicked={isGoNextClicked}
            updateTrackingErrors={updateTrackingErrors}
          />
        }
        onChange={handleOnChangeRadioDrawer}
      />
      <ErrorsForInput
        errors={selectedError}
        updateTrackingErrors={updateTrackingErrors}
      />
    </div>
  );
};
