import { ServerRoute } from '../../../types';

export const createOffer: ServerRoute = {
  method: 'POST',
  path: '/angebote',
};

export const readOffer: ServerRoute = {
  method: 'GET',
  path: '/angebote/:businessId',
};

export const updateOffer: ServerRoute = {
  method: 'PUT',
  path: '/angebote/:businessId',
};

export const createRole: ServerRoute = {
  method: 'POST',
  path: '/angebote/:businessId/rollen',
};

export const createPreviousInsurance: ServerRoute = {
  method: 'POST',
  path: '/angebote/:businessId/vorversicherung',
};

export const createPreviousDamage: ServerRoute = {
  method: 'POST',
  path: '/angebote/:businessId/vorschaden',
};

export const isOfferLocked: ServerRoute = {
  method: 'GET',
  path: '/angebote/:businessId/abschliessbar',
};

export const updateRole: ServerRoute = {
  method: 'PUT',
  path: '/angebote/:businessId/rollen',
};

export const deletePreviousDamage: ServerRoute = {
  method: 'DELETE',
  path: '/angebote/:businessId/vorschaden/:previousDamageId',
};

export const deletePreviousInsurace: ServerRoute = {
  method: 'DELETE',
  path: '/angebote/:businessId/vorversicherung/:previousInsuranceId',
};

export const getUrl = (path: string): string => {
  const baseUrl = process.env.OFFERENGINE_URL;
  return `${baseUrl}${path}`;
};
