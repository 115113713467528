import { Dispatch } from '@reduxjs/toolkit';
import { getFloorTypeFromMapping, getLivingPlaceFromMapping } from 'commons';
import { Angebot } from 'commons/apis/offerEngine';
import { Person } from 'commons/apis/spcs/persons/types';
import {
  setTypeOfHouse,
  setTypeOfHouseValid,
} from '../../pages/step1/livingPlaceSlice';
import {
  setFloorTypeValid,
  setTypeOfFloor,
} from '../../pages/step2/homeLocationSlice';
import {
  setHomeSquareMetersValid,
  setSizeInSM,
} from '../../pages/step3/HomeSquareMetersSlice';
import {
  setCity,
  setRiskZone,
  setStreetName,
  setStreetNumber,
  setZipCode,
} from '../../pages/step4/addressSlice';
import {
  setDateOfStart,
  setInsuranceDateValid,
  setTypeOfDate,
} from '../../pages/step5/insuranceDateSlice';
import { TypesOfDate } from '../../pages/step5/InsuranceDateTypes';
import {
  setDateOfBirth,
  setDateOfBirthValid,
} from '../../pages/step6/dateOfBirthSlice';
import {
  initProductModule,
  setBicycleAndEbikeCoInsuredAmount,
  setBicycleAndEbikeCoInsuredCover,
  setContractPeriod,
  setCoverageProductVariant,
  setDeductibleAmount,
  setPaymentMethod,
} from '../../pages/step7/insuranceCoverageSlice';
import {
  setEmail,
  setFirstName,
  setGender,
  setSurname,
  setTelephoneNumber,
  setPrefixNumber,
} from '../../pages/step9/personalDataSlice';
import {
  setBankDetails,
  setIban,
  setIbanIsValidated,
} from '../../pages/step11/bankDetailsSlice';
import { setPaymentId, setStepValidated } from '../../store/appSlice';
import { RootState } from '../../store/types';

import { dateToDateInputValue, yearFirstFormat } from '../dates/dates';
import {
  additionalQuestionsFetchCompanyNames,
  setElectronicOrMechatronicLocks,
  setGlassInsuranceCompanyVuNR,
  setInsuranceCompanyVuNR,
  setPreviousGlassDamage,
  setPreviousGlassDamageCost,
  setPreviousGlassDamageNumber,
  setPreviousGlassDamageType,
  setPreviousGlassDamageYear,
  setPreviousGlassInsurance,
  setPreviousGlassInsuranceCompany,
  setPreviousGlassInsuranceEndDate,
  setPreviousGlassInsurancePolicyNumber,
  setPreviousGlassInsuranceTerminator,
  setPreviousHouseholdDamage,
  setPreviousHouseholdDamageCost,
  setPreviousHouseholdDamageNumber,
  setPreviousHouseholdDamageType,
  setPreviousHouseholdDamageYear,
  setPreviousHouseholdInsurance,
  setPreviousHouseholdInsuranceCompany,
  setPreviousHouseholdInsuranceEndDate,
  setPreviousHouseholdInsurancePolicyNumber,
  setPreviousHouseholdInsuranceTerminator,
} from '../../pages/step8/additionalQuestionsSlice';
import {
  getAdditionalQuestionsFromOffer,
  getAddressFromOffer,
  getBankDetailsFromPerson,
  getBirthDateFromPerson,
  getHomeSquareMetersFromOffer,
  getInsuranceCoverageFromOffer,
  getInsuranceDateFromOffer,
  getLivingPlaceTypeFromOffer,
  getLocationHomeFromOffer,
  getPersonalDataFromPerson,
  getZursZoneFromOffer,
} from '../../store/offerSlice';
import { extractCountryCode, formatTelephoneNumber } from '../../utils';
import { retrieveAdditionalQeustionsTouched } from '../../storage/storage';
import {
  getNextMonth,
  getTomorrow,
} from '../../pages/step5/components/InsuranceDateOptions/helpers/dates';

let angebot: Angebot | undefined;
let person: Person | undefined;

export const storeOffer = (rawOffer: Angebot, rawPerson: Person): void => {
  angebot = rawOffer;
  person = rawPerson;
};

export const setupInitialState = (dispatch: Dispatch): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - We don't need to replicate the entire `RootState`
  const mockRootState: RootState = {
    offer: {
      angebot,
      person,
    },
  };

  // Step 1: Type of house
  const houseType = getLivingPlaceTypeFromOffer(mockRootState);
  if (houseType) {
    const parsedHouseType = getLivingPlaceFromMapping(houseType);
    if (parsedHouseType) {
      dispatch(setTypeOfHouse(parsedHouseType));
      dispatch(setTypeOfHouseValid(true));
      dispatch(setStepValidated(1));
    }
  }

  // Step 2: Type of floor
  const floorType = getLocationHomeFromOffer(mockRootState);
  if (floorType) {
    const parsedFloorType = getFloorTypeFromMapping(floorType);
    if (parsedFloorType) {
      dispatch(setTypeOfFloor(parsedFloorType));
      dispatch(setFloorTypeValid(true));
      dispatch(setStepValidated(2));
    }
  }

  // Step 3: Home Square metters
  const squareMetters = getHomeSquareMetersFromOffer(mockRootState);
  if (squareMetters) {
    dispatch(setSizeInSM(squareMetters));
    dispatch(setHomeSquareMetersValid(true));
    dispatch(setStepValidated(3));
  }

  // Step 4: Address
  const { streetName, streetNumber, zipCode, city } =
    getAddressFromOffer(mockRootState);
  if (streetNumber) {
    dispatch(setStreetNumber(streetNumber));
  }
  if (streetName) {
    dispatch(setStreetName(streetName));
  }
  if (zipCode) {
    dispatch(setZipCode(zipCode));
  }
  if (city) {
    dispatch(setCity(city));
  }

  // Init Zurs zone in address Slice
  const zurs = getZursZoneFromOffer(mockRootState);
  if (zurs) {
    dispatch(setRiskZone(zurs));
  }

  // Step 5: Insurance start date
  const startDate = getInsuranceDateFromOffer(mockRootState);
  if (startDate) {
    const tomorrowRaw = getTomorrow();
    const tomorrow = yearFirstFormat(
      dateToDateInputValue(tomorrowRaw),
      'YYYY-MM-DD'
    );

    const nextMonth = yearFirstFormat(
      dateToDateInputValue(getNextMonth(tomorrowRaw)),
      'YYYY-MM-DD'
    );

    let typeOfDate: TypesOfDate = 'anotherDate';
    if (startDate === tomorrow) {
      typeOfDate = 'tomorrow';
    } else if (startDate === nextMonth) {
      typeOfDate = 'nextMonth';
    }

    dispatch(setTypeOfDate(typeOfDate));
    dispatch(setDateOfStart(dateToDateInputValue(new Date(startDate))));
    dispatch(setInsuranceDateValid(true));
    dispatch(setStepValidated(5));
  }

  // Step 6: Birthdate
  const birthDate = getBirthDateFromPerson(mockRootState);
  if (birthDate) {
    const formattedDate = dateToDateInputValue(new Date(birthDate));
    dispatch(setDateOfBirth(formattedDate));
    dispatch(setDateOfBirthValid(true));
  }

  // Step 7: Insurance coverage
  const {
    coverageProductVariant,
    productModules,
    bicycleAndEbikeCoInsuredCover,
    bicycleAndEbikeCoInsuredAmount,
    paymentMethod,
    deductibleAmount,
    contractPeriod,
  } = getInsuranceCoverageFromOffer(mockRootState);
  if (coverageProductVariant) {
    dispatch(setCoverageProductVariant(coverageProductVariant));
  }
  if (productModules) {
    dispatch(initProductModule(productModules));
  }
  if (bicycleAndEbikeCoInsuredCover) {
    dispatch(setBicycleAndEbikeCoInsuredCover(bicycleAndEbikeCoInsuredCover));

    if (bicycleAndEbikeCoInsuredAmount) {
      dispatch(
        setBicycleAndEbikeCoInsuredAmount(bicycleAndEbikeCoInsuredAmount)
      );
    }
  }
  if (paymentMethod) {
    dispatch(setPaymentMethod(paymentMethod));
  }
  if (deductibleAmount) {
    dispatch(setDeductibleAmount(deductibleAmount));
  }
  if (contractPeriod) {
    dispatch(setContractPeriod(contractPeriod));
  }

  // Step 9: Personal data
  let hasReachedStep9 = false;

  const { salutation, firstName, familyName, email, phoneNumber } =
    getPersonalDataFromPerson(mockRootState);

  if (salutation) {
    dispatch(setGender(salutation));
    hasReachedStep9 = true;
  }

  if (firstName) {
    dispatch(setFirstName(firstName));
    hasReachedStep9 = true;
  }

  if (familyName) {
    dispatch(setSurname(familyName));
    hasReachedStep9 = true;
  }

  if (email) {
    dispatch(setEmail(email));
    hasReachedStep9 = true;
  }

  if (phoneNumber) {
    dispatch(setTelephoneNumber(formatTelephoneNumber(phoneNumber)));
    dispatch(setPrefixNumber(extractCountryCode(phoneNumber)));

    hasReachedStep9 = true;
  }

  // Step 8: Additional questions
  const isAdditionalQuestionTouched = retrieveAdditionalQeustionsTouched();

  if (isAdditionalQuestionTouched) {
    const {
      hasElectronicOrMechatronickLocks,
      previousGlassDamage,
      previousGlassInsurance,
      previousHouseholdDamage,
      previousHouseholdInsurance,
    } = getAdditionalQuestionsFromOffer(mockRootState);

    dispatch(
      setElectronicOrMechatronicLocks(hasElectronicOrMechatronickLocks.selected)
    );
    if (hasElectronicOrMechatronickLocks.selected) {
    } else if (hasReachedStep9) {
      dispatch(setElectronicOrMechatronicLocks(false));
    }

    dispatch(setPreviousGlassInsurance(previousGlassInsurance.selected));
    if (previousGlassInsurance.selected) {
      dispatch(
        setPreviousGlassInsuranceCompany(previousGlassInsurance.companyName)
      );
      dispatch(setGlassInsuranceCompanyVuNR(previousGlassInsurance.vuNr));
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        additionalQuestionsFetchCompanyNames({
          target: 'previousGlassInsuranceCompanies',
          value: previousGlassInsurance.companyName,
        })
      );
      dispatch(
        setPreviousGlassInsuranceTerminator(
          previousGlassInsurance.policyTerminatedBy
        )
      );
      dispatch(
        setPreviousGlassInsuranceEndDate(previousGlassInsurance.endDate)
      );
      dispatch(
        setPreviousGlassInsurancePolicyNumber(
          previousGlassInsurance.policyNumber
        )
      );
    } else if (hasReachedStep9) {
      dispatch(setPreviousGlassInsurance(false));
    }

    dispatch(setPreviousGlassDamage(previousGlassDamage.selected));
    if (previousGlassDamage.selected) {
      dispatch(setPreviousGlassDamageNumber(previousGlassDamage.number));
      dispatch(setPreviousGlassDamageCost(previousGlassDamage.cost));
      dispatch(setPreviousGlassDamageYear(previousGlassDamage.year));
      dispatch(setPreviousGlassDamageType(previousGlassDamage.type));
    } else if (hasReachedStep9) {
      dispatch(setPreviousGlassDamage(false));
    }

    dispatch(
      setPreviousHouseholdInsurance(previousHouseholdInsurance.selected)
    );
    if (previousHouseholdInsurance.selected) {
      dispatch(
        setPreviousHouseholdInsuranceCompany(
          previousHouseholdInsurance.companyName
        )
      );
      console.warn(previousHouseholdInsurance);
      dispatch(setInsuranceCompanyVuNR(previousHouseholdInsurance.vuNr));
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        additionalQuestionsFetchCompanyNames({
          target: 'previousInsuranceCompanies',
          value: previousHouseholdInsurance.companyName,
        })
      );
      dispatch(
        setPreviousHouseholdInsuranceTerminator(
          previousHouseholdInsurance.policyTerminatedBy
        )
      );
      dispatch(
        setPreviousHouseholdInsuranceEndDate(previousHouseholdInsurance.endDate)
      );
      dispatch(
        setPreviousHouseholdInsurancePolicyNumber(
          previousHouseholdInsurance.policyNumber
        )
      );
    } else if (hasReachedStep9) {
      dispatch(setPreviousHouseholdInsurance(false));
    }

    dispatch(setPreviousHouseholdDamage(previousHouseholdDamage.selected));
    if (previousHouseholdDamage.selected) {
      dispatch(
        setPreviousHouseholdDamageNumber(previousHouseholdDamage.number)
      );
      dispatch(setPreviousHouseholdDamageCost(previousHouseholdDamage.cost));
      dispatch(setPreviousHouseholdDamageYear(previousHouseholdDamage.year));
      dispatch(setPreviousHouseholdDamageType(previousHouseholdDamage.type));
    } else if (hasReachedStep9) {
      dispatch(setPreviousHouseholdDamage(false));
    }
  }

  // Step 11: Bank details (need to fetch payment by ID from SPCS)
  const {
    iban,
    id: paymentId,
    ...bankDetails
  } = getBankDetailsFromPerson(mockRootState) ?? {};

  if (iban) {
    dispatch(setPaymentId(paymentId));
    dispatch(setIban(iban));
    dispatch(setIbanIsValidated(true));
  }
  if (Object.entries({ paymentId, ...bankDetails }).length) {
    dispatch(setBankDetails({ paymentId, ...bankDetails }));
  }
};
