import React from 'react';
import Radio from '@eg/elements/Radio';
import { ERadioProps } from './ERadio.d';

/** ERGO elements' radio button wrapped with tracking */
const ERadio = ({
  className,
  checked = false,
  'data-testid': dataTestId,
  disabled = false,
  label,
  onChange,
  required = false,
  tabIndex,
  trackingOptions,
  value,
  name,
  id,
  error,
}: ERadioProps): JSX.Element => {
  const trackedOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // TODO, here we'll have the tracking dispatch logic, when it's shaped
    console.log('!!!!-----ಠಠ 🕵️ trackingOptions ------>', trackingOptions); // eslint-disable-line
    return onChange(e);
  };

  return (
    <Radio
      checked={checked}
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      label={label}
      required={required}
      onChange={trackingOptions ? trackedOnChange : onChange}
      tabIndex={tabIndex}
      value={value}
      name={name}
      id={id}
      error={error}
    />
  );
};

export default ERadio;
