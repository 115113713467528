export const isEmptyString = (string: string): boolean =>
  typeof string === 'string' && string.length === 0;

export const capitalizeFirstLetter = (string: string): string => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};
