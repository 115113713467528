import React, { useMemo } from 'react';

import Radio from '../ERadio/ERadio';
import './RadioButton.scss';
import type { RadioButtonProps, RadioButtonType } from './RadioButtonTypes';

const RadioButton = <T extends RadioButtonType>({
  text,
  value,
  icon,
  onClick,
  checked = false,
  inline,
  subtitle,
  dataTestId = 'radio-button',
}: RadioButtonProps<T>): JSX.Element => {
  const preventOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    onClick?.(value);
  };

  const radio = useMemo(() => {
    return <Radio value={value} checked={checked} label="" tabIndex={-1} />;
  }, [checked, value]);

  const inlineButton = useMemo(() => {
    return (
      <button
        className={`radio-button radio-button--inline ${
          checked ? 'checked' : ''
        }`}
        onClick={preventOnClick}
        data-testid={dataTestId}
      >
        <div
          className={
            subtitle
              ? 'radio-button--inline__wrapper_subtitle'
              : 'radio-button--inline__wrapper'
          }
        >
          {radio}
          <div>
            {text && (
              <div
                className={
                  subtitle
                    ? 'radio-button--inline__text_subtitle'
                    : 'radio-button--inline__text'
                }
              >
                {text}
              </div>
            )}
            {subtitle && (
              <span
                data-testid="radio-button-subtitle"
                className="radio-button__subtitle"
              >
                {subtitle}
              </span>
            )}
          </div>
        </div>
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, onClick, radio, subtitle, text, value]);

  const columnButton = useMemo(() => {
    return (
      <button
        className={`radio-button radio-button--column ${
          checked ? 'checked' : ''
        }`}
        onClick={preventOnClick}
        data-testid={dataTestId}
      >
        {icon && <div className="radio-button--column__icon">{icon}</div>}
        <div className="radio-button--column__text">{text}</div>
        <div id="override-padding">{radio}</div>
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, icon, onClick, radio, text, value]);

  return inline ? inlineButton : columnButton;
};

export default RadioButton;
