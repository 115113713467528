import React, { useEffect, useState } from 'react';
import DateInputRow from '@eg/elements/DateInputRow';
import RadioGroup from '../../../../../components/ERadioGroup/ERadioGroup';
import Radio from '../../../../../components/ERadio/ERadio';
import { useForm } from '../../../../../store/useForm';

import {
  additionalQuestionsFetchCompanyNames,
  insuranceCompaniesSuggestionsSelector,
  setGlassInsuranceCompanyVuNR,
  setPreviousGlassInsuranceCompany,
  setPreviousGlassInsuranceEndDate,
  setPreviousGlassInsurancePolicyNumber,
  setPreviousGlassInsuranceTerminator,
} from '../../../additionalQuestionsSlice';
import { TextInput } from '../../../../../components/TextInput/TextInput';

import '../PreviousGlassDamageQuestion.scss';
import {
  InsuranceEndDate,
  InsurancePolicyTerminated,
} from 'commons/apis/hausrat/types';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import {
  PreviousGlassInsuranceErrorKey,
  previousGlassInsuranceErrors,
  previousGlassInsuranceValidations,
  isInvalidGlassInsuranceDate,
  isInvalidTerminatedPerson,
} from '../../../utils/validations/previousGlassInsurance';
import {
  PreviousGlassInsuranceQuestionFieldsProps,
  PreviousInsuranceKey,
} from '../../../AdditionalQuestionsTypes';
import { getErrorMessage } from '../../../utils/validations/validators';
import translations from '../../../../../constants/translations';
import Autocomplete from '../../../../../components/Autocomplete';
import { useAppSelector } from '../../../../../store/hooks';
import { useInputMode } from '../../../../../hooks/useInputMode';
import { useTouched } from '../../../hooks/useTouched';

type PreviousGlassInsuranceFormInputs =
  | 'previousGlassInsuranceCompanyName'
  | 'previousInsurancePolicyNumber'
  | 'previousInsuranceContractExpiration';
interface Props extends PreviousGlassInsuranceQuestionFieldsProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (error: string) => void;
}

export const PreviousGlassInsuranceQuestionFields = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const [selectedCompanyName, setSelectedCompanyName] = useState('');

  const {
    dispatch: dispatchState,
    isFocusedElement,
    isTouchedElement,
  } = useTouched<PreviousGlassInsuranceFormInputs>([
    'previousGlassInsuranceCompanyName',
    'previousInsurancePolicyNumber',
    'previousInsuranceContractExpiration',
  ]);

  const { previousGlassInsurance, previousInsurance, dispatch } = useForm();

  const { setInputMode } = useInputMode('.ee_date-input .ee_input');
  useEffect(() => {
    setInputMode('numeric');
  }, [setInputMode]);

  const suggestions = useAppSelector(insuranceCompaniesSuggestionsSelector);
  const validations = useFormValidations(
    previousGlassInsurance,
    previousGlassInsuranceValidations(
      suggestions.previousGlassInsuranceCompanies
    )
  );
  const getError = (
    field: PreviousInsuranceKey | PreviousGlassInsuranceErrorKey
  ): string => {
    if (typeof validations[field] !== 'undefined') {
      return getErrorMessage(validations[field]);
    }

    if (typeof validations[field] === 'undefined') {
      return previousGlassInsuranceErrors[field];
    }
  };

  const renderWithTrackingError = (error: string): string => {
    updateTrackingErrors(error);
    return error;
  };

  const companyNameError = (): string[] | undefined => {
    if (isFocusedElement('previousGlassInsuranceCompanyName')) {
      return;
    }

    const error =
      !previousGlassInsurance?.companyName ||
      previousGlassInsurance?.companyName === ''
        ? getError('companyNameNoEntry')
        : getError('companyName');
    if (!error) {
      return;
    }

    if (
      isGoNextClicked ||
      isTouchedElement('previousGlassInsuranceCompanyName')
    ) {
      return [renderWithTrackingError(error)];
    }
  };

  const policyNumberError = (): string[] | undefined => {
    if (isFocusedElement('previousInsurancePolicyNumber')) {
      return;
    }

    const error = getError('policyNumber');
    if (!error) {
      return;
    }

    if (isGoNextClicked || isTouchedElement('previousInsurancePolicyNumber')) {
      return [renderWithTrackingError(error)];
    }
  };

  const policyTerminatedByError = (): string | undefined => {
    const error = getError('policyTerminatedBy');
    if (error && isGoNextClicked) {
      return renderWithTrackingError(error);
    } else if (
      isInvalidTerminatedPerson(previousInsurance, previousGlassInsurance) &&
      isGoNextClicked
    ) {
      return renderWithTrackingError(translations.step8.terminationMismatch);
    }
  };

  const policyEndDateError = (): string | undefined => {
    let error = getError('endDate');

    const dateInputsAreEmpty =
      !previousGlassInsurance?.endDate ||
      (previousGlassInsurance?.endDate &&
        Object.keys(previousGlassInsurance.endDate)?.length === 0);

    if (dateInputsAreEmpty) {
      error = translations.step8.pleaseFill;
    } else if (
      isInvalidGlassInsuranceDate(previousInsurance, previousGlassInsurance)
    ) {
      error = translations.step8.dateMismatch;
    }

    const isErrorOnFocus =
      isFocusedElement('previousInsuranceContractExpiration') &&
      previousGlassInsurance?.endDate &&
      Object.keys(previousGlassInsurance?.endDate)?.length === 3;
    if (
      isGoNextClicked ||
      isTouchedElement('previousInsuranceContractExpiration') ||
      isErrorOnFocus
    ) {
      return renderWithTrackingError(error);
    }
  };

  const handleGlassInsuranceCompany = (value: string): void => {
    setSelectedCompanyName(value);
    if (value.length === 2) {
      dispatch(
        additionalQuestionsFetchCompanyNames({
          target: 'previousGlassInsuranceCompanies',
          value,
        })
      );
    }
    dispatch(setPreviousGlassInsuranceCompany(value));
    const nameMatchedCompany = suggestions.previousGlassInsuranceCompanies.find(
      company => company.name === value
    );
    if (nameMatchedCompany) {
      dispatch(setGlassInsuranceCompanyVuNR(nameMatchedCompany.vuNr));
    }
  };

  const handleGlassInsurancePolicyNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(setPreviousGlassInsurancePolicyNumber(e.target.value));
  };

  const handleGlassInsuranceTerminator = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const terminator = e.target.value as InsurancePolicyTerminated;
    dispatch(setPreviousGlassInsuranceTerminator(terminator));

    if (onClick) {
      const isPolicyHolder = terminator === 'policyHolder';
      const elementKey = isPolicyHolder
        ? 'RBUTTON_GEK_GLAS_VN'
        : 'RBUTTON_GEK_GLAS_VERS';
      onClick(elementKey);
    }
  };

  const handleGlassInsuranceEndDate = (value: InsuranceEndDate): void => {
    dispatch(setPreviousGlassInsuranceEndDate(value));
  };

  const filterSuggestions = (): string[] => {
    return suggestions.previousGlassInsuranceCompanies
      .map(el => el.name)
      .filter(name =>
        name.toLowerCase().includes(selectedCompanyName.toLowerCase())
      );
  };

  const handleCompanyNameEvents = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    switch (e.type) {
      case 'blur':
        dispatchState({
          type: 'blur',
          payload: 'previousGlassInsuranceCompanyName',
        });
        break;
      case 'focus':
        dispatchState({
          type: 'focus',
          payload: 'previousGlassInsuranceCompanyName',
        });
        break;
      default:
        break;
    }
  };

  const handlePolicyNumberEvents = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    switch (e.type) {
      case 'blur':
        dispatchState({
          type: 'blur',
          payload: 'previousInsurancePolicyNumber',
        });
        break;
      case 'focus':
        dispatchState({
          type: 'focus',
          payload: 'previousInsurancePolicyNumber',
        });
        break;
      default:
        break;
    }
  };

  const handlePolicyContractExpirationEvent = (
    event?: React.FocusEvent<HTMLInputElement> | undefined
  ): void => {
    const dispatch = (eventType: 'blur' | 'focus'): void =>
      dispatchState({
        type: eventType,
        payload: 'previousInsuranceContractExpiration',
      });

    event ? dispatch('focus') : dispatch('blur');
  };

  return (
    <div className="additional-question__content additional-question__card">
      <div className="additional-question-field additional-question-field--first">
        <Autocomplete
          label={translations.step8.companyName}
          value={previousGlassInsurance.companyName}
          errors={companyNameError()}
          data-testid="previous-insurance-company-name"
          options={filterSuggestions()}
          onChange={handleGlassInsuranceCompany}
          onBlur={handleCompanyNameEvents}
          onFocus={handleCompanyNameEvents}
          name="previousGlassInsuranceCompanyName"
        />
      </div>

      <div className="additional-question-field">
        <TextInput
          className="policy-number"
          label={translations.step8.policyNumber}
          id="previousGlassInsurancePolicyNumber"
          display="inline"
          name="previousInsurancePolicyNumber"
          value={previousGlassInsurance.policyNumber}
          onChange={handleGlassInsurancePolicyNumber}
          onBlur={handlePolicyNumberEvents}
          onFocus={handlePolicyNumberEvents}
          errors={policyNumberError()}
        />
      </div>

      <div className="additional-question-field">
        <RadioGroup
          label={translations.step8.policyTerminatedBy}
          name="previousInsurancePolicyTerminatedBy"
          value={previousGlassInsurance.policyTerminatedBy}
          onChange={handleGlassInsuranceTerminator}
          error={policyTerminatedByError()}
        >
          <Radio value="policyHolder" label={translations.step8.policyHolder} />
          <Radio value="insurer" label={translations.step8.insurer} />
        </RadioGroup>
      </div>
      <div className="additional-question-field--last">
        <DateInputRow
          autoTab
          label={translations.step8.contractExpiration}
          value={previousGlassInsurance.endDate || {}}
          onChange={handleGlassInsuranceEndDate}
          onFocus={handlePolicyContractExpirationEvent}
          onBlur={(): void => handlePolicyContractExpirationEvent()}
          error={policyEndDateError()}
        />
      </div>
    </div>
  );
};
