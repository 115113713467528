import {
  previousGlassInsuranceCompanyValidator,
  validateDateField,
  validatePolicyNumberField,
  validatePolicyTerminatorField,
  validateSelectedField,
  validateCompanyVuNr,
  validateYearIsNotOlderThanFiveYearsAgo,
  validateDateYearField,
} from 'commons';
import {
  InsuranceEndDate,
  PreviousInsurance,
} from 'commons/apis/hausrat/types';
import { InsuranceCompany } from 'commons/apis/insuranceCompanies/types';
import translations from '../../../../constants/translations';
import {
  InvalidField,
  ValidatorFunction,
  ValidField,
} from '../../../../store/types';
import { PreviousInsuranceKey } from '../../AdditionalQuestionsTypes';
import { generateInvalidField, generateValidField } from './validators';

export const previousGlassInsuranceErrors = {
  selected: translations.step8.glassInsuranceSelectedError,
  companyName: translations.step8.companyNameError,
  companyNameNoEntry: translations.step8.companyNameNoEntryError,
  vuNr: '',
  policyNumber: translations.step8.policyNumberError,
  policyTerminatedBy: translations.step8.policyTerminatorError,
  endDate: translations.step8.endDateError,
  endDateNotWithinTheLast5Years:
    translations.step8.endDateNotWithinTheLast5YearsError,
  endDateInvalid: translations.step8.endDateInvalidError,
};

export type PreviousGlassInsuranceError = typeof previousGlassInsuranceErrors;
export type PreviousGlassInsuranceErrorKey = keyof PreviousGlassInsuranceError;

export const previousGlassInsuranceValidations = (
  suggestions: InsuranceCompany[]
): Record<
  PreviousInsuranceKey,
  ValidatorFunction<PreviousInsurance[PreviousInsuranceKey]>
> => ({
  selected: (value: boolean | undefined): ValidField | InvalidField => {
    return validateSelectedField(value)
      ? generateValidField()
      : generateInvalidField(previousGlassInsuranceErrors, 'selected');
  },
  companyName: (value: string): ValidField | InvalidField => {
    let result: ValidField | InvalidField = generateInvalidField(
      previousGlassInsuranceErrors,
      'companyName'
    );

    // Alternate logic
    if (previousGlassInsuranceCompanyValidator(value, suggestions)) {
      result = generateValidField();
    }

    if (!value.length) {
      result = generateInvalidField(
        previousGlassInsuranceErrors,
        'companyNameNoEntry'
      );
    }

    return result;
  },
  vuNr: (value: string): ValidField | InvalidField => {
    return validateCompanyVuNr(value)
      ? generateValidField()
      : generateInvalidField(previousGlassInsuranceErrors, 'vuNr');
  },
  policyNumber: (value: string): ValidField | InvalidField => {
    return validatePolicyNumberField(value)
      ? generateValidField()
      : generateInvalidField(previousGlassInsuranceErrors, 'policyNumber');
  },
  policyTerminatedBy: (value: string): ValidField | InvalidField => {
    return validatePolicyTerminatorField(value)
      ? generateValidField()
      : generateInvalidField(
          previousGlassInsuranceErrors,
          'policyTerminatedBy'
        );
  },
  endDate: (value: InsuranceEndDate): ValidField | InvalidField => {
    let validationResult: ValidField | InvalidField = generateValidField();

    if (!validateYearIsNotOlderThanFiveYearsAgo(value)) {
      validationResult = generateInvalidField(
        previousGlassInsuranceErrors,
        'endDateNotWithinTheLast5Years'
      );
    }

    if (!validateDateField(value)) {
      validationResult = generateInvalidField(
        previousGlassInsuranceErrors,
        'endDate'
      );
    }

    if (!validateDateYearField(value)) {
      validationResult = generateInvalidField(
        previousGlassInsuranceErrors,
        'endDateInvalid'
      );
    }

    return validationResult;
  },
});

export const isInvalidGlassInsuranceDate = (
  previousInsurance,
  previousGlassInsurance
): boolean => {
  return (
    previousInsurance &&
    previousGlassInsurance &&
    previousInsurance.companyName === previousGlassInsurance.companyName &&
    previousInsurance.policyNumber === previousGlassInsurance.policyNumber &&
    (previousGlassInsurance.endDate?.day !== previousInsurance.endDate?.day ||
      previousGlassInsurance.endDate?.month !==
        previousInsurance.endDate?.month ||
      previousGlassInsurance.endDate?.year !== previousInsurance.endDate?.year)
  );
};

export const isInvalidTerminatedPerson = (
  previousInsurance,
  previousGlassInsurance
): boolean => {
  return (
    previousInsurance &&
    previousGlassInsurance &&
    previousInsurance.companyName === previousGlassInsurance.companyName &&
    previousInsurance.policyNumber === previousGlassInsurance.policyNumber &&
    previousGlassInsurance.policyTerminatedBy !==
      previousInsurance.policyTerminatedBy
  );
};
