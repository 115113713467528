import { AxiosInstance } from 'axios';
import { HttpMethod } from '../../types';
import { createInstance } from './commons';

type HttpOptions = {
  method?: HttpMethod;
  payload?: Record<string, unknown> | Record<string, unknown>[];
};

let fetch: AxiosInstance | undefined;

export const insuranceCompaniesHttp = async <T = unknown>(
  url: string,
  options?: HttpOptions
): Promise<T> => {
  try {
    if (!fetch) {
      fetch = createInstance();
    }

    const { method = 'GET', payload } = options ?? {};
    const body = method !== 'GET' ? payload : undefined;

    const { data, ...response } = await fetch[method.toLowerCase()](
      url,
      Array.isArray(payload)
        ? payload
        : {
            ...body,
          }
    );

    if (!/^2[0-9]{2,2}/.test(response.status.toString())) {
      throw new Error(response.statusText);
    }

    return data as T;
  } catch (error) {
    throw error;
  }
};
