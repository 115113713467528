import axios, { AxiosInstance } from 'axios';

const noCacheHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1',
};

const createInstance = (): AxiosInstance => {
  return axios.create({
    headers: noCacheHeaders,
  });
};

let fetch: AxiosInstance | undefined;
export const aemIntegrationHttp = async <T = unknown>(
  url: string
): Promise<T> => {
  try {
    if (!fetch) {
      fetch = createInstance();
    }

    const { data, ...response } = await fetch.get(url);

    if (!/^2[0-9]{2,2}/.test(response.status.toString())) {
      throw new Error(response.statusText);
    }

    return data as T;
  } catch (error) {
    throw Error(error);
  }
};
