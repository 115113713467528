import {
  addDates,
  addMonths,
  nextMonthFirstDay,
} from '../../../../../helpers/dates/dates';
import { TypesOfDate } from '../../../InsuranceDateTypes';

const OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'long',
};

export const addDaysFromTodayFormatted = (daysToAdd: number): string => {
  const date = addDates({ datesToAdd: daysToAdd });

  return `${String(date.getDate()).padStart(2, '0')}.${String(
    date.getMonth() + 1
  ).padStart(2, '0')}.${date.getFullYear()}`;
};

export const addDaysGetDayOfTheWeek = (daysToAdd: number): string => {
  const date = addDates({ datesToAdd: daysToAdd });
  return date.toLocaleDateString('de-DE', OPTIONS);
};

export const firstDayOfTheNextMonthFormatted = (
  from = new Date(Date.now())
): string => {
  const date = nextMonthFirstDay(from);

  return `01.${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}.${date.getFullYear()}`;
};

export const firstDayOfTheNextMonthDayOfTheWeek = (
  from = new Date(Date.now())
): string => {
  const date = nextMonthFirstDay(from);
  return date.toLocaleDateString('de-DE', OPTIONS);
};

export const getTomorrow = (): Date =>
  addDates({ datesToAdd: 1, resetTime: true });

export const getNextMonth = (tomorrow: Date): Date => {
  const nextMonth = addMonths({ monthsToAdd: 1, resetTime: true });
  nextMonth.setDate(1);

  let returnValue = nextMonth;
  if (tomorrow.getTime() === nextMonth.getTime()) {
    returnValue = addMonths({ monthsToAdd: 2 });
    returnValue.setDate(1);
  }
  return returnValue;
};

export const insuranceDateOfStartByTypeOfDate = (
  typeOfDate: TypesOfDate
): Date | null => {
  const tomorrow = getTomorrow();

  switch (typeOfDate) {
    case 'tomorrow':
      return tomorrow;

    case 'nextMonth':
      return getNextMonth(tomorrow);

    default:
      return null;
  }
};
