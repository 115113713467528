import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../router';

type UseStepNavigationType = {
  goNext?: (value?: number) => void;
  goPrev?: () => void;
};

export const useStepNavigation = (): UseStepNavigationType => {
  const location = useLocation();
  const navigation = useNavigate();

  const currentStep = routes.findIndex(
    route => route.path === location.pathname
  );
  const totalSteps = routes.length - 1;

  const goNext = (forcePage?: number): void => {
    if (currentStep >= totalSteps) {
      return;
    }

    const nextRoute = forcePage ?? currentStep + 1;

    navigation(routes[nextRoute].path);
  };

  const goPrev = (): void => {
    if (currentStep === 0) {
      return;
    }

    navigation(routes[currentStep - 1].path);
  };

  return { goNext, goPrev };
};

export default useStepNavigation;
