import './InfoMsg.scss';
import React from 'react';
import { TypeInfoMsg } from './InfoMsgTypes';
import translations from '../../../../constants/translations';

const InfoMsg = ({ minValue, maxValue }: TypeInfoMsg): JSX.Element => {
  return (
    <div className="info">
      <span className="info__message" data-testid="info-msg">
        {translations.step3.infoMsg(minValue, maxValue)}
      </span>
    </div>
  );
};

export default InfoMsg;
