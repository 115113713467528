import { AemAssetsKeys } from 'commons';
import React, { useState } from 'react';
import '../../../api/aemIntegration/AemDownloadLink/AemDownloadLink.scss';
import AemFragment from '../AemFragment';
import AemModal from '../AemModal';

interface Props {
  extendedWidth?: boolean;
  fragmentName: AemAssetsKeys;
  shouldUseIframe?: boolean;
}

interface AemModalReturn {
  openModal: () => void;
  render: () => JSX.Element;
}

export const useAemModal = ({
  extendedWidth = true,
  fragmentName,
  shouldUseIframe = false,
}: Props): AemModalReturn => {
  const [showModal, setShowModal] = useState(false);

  const openModal = (): void => {
    setShowModal(true);
  };

  const closeModal = (): void => {
    setShowModal(false);
  };

  const render = (): JSX.Element => (
    <AemModal
      extendedWidth={extendedWidth}
      onDismiss={closeModal}
      open={showModal}
      shouldUseIframe={shouldUseIframe}
    >
      <AemFragment
        fragmentName={fragmentName}
        shouldUseIframe={shouldUseIframe}
      />
    </AemModal>
  );

  return {
    openModal,
    render,
  };
};
