import { trackClickEvent } from '../../../api/tracking';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { TrackingElementPage9 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onClickTracking: (id: string) => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onClickTracking = (id: string): void => {
    const element = TrackingElementPage9[id];
    trackClickEvent(element, trackingOptions);
  };

  return {
    trackingOptions,
    onClickTracking,
  };
};

export default useClickTracking;
