import React from 'react';

export const ErrorsForInput = ({
  errors,
  updateTrackingErrors,
}: {
  errors: string[];
  updateTrackingErrors?: (id: string) => void;
}): JSX.Element => {
  return (
    <div className="errors-for-input">
      {errors?.map(error => {
        updateTrackingErrors(error);
        return (
          <p key={error} className="error-text">
            {error}
          </p>
        );
      })}
    </div>
  );
};
