import React from 'react';
import type { FormSectionBodyProps } from './FormSectionBodyTypes';
import './FormSectionBody.scss';

const FormSectionBody = ({
  children,
  box = false,
  className,
}: FormSectionBodyProps): JSX.Element => {
  const sectionClasses = (): string => {
    const classes = ['form-section-body', 'esc_container--l'];
    if (className) {
      classes.push(className);
    }

    if (box) {
      classes.push('esc_box');
    }

    return classes.join(' ');
  };

  return <section className={sectionClasses()}>{children}</section>;
};

export default FormSectionBody;
