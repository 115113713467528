import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { PreviousDamage, PreviousInsurance } from 'commons/apis/hausrat/types';
import {
  PreviousInsuranceKey,
  AdditionalQuestionsState,
  PreviousDamageKey,
} from '../AdditionalQuestionsTypes';

type InsuranceTargetKeys = 'previousInsurance' | 'previousGlassInsurance';
type DamageTargetKeys = 'previousHouseholdDamage' | 'previousGlassDamage';

const getPreviousInsuranceReducer = <K extends PreviousInsuranceKey>(
  target: K,
  insurance: InsuranceTargetKeys
): CaseReducer<
  AdditionalQuestionsState,
  PayloadAction<PreviousInsurance[K]>
> => {
  const reducer = (
    state: AdditionalQuestionsState,
    action: PayloadAction<PreviousInsurance[K]>
  ): void => {
    state.form[insurance] = {
      ...state.form[insurance],
      [target]: action.payload,
    };
  };

  return reducer;
};

const getPreviousDamagesReducer = <
  K extends PreviousDamageKey,
  DT extends DamageTargetKeys
>(
  target: K,
  damage: DT
): CaseReducer<AdditionalQuestionsState, PayloadAction<PreviousDamage[K]>> => {
  const reducer = (
    state: AdditionalQuestionsState,
    action: PayloadAction<PreviousDamage[K]>
  ): void => {
    state.form[damage] = {
      ...state.form[damage],
      [target]: action.payload,
    };
  };

  return reducer;
};

const previousInsuranceReducers = {
  setPreviousHouseholdInsurance: getPreviousInsuranceReducer(
    'selected',
    'previousInsurance'
  ),
  setPreviousHouseholdInsuranceCompany: getPreviousInsuranceReducer(
    'companyName',
    'previousInsurance'
  ),
  setPreviousHouseholdInsuranceTerminator: getPreviousInsuranceReducer(
    'policyTerminatedBy',
    'previousInsurance'
  ),
  setPreviousHouseholdInsuranceEndDate: getPreviousInsuranceReducer(
    'endDate',
    'previousInsurance'
  ),
  setPreviousHouseholdInsurancePolicyNumber: getPreviousInsuranceReducer(
    'policyNumber',
    'previousInsurance'
  ),
};

const previousGlassInsuranceReducers = {
  setPreviousGlassInsurance: getPreviousInsuranceReducer(
    'selected',
    'previousGlassInsurance'
  ),
  setPreviousGlassInsuranceCompany: getPreviousInsuranceReducer(
    'companyName',
    'previousGlassInsurance'
  ),
  setPreviousGlassInsuranceTerminator: getPreviousInsuranceReducer(
    'policyTerminatedBy',
    'previousGlassInsurance'
  ),
  setPreviousGlassInsuranceEndDate: getPreviousInsuranceReducer(
    'endDate',
    'previousGlassInsurance'
  ),
  setPreviousGlassInsurancePolicyNumber: getPreviousInsuranceReducer(
    'policyNumber',
    'previousGlassInsurance'
  ),
};

const previousHouseholdDamageReducers = {
  setPreviousHouseholdDamage: getPreviousDamagesReducer(
    'selected',
    'previousHouseholdDamage'
  ),
  setPreviousHouseholdDamageNumber: getPreviousDamagesReducer(
    'number',
    'previousHouseholdDamage'
  ),
  setPreviousHouseholdDamageCost: getPreviousDamagesReducer(
    'cost',
    'previousHouseholdDamage'
  ),
  setPreviousHouseholdDamageYear: getPreviousDamagesReducer(
    'year',
    'previousHouseholdDamage'
  ),
  setPreviousHouseholdDamageType: getPreviousDamagesReducer(
    'type',
    'previousHouseholdDamage'
  ),
};

const previousGlassDamageReducers = {
  setPreviousGlassDamage: getPreviousDamagesReducer(
    'selected',
    'previousGlassDamage'
  ),
  setPreviousGlassDamageNumber: getPreviousDamagesReducer(
    'number',
    'previousGlassDamage'
  ),
  setPreviousGlassDamageCost: getPreviousDamagesReducer(
    'cost',
    'previousGlassDamage'
  ),
  setPreviousGlassDamageYear: getPreviousDamagesReducer(
    'year',
    'previousGlassDamage'
  ),
  setPreviousGlassDamageType: getPreviousDamagesReducer(
    'type',
    'previousGlassDamage'
  ),
};

export const reducers = {
  ...previousInsuranceReducers,
  ...previousGlassInsuranceReducers,
  ...previousHouseholdDamageReducers,
  ...previousGlassDamageReducers,
};
