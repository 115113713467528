import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DropDown from '../../../../components/DropDown/DropDown';
import type { DropdownOption } from '../../../../components/DropDown/DropDown.d';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { TextInput } from '../../../../components/TextInput/TextInput';
import translations from '../../../../constants/translations';
import { blockedCharactersOnInput } from '../../../../utils';
import { validateZipCode } from '../../addressSlice';
import './ZipCodeInput.scss';
import { ZipCodeInputProps } from './ZipCodeInputTypes';

export const ZipCodeInput = ({
  city,
  cityOptions,
  zipCode,
  zipCodeError,
  onChangeZipCode,
  onChangeCity,
  isLoading,
}: ZipCodeInputProps): JSX.Element => {
  const dispatch = useDispatch();
  const [inputInFocus, setInputInFocus] = useState(false);
  const [cityVal, setCityVal] = useState('');
  const [singleCity, setSingleCity] = useState(true);

  const handleChangeZipCode = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const zipcodeLength = e.target.value.length;
    const zipcodeValue = e.target.value;

    if (zipcodeLength > 5) {
      e.preventDefault();
      return;
    }

    onChangeZipCode(zipcodeValue);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    e.clipboardData.getData('text/plain');

    const pastedData =
      e.clipboardData.getData('Text').slice(0, 5).match(/[0-9]/g).join('') ||
      '';

    onChangeZipCode(pastedData);
  };

  const cityOptionsMap: DropdownOption[] = cityOptions?.map(city => {
    return {
      label: city,
      value: city,
    };
  });

  const handleInputFocus = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputInFocus(event.nativeEvent.type === 'focusin');
    if (event.nativeEvent.type === 'focusout') {
      dispatch(validateZipCode(event.target.value));
    }
  };

  const showInputErrors = (): string[] | undefined => {
    if (inputInFocus) {
      return;
    }

    if (!inputInFocus && zipCodeError) {
      return [zipCodeError];
    }
  };

  useEffect(() => {
    if (zipCode?.length < 5) {
      setCityVal('');
      setSingleCity(true);
    } else {
      setCityVal(city);
      setSingleCity(cityOptions.length < 2);
    }
  }, [zipCode, city, cityOptions.length]);

  return (
    <div className="zip-code">
      <div className="zip-code__zip-number">
        <TextInput
          display="col"
          errors={showInputErrors()}
          id="zip-code-input"
          label={translations.step4.zipCodeLabel}
          onChange={handleChangeZipCode}
          onKeyDown={blockedCharactersOnInput}
          onPaste={handlePaste}
          placeholder={translations.step4.zipCodePlaceholder}
          value={zipCode}
          onBlur={handleInputFocus}
          onFocus={handleInputFocus}
          type="number"
        />
      </div>

      <div className="zip-code__zip-city">
        {isLoading && <LoadingIndicator />}

        {!isLoading && (
          <DropDown
            id="city-options"
            options={cityOptionsMap}
            value={cityVal}
            label={translations.step4.cityLabel}
            onChange={onChangeCity}
            readonly={singleCity}
            placeholder={translations.step4.cityPlaceholder}
            dataTestId="city-dropdown"
          />
        )}
      </div>
    </div>
  );
};
