import React from 'react';
import { useForm } from '../../../../../store/useForm';
import {
  setPreviousGlassDamageCost,
  setPreviousGlassDamageType,
  setPreviousGlassDamageYear,
} from '../../../additionalQuestionsSlice';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { GlassDamage } from 'commons/apis/hausrat/types';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import {
  previousGlassDamageErrors,
  previousGlassDamageValidations,
} from '../../../utils/validations/previousGlassDamage';
import {
  OneDamageGlassFieldsProps,
  PreviousDamageKey,
} from '../../../AdditionalQuestionsTypes';
import {
  getErrorMessage,
  getSanitizedNumber,
} from '../../../utils/validations/validators';
import translations from '../../../../../constants/translations';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import DropDown from '../../../../../components/DropDown/DropDown';
import { DropdownOption } from '../../../../../components/DropDown/DropDown.d';

export const OneDamageGlassFields = ({
  isGoNextClicked,
  updateTrackingErrors,
}: OneDamageGlassFieldsProps): JSX.Element => {
  const { previousGlassDamage, dispatch } = useForm();
  const validations = useFormValidations(
    previousGlassDamage,
    previousGlassDamageValidations
  );

  const displayError = (field: PreviousDamageKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = previousGlassDamageErrors[field];
    }
    updateTrackingErrors(error);
    return error;
  };

  const damageCostError = displayError('cost')
    ? [displayError('cost')]
    : undefined;

  const damageTypeError = displayError('type')
    ? [displayError('type')]
    : undefined;
  const damageYearError = displayError('year')
    ? [displayError('year')]
    : undefined;
  const handleGlassDamageCost = ({ target: { value } }): void => {
    const sanitizedValue = getSanitizedNumber(value);
    if (sanitizedValue !== false) {
      dispatch(setPreviousGlassDamageCost(sanitizedValue));
    }
  };

  const handleGlassDamageType = (value: string): void => {
    dispatch(setPreviousGlassDamageType(value as GlassDamage));
  };

  const handleGlassDamageYear = (value: string): void => {
    const sanitizedValue = getSanitizedNumber(value);
    if (sanitizedValue !== false) {
      dispatch(setPreviousGlassDamageYear(Number(value)));
    }
  };

  const damageCostErrorElements = (): JSX.Element[] => {
    if (previousGlassDamage.cost !== undefined && damageCostError) {
      return [
        <>
          <ErrorsForInput
            errors={[translations.step8.errorGlassExeed]}
            updateTrackingErrors={updateTrackingErrors}
          />
        </>,
      ];
    }
    if (previousGlassDamage.cost == undefined && isGoNextClicked) {
      return [
        <>
          <ErrorsForInput
            errors={[translations.step8.pleaseStateTheAmountOfTheDamage]}
            updateTrackingErrors={updateTrackingErrors}
          />
        </>,
      ];
    }
    return undefined;
  };

  const damageTypes: {
    label: string;
    value: GlassDamage;
  }[] = [
    { label: translations.step8.glassBreakage, value: 'glassBreakage' },
    { label: translations.step8.other, value: 'other' },
  ];

  const getDamageYearOptions = (): DropdownOption[] => {
    const date = new Date(Date.now());
    const currentYear = date.getFullYear();
    const result: DropdownOption[] = [];
    for (let i = 0; i < 6; i++) {
      result.push({
        label: String(currentYear - i),
        value: String(currentYear - i),
      });
    }
    return result;
  };

  const damageTypeOptions = [...damageTypes];

  const damageYearOptions = getDamageYearOptions();

  return (
    <>
      <p>{translations.step8.informationAboutDamageQuestion}</p>
      <div className="additional-question-field">
        <DropDown
          id="glassDamageType"
          label={translations.step8.damageType}
          placeholder={translations.step8.pleaseSelect}
          value={previousGlassDamage.type}
          className="additional-question__select"
          onChange={handleGlassDamageType}
          errors={damageTypeError}
          display="inline"
          options={damageTypeOptions}
        ></DropDown>
      </div>

      <div className="additional-question-field">
        <TextInput
          className="additional-question__field"
          id="previousGlassInsuranceDamageAmount"
          label={translations.step8.damageAmount}
          display="inline"
          type="number"
          value={previousGlassDamage.cost}
          placeholder="z.B. 500 €"
          onChange={handleGlassDamageCost}
          errors={damageCostErrorElements()}
        />
      </div>

      <div className="additional-question-field">
        <DropDown
          id="glassDamageYear"
          label={translations.step8.damageYear}
          value={previousGlassDamage.year?.toString()}
          className="additional-question__select"
          onChange={handleGlassDamageYear}
          errors={damageYearError}
          display="inline"
          options={damageYearOptions}
        ></DropDown>
      </div>
    </>
  );
};
