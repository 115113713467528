import React from 'react';
import FormFooter from '@eg/elements/FormFooter';
import { useRunAfterUpdate } from '@eg/elements/hooks/useRunAfterUpdate';
import { getFilteredTextAndCursorPositon } from '@eg/elements/utils/validation/inputValidationHelper';
import { validateIban, beautifyIbanValue } from 'commons';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import { useForm } from '../../store/useForm';
import {
  setIban,
  bankDetailsFetchValidation,
  fetchBankDataFromIban,
  getBankDataSelector,
  isLoadingBankDataSelector,
  isValidatedSelector,
  getIbanError,
  isLoadingSelector,
  setIbanError,
} from './bankDetailsSlice';
import AccountDetailsNumber from './components/AccountDetails/AccountDetails';
import AccountOwnerName from './components/AccountOwner/AccountOwnerName';
import BankDetailsTooltipInfo from './components/BankDetailsTooltipInfo/BankDetailsTooltipInfo';
import { FooterNotes } from './components/FooterNotes/FooterNotes';
import translations from '../../constants/translations';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useAppSelector } from '../../store/hooks';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import { isBankDetailsUpdated } from '../../store/offerSlice';
import useClickTracking from './hooks/useClickTracking';
import './BankDetails.scss';

export const BankDetails = (): JSX.Element => {
  const { dispatch, iban, firstName, lastname } = useForm();
  const isFetchError = useAppSelector(getGenericErrorSelector);
  const { goNext, goPrev } = useStepNavigation();
  const bankData = useAppSelector(getBankDataSelector);
  const ibanError = useAppSelector(getIbanError);
  const isValidStep = useAppSelector(isValidatedSelector);
  const isFormModifed = useAppSelector(isBankDetailsUpdated);
  const isLoadingBankData = useAppSelector(isLoadingBankDataSelector);
  const isLoading = useAppSelector(isLoadingSelector);
  const {
    trackingOptions,
    onOpenInfoIcon,
    onVerificationStart,
    onVerificationSuccess,
  } = useClickTracking();

  const runAfterUpdate = useRunAfterUpdate();

  const ibanValidation = async (value: string): Promise<void> => {
    onVerificationStart();

    if (validateIban(value)) {
      dispatch(fetchBankDataFromIban());
      onVerificationSuccess();
    } else {
      dispatch(setIbanError(translations.step11.ibanErrorInvalid));
    }
  };

  const handleOnChangeIban = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const input = event.target;
    const ibanFromInput = event.target.value;

    const cursor = input.selectionStart;

    // Necessary to check where the cursor was before updating
    const [newIban, newCursor] = getFilteredTextAndCursorPositon(
      ibanFromInput,
      cursor,
      beautifyIbanValue
    ) as [string, number];

    const newIbanTrim = newIban.replace(/\s/g, '');

    dispatch(setIban(newIban));

    const ibanIsValidable = (): boolean => {
      const isGermanIban = /^DE/.test(newIbanTrim);
      const hasTheCorrectLength = newIbanTrim.length >= 22;
      const isNotTheSameIban = newIbanTrim !== iban.replace(/\s/g, '');

      return isGermanIban && hasTheCorrectLength && isNotTheSameIban;
    };

    if (ibanIsValidable()) {
      ibanValidation(newIbanTrim);
    }

    if (/^DE/.test(newIbanTrim) && newIbanTrim.length < 22) {
      dispatch(setIbanError(''));
    }

    // Necessary to return the cursor to where it was before the update.
    runAfterUpdate(() => {
      input.selectionStart = newCursor;
      input.selectionEnd = newCursor;
    });
  };

  const handleOnBlurIban = async (value: string): Promise<void> => {
    if (isValidStep) {
      return;
    }

    const ibanTrim = value.replace(/ /g, '');
    ibanValidation(ibanTrim);
  };

  const handleOnFocus = (): void => {
    dispatch(setIbanError(''));
  };

  const handleContinue = (): void => {
    if (isFormModifed) {
      dispatch(bankDetailsFetchValidation())
        .unwrap()
        .then(() => {
          goNext();
        });
    } else {
      goNext();
    }
  };

  const handleBackButton = (): void => {
    goPrev();
  };

  const beautifiedIban = beautifyIbanValue(iban.toUpperCase());

  const onOpenIBANInfoIconHandler = (): void => onOpenInfoIcon('INFOICON_IBAN');
  const onOpenSEPAInfoIconHandler = (): void => onOpenInfoIcon('INFOICON_SEPA');

  return (
    <FormSection>
      <FormSectionHeader
        title={translations.step11.title}
        subtitle={translations.step11.subtitle}
        showTooltip
        toolTipComponent={<BankDetailsTooltipInfo />}
        onOpened={onOpenIBANInfoIconHandler}
      />

      <FormSectionBody>
        <div className="bank-account-details__container">
          <AccountOwnerName firstName={firstName} lastName={lastname} />

          <AccountDetailsNumber
            bankData={bankData}
            ibanError={iban && ibanError && !isFetchError ? ibanError : null}
            isLoading={isLoadingBankData}
            isValid={isValidStep}
            onChangeIban={handleOnChangeIban}
            onBlurIban={handleOnBlurIban}
            value={beautifiedIban}
            onHandleFocus={handleOnFocus}
          />
        </div>

        <FooterNotes onOpened={onOpenSEPAInfoIconHandler} />
      </FormSectionBody>

      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBackButton}
          isLoading={isLoading}
          disableContinue={isLoadingBankData || !isValidStep}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isFetchError} />
    </FormSection>
  );
};

export default BankDetails;
