import React, { useEffect, useState } from 'react';
import DateInputRow from '@eg/elements/DateInputRow';
import { DateInputValue } from '@eg/elements/DateInput';
import RadioGroup from '../../../../../components/ERadioGroup/ERadioGroup';
import Radio from '../../../../../components/ERadio/ERadio';
import { useForm } from '../../../../../store/useForm';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import {
  setPreviousHouseholdInsuranceCompany,
  setPreviousHouseholdInsurancePolicyNumber,
  setPreviousHouseholdInsuranceTerminator,
  setPreviousHouseholdInsuranceEndDate,
  insuranceCompaniesSuggestionsSelector,
  additionalQuestionsFetchCompanyNames,
  setInsuranceCompanyVuNR,
} from '../../../additionalQuestionsSlice';

import './PreviousInsuranceQuestionFields.scss';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import {
  PreviousInsuranceErrorKey,
  previousInsuranceErrors,
  previousInsuranceValidations,
} from '../../../utils/validations/previousInsurance';
import { getErrorMessage } from '../../../utils/validations/validators';
import {
  PreviousInsuranceKey,
  PreviousInsuranceQuestionFieldsProps,
} from '../../../AdditionalQuestionsTypes';
import translations from '../../../../../constants/translations';
import Autocomplete from '../../../../../components/Autocomplete';
import { useAppSelector } from '../../../../../store/hooks';
import { InsurancePolicyTerminated } from 'commons/apis/hausrat/types';
import { useInputMode } from '../../../../../hooks/useInputMode';
import { useTouched } from '../../../hooks/useTouched';

type PreviousInsuranceFormInputs =
  | 'previousHouseholdInsuranceCompanyName'
  | 'previousHouseholdInsurancePolicyNumber'
  | 'previousHouseholdInsuranceContractExpiration';
interface Props extends PreviousInsuranceQuestionFieldsProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (id: string) => void;
}

export const PreviousInsuranceQuestionFields = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const { previousInsurance, dispatch } = useForm();
  const { setInputMode } = useInputMode('.ee_date-input .ee_input');
  useEffect(() => {
    setInputMode('numeric');
  }, [setInputMode]);

  const suggestions = useAppSelector(insuranceCompaniesSuggestionsSelector);
  const validations = useFormValidations(
    previousInsurance,
    previousInsuranceValidations(suggestions.previousInsuranceCompanies)
  );
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const {
    dispatch: dispatchState,
    isFocusedElement,
    isTouchedElement,
  } = useTouched<PreviousInsuranceFormInputs>([
    'previousHouseholdInsuranceCompanyName',
    'previousHouseholdInsurancePolicyNumber',
    'previousHouseholdInsuranceContractExpiration',
  ]);

  const getError = (
    field: PreviousInsuranceKey | PreviousInsuranceErrorKey
  ): string => {
    if (typeof validations[field] !== 'undefined') {
      return getErrorMessage(validations[field]);
    }

    if (typeof validations[field] === 'undefined') {
      return previousInsuranceErrors[field];
    }
  };

  const renderWithTrackingError = (error: string): string => {
    updateTrackingErrors(error);
    return error;
  };

  const companyNameError = (): string[] | undefined => {
    if (isFocusedElement('previousHouseholdInsuranceCompanyName')) {
      return;
    }

    const error =
      !previousInsurance?.companyName || previousInsurance?.companyName === ''
        ? getError('companyNameNoEntry')
        : getError('companyName');
    if (!error) {
      return;
    }

    if (
      isGoNextClicked ||
      isTouchedElement('previousHouseholdInsuranceCompanyName')
    ) {
      return [renderWithTrackingError(error)];
    }
  };

  const policyNumberError = (): string[] | undefined => {
    if (isFocusedElement('previousHouseholdInsurancePolicyNumber')) {
      return;
    }

    const error = getError('policyNumber');
    if (!error) {
      return;
    }

    if (
      isGoNextClicked ||
      isTouchedElement('previousHouseholdInsurancePolicyNumber')
    ) {
      return [renderWithTrackingError(error)];
    }
  };

  const policyTerminatedByError = (): string | undefined => {
    const error = getError('policyTerminatedBy');
    if (error && isGoNextClicked) {
      return renderWithTrackingError(error);
    }
  };

  const policyEndDateError = (): string | undefined => {
    let error = getError('endDate');

    const dateInputsAreEmpty =
      !previousInsurance?.endDate ||
      (previousInsurance?.endDate &&
        Object.keys(previousInsurance.endDate).length === 0);
    if (dateInputsAreEmpty) {
      error = translations.step8.pleaseFill;
    }

    const isErrorOnFocus =
      isFocusedElement('previousHouseholdInsuranceContractExpiration') &&
      previousInsurance?.endDate &&
      Object.keys(previousInsurance.endDate)?.length === 3;
    if (
      isGoNextClicked ||
      isTouchedElement('previousHouseholdInsuranceContractExpiration') ||
      isErrorOnFocus
    ) {
      return renderWithTrackingError(error);
    }
  };

  const handleCompanyNameChange = (value: string): void => {
    setSelectedCompanyName(value);
    dispatch(setPreviousHouseholdInsuranceCompany(value));
    if (value.length === 2) {
      dispatch(
        additionalQuestionsFetchCompanyNames({
          target: 'previousInsuranceCompanies',
          value,
        })
      );
    }

    const nameMatchedCompany = suggestions.previousInsuranceCompanies.find(
      company => company.name === value
    );
    if (nameMatchedCompany) {
      dispatch(setInsuranceCompanyVuNR(nameMatchedCompany.vuNr));
    }
  };

  const handlePolicyNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(setPreviousHouseholdInsurancePolicyNumber(e.target.value));
  };

  const handlePolicyTerminationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const terminator = e.target.value as InsurancePolicyTerminated;
    dispatch(setPreviousHouseholdInsuranceTerminator(terminator));

    if (onClick) {
      const isPolicyHolder = terminator === 'policyHolder';
      const elementKey = isPolicyHolder ? 'RBUTTON_GEK_VN' : 'RBUTTON_GEK_VERS';
      onClick(elementKey);
    }
  };

  const handleContractExpirationChange = (value: DateInputValue): void => {
    dispatch(setPreviousHouseholdInsuranceEndDate(value));
  };

  const filterSuggestions = (): string[] => {
    const insuraceCompanyNames = suggestions.previousInsuranceCompanies
      .filter(company => company.vuNr)
      .map(el => el.name)
      .filter(name =>
        name.toLowerCase().includes(selectedCompanyName.toLowerCase())
      );
    return [...new Set(insuraceCompanyNames)];
  };

  const handleCompanyNameEvents = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    switch (e.type) {
      case 'blur':
        dispatchState({
          type: 'blur',
          payload: 'previousHouseholdInsuranceCompanyName',
        });
        break;
      case 'focus':
        dispatchState({
          type: 'focus',
          payload: 'previousHouseholdInsuranceCompanyName',
        });
        break;
      default:
        break;
    }
  };

  const handlePolicyNumberEvents = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    switch (e.type) {
      case 'blur':
        dispatchState({
          type: 'blur',
          payload: 'previousHouseholdInsurancePolicyNumber',
        });
        break;
      case 'focus':
        dispatchState({
          type: 'focus',
          payload: 'previousHouseholdInsurancePolicyNumber',
        });
        break;
      default:
        break;
    }
  };

  const handlePolicyContractExpirationEvent = (
    event?: React.FocusEvent<HTMLInputElement> | undefined
  ): void => {
    const dispatch = (eventType: 'blur' | 'focus'): void =>
      dispatchState({
        type: eventType,
        payload: 'previousHouseholdInsuranceContractExpiration',
      });

    event ? dispatch('focus') : dispatch('blur');
  };

  return (
    <div className="additional-question__content additional-question__card">
      <div className="additional-question-field additional-question-field--first">
        <Autocomplete
          label={translations.step8.companyName}
          value={previousInsurance.companyName}
          errors={companyNameError()}
          data-testid="previous-insurance-company-name"
          options={filterSuggestions()}
          onChange={handleCompanyNameChange}
          onBlur={handleCompanyNameEvents}
          onFocus={handleCompanyNameEvents}
          name="previousHouseholdInsuranceCompanyName"
        />
      </div>

      <div className="additional-question-field">
        <TextInput
          className="policy-number"
          label={translations.step8.policyNumber}
          name="previousHouseholdInsurancePolicyNumber"
          display="inline"
          id="previousHouseholdInsurancePolicyNumber"
          value={previousInsurance.policyNumber}
          onChange={handlePolicyNumberChange}
          onBlur={handlePolicyNumberEvents}
          onFocus={handlePolicyNumberEvents}
          errors={policyNumberError()}
        />
      </div>

      <div className="additional-question-field">
        <RadioGroup
          label={translations.step8.policyTerminatedBy}
          name="previousHouseholdInsurancePolicyTerminatedBy"
          value={previousInsurance.policyTerminatedBy}
          onChange={handlePolicyTerminationChange}
          error={policyTerminatedByError()}
        >
          <Radio value="policyHolder" label={translations.step8.policyHolder} />
          <Radio value="insurer" label={translations.step8.insurer} />
        </RadioGroup>
      </div>

      <div className="additional-question-field--last">
        <DateInputRow
          autoTab
          label={translations.step8.contractExpiration}
          value={previousInsurance.endDate}
          onChange={handleContractExpirationChange}
          onBlur={(): void => handlePolicyContractExpirationEvent()}
          onFocus={handlePolicyContractExpirationEvent}
          error={policyEndDateError()}
        />
      </div>
    </div>
  );
};
