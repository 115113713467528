import addressValidationHandlers from '../apis/addressValidation/index.handlers';
import hausratHandlers from '../apis/hausrat/offers/index.handlers';
import { insuranceCompaniesHandlers } from '../apis/insuranceCompanies/services/index.handlers';
import offerEngineHandlers from '../apis/offerEngine/offers/index.handlers';
import spcsHandlers from '../apis/spcs/index.handlers';
import submitEngineHandlers from '../apis/submitEngine/submitOffer/index.handlers';
import downloadEngineHanlders from '../apis/documentEngine/downloadOffer/index.handlers';

const handlers = [
  ...addressValidationHandlers,
  ...hausratHandlers,
  ...insuranceCompaniesHandlers,
  ...offerEngineHandlers,
  ...spcsHandlers,
  ...submitEngineHandlers,
  ...downloadEngineHanlders,
];

export default handlers;
