import { insuranceCompaniesHttp } from '../../http/insuranceCompaniesHttp';
import {
  getInsuranceCompanies as getInsuranceCompaniesRoute,
  getUrl,
} from '../constants';
import { InsuranceCompaniesResponse } from '../types';

export const getInsuranceCompanies =
  async (): Promise<InsuranceCompaniesResponse> => {
    try {
      const { method, path } = getInsuranceCompaniesRoute;
      const queryParams = '?hauptstelle=J&zweig=3200';
      const url = `${getUrl(path)}${queryParams}`;

      const response = await insuranceCompaniesHttp<InsuranceCompaniesResponse>(
        url,
        { method }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
