export const step12 = {
  title: 'Schließen Sie Ihren Vertrag jetzt online ab.',
  subtitle:
    'Mit dem Klick auf "Online abschließen" werden Ihre Vertragsdaten verbindlich an die ERGO Versicherung AG übertragen.',
  downloadOrSubmitError:
    'Ein Online-Abschluss ist zurzeit leider nicht möglich. Bitte versuchen Sie es später wieder. Oder Sie rufen an und lassen sich telefonisch beraten.',
  note: {
    title: 'EU-Vorgabe: Beratungsverzicht',
    body: [
      'Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet:',
      'Wir müssen Sie vorher beraten und dies dokumentieren. Sie können auch schnell und dafür ohne Beratung abschließen. Dann brauchen wir vor dem Abschluss Ihren Beratungsverzicht.',
    ],
  },
  checkbox: {
    label:
      'Ich verzichte für diesen Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.',
    error:
      'Bitte bestätigen Sie, dass Sie für diesen Vertragsabschluss auf eine persönliche Beratung verzichten.',
  },
  steps: {
    one: {
      title: 'Vertragsunterlagen',
      subtitle:
        'Bitte speichern Sie vor dem Online-Abschluss die Vertragsunterlagen und Informationen als PDF und schließen Sie dann online ab. ',
      buttonText: 'Herunterladen',
    },
    two: {
      title: 'Zahlungspflichtiger Vertrag',
      subtitle:
        'Bei Vertragsabschluss fallen für Sie keine weiteren Kosten an.',
      buttonText: 'Online abschließen',
      buttonAdditionalText: 'zahlungspflichtiger Vertrag',
    },
  },
};
