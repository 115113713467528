import * as constants from './constants';
import * as offers from './offers';

const { frontendConfig, getUrl, ...endpoints } = constants;

const hausratApi = {
  endpoints,
  offers,
  frontendConfig,
};

export default hausratApi;
