import React from 'react';
import Modal from '@eg/elements/Modal';
import translations from '../../../../constants/translations';

import type { ContactModalProps } from './ContactModalTypes';

const ContactModal = ({ open, onClose }: ContactModalProps): JSX.Element => {
  return (
    <Modal
      open={open}
      useEscKeyToDismiss
      dismissible
      onDismiss={onClose}
      backdropDismissesModal
      extendedWidth
    >
      <div data-testid="apartment-type-modal">
        <h3 className="modal-title">
          {translations.step1.contactInfoModalTitle}
        </h3>
        <p
          dangerouslySetInnerHTML={{
            __html: translations.step1.contactInfoModalSubTitle,
          }}
        />
        <div className="modal-info">
          <p className="modal-info-title">
            <strong>{translations.step1.listTitle}</strong>
          </p>
          <ul className="bullet-list">
            <li> {translations.step1.list1}</li>
            <li> {translations.step1.list2}</li>
            <li> {translations.step1.list3}</li>
          </ul>
          <p> {translations.step1.listClosure}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
