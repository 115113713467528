import { URLs } from './urls';

export * from './apis';
export * from './constants';
export * from './mocks/offer';
export * from './utils';
export * from './types';
export * from './apis/aemIntegration/types/index';
export * from './validators/addressAndZipcode.validator';
export * from './validators/additionalQuestions.validator';
export * from './validators/squareMeteres.validator';
export * from './validators/validateBirthDate.validator';
export * from './validators/validateStartDate.validator';
export * from './validators/validateEmail.validator';
export * from './validators/validateIban.validator';

export type BirthDatePayload = {
  birthDate: string;
};

export type BusinessId = {
  businessId: string;
};

export type FloorType =
  | 'basement'
  | 'groundFloor'
  | 'firstFloor'
  | 'secondFloor'
  | 'otherFloor';

export type FloorTypePayload = {
  floorType: FloorType;
};

export type InsuranceStartDatePayload = {
  insuranceStartDate: string;
};

export type LivingPlace = 'apartment' | 'singleFamilyHouse';

export type PersonalData = 'setErrorMessage' | 'setEmail';

export type LivingPlaceTypePayload = {
  livingPlaceType: LivingPlace;
};

export type SquareMetersPayload = {
  squareMeters: number;
};

export type CustomerAddress = {
  streetName: string;
  streetNumber: string;
  zipCode: string;
  city: string;
};

export type CustomerAddressPayload = {
  address: CustomerAddress;
};

export type CustomerPostalCodePayload = {
  zipCode: string;
};

export type IbanValidatorPayload = {
  iban: string;
  countryCode: string;
};

export type RequestBodyPayload =
  | FloorTypePayload
  | LivingPlaceTypePayload
  | SquareMetersPayload
  | CustomerAddressPayload
  | CustomerPostalCodePayload
  | InsuranceStartDatePayload
  | BirthDatePayload
  | IbanValidatorPayload;

type Keys = keyof typeof URLs;

export type OfferApiEndpoints = (typeof URLs)[Keys];

export { URLs };

export enum NumberPages {
  LIVING_PLACE,
  HOME_LOCATION,
  HOME_SQUARE_METERS,
  ADDRESS,
  INSURANCE_DATE,
  BIRTH_DATE,
  INSURANCE_COVERAGE,
  ADDITIONAL_QUESTIONS,
  PERSONAL_DATA,
  CONTRACT_REVIEW,
  BANK_DETAILS,
  TERMS_OF_SERVICE,
  FEEDBACK,
}
