import './CheckBoxRow.scss';

import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';
import CheckIcon from '@eg/elements/Icons/CheckIcon';

import Checkbox from '../ECheckbox/ECheckbox';
import type { CheckBoxRowProps } from './CheckBoxRowTypes';

const CheckBoxRow = ({
  title,
  subtitle,
  readOnly = false,
  required = false,
  showTooltip = false,
  toolTipComponent,
  checkedValue,
  onChange,
  name,
  disabled = false,
  dataTestId = 'checkbox-row-wrapper',
  onOpened,
}: CheckBoxRowProps): JSX.Element => {
  const isDisabled = readOnly || disabled;

  const onToggledOpenHandler = (isOpen: boolean): void => {
    if (onOpened && isOpen) {
      onOpened();
    }
  };

  return (
    <div className="checkboxRow" data-testid={dataTestId}>
      <div
        className={`checkboxRow__field ${
          isDisabled ? 'checkboxRow__field--disabled' : ''
        }`}
      >
        {isDisabled && (
          <>
            {checkedValue && <CheckIcon width={18} height={18} />}

            {!checkedValue && <div className="checkboxRow__square" />}
          </>
        )}

        {!isDisabled && (
          <Checkbox
            disabled={isDisabled}
            id={name}
            checked={checkedValue}
            onChange={onChange}
            label={null}
            required={required}
          />
        )}
      </div>

      <label
        htmlFor={isDisabled ? undefined : name}
        className={`checkboxRow__label ${
          isDisabled ? 'checkboxRow__label--disabled' : ''
        }`}
      >
        <span className="checkboxRow__labelTitle">{title}</span>

        {!!subtitle && (
          <span className="checkboxRow__labelSubtitle">{subtitle}</span>
        )}
      </label>

      {showTooltip && toolTipComponent && (
        <TooltipIcon
          className="checkboxRow__tooltip"
          data-testid="tooltip-checkbox-row"
          isModal
          onToggledOpen={onToggledOpenHandler}
        >
          {toolTipComponent}
        </TooltipIcon>
      )}
    </div>
  );
};

export default CheckBoxRow;
