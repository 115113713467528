import {
  getDeductibleAmountFromMapping,
  getFloorTypeFromMapping,
  getLivingPlaceFromMapping,
  NumberPages,
} from 'commons';
import React from 'react';
import { useSelector } from 'react-redux';
import Price from '../../../components/Price/Price';
import translations from '../../../constants/translations';
import {
  dateFirstFormat,
  dateToDateInputValue,
} from '../../../helpers/dates/dates';
import { useAppSelector } from '../../../store/hooks';
import {
  getAdditionalQuestionsFromOffer,
  getAddressFromOffer,
  getBirthDateFromPerson,
  getBonusFromOffer,
  getHomeSquareMetersFromOffer,
  getInsuranceCoverageFromOffer,
  getInsuranceDateFromOffer,
  getInsuredAmountFromOffer,
  getLivingPlaceTypeFromOffer,
  getLocationHomeFromOffer,
  getPersonalDataFromPerson,
  getPremiumFromOffer,
  getSelectedVariantFromOffer,
  getOfferFeedbackSelector,
} from '../../../store/offerSlice';
import { personalDataFormSelector } from '../../step9/personalDataSlice';
import {
  houseTypeCopies,
  floorTypeCopies,
  previousContractTermitedByCopies,
  productModulesCopies,
  deductibleAmountCopies,
  contractPeriodCopies,
  damageTypeCopies,
  damageTypeGlassCopies,
} from '../constants';
import type { SummaryData, SummarySection } from './types/offerSummary';

export const useOfferSummary = (): SummarySection[] => {
  /**
   * Insurance Coverage information
   *
   * This block builds anything related with the insurance coverage
   * Tariffs selected, contract term, payment method, starting date, etc
   */

  // Start date
  const rawStartDate = useSelector(getInsuranceDateFromOffer);
  const startDate = dateToDateInputValue(new Date(rawStartDate));

  // Birthdate
  const rawBirthDate = useSelector(getBirthDateFromPerson);
  const birthDate = dateToDateInputValue(new Date(rawBirthDate));

  // Insurance coverage
  const insuranceCoverage = useSelector(getInsuranceCoverageFromOffer);
  const deductibleAmount = getDeductibleAmountFromMapping(
    insuranceCoverage.deductibleAmount
  );
  const selectedVariant = useSelector(getSelectedVariantFromOffer);
  const startingBonus = useSelector(getBonusFromOffer);
  const insuredAmount = useSelector(getInsuredAmountFromOffer);
  const premium = useSelector(getPremiumFromOffer);
  const { bonus } = useAppSelector(getOfferFeedbackSelector);

  const isProductWithGlassSelected =
    insuranceCoverage.productModules.includes('glass');
  const insruanceCoverageData = [
    {
      title: translations.step10.formSubCategoryNameTariffType,
      description: [
        !startingBonus && !selectedVariant
          ? ''
          : translations.step10.constantTarifTypeCopies(
              bonus ? translations.step10.bonusText : '',
              selectedVariant?.tarifvariante
            ),
      ],
    },
    {
      title: translations.step10.formSubCategoryNameSum,
      description: [<Price value={insuredAmount} withSpace unstyled />],
    },
    {
      title: translations.step10.formSubCategoryNameModules,
      description: [
        ...insuranceCoverage.productModules?.map(product => (
          <p key={product} className="description-element">
            {product === 'bicycleAndEbike'
              ? productModulesCopies.bicycleAndEbike(
                  insuranceCoverage.bicycleAndEbikeCoInsuredCover,
                  insuranceCoverage.bicycleAndEbikeCoInsuredAmount
                )
              : productModulesCopies[product]}
          </p>
        )),
        <p className="description-element">
          {translations.step10.constantTarifTypeSubLabelCopies(
            selectedVariant?.tarifvariante
          )}
        </p>,
      ],
    },
    {
      title: translations.step10.formSubCategoryNameExcess,
      description: [`${deductibleAmountCopies[deductibleAmount]}`],
    },
    {
      title: translations.step10.formSubCategoryNameStartDate,
      description: [dateFirstFormat(startDate)],
    },
    {
      title: translations.step10.formSubCategoryNameContractTerm,
      description: [
        `${contractPeriodCopies[insuranceCoverage.contractPeriod]}`,
      ],
    },
  ];

  if (isProductWithGlassSelected) {
    insruanceCoverageData.push(
      {
        title: translations.step10.formSubCategoryHouseDueContribution,
        description: [
          !premium
            ? ''
            : `${premium.housePremium} € ${
                premium.paymentMethod
              } ${translations.step10.formSubCategoryContentContribution(
                premium.taxes
              )}`,
        ],
      },
      {
        title: translations.step10.formSubCategoryGlassDueContribution,
        description: [
          !premium
            ? ''
            : `${premium.glassPremium} € ${
                premium.paymentMethod
              } ${translations.step10.formSubCategoryContentContribution(
                premium.glassTax
              )}`,
        ],
      },
      {
        title: translations.step10.formSubCategoryTotalDueContribution,
        description: [
          !premium
            ? ''
            : `${premium.grossPremium} € ${premium.paymentMethod} ${translations.step10.formSubCategoryTotalDueContent}`,
        ],
      }
    );
  } else {
    insruanceCoverageData.push({
      title: translations.step10.formSubCategoryNameContribution,
      description: [
        !premium
          ? ''
          : `${premium.grossPremium} € ${
              premium.paymentMethod
            } ${translations.step10.formSubCategoryContentContribution(
              premium.taxes
            )}`,
      ],
    });
  }

  const INSURANCE_COVERAGE: SummarySection = {
    name: translations.step10.formCategoryNameTariff,
    editRoute: NumberPages.INSURANCE_COVERAGE,
    data: insruanceCoverageData,
  };

  /**
   * Previous insurance information
   *
   * This block builds anything related with the previous insurance
   * and previous damages
   */

  // Additional questions
  const additionalQuestions = useSelector(getAdditionalQuestionsFromOffer);

  const previousInsurance: SummaryData[] = [];
  if (additionalQuestions.previousHouseholdInsurance.selected) {
    previousInsurance.push(
      {
        title: translations.step10.formSubCategoryNameCompanyName,
        description: [
          additionalQuestions.previousHouseholdInsurance.companyName,
        ],
      },
      {
        title: translations.step10.formSubCategoryNamePolicyNumber,
        description: [
          additionalQuestions.previousHouseholdInsurance.policyNumber,
        ],
      },
      {
        title: translations.step10.formSubCategoryNamePolicyTerminated,
        description: [
          previousContractTermitedByCopies[
            additionalQuestions.previousHouseholdInsurance.policyTerminatedBy
          ],
        ],
      },
      {
        title: translations.step10.formSubCategoryNameExpiration,
        description: [
          dateFirstFormat(
            additionalQuestions.previousHouseholdInsurance.endDate
          ),
        ],
      }
    );
  }

  const previousDamage: SummaryData[] = [];
  if (additionalQuestions.previousHouseholdDamage.selected) {
    previousDamage.push(
      {
        title: translations.step10.formSubCategoryNameDamageArt,
        description: [
          damageTypeCopies[additionalQuestions.previousHouseholdDamage.type],
        ],
      },
      {
        title: translations.step10.formSubCategoryNameDamageAmount,
        description: [
          <Price
            value={additionalQuestions.previousHouseholdDamage.cost}
            withSpace
            unstyled
          />,
        ],
      },
      {
        title: translations.step10.formSubCategoryNameDamageYear,
        description: [`${additionalQuestions.previousHouseholdDamage.year}`],
      }
    );
  }

  const previousGlassInsurance: SummaryData[] = [];
  if (additionalQuestions.previousGlassInsurance.selected) {
    previousGlassInsurance.push(
      {
        title: translations.step10.formSubCategoryNameCompanyName,
        description: [additionalQuestions.previousGlassInsurance.companyName],
      },
      {
        title: translations.step10.formSubCategoryNamePolicyNumber,
        description: [additionalQuestions.previousGlassInsurance.policyNumber],
      },
      {
        title: translations.step10.formSubCategoryNamePolicyTerminated,
        description: [
          previousContractTermitedByCopies[
            additionalQuestions.previousGlassInsurance.policyTerminatedBy
          ],
        ],
      },
      {
        title: translations.step10.formSubCategoryNameExpiration,
        description: [
          dateFirstFormat(additionalQuestions.previousGlassInsurance.endDate),
        ],
      }
    );
  }

  const previousGlassDamage: SummaryData[] = [];
  if (additionalQuestions.previousGlassDamage.selected) {
    console.log({
      damageTypeGlassCopies,
      key: additionalQuestions.previousGlassDamage.type,
    });

    previousGlassDamage.push(
      {
        title: translations.step10.formSubCategoryNameDamageArt,
        description: [
          damageTypeGlassCopies[additionalQuestions.previousGlassDamage.type],
        ],
      },
      {
        title: translations.step10.formSubCategoryNameDamageAmount,
        description: [
          <Price
            value={additionalQuestions.previousGlassDamage.cost}
            withSpace
            unstyled
          />,
        ],
      },
      {
        title: translations.step10.formSubCategoryNameDamageYear,
        description: [`${additionalQuestions.previousGlassDamage.year}`],
      }
    );
  }

  const PREVIOUS_INSURANCE: SummarySection = {
    name: translations.step10.formCategoryNamePrevious,
    editRoute: NumberPages.ADDITIONAL_QUESTIONS,
    data: [
      {
        title: translations.step10.formSubCategoryNameLastYears,
        headline: translations.step10.formCategoryNamePreviousInsuranceHeadline,
        description: [
          additionalQuestions.previousHouseholdInsurance.selected
            ? translations.commons.yesLabel
            : translations.commons.noLabel,
        ],
      },
      ...previousInsurance,
      {
        title: translations.step10.formSubCategoryNameDamageFiveYears,
        description: [
          additionalQuestions.previousHouseholdDamage.selected
            ? translations.commons.yesLabel
            : translations.commons.noLabel,
        ],
      },
      ...previousDamage,
    ],
  };

  if (isProductWithGlassSelected) {
    PREVIOUS_INSURANCE.data.push(
      {
        headline:
          translations.step10.formCategoryNamePreviousGlassInsuranceHeadline,
        title: translations.step10.formPreinsuranceGlassInsurance,
        description: [
          additionalQuestions.previousGlassInsurance?.selected
            ? translations.commons.yesLabel
            : translations.commons.noLabel,
        ],
      },
      ...previousGlassInsurance,
      {
        title: translations.step10.formPreviousInsuraneGlassDamage,
        description: [
          additionalQuestions.previousGlassDamage?.selected
            ? translations.commons.yesLabel
            : translations.commons.noLabel,
        ],
      },
      ...previousGlassDamage
    );
  }

  /**
   * Personal data information
   *
   * This block builds anything related with the customer,
   * such as address, telephone, name, etc
   */

  // Address
  const address = useSelector(getAddressFromOffer);
  const addressLocation: SummaryData = {
    title: translations.step10.formSubCategoryNameClientAddress,
    description: [
      `${address.streetName} ${address.streetNumber}, ${address.zipCode} ${address.city}`,
    ],
  };

  // Personal information
  const personalData = useSelector(getPersonalDataFromPerson);
  const hasValidatedAddress =
    useSelector(personalDataFormSelector)?.addressConfirmation?.length > 0;

  const phoneNumber: SummaryData[] = [];
  if (!!personalData.phoneNumber) {
    phoneNumber.push({
      title: translations.step10.formSubCategoryNameTelephone,
      description: [`${personalData.phoneNumber}`],
    });
  }

  const PERSONAL_DATA: SummarySection = {
    name: translations.step10.formCategoryNameAddress,
    editRoute: NumberPages.PERSONAL_DATA,
    data: [
      {
        title: translations.step10.formSubCategoryNameClientGender,
        description: [
          personalData.salutation === 'MR'
            ? translations.step9.radioButtonMale
            : translations.step9.radioButtonFemale,
        ],
      },
      {
        title: translations.step10.formSubCategoryNameClientFirstName,
        description: [personalData.firstName],
      },
      {
        title: translations.step10.formSubCategoryNameClientSurname,
        description: [personalData.familyName],
      },
      {
        title: translations.step10.formSubCategoryNameClientBirth,
        description: [dateFirstFormat(birthDate)],
      },
      addressLocation,
      {
        title: translations.step10.formSubCategoryNameMandatory,
        description: [
          hasValidatedAddress
            ? translations.commons.yesLabel
            : translations.commons.noLabel,
        ],
      },
      {
        title: translations.step10.formSubCategoryNameEmail,
        description: [personalData.email],
      },
      ...phoneNumber,
    ],
  };

  /**
   * Living place information
   *
   * This block builds anything related with the type of
   * house/building
   */

  // House type
  const rawHouseType = useSelector(getLivingPlaceTypeFromOffer);
  const houseType = getLivingPlaceFromMapping(rawHouseType);

  // Floor type
  const rawFloorType = useSelector(getLocationHomeFromOffer);
  const floorType = getFloorTypeFromMapping(rawFloorType);

  // Square meters
  const squareMeters = useSelector(getHomeSquareMetersFromOffer);

  const LIVING_PLACE: SummarySection = {
    name: translations.step10.formCategoryNameHouse,
    editRoute: NumberPages.LIVING_PLACE,
    data: [
      {
        title: translations.step10.formSubCategoryNameHouseType,
        description: [houseTypeCopies[houseType]],
      },
      {
        title: translations.step10.formSubCategoryNameSize,
        description: [`${squareMeters} qm`],
      },
      {
        title: `${translations.step10.formSubCategoryNameClientAddress}:`,
        description: addressLocation.description,
      },
      {
        title: translations.step10.formSubCategoryNameLocks,
        description: [
          additionalQuestions.hasElectronicOrMechatronickLocks.selected
            ? translations.commons.yesLabel
            : translations.commons.noLabel,
        ],
      },
    ],
  };

  if (houseType === 'apartment') {
    LIVING_PLACE.data.splice(1, 0, {
      title: translations.step10.formSubCategoryNameFloorType,
      description: [floorTypeCopies[floorType]],
    });
  }

  return [INSURANCE_COVERAGE, LIVING_PLACE, PREVIOUS_INSURANCE, PERSONAL_DATA];
};
