import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { trackClickEvent } from '../../../api/tracking';
import { TrackingElementPage3 } from '../TrackingElements';

type ModalOrigin = 'header' | 'info';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onModalTracking: (origin: ModalOrigin) => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onModalTracking = (origin: ModalOrigin): void => {
    const element =
      origin === 'header'
        ? TrackingElementPage3.INFO_ICON_HEADER
        : TrackingElementPage3.INFO_ICON_SUM;

    trackClickEvent(element, trackingOptions);
  };

  return { trackingOptions, onModalTracking };
};

export default useClickTracking;
