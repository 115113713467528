import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

export const CustomerSelectionEbike = (): JSX.Element => (
  <div data-testid="customer-selection-ebike-info">
    <p
      data-testid="customer-selection-ebike-p1"
      dangerouslySetInnerHTML={{
        __html: step7.customerSelection.paragraph1,
      }}
    ></p>
    <p
      data-testid="customer-selection-ebike-p2"
      dangerouslySetInnerHTML={{
        __html: step7.customerSelection.paragraph2,
      }}
    ></p>
  </div>
);
