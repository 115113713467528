export * from './maps';

export const MAX_ALLOWED_HOUSEHOLD_DAMAGE_COST = 99_999;

export const MAX_ALLOWED_GLASS_DAMAGE_COST = 9_999;

export const commonOffer = {
  tax: 16.15,
  bonus: 13,
  glassTax: 19,
};
export const MAX_YEARS_PREVIOUS_INSURANCE_ALLOWED = 5;
