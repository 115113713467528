import { spcsHttp } from '../../http/spcsHttp';
import { createDocument as createDocumentRoute, getUrl } from '../constants';
import { CreateDocument, CreateDocumentResponse } from './types';

export const createDocument = async ({
  businessId,
}: CreateDocument): Promise<CreateDocumentResponse> => {
  try {
    const { method, path } = createDocumentRoute;
    const url = getUrl(path).replace(':businessId', businessId);
    const payload = {
      mandant: '1',
      vertriebskanal: 'INTERNET',
      quellsystem: 'ERGO_INTERNET',
      performMigration: false,
    };

    const response = await spcsHttp<CreateDocumentResponse>(url, {
      method,
      payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
