export function sessionStorageSet<T>(key: string, value: T): void {
  global.sessionStorage.setItem(key, JSON.stringify(value));
}

export function sessionStorageGet<T>(key: string): T | undefined {
  const data = global.sessionStorage.getItem(key);

  if (typeof data === 'string') {
    return JSON.parse(data);
  } else if (typeof data === 'object') {
    return data;
  }

  return undefined;
}

export function sessionStorageRemove(key: string): void {
  global.sessionStorage.removeItem(key);
}
