import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import { AemAssetsKeys } from 'commons';
import React, { useEffect, useState } from 'react';
import aemIntegration from '..';
import './AemFragment.scss';

type Props = {
  fragmentName: AemAssetsKeys;
  shouldUseIframe?: boolean;
};

const AemFragment = ({
  fragmentName,
  shouldUseIframe = false,
}: Props): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);
  const [fragmentUrl, setFragmentUrl] = useState('');
  const [fragmentContent, setFragmentContent] = useState('');

  const loadContent = async (name: string, iframe: boolean): Promise<void> => {
    setIsLoading(true);

    try {
      if (iframe) {
        const url = aemIntegration.getXfUrlByAemProperties(name) ?? '';
        setFragmentUrl(url);
      } else {
        const content = (await aemIntegration.getFragment(name)) ?? '';
        setFragmentContent(content);
      }
    } catch (e) {
      console.error(e);
      setFragmentUrl('');
      setFragmentContent('');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadContent(fragmentName, shouldUseIframe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fragmentName, shouldUseIframe]);

  return (
    <div className="AemFragment">
      {isLoading && (
        <div className="AemFragment__loadingSpinner">
          <LoaderIcon />
        </div>
      )}

      {shouldUseIframe && (
        <iframe
          className="AemFragment__iframe"
          onLoad={(): void => setIsLoading(false)}
          src={fragmentUrl}
          title="productTables"
        />
      )}

      {!shouldUseIframe && (
        <div
          className="AemFragment__injected"
          dangerouslySetInnerHTML={{ __html: fragmentContent }}
          data-testid={`XF_url-${fragmentName}`}
          id={`XF_url-${fragmentName}`}
        />
      )}
    </div>
  );
};

export default AemFragment;
