import { DateInputValue } from '@eg/elements/DateInput';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  dateFirstFormat,
  dateInputValueToDate,
  dateFormatted,
} from '../../helpers/dates/dates';
import { isEmptyObject } from '../../helpers/objects/objects';
import { birthDate } from '../../services/offerService/offerService';
import { RootState } from '../../store/types';
import { setStepValidated } from '../../store/appSlice';
import { saveOffer, savePerson } from '../../store/offerSlice';
import type {
  DateOfBirthFormTypes,
  DateOfBirthState,
} from './DateOfBirthTypes';

const initialState: DateOfBirthState = {
  isValidated: false,
  isValid: false,
  isLoading: false,
  form: {
    dateOfBirth: {} as DateInputValue,
  },
};

export const dateOfBirthFetchValidation = createAsyncThunk(
  'dateOfBirth/validation',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    try {
      const response = await birthDate({
        businessId: state.app.businessId,
        dateOfBirth: dateOfBirthBackEndFormattedSelector(state),
        personId: state.app.personId,
      });

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      dispatch(setStepValidated(6));

      return response;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const dateOfBirthSlice = createSlice({
  name: 'dateOfBirth',
  initialState,
  reducers: {
    setDateOfBirth: (state, action: PayloadAction<DateInputValue>) => {
      state.form.dateOfBirth = action.payload;
    },
    setDateOfBirthValid: (state, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(dateOfBirthFetchValidation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(dateOfBirthFetchValidation.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(dateOfBirthFetchValidation.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
    });
  },
});

export const { setDateOfBirth, setDateOfBirthValid } = dateOfBirthSlice.actions;

export const dateOfBirthFormatted = ({
  dateOfBirth: { form },
}: RootState): string | null => {
  const { dateOfBirth } = form;
  if (isEmptyObject(dateOfBirth)) {
    return null;
  }

  return dateFirstFormat(dateOfBirth);
};

export const dateOfBirthBackEndFormattedSelector = ({
  dateOfBirth: { form },
}: RootState): string | null => {
  const { dateOfBirth } = form;
  if (isEmptyObject(dateOfBirth)) {
    return null;
  }

  return dateFormatted(dateInputValueToDate(dateOfBirth));
};

export const isLoadingDateOfBirthSelector = (state: RootState): boolean =>
  state.dateOfBirth.isLoading;

export const isDateOfBirthValidSelector = (state: RootState): boolean =>
  state.dateOfBirth.isValid;

export const dateOfBirthFormSelector = (
  state: RootState
): DateOfBirthFormTypes => state.dateOfBirth.form;

export default dateOfBirthSlice.reducer;
