import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import React, { useEffect } from 'react';
import { useAemIntegration } from '../../api/aemIntegration/hooks/aemIntegration';
import translations from '../../constants/translations';
import { usePerson } from '../../hooks/usePerson';
import './FeedbackHeader.scss';

export const FeedbackHeader = (): JSX.Element => {
  const { adjustContentToHeader } = useAemIntegration();

  const { nameFormatted } = usePerson();

  useEffect(adjustContentToHeader);

  return (
    <header className="feedback-header">
      <CheckColorIcon width={36} height={36} />
      <h1 className="feedback-header__title" data-testid="fedback-header-title">
        {translations.step13.headerTitle(`${nameFormatted}`)}
      </h1>
      <p
        className="feedback-header__subtitle"
        data-testid="fedback-header-subtitle"
      >
        {translations.step13.headerSubtitle}
      </p>
    </header>
  );
};
