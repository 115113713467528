import React from 'react';
import translations from '../../../../constants/translations';

const BankDetailsTooltipInfo = (): JSX.Element => {
  return (
    <div data-testid="bankDetails-tooltip-info">
      <h3>{translations.step11.tooltipTitle}</h3>

      <p>{translations.step11.tooltipMsg}</p>
    </div>
  );
};

export default BankDetailsTooltipInfo;
