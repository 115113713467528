import {
  Angebot,
  Leistungsvereinbarungen,
  Versicherungen,
} from 'commons/apis/offerEngine';
import { Vertraege } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import { RootState } from '../../types';

export const getOfferSelector = (state: RootState): Angebot | undefined =>
  state.offer.angebot;

export const offerExistSelector = (state: RootState): boolean =>
  !!getOfferSelector(state);

export const getPersonSelector = (state: RootState): Person | undefined =>
  state.offer.person;

export const getVariantsFromOffer =
  (variantId?: string) =>
  (state: RootState): Versicherungen[] => {
    const variants = state.offer.angebot?.versicherungen ?? [];
    if (!variantId) {
      return variants;
    }

    return variants.filter(
      v => v.versicherungsId.toLowerCase() === variantId.toLowerCase()
    );
  };

export const getTariffsFromOffer =
  (variantId?: string) =>
  (state: RootState): Leistungsvereinbarungen[] => {
    const tariffs = getOfferSelector(state)?.leistungsvereinbarungen ?? [];
    if (!variantId) {
      return tariffs;
    }

    return tariffs.filter(
      t => t.struktur.versicherungen.toLowerCase() === variantId.toLowerCase()
    );
  };

export const getContractsFromOffer =
  (variantId?: string) =>
  (state: RootState): Vertraege[] => {
    const contracts = getOfferSelector(state)?.vertraege ?? [];
    if (!variantId) {
      return contracts;
    }

    return contracts.filter(
      t => t.struktur.versicherungen.toLowerCase() === variantId.toLowerCase()
    );
  };

export const getRisikoOrtFromOffer = (
  state: RootState
): Angebot['risikoOrt'] | undefined => getOfferSelector(state)?.risikoOrt;

export const getSelectedVariantFromOffer = (
  state: RootState
): Versicherungen | undefined => {
  const variants = getVariantsFromOffer()(state);
  const selected = variants.find(v => v.ausgewaehlt === true);

  if (selected) {
    return selected;
  }

  return variants.find(v => v.tarifvariante.toLowerCase() === 'best');
};
