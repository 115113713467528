import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';

import './ContactInformation.scss';
import type { ContactInformationProps } from './ContactInformation.d';
import TelephoneInput from '../../../../components/TelephoneInput/TelephoneInput';
import { TextInput } from '../../../../components/TextInput/TextInput';
import translations from '../../../../constants/translations';

const ContactInformation = ({
  handleOnChangeEmail,
  handleOnBlurEmailValidation,
  handleChangePrefix,
  handleChangeTelephone,
  handleTelephoneValidation,
  prefixNumber,
  telephoneNumber,
  errorMessageTelephone,
  errorMessage,
  email,
  emailInputRef,
  onOpened,
}: ContactInformationProps): JSX.Element => {
  const handleToggleOpen = (isOpen: boolean): void => {
    if (onOpened && isOpen) {
      onOpened();
    }
  };

  return (
    <div className="contact-information">
      <div className="contact-information-email">
        <div className="contact-information-email__addresse">
          <p className="contact-information-email__addresse-title">
            {translations.step9.emailAddress}
          </p>
          <TooltipIcon isModal onToggledOpen={handleToggleOpen}>
            <h3>{translations.step9.emailAddressToolTipHeader}</h3>
            <p>{translations.step9.emailAddressToolTipContent}</p>
          </TooltipIcon>
        </div>
        <div
          className="contact-information-email__input-container"
          ref={emailInputRef}
        >
          <label htmlFor="email" />
          <TextInput
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleOnChangeEmail}
            onBlur={handleOnBlurEmailValidation}
            placeholder="beispiel@beispiel.de"
          />
          <p className="contact-information-telephone__inputs--error">
            {errorMessage}
          </p>
        </div>
      </div>
      <div className="contact-information-telephone">
        <p className="contact-information-telephone__title">
          {translations.step9.telephone}
        </p>
        <TelephoneInput
          code={prefixNumber}
          value={telephoneNumber}
          handleChangePrefix={handleChangePrefix}
          handleChangeTelephone={handleChangeTelephone}
          handleTelephoneValidation={handleTelephoneValidation}
          errorMessageTelephone={errorMessageTelephone}
        />
      </div>
      <div className="contact-information-telephone__texts">
        <div className="contact-information-telephone__texts__title"></div>
        <div className="contact-information-telephone__texts__content">
          <p className="contact-information-telephone__texts-title">
            {translations.step9.telephoneInfoHeader}
          </p>
          <p className="contact-information-telephone__texts-description">
            {translations.step9.telephoneInfoContent}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
