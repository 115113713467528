import { IconProps } from '@eg/elements/components/SvgIcon';
import ApartmentBuildingIcon from '@eg/elements/Icons/ApartmentBuildingIcon';
import HouseGroundIcon from '@eg/elements/Icons/HouseGroundIcon';
import { LivingPlace } from 'commons';
import translations from '../../../../constants/translations';

export const typesOfHouseData: {
  value: LivingPlace;
  text: string;
  icon: React.FunctionComponent<IconProps>;
}[] = [
  {
    value: 'apartment',
    text: translations.step1.apartmentText,
    icon: ApartmentBuildingIcon,
  },
  {
    value: 'singleFamilyHouse',
    text: translations.step1.singleHouse,
    icon: HouseGroundIcon,
  },
];
