export type CreateOfferParams = {
  quellsystem: string;
  verkaufsproduktart: string;
  vertriebskanal: string;
};

type Structure = {
  versicherungen: string;
};

export type Beitraege = {
  bruttobeitragNachZahlweise: number;
  link: {
    methods: [string];
    uri: string;
  };
  nettobeitragNachZahlweise: number;
  struktur: {
    versicherungen: string;
    vertraege?: string;
  };
};

type Risikotyp =
  | 'HAUPTWOHNUNG_IN_EINFAMILIENHAUS'
  | 'HAUPTWOHNUNG_IN_MEHRFAMILIENHAUS';

export type Wertebereiche = {
  attribut: string;
  /**
   * Dropdown's options' labels
   */
  bezeichnungen: string[];
  obligatorisch: boolean;
  typ: 'AUFZAEHLUNG';
  /**
   * Default value
   */
  vorbelegung: string;
  /**
   * Dropdown's options' values
   */
  werte: string[];
};

export type DeductibleAmount = null | 'EUR_300_FLEXI' | 'EUR_300_FEST';

export type Leistungsvereinbarungen = {
  aktionsnummer: string;
  alterUrsprungsbeginn: string;
  auswaehlbar: boolean;
  beitragBestandstarif: number;
  bezeichnung: string;
  bezeichnungBestandstarif: string;
  leistungsvereinbarungsId: string;
  leistungsvereinbarungsart: string;
  link: {
    methods: [string];
    uri: string;
  };
  mitarbeiternummer: string;
  obligatorisch: boolean;
  selbstbehalt: DeductibleAmount;
  struktur: Structure;
  ursprungsbeginnBestandstarif: string;
  vereinbart: boolean;
  versicherungssumme: number;
  werbeaktionscode: string;
  wertebereiche: Wertebereiche[];
  zahlweiseBestandstarif: 'EINMALZAHLUNG';
};

type Rollen = {
  personId: string;
  reference: {
    addressId: string;
    emailId: string;
    paymentId: string;
    personId: string;
    telephoneId: string;
  };
  rollentyp: 'VERSICHERUNGSNEHMER';
  zahlungsId: string;
};

type Druckauftragsinformationen = {
  auftragsnummer: string;
  aussendeart: 'HAUPT_AUSSENDUNG';
  docAuftraege: [
    {
      docAuftragId: string;
      pdf: string;
    }
  ];
  freundschaftswerbung: boolean;
  versandweg: 'DIGITALE_KUNDENAKTE';
  zeitpunktAuftragserstellung: string;
};

type Historien = {
  historiennummer: number;
  versicherungsstatus: 'ABSCHLUSS_BEANTRAGT';
  versicherungsstatusaenderung: string;
};

export type PaymentMethods =
  | 'JAEHRLICH'
  | 'HALBJAEHRLICH'
  | 'VIERTELJAEHRLICH'
  | 'MONATLICH';

export type Versicherungen = {
  abbuchungstag: number;
  antragseingang: string;
  ausgewaehlt: boolean;
  berechenbar: boolean;
  druckauftragsinformationen: Druckauftragsinformationen[];
  historien: Historien[];
  laufzeit?: string;
  link: {
    methods: [string];
    uri: string;
  };
  statusaenderungsdatum: string;
  struktur: {
    versicherungen: string;
  };
  tarifvariante: string;
  variantennummer: number;
  verkaufsname: string;
  verkaufszeitpunkt: string;
  versicherungsId: string;
  versicherungsbeginn: string;
  versicherungsstatus: 'ABSCHLUSS_BEANTRAGT';
  versicherungssumme?: number;
  werbeaktionsdaten: {
    werbeaktionscode: number;
  };
  wertebereiche: Wertebereiche[];
  workflownummer: string;
  zahlweise: PaymentMethods;
};

export type Vertraege = {
  selbstbehalt?: string;
  struktur: Structure;
  vereinbart: boolean;
  vertragsArt: string;
  vertragsId: string;
  wertebereiche: Wertebereiche[];
};

export type ContractType = 'HAUSRAT' | 'HAUSHALT_GLAS';

export type PreviousDamages =
  | 'EINBRUCHDIEBSTAHL'
  | 'FEUER'
  | 'GLASBRUCH'
  | 'LEITUNGSWASSER'
  | 'RAUB'
  | 'SONSTIGE'
  | 'STURM_ODER_HAGEL'
  | 'VANDALISMUS'
  | 'WEITERE_NATURGEFAHREN';

export type PolicyTerminator =
  | 'GEKUENDIGT_DURCH_KUNDEN'
  | 'GEKUENDIGT_DURCH_VERSICHERER';

export type PreviousDamagePayload = {
  regulierung: 'VOLLSTAENDIG_REGULIERT';
  /**
   * Type of damage
   */
  schadenArt: PreviousDamages;
  /**
   * Year where damage happened
   */
  schadenjahr: number;
  /**
   * Damage cost
   */
  schadensumme: number;
  /**
   * Contract type
   */
  vertragsArt: ContractType;
  /**
   * Policy ID
   */
  vorschadenId: string;
  /**
   * Fields' properties
   */
  wertebereiche: Wertebereiche[];
};

export type PreviousInsurancePayload = {
  /**
   * Reason why the policy was terminated
   */
  status: PolicyTerminator;
  /**
   * End of policy's date
   */
  versicherungsEnde: string;
  /**
   * Contract type
   */
  vertragsArt: ContractType;
  /**
   * Previous Insurance ID
   */
  vorversicherungsId: string;
  /**
   * Previous Policy number
   */
  vsScheinNr: string;
  /**
   * Previous company's Name
   */
  vuName: string;
  /**
   * Previous company's ID
   */
  vuNr: string;
  /**
   * Field's properties
   */
  wertebereiche: Wertebereiche;
};

export enum ElectronicOrMechatronickLocks {
  Unavailable = 'NICHT_VORHANDEN',
  Present = 'VORHANDEN',
}

export type ZuschlaegeNachlaesse = {
  bezeichnung: string;
  struktur: Structure;
  value: number;
  zuschlagNachlassId: string;
  zuschlagNachlasstyp: 'ZUSCHLAG';
  vereinbart: boolean;
};

type InvalidObjekte = {
  attribute: [string];
  struktur: Structure;
};

type Meldungen = {
  code: string;
  invalideObjekte: InvalidObjekte[];
  messageMarkers: [string];
  schweregrad: 'ERROR';
  text: string;
};

export type CreateOfferResponse = {
  angebot: Angebot;
  apiVersion: string;
  baseVersion: string;
  meldungen: Meldungen[];
};

export type Angebot = {
  ablehnungsgrund: string;
  adsId: string;
  aenderungsdatum: string;
  aktionskennzeichen: 'HRE';
  aktionsnummer: string;
  angebotsnummer: string;
  angebotsstatus: 'INITIALISIERT';
  angebotsstatusaenderung: string;
  beitraege?: Beitraege[];
  beratungsprotokollId: string;
  bonitaet: 'POSITIV' | 'NEGATIV';
  buendelId: string;
  businessId: string;
  erstelldatum: string;
  erstellernummer: string;
  gevoArt: 'ERSATZANTRAG';
  historiennummer: string;
  kanalrichtung: 'EINGEHEND';
  kundenberater: {
    kundencentermitarbeiter: boolean;
    nummer: string;
    nummerAntragVersendet: string;
  };
  leistungsvereinbarungen: Leistungsvereinbarungen[];
  mandant: string;
  permitNumber: string;
  quellsystem: 'DAVID';
  risikoOrt: {
    addressId: string;
    alarmierung: string;
    alarmierungOertlich: boolean;
    artDesTresors: 'MEHRW_STAHLSCHRANK_200_KG';
    differenzdeckung: number;
    edSicherungFenster: 'KEINE_SICHERUNG';
    edSicherungHaustuer: 'TUERNICHTVORHANDEN';
    edSicherungSeitenKellerTuer: 'TUER_NICHT_VORHANDEN_RELEVANT';
    edSicherungTerrassenBalkonTuer: 'TUER_NICHT_VORHANDEN';
    elektronischMechatronischesSchloss: ElectronicOrMechatronickLocks;
    emaAufschaltungWachdienst: boolean;
    emaMitVDSAnerkennung: boolean;
    hausnummer: string;
    installationsattest: boolean;
    kellerFensterSchaechte: 'NICHT_VORHANDEN_RELEVANT';
    laendercode: string;
    lageDerEingangstuer: 'ERDGESCHOSS';
    land: string;
    letzteHochstufungSB: string;
    nr: string;
    ort: string;
    ortsteil: string;
    plz: string;
    risikotyp: Risikotyp;
    sicherungsbeschreibungVom: string;
    statusEMA: 'VORHANDEN';
    strasse: string;
    vdsKlasse: 'KLASSE_A';
    wartungsvertrag: 'JAEHRLICHE_UEBERPRUEFUNG';
    wertebereiche: Wertebereiche[];
    widerstandTresor: 'WIDERSTANDSGRAD_NICHT_VORHANDEN';
    wohnflaeche: number;
    /**
     * Zürg Zone
     */
    zuersGefaehrdungsklasse: string;
  };
  rollen: Rollen[];
  sparte: string;
  spcs: boolean;
  tarifgeneration: string;
  ursprungAngebotsnummer: string;
  veredelungsgruende: [string];
  veredelungsnotiz: string;
  verkaufsname: string;
  verkaufsproduktart: string;
  verkaufsprozessart: 'DIREKTABSCHLUSS';
  versandAdresseId: string;
  versandweg: 'POST';
  versicherungen: Versicherungen[];
  vertraege: Vertraege[];
  vertriebskanal: 'TELEFON';
  vertriebsmodell: 'A_MODELL';
  vorschaeden: PreviousDamagePayload[];
  vorversicherungen: PreviousInsurancePayload[];
  vorversicherungsscheinnummer: string;
  vsnrErgoDirekt: string;
  vsnrRisikotraeger: string;
  wertebereiche: Wertebereiche[];
  zuschlaegeNachlaesse: ZuschlaegeNachlaesse[];
};
