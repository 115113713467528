import Modal from '@eg/elements/Modal';
import React, { PropsWithChildren } from 'react';
import './AemModal.scss';

interface Props {
  extendedWidth?: boolean;
  onDismiss: () => void;
  open?: boolean;
  shouldUseIframe?: boolean;
}

const AemModal = ({
  children,
  extendedWidth = true,
  onDismiss,
  open,
  shouldUseIframe = false,
}: PropsWithChildren<Props>): JSX.Element => (
  <Modal
    backdropDismissesModal
    className={['AemModal', shouldUseIframe ? 'AemModal--iframe' : ''].join(
      ' '
    )}
    dismissible
    extendedWidth={extendedWidth}
    onDismiss={onDismiss}
    open={open}
  >
    {children}
  </Modal>
);

export default AemModal;
