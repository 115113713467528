export enum TrackingElementPage7 {
  RBUTTON_SMART = 'RButton_Smart',
  RBUTTON_BEST = 'RButton_Best',
  INFOICON_USP_SUM_INSURANCE = 'InfoIcon_USPVersicherungssumme',
  INFOICON_USP_GROSS_NEGLIGENCE = 'InfoIcon_USPGrobeFahrlaessigkeit',
  INFOICON_USP_SIMPLE_THEFT = 'InfoIcon_USPEinfacherDiebstahl',
  LINK_ALL_SERVICES = 'Link_AlleLeistungen',
  CHECKBOX_SIMPLE_THEFT_ACTIVE = 'CheckBox_EinfacherDiebstahl_Active',
  CHECKBOX_SIMPLE_THEFT_INACTIVE = 'CheckBox_EinfacherDiebstahl_Inactive',
  INFOICON_SIMPLE_THEFT = 'InfoIcon_EinfacherDiebstahl',
  CHECKBOX_HOUSE_AND_FLAT_ACTIVE = 'CheckBox_Schutzbrief_Active',
  CHECKBOX_HOUSE_AND_FLAT_INACTIVE = 'CheckBox_Schutzbrief_Inactive',
  INFOICON_HOUSE_AND_FLAT = 'InfoIcon_Schutzbrief',
  CHECKBOX_GLASS_ACTIVE = 'CheckBox_Glas_Active',
  CHECKBOX_GLASS_INACTIVE = 'CheckBox_Glas_InActive',
  INFOICON_GLASS = 'InfoIcon_Glas',
  CHECKBOX_NATURAL_HAZARDS_ACTIVE = 'CheckBox_WeitereNaturgefahren_Active',
  CHECKBOX_NATURAL_HAZARDS_INACTIVE = 'CheckBox_WeitereNaturgefahren_Inactive',
  INFOICON_NATURAL_HAZARDS = 'InfoIcon_WeitereNaturgefahren',
  CHECKBOX_UNNAMED_DANGER_ACTIVE = 'CheckBox_UnbenannteGefahren_Active',
  CHECKBOX_UNNAMED_DANGER_INACTIVE = 'CheckBox_UnbenannteGefahren_Inactive',
  INFOICON_UNNAMED_DANGER = 'InfoIcon_UnbenannteGefahren',
  CHECKBOX_BICYCLE_ACTIVE = 'CheckBox_Fahrradschutz_Active',
  CHECKBOX_BICYCLE_INACTIVE = 'CheckBox_Fahrradschutz_Inactive',
  INFOICON_BICYCLE = 'InfoIcon_Fahrradschutz',
  RBUTTON_BICYCLE_INSURANCE = 'RButton_Fahrradkasko',
  DROPDOWN_BICYCLE_COINSURANCE = 'Dropdown_Mitversichert ',
  INFOICON_BICYCLE_INSURANCE = 'InfoIcon_Fahrradkasko',
  INFOICON_BICYCLE_INSURANCE_TYPE = 'InfoIcon_FahrradIhreAuswahl',
  RBUTTON_BICYCLE_THEFT = 'RButton_Fahrraddiebstahl',
  INFOICON_BICYCLE_THEFT = 'InfoIcon_Fahrraddiebstahl',
  INFOICON_EXCESS = 'InfoIcon_Selbstbeteiligung',
  INFOICON_PAYMENT_METHOD = 'InfoIcon_Zahlungsweise',
  INFOICON_CONTRACT_TERM = 'InfoIcon_Vertragslaufzeit',
  LINK_DOWNLOAD_PDF = 'Link_DownloadPDF',
  DROPDOWN_BIKE_VALUE = 'Dropdown_Fahrradwert_Selected',
  DROPDOWN_EXCESS = 'Dropdown_Selbstbeteiligung_Selected',
  DROPDOWN_PAYMENT_METHOD = 'Dropdown_Zahlungsweise_Selected',
  DROPDOWN_CONTRACT_TERM = 'Dropdown_Vertragslaufzeit_Selected',
}

export enum TrackingIDs {
  productSelector = 'Product Selector',
  simpleTheft = 'simpleTheft',
  houseAndFlat = 'houseAndFlat',
  otherNaturalHazards = 'otherNaturalHazards',
  unnamedPerils = 'unnamedPerils',
  glass = 'glass',
  bikeModule = 'bike-module',
  fahrradkaskoUndMobilitätsschutz = 'fahrradkaskoUndMobilitätsschutz',
  fahrraddiebstahl = 'fahrraddiebstahl',
  bikeAmount = 'bike-amount',
  deductibleAmount = 'deductible-amount',
  paymentMethod = 'payment-method',
  contractTerm = 'contract-term',
}

export const trackingMapPage7 = new Map<TrackingIDs, string | null>();
trackingMapPage7.set(TrackingIDs.productSelector, 'Tarif');
trackingMapPage7.set(TrackingIDs.simpleTheft, 'EDieb');
trackingMapPage7.set(TrackingIDs.houseAndFlat, 'Schutzb');
trackingMapPage7.set(TrackingIDs.otherNaturalHazards, 'NatG');
trackingMapPage7.set(TrackingIDs.unnamedPerils, 'UnbG');
trackingMapPage7.set(TrackingIDs.glass, 'Glas');
trackingMapPage7.set(TrackingIDs.bikeModule, 'Fahrrad');
trackingMapPage7.set(TrackingIDs.fahrradkaskoUndMobilitätsschutz, 'FaKaDie');
trackingMapPage7.set(TrackingIDs.fahrraddiebstahl, 'FaKaDie');
trackingMapPage7.set(TrackingIDs.bikeAmount, 'FaKaDie');
trackingMapPage7.set(TrackingIDs.deductibleAmount, 'SB');
trackingMapPage7.set(TrackingIDs.paymentMethod, 'Zahlung');
trackingMapPage7.set(TrackingIDs.contractTerm, 'Dauer');
