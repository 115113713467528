export const step8 = {
  pageTitle: 'Individualisieren Sie Ihren Vertrag.',
  pageSubtitle: 'Bitte beachten Sie die vorvertragliche Anzeigepflicht.',
  householdInsuranceSectionTitle: 'Hausratversicherung',
  glassInsuranceSectionTitle: 'Glasversicherung',
  existingDoorLocksSectionTitle: 'Weitere Angaben',
  previousInsurancesInLast5Years: 'Vorversicherungen in den letzten 5 Jahren',
  previousHouseholdInsuranceSubtitle:
    'Bestehen oder bestanden für Ihren zu versichernden Hausrat in den letzten <strong>5 Jahren</strong> andere Hausratversicherungen?',
  companyName: 'Name der Gesellschaft:',
  policyNumber: 'Versicherungsscheinnummer:',
  policyTerminatedBy: 'Gekündigt von:',
  policyHolder: 'Versicherungsnehmer',
  insurer: 'Versicherer',
  contractExpiration: 'Vertragsablauf:',
  previousDamageInLast5Years: 'Schäden in den letzten 5 Jahren',
  previousDamageSubtitle:
    'Gab es in den letzten 5 Jahren Schäden an Ihrem zu versichernden Hausrat (auch nicht versicherte)?',
  previousGlassDamageMainSubtitle:
    'Gab es in den letzten 5 Jahren Schäden an Ihrer zu versichernden Verglasung (auch nicht versicherte)?',
  damageAmountTitle: 'Wie viele Schäden gab es in den letzten 5 Jahren?',
  damageAmountQuestion:
    'Bitte geben Sie an, ob in den letzten 5 Jahren Schäden an Ihrem zu versichernden Hausrat eingetreten sind.',
  damageGlassAmountQuestion:
    'Bitte geben Sie an, ob in den letzten 5 Jahren Schäden an Ihrer zu versichernden Verglasung eingetreten sind.',
  moreThanOne: 'mehr als 1',
  moreThanOneDamageError:
    'Den Tarif können Sie leider nicht online abschließen. Bitte wenden Sie sich an einen ERGO Berater in Ihrer Nähe.',
  previousGlassDamageSubtitle:
    'Bestehen oder bestanden für Ihre zu versichernde Verglasung in den letzten <strong>5 Jahren</strong> andere Glasversicherungen?',
  fire: 'Feuer',
  burglary: 'Einbruchdiebstahl',
  robbery: 'Raub',
  tapWater: 'Leitungswasser',
  storm: 'Sturm/Hagel',
  vandalism: 'Vandalismus',
  otherNaturalHazards: 'Schäden durch "weitere Naturgefahren"',
  other: 'Sonstige/Andere',
  contactAdvisor: 'Berater kontaktieren',
  informationAboutDamageQuestion: 'Bitte machen Sie Angaben zu den Schäden:',
  damageType: 'Schadenart',
  pleaseSelect: 'Bitte auswählen',
  pleaseStateTheAmountOfTheDamage: 'Bitte geben Sie die Höhe des Schadens an.',
  pleaseFill: 'Bitte ausfüllen',
  damageAmount: 'Schadenhöhe',
  errorExeed:
    'Den Tarif können Sie leider nicht online abschließen. Bitte wenden Sie sich an einen ERGO Berater in Ihrer Nähe.',
  errorGlassExeed:
    'Den Tarif können Sie leider nicht online abschließen. Bitte wenden Sie sich an einen ERGO Berater in Ihrer Nähe.',
  damageYear: 'Schadenjahr',
  glassBreakage: 'Glasbruch',
  existingDoorLocks: 'Elektronische oder mechatronische Türschlösser',
  existingDoorLocksQuestion:
    'Ist Ihre Wohnung mit elektronischen bzw. mechatronischen Schlössern ausgestattet?',
  existingDoorLocksQuestionInfo: {
    title:
      'Einbruchdiebstahl bei installierten elektronischen bzw. mechatronischen Schlössern',
    paragraph1: `Elektronische Schlösser werden mithilfe eines
    <strong>elektronischen Signals</strong>
    geöffnet. Mechatronische Schlösser prüfen die Schließberechtigung über
    den <strong>mechanischen Schlüssel</strong> und zusätzlich über eine
    <strong>elektronische Identifikation</strong>.`,
    paragraph2: `Bei einem Einbruchdiebstahl in Ihre Wohnung müssen Sie dem Versicherer
    <strong>glaubhaft nachweisen</strong>, dass in Ihre Wohnung eingebrochen wurde.
    Das ist bei elektronischen und mechatronischen Schlössern schwierig.`,
    paragraph3: `<strong>
    Wenn Sie ein elektronisches oder mechatronisches Schloss haben, gilt
    daher:
  </strong>
  Ist der <strong>Nachweis</strong> der Manipulation des elektronischen bzw.
  mechatronischen Schlosses an Ihrer Wohnungstür <strong>nicht möglich</strong>, so
  gilt als Einbruchdiebstahl, wenn der Sachverhalt (Spurenbild) mit
  hinreichender Wahrscheinlichkeit auf einen Einbruchdiebstahl schließen
  lässt.`,
    paragraph4: `Diese nachweiserleichternde Vereinbarung wird in Ihren
    Versicherungsschein eingedruckt.`,
  },
  pleaseSelectPreviousInsuranceRadio:
    'Bitte geben Sie an, ob für Ihren zu versichernden Hausrat andere Hausratversicherungen bestehen oder bestanden.',
  pleaseSelectPreviousInsuranceDamageRadio:
    'Bitte geben Sie an, ob in den letzten 5 Jahren Schäden an Ihrem zu versichernden Hausrat eingetreten sind.',
  pleaseSelectPreviousGlassInsuranceRadio:
    'Bitte geben Sie an, ob für Ihre zu versichernde Verglasung andere Glasversicherungen bestehen oder bestanden.',
  pleaseSelectPreviousGlassInsuranceDamageRadio:
    'Bitte geben Sie an, ob in den letzten 5 Jahren Schäden an Ihrer zu versichernden Verglasung eingetreten sind.',
  pleaseSelectDoorLocksRadio:
    'Bitte geben Sie an, ob Ihre Wohnung über elektronische bzw. mechatronische Türschlösser verfügt.',
  companyNameError: 'Die angegebene Vorversicherung ist nicht gültig.',
  companyNameNoEntryError: 'Bitte geben Sie den Namen der Gesellschaft an.',
  policyNumberError: 'Bitte geben Sie die Versicherungsscheinnummer an.',
  policyTerminatorError: 'Bitte geben Sie an, wer gekündigt hat.',
  endDateError: 'Bitte geben Sie den Vertragsablauf im Format TT.MM.JJJJ an.',
  endDateNotWithinTheLast5YearsError:
    'Bitte geben Sie ein Datum innerhalb der letzten 5 Jahre an.',
  endDateInvalidError:
    'Bitte geben Sie ein gültiges Datum innerhalb der letzten 5 Jahre an.',
  damageAmountError: 'Bitte geben Sie die Anzahl der Vorschäden an.',
  damageTypeError: 'Bitte geben Sie die Art des Schadens an.',
  damageCostError: 'Bitte geben Sie die Höhe des Schadens an.',
  damageYearError:
    'Bitte geben Sie das Jahr an, in dem der Schaden passiert ist.',
  dateMismatch:
    'Bitte überprüfen Sie den Vertragsablauf der Vorversicherungen für Hausrat- und Glasversicherung. Sie haben unterschiedliche Angaben gemacht.',
  terminationMismatch:
    'Bitte überprüfen Sie, wer die Vorversicherungen für Hausrat- und Glasversicherung gekündigt hat. Sie haben unterschiedliche Angaben gemacht.',
  glassInsuranceSelectedError:
    'Bitte geben Sie an, ob für Ihre zu versichernde Verglasung andere Glasversicherungen bestehen oder bestanden.',
  existingDoorLocksSelectedError:
    'Bitte geben Sie an, ob Ihre Wohnung über elektronische bzw. mechatronische Türschlösser verfügt.',
  modalTitle:
    'Wichtige Mitteilung zu den Folgen einer Anzeigepflichtverletzung',
  subInfoTitle1:
    'Werden die in Textform gestellten Fragen, soweit sie für die Übernahme der Gefahr erheblich sind, <strong>nicht wahrheitsgemäß</strong> oder <strong>nicht vollständig</strong> beantwortet, kann ERGO den Vertrag unter Einhaltung einer Frist von einem Monat <strong>kündigen</strong>.',
  subInfoTitle2:
    'Bei Vorsatz oder grober Fahrlässigkeit kann ERGO sogar von dem Vertrag <strong>zurücktreten</strong>. Sie haben dann von Anfang an keinen Versicherungsschutz, es sei denn, durch die Verletzung der Anzeigepflicht ist ERGO kein Nachteil entstanden.',
  subInfoTitle3:
    'Das Rücktritts- und Kündigungsrecht des Versicherers ist – außer bei Vorsatz – ausgeschlossen, wenn ERGO den Vertrag auch bei Kenntnis der nicht angezeigten Umstände geschlossen hätte, wenn auch zu anderen Bedingungen.',
  subInfoTitle4:
    'Die <strong>Vertragsanpassung</strong>, etwa in Form eines Risikoausschlussesoder einer Beitragserhöhung, wird auf Verlangen von ERGO <strong>rückwirkend wirksam</strong>, bei einer von Ihnen nicht zu vertretenden Pflichtverletzung ab der laufenden Versicherungsperiode. Durch die rückwirkende Einfügung eines Risikoausschlusses kann Ihr Versicherungsschutz für einen eingetretenen oder zukünftigen Versicherungsfall ebenfalls entfallen.',
};
