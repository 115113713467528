import React from 'react';

const Grid = ({
  className = '',
  children,
}: {
  className?: string;
  children: React.ReactElement;
}): JSX.Element => {
  return (
    <div className={`${className} esc_grid esc_grid__wrapper`}>{children}</div>
  );
};

export default Grid;
