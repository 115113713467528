import { rest } from 'msw';
import { Handler } from '../../../types';
import { getBankDataFromIban, getUrl, deleteBankData } from '../constants';

export const getBankDataFromIbanHandler = (
  rest[getBankDataFromIban.method.toLowerCase()] as Handler
)(getUrl(getBankDataFromIban.path), (req, res, ctx) => {
  const { iban } = req.body as unknown as Record<string, string>;
  const { personId } = req.params;

  return res(
    ctx.status(201),
    ctx.json({
      contextDetails: {
        businessId: '08d098a0s98d0as98d09a8s0d98as0',
        creationTime: 123456789,
        personId,
      },
      data: {
        persons: [
          {
            payments: [
              {
                bankdata: [
                  {
                    id: 'BankId',
                    iban,
                    bic: 'BankBic',
                    financialInstitutionName: 'Bank Name',
                    city: 'Berlin',
                    mrn: '',
                  },
                ],
              },
            ],
          },
        ],
      },
      responseDetails: {},
    })
  );
});

export const deleteBankDataHandler = (
  rest[deleteBankData.method.toLowerCase()] as Handler
)(getUrl(deleteBankData.path), (req, res, ctx) => {
  return res(
    ctx.status(201),
    ctx.json({
      contextDetails: {
        businessId: '08d098a0s98d0as98d09a8s0d98as0',
        creationTime: 123456789,
      },
      data: {
        persons: [
          {
            payments: [
              {
                bankdata: [],
              },
            ],
          },
        ],
      },
      responseDetails: {},
    })
  );
});
