import { DateInputValue } from '@eg/elements/DateInput';
import { isValidDateObject, validateBirthDate } from 'commons';
import { isEmptyObject } from '../objects/objects';
import { isEmptyString } from '../strings/strings';
import type {
  AddDates,
  AddMonths,
  AddYears,
  DifferenceBetweenDates,
  TimestampBetweenTwoDates,
  IsDateOlderThanParams,
} from './dateTypes';

export const addDates: AddDates = ({ datesToAdd, from, resetTime = false }) => {
  let date = from ? new Date(from.getTime()) : new Date(Date.now());

  const timeToAdd = datesToAdd * 24 * 60 * 60 * 1000;
  const resultingTime = date.getTime() + timeToAdd;
  date.setTime(resultingTime);

  if (resetTime) {
    date.setHours(0, 0, 0, 0);
  }

  const dateISO = date.toISOString().replace(/\.[0-9]{3,3}Z/, '.000Z');
  date = new Date(dateISO);

  return date;
};

export const addMonths: AddMonths = ({ monthsToAdd, from, resetTime }) => {
  let date = from ? new Date(from.getTime()) : new Date(Date.now());

  const currMonth = date.getMonth() + 1;
  const totalMonths = currMonth + monthsToAdd;

  const additionalYears = Math.trunc(totalMonths / 12);
  const resultingMonth = totalMonths - additionalYears * 12;

  if (additionalYears) {
    date = addYears({ yearsToAdd: additionalYears, from: date });
  }

  date.setMonth(resultingMonth - 1);

  if (resetTime) {
    date.setHours(0, 0, 0, 0);
  }

  return date;
};

export const addYears: AddYears = ({ yearsToAdd, from }) => {
  const date = from ? new Date(from.getTime()) : new Date(Date.now());

  date.setFullYear(date.getFullYear() + yearsToAdd);

  return date;
};

export const nextMonthFirstDay = (from = new Date(Date.now())): Date => {
  const date = new Date(from.getTime());

  const currMonth = date.getMonth() + 1;
  if (currMonth === 12) {
    date.setFullYear(date.getFullYear() + 1, 0, 1);
  } else {
    date.setMonth(date.getMonth() + 1, 1);
  }

  return date;
};

export const dateToDateInputValue = (date: Date): DateInputValue => {
  if (!isValidDateObject(date)) {
    return {
      day: '0',
      month: '0',
      year: '0',
    };
  }

  return {
    day: String(date.getDate()).padStart(2, '0'),
    month: String(date.getMonth() + 1).padStart(2, '0'),
    year: String(date.getFullYear()).padStart(4, '0'),
  };
};

export const dateInputValueToDate = (
  dateInputValue: DateInputValue
): Date | null => {
  if (isEmptyObject(dateInputValue)) {
    return null;
  }

  if (
    Object.keys(dateInputValue).length !== 3 ||
    Object.values(dateInputValue).some(value => isEmptyString(value))
  ) {
    return null;
  }

  const date = new Date(
    Number(dateInputValue.year),
    Number(dateInputValue.month) - 1,
    Number(dateInputValue.day)
  );

  return date;
};

export const dateFormatted = (date: Date, format = 'YYYY-MM-DD'): string => {
  if (!isValidDateObject(date) || !format) {
    return '';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  if (format === 'YYYY-MM-DD') {
    return `${year}-${month}-${day}`;
  }
};

export const yearFirstFormat = (
  dateInputValue: DateInputValue,
  format = 'YYYY.MM.DD'
): string => {
  if (isEmptyObject(dateInputValue)) {
    return null;
  }

  if (
    Object.keys(dateInputValue).length !== 3 ||
    Object.values(dateInputValue).some(value => isEmptyString(value))
  ) {
    return null;
  }

  const day = dateInputValue.day.padStart(2, '0');
  const month = dateInputValue.month.padStart(2, '0');
  const year = dateInputValue.year.padStart(4, '0');

  if (format === 'YYYY.MM.DD') {
    return `${year}.${month}.${day}`;
  }
  if (format === 'YYYY-MM-DD') {
    return `${year}-${month}-${day}`;
  }
};

export const dateFirstFormat = (
  dateInputValue: DateInputValue,
  format = 'DD.MM.YYYY'
): string => {
  if (isEmptyObject(dateInputValue)) {
    return null;
  }

  if (
    Object.keys(dateInputValue).length !== 3 ||
    Object.values(dateInputValue).some(isEmptyString)
  ) {
    return null;
  }

  const day = dateInputValue.day.padStart(2, '0');
  const month = dateInputValue.month.padStart(2, '0');
  const year = dateInputValue.year.padStart(4, '0');

  if (format === 'DD.MM.YYYY') {
    return `${day}.${month}.${year}`;
  }
};

export const timestampBetweenTwoDates: TimestampBetweenTwoDates = ({
  startDate,
  endDate,
  resetTime = false,
}) => {
  if (!isValidDateObject(startDate) || !isValidDateObject(endDate)) {
    return 0;
  }

  const copyOfStartDate = new Date(startDate.getTime());
  if (resetTime) {
    copyOfStartDate.setHours(0);
    copyOfStartDate.setMinutes(0);
    copyOfStartDate.setSeconds(0);
  }

  const copyOfEndDate = new Date(endDate.getTime());
  if (resetTime) {
    copyOfEndDate.setHours(0);
    copyOfEndDate.setMinutes(0);
    copyOfEndDate.setSeconds(0);
  }

  const difference = copyOfEndDate.getTime() - copyOfStartDate.getTime();

  return difference;
};

export const differenceBetweenDates: DifferenceBetweenDates = ({
  startDate,
  endDate,
  resetTime = false,
}) => {
  if (!isValidDateObject(startDate) || !isValidDateObject(endDate)) {
    return {
      days: 0,
      months: 0,
      years: 0,
    };
  }

  const differenceInTimestamp = timestampBetweenTwoDates({
    startDate,
    endDate,
    resetTime,
  });

  const seconds = differenceInTimestamp / 1000;

  const days = Math.floor(seconds / 86400);

  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();

  const years = Math.floor(months / 12);

  return {
    days,
    months,
    years,
  };
};

export const isBetween18and35yearsOld = (
  dateOfBirth: DateInputValue,
  comparisionDate = new Date(Date.now())
): boolean => {
  if (
    !dateOfBirth.day ||
    !dateOfBirth.month ||
    !dateOfBirth.year ||
    !isValidDateObject(comparisionDate)
  ) {
    return false;
  }

  comparisionDate.setHours(0, 0, 0, 0);

  const birthDate = new Date(Date.now());
  //Set the birthDate based on argument
  birthDate.setHours(0, 0, 0, 0);
  birthDate.setDate(Number(dateOfBirth.day));
  birthDate.setMonth(Number(dateOfBirth.month) - 1);
  birthDate.setFullYear(Number(dateOfBirth.year));

  const is36orOlder = validateBirthDate(
    birthDate.toISOString(),
    36,
    comparisionDate
  );
  const is18 = validateBirthDate(birthDate.toISOString(), 18, comparisionDate);

  return is18 && !is36orOlder;
};

export const isDateOlderThan = ({
  startDate,
  dateToCheck,
  resetTime = false,
}: IsDateOlderThanParams): boolean => {
  if (!isValidDateObject(startDate) || !isValidDateObject(dateToCheck)) {
    return false;
  }

  const copyOfStartDate = new Date(startDate.getTime());
  if (resetTime) {
    copyOfStartDate.setHours(0, 0, 0, 0);
  }

  const copyOfDateToCheck = new Date(dateToCheck.getTime());
  if (resetTime) {
    dateToCheck.setHours(0, 0, 0, 0);
  }

  return copyOfDateToCheck.getTime() < copyOfStartDate.getTime();
};
