import { useState, ChangeEvent } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import {
  setPrefixNumber,
  setTelephoneNumber,
  setValidationError,
} from '../../personalDataSlice';
import {
  PersonalDataForm,
  useTelephoneNumberTypes,
} from '../../PersonalDataTypes';
import translations from '../../../../constants/translations';

const useTelephoneNumber = (
  form: PersonalDataForm
): useTelephoneNumberTypes => {
  const dispatch = useAppDispatch();
  const [errorMessageTelephone, setErrorMessageTelephone] = useState('');
  const { prefixNumber, telephoneNumber } = form;

  const handleTelephoneValidation = (): void => {
    const telephoneNumberValidationRegex = /^\d{6,14}$/g;
    if (
      telephoneNumber &&
      !telephoneNumberValidationRegex.test(telephoneNumber)
    ) {
      setErrorMessageTelephone(translations.step9.telephoneInvalid);
    } else {
      setErrorMessageTelephone('');
    }
  };

  const handleChangePrefix = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setPrefixNumber(e.target.value));
  };

  const handleChangeTelephone = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setValidationError(''));
    setErrorMessageTelephone('');
    dispatch(setTelephoneNumber(e.target.value));
  };

  return {
    handleChangeTelephone,
    handleChangePrefix,
    handleTelephoneValidation,
    prefixNumber,
    telephoneNumber,
    errorMessageTelephone,
    setErrorMessageTelephone,
  };
};

export default useTelephoneNumber;
