export const step4 = {
  pageTitle: 'Wo wohnen Sie?',
  pageSubtitle:
    'Ihre Adresse ist notwendig, um Ihren Versicherungsbeitrag zu ermitteln.',

  addressCorrectedHint: 'Hinweis',
  addressCorrected:
    'Ihre Eingabe wurde korrigiert. Bitte prüfen Sie, ob es sich um Ihre richtige Anschrift handelt.',
  addressTooltipTitle: 'Adresse',
  addressTooltipMsg1:
    'Die genannte Adresse muss mit der zu versichernden Wohnung identisch sein.',
  addressTooltipMsg2:
    'Ihre Adresse wird nicht angezeigt? Bitte wenden Sie sich an einen ERGO Berater in Ihrer Nähe oder rufen Sie an.',

  streetNameLabel: 'Straße',
  streetNamePlaceholder: 'z. B. Musterstraße',
  //streetNameError: '[EN] Please, write a valid street',
  streetNameError: 'Bitte geben Sie die Straße an.',

  streetNumberLabel: 'Hausnummer',
  streetNumberPlaceholder: 'z. B. 27b',
  streetNumberError: 'Bitte geben Sie die Hausnummer an.',

  zipCodeLabel: 'Postleitzahl',
  zipCodePlaceholder: 'PLZ',
  zipCodeErrorInvalid: 'Bitte geben Sie eine gültige Postleitzahl an.',

  cityLabel: 'Wohnort',
  cityPlaceholder: 'wird automatisch ermittelt',
  //cityError: '[EN] Please, select a city from the list',
  cityError: 'Bitte wählen Sie eine Stadt aus der Liste',

  //addressError: '[EN] Please, write a valid address',
  addressError: 'Bitte geben Sie eine gültige Adresse an',

  // Confirmation Modal in step 4
  modalTitle: 'Unbekannte Anschrift',
  modalp1:
    'Bei der Prüfung der Adressdaten konnte keine Anschrift ermittelt werden. Ein Online-Abschluss ist aber nur mit einer gültigen Anschrift möglich.',
  modalDescription: 'Sie haben eingegeben:',
  modalp2:
    'Bitte korrigieren Sie Ihre Eingabe und versuchen Sie es noch einmal.',
  modalp3: (isAgentPage: boolean): string =>
    `Sie möchten eine Hausratversicherung für genau die oben genannte Anschrift abschließen? Dann lassen Sie sich telefonisch beraten${
      isAgentPage ? '' : ' unter 0800 3746 180'
    }.`,
  button1: 'Eingabe korrigieren',
  button2: '0800 / 3746 180',
};
