import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Angebot } from 'commons/apis/offerEngine';
import { Person } from 'commons/apis/spcs/persons/types';
import { OfferState } from './types';

const initialState: OfferState = {
  angebot: undefined,
  person: undefined,
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    saveOffer: (state, action: PayloadAction<Angebot>) => {
      state.angebot = action.payload;
    },
    savePerson: (state, action: PayloadAction<Person>) => {
      state.person = action.payload;
    },
  },
});

export const { saveOffer, savePerson } = offerSlice.actions;
