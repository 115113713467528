import { rest } from 'msw';
import { Handler } from '../../../types';
import { getInsuranceCompanies, getUrl } from '../constants';

export const getInsuranceCompaniesHandler = (
  rest[getInsuranceCompanies.method.toLocaleLowerCase()] as Handler
)(getUrl(getInsuranceCompanies.path), async (req, res, ctx) => {
  const response = await import('./utils/companiesOkResponse.json');

  return res(ctx.status(200), ctx.json(response));
});

export const insuranceCompaniesHandlers = [getInsuranceCompaniesHandler];
