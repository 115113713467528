import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';
import translations from '../../../constants/translations';
import { ModalProps } from './types';
import { InfoIcons } from '../TrackingTypes';

const { step7 } = translations;

const content = (
  <div data-testid="excess-info">
    <h3 data-testid="excess-title">{step7.excess.title}</h3>
    <p
      data-testid="excess-p1"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph1,
      }}
    ></p>
    <p
      data-testid="excess-p2"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph2,
      }}
    ></p>
    <p
      data-testid="excess-p3"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph3,
      }}
    ></p>
    <p
      data-testid="excess-p4"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph4,
      }}
    />
    <p
      data-testid="excess-p5"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph5,
      }}
    ></p>
    <p
      data-testid="excess-p6"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph6,
      }}
    ></p>
    <p
      data-testid="excess-p7"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph7,
      }}
    ></p>
    <p
      data-testid="excess-p8"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph8,
      }}
    ></p>
    <p
      data-testid="excess-9"
      dangerouslySetInnerHTML={{
        __html: step7.excess.paragraph9,
      }}
    ></p>
  </div>
);

const Excess = ({ onOpen }: ModalProps): JSX.Element => {
  const onToggleHandler = (isOpen: boolean): void =>
    isOpen && onOpen(InfoIcons.EXCESS);

  return (
    <TooltipIcon isModal onToggledOpen={onToggleHandler}>
      {content}
    </TooltipIcon>
  );
};

export { Excess };
