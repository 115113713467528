import React from 'react';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { ERadioGroupProps } from './ERadioGroup.d';
import './ERadioGroup.scss';

/** ERGO elements' radio button group row wrapped with tracking */
const ERadioGroup = ({
  className,
  children,
  'data-testid': dataTestId,
  error,
  label,
  name,
  onChange,
  placeholder,
  tooltip,
  trackingOptions,
  value,
}: React.PropsWithChildren<ERadioGroupProps>): JSX.Element => {
  const trackedOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // TODO, here we'll have the tracking dispatch logic, when it's shaped
    console.log('!!!!-----ಠಠ 🕵️ trackingOptions ------>', trackingOptions); // eslint-disable-line
    return onChange(e);
  };

  return (
    <RadioGroupRow
      className={`RadioGroup ${className}`}
      data-testid={dataTestId}
      error={error}
      label={label}
      name={name}
      onChange={trackingOptions ? trackedOnChange : onChange}
      placeholder={placeholder}
      tooltip={tooltip}
      value={value}
    >
      {children}
    </RadioGroupRow>
  );
};

export default ERadioGroup;
