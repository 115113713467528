import { ServerRoute } from '../../../types';

export const getCitiesFromAddress: ServerRoute = {
  method: 'GET',
  path: '/address/search/city?consumerKey=consumerKey&zipCode=:zipCode&street=:streetName',
};

export const validateAddress: ServerRoute = {
  method: 'POST',
  path: '/address/validation/location?consumerKey=consumerKey',
};

export const getUrl = (path: string): string => {
  const baseUrl = process.env.ADDRESSVALIDATION_URL;
  return `${baseUrl}${path}`;
};
