import React, { useEffect, useState, useRef } from 'react';
import FormFooter from '@eg/elements/FormFooter';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import AdditionalQuestionsOptions from './AdditionalQuestionsOptions';
import {
  additionalQuestionsFetchValidation,
  additionalQuestionsSelector,
  selectIsLoading,
} from './additionalQuestionsSlice';
import './AdditionalQuestions.scss';
import { useFormValidations } from '../../hooks/useFormValidations';
import { useAdditionalQuestionsValidator } from './utils/validations/additionalQuestionsForm';
import translations from '../../constants/translations';
import useStepNavigation from '../../hooks/useStepNavigation';
import AdditionalQuestionsInfo from './components/AdditionalQuestionsInfo';
import { useAppSelector } from '../../store/hooks';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import useClickTracking from './hooks/useClickTracking';
import { useForm } from '../../store/useForm';
import { useSelectionErrors } from './components/hooks/useSelectionErrors';
import { trackFrontendError } from '../../utils/errorHandler';
import {
  isInvalidGlassInsuranceDate,
  isInvalidTerminatedPerson,
} from './utils/validations/previousGlassInsurance';

const AdditionalQuestions = (): JSX.Element => {
  const { dispatch } = useForm();
  const tackingErrors = new Set([]);
  const [updateTracking, setUpdateTracking] = useState(false);
  const isError = useAppSelector(getGenericErrorSelector);
  const formData = useAppSelector(additionalQuestionsSelector);
  const isLoading = useAppSelector(selectIsLoading);
  const { goNext, goPrev } = useStepNavigation();

  const additionalQuestionsFormValidations = useAdditionalQuestionsValidator();

  const validations = useFormValidations(
    formData,
    additionalQuestionsFormValidations
  );
  const { trackingOptions, onClickTracking } = useClickTracking();

  const { isGoNextClicked, setIsGoNextValue } = useSelectionErrors();
  const householdInsuranceRef = useRef(null);
  const householdDamageRef = useRef(null);
  const glassInsuranceRef = useRef(null);
  const glassDamageRef = useRef(null);
  const existingDoorLockRef = useRef(null);

  const isFormValid = !Object.values(validations).find(
    result => result?.isValid === false
  );

  const updateTrackingErrors = (error: string): void => {
    if (error) {
      tackingErrors.add(error);
    }
  };
  const isCheckedMoreThanOneDamage =
    (formData.previousHouseholdDamage?.selected === true &&
      formData.previousHouseholdDamage?.number === 'moreThanOne') ||
    (formData.previousGlassDamage?.selected === true &&
      formData.previousGlassDamage?.number === 'moreThanOne');
  useEffect(() => {
    if (isGoNextClicked || isCheckedMoreThanOneDamage) {
      tackingErrors.forEach(errorItem => {
        trackFrontendError({ errorMessages: errorItem });
      });
    }
  }, [updateTracking, isCheckedMoreThanOneDamage]);

  const validatePage = (): void => {
    setUpdateTracking(!updateTracking);
    setIsGoNextValue(true);

    if (
      isInvalidGlassInsuranceDate(
        formData.previousInsurance,
        formData.previousGlassInsurance
      ) ||
      isInvalidTerminatedPerson(
        formData.previousInsurance,
        formData.previousGlassInsurance
      )
    ) {
      return;
    }
    if (!isLoading && isFormValid) {
      dispatch(additionalQuestionsFetchValidation())
        .unwrap()
        .then(() => {
          goNext();
        });
    }

    if (!validations?.hasElectronicOrMechatronicLocks?.isValid) {
      existingDoorLockRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (!validations?.previousGlassDamage?.isValid) {
      glassDamageRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (!validations?.previousGlassInsurance?.isValid) {
      glassInsuranceRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (!validations?.previousHouseholdDamage?.isValid) {
      householdDamageRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (!validations?.previousInsurance?.isValid) {
      householdInsuranceRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const goToBackPage = (): void => {
    goPrev();
  };

  return (
    <FormSection className="form-section additional-questions">
      <FormSectionHeader
        title={translations.step8.pageTitle}
        subtitle={translations.step8.pageSubtitle}
        showTooltip
        toolTipComponent={<AdditionalQuestionsInfo />}
      />
      <FormSectionBody className="form-section-body--small-padding">
        <AdditionalQuestionsOptions
          isGoNextClicked={isGoNextClicked}
          onClick={onClickTracking}
          updateTrackingErrors={updateTrackingErrors}
          householdInsurance={householdInsuranceRef}
          householdDamage={householdDamageRef}
          glassInsurance={glassInsuranceRef}
          glassDamage={glassDamageRef}
          existingDoorLock={existingDoorLockRef}
        />
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={validatePage}
          onClickCancel={goToBackPage}
          disableContinue={isCheckedMoreThanOneDamage}
          isLoading={isLoading}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default AdditionalQuestions;
