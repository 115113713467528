import { offerEngineHttp } from '../../http/offerEngineHttp';
import {
  createOffer as createOfferRoute,
  getUrl,
  updateOffer as updateOfferRoute,
  createPreviousInsurance as createPreviousInsuranceRoute,
  createPreviousDamage as createPreviousDamageRoute,
  isOfferLocked as isOfferLockedRoute,
  deletePreviousDamage as deletePreviousDamageRoute,
  deletePreviousInsurace as deletePreviousInsuraceRoute,
} from '../constants';
import {
  CreateOfferParams,
  CreateOfferResponse,
  PreviousDamagePayload,
  PreviousInsurancePayload,
} from './types';

export const createOffer = async (
  payload: CreateOfferParams
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = createOfferRoute;
    const url = getUrl(path);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
      payload,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateOffer = async (
  businessId: string,
  payload?: Record<string, unknown>
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = updateOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
      payload,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const readOffer = async (
  businessId: string
): Promise<CreateOfferResponse> => {
  // try {
  //   const { method, path } = readOfferRoute;
  //   const url = getUrl(path).replace(':businessId', businessId);
  //   const data = await offerEngineHttp<CreateOfferResponse>(url, {
  //     method,
  //   });
  //   return data;
  // } catch (error) {
  //   throw error;
  // }

  return await updateOffer(businessId, {});
};

export const updateVertrag = async (
  businessId: string,
  variantIds: string[],
  value: boolean
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = updateOfferRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
      payload: {
        vertraege: [
          {
            struktur: {
              versicherungen: variantIds[0],
            },
            vereinbart: value,
            vertragsId: 'HRG',
          },
          {
            struktur: {
              versicherungen: variantIds[1],
            },
            vereinbart: value,
            vertragsId: 'HRG',
          },
        ],
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createPreviousInsurance = async (
  businessId: string,
  payload: Partial<PreviousInsurancePayload>[]
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = createPreviousInsuranceRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
      payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createPreviousDamage = async (
  businessId: string,
  payload: Partial<PreviousDamagePayload>[]
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = createPreviousDamageRoute;
    const url = getUrl(path).replace(':businessId', businessId);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
      payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deletePreviousInsurance = async (
  businessId: string,
  previousInsuranceId: string
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = deletePreviousInsuraceRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':previousInsuranceId', previousInsuranceId);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deletePreviousDamage = async (
  businessId: string,
  previousDamageId: string
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = deletePreviousDamageRoute;
    const url = getUrl(path)
      .replace(':businessId', businessId)
      .replace(':previousDamageId', previousDamageId);

    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const isOfferLocked = async (
  businessId: string,
  payload?: Record<string, unknown>
): Promise<CreateOfferResponse> => {
  try {
    const { method, path } = isOfferLockedRoute;
    const url = getUrl(path).replace(':businessId', businessId);
    const data = await offerEngineHttp<CreateOfferResponse>(url, {
      method,
      payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
