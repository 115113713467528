export type Message = {
  code: string;
  messages: string[];
};

export type Address = {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
};

export enum Validity {
  INVALID = 'INVALID',
  VALID = 'VALID',
  CORRECTED = 'CORRECTED',
}

export type UncorrectedAddress = {
  validity: Validity.INVALID | Validity.VALID;
  correlationId: string;
  message: Message;
  correctedAddress: null | {
    zipCode: string;
    city: string;
    street: string;
    municipalityKey: string;
  };
};

export type CorrectedAddress = {
  validity: Validity.CORRECTED;
  correlationId: string;
  message: Message;
  correctedAddress: {
    zipCode: string;
    city: string;
    street: string;
    municipalityKey: string;
  };
};

export type CustomerAddressResponse = UncorrectedAddress | CorrectedAddress;

export type ValidateAddressParam = {
  streetName: string;
  streetNumber: string;
  zipCode: string;
  city: string;
};

export enum SearchStatus {
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
}

export type GetCitiesFromAddressResponse = {
  statusCode: SearchStatus;
  correlationId: string;
  message: Message;
  matchingAddresses: {
    zipCode: string;
    city: string;
    street: string;
    municipalityKey: string;
  }[];
};
