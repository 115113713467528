import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import RadioTabs from '@eg/elements/RadioTabs';
import RadioTab from '@eg/elements/RadioTab';
import React from 'react';
import { useAemModal } from '../../api/aemIntegration/hooks/aemModal';
import InformationRow from '../../components/InformationRow/InformationRow';
import { useForm } from '../../store/useForm';
import translations from '../../constants/translations';
import InsuranceCoveragePayment from './InsuranceCoveragePayment';
import ModuleBenefits from './ModuleBenefits';
import type {
  CoverageModuleType,
  CoverageProductVariantType,
} from './InsuranceCoverageTypes';
import ModuleCycleAndEBike from './ModuleCycleAndEBike';
import { bestSpecialFeatures, smartSpecialFeatures } from './data';
import {
  setCoverageProductVariant,
  setEbikeCheckbox,
} from './insuranceCoverageSlice';
import ComparativeServicesButtonRender from './components/ComparativeServicesButtonRender';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import {
  getVariantsFromOffer,
  InsuranceCoverageFields,
  getInsuredAmountFromOffer,
} from '../../store/offerSlice';
import { useSelector } from 'react-redux';

import './InsuranceCoverage.scss';
import Price from '../../components/Price/Price';
import SumInsured from './modals/SumInsured';
import { AemAssetsKeys } from 'commons';
import { InfoIcons } from './TrackingTypes';
import { useInsuranceFromOffer } from './hooks/useInsuranceFromOffer';

type Props = {
  fields: InsuranceCoverageFields;
  handleChangeOption: (
    id: string,
    selected: string | boolean,
    value?: number | string
  ) => void;
  isLoading: boolean;
  onOpenInfoIcon?: (key: InfoIcons) => void;
};

export const InsuranceCoverageOptions = ({
  fields,
  handleChangeOption,
  isLoading,
  onOpenInfoIcon,
}: Props): JSX.Element => {
  const variants = useSelector(getVariantsFromOffer());
  const { coverageProductVariant, dispatch, ebikeCheckbox } = useForm();
  const { productModules } = useInsuranceFromOffer();
  const { openModal, render } = useAemModal({
    fragmentName: AemAssetsKeys.PRODUCT_TABLE,
    shouldUseIframe: true,
  });

  const moduleIsChecked = (moduleName: CoverageModuleType): boolean => {
    return productModules?.includes(moduleName) || ebikeCheckbox;
  };

  const handleChangeVariant: React.FormEventHandler<HTMLDivElement> = (
    event
  ): void => {
    const target = event.target as HTMLInputElement;
    const id = target.name;
    const selected = target.value as CoverageProductVariantType;
    const { tarifvariante: value } = variants.find(
      variant => variant.versicherungsId === selected
    );
    dispatch(setCoverageProductVariant(selected));
    dispatch(setEbikeCheckbox(false));
    handleChangeOption(id, selected, value);
  };

  const handleChangeVariantTariff = (
    id: string,
    selected: boolean,
    value?: number
  ): void => {
    handleChangeOption(id, selected, value);
  };

  const handleChangeBike = (
    id: string,
    selected: boolean,
    value?: number
  ): void => {
    handleChangeOption(id, selected, value);
  };

  const handleInfoIcons = (key: InfoIcons): void => {
    onOpenInfoIcon && onOpenInfoIcon(key);
  };

  const handleFirstUSPModal = (): void => {
    handleInfoIcons('sumInsurance' as InfoIcons);
  };

  const handleLinkOpenModal = (): void => {
    handleInfoIcons(InfoIcons.LINK_ALL_SERVICES);
    openModal();
  };

  const sumInsuredData = useSelector(getInsuredAmountFromOffer);

  return (
    <>
      {!!variants.length && !!coverageProductVariant && (
        <div className="insurance-coverage__options">
          <RadioTabs
            currentValue={coverageProductVariant}
            onChange={handleChangeVariant}
            name="Product Selector"
          >
            {variants.map(variant => {
              const isBest = variant?.tarifvariante?.toLowerCase() === 'best';

              const title = isBest
                ? translations.step7.extraProtection
                : translations.step7.powerfulHeading;

              const features = isBest
                ? bestSpecialFeatures
                : smartSpecialFeatures;

              return (
                <RadioTab
                  key={variant.versicherungsId}
                  title={variant.tarifvariante}
                  value={variant.versicherungsId}
                >
                  <div className="card-section card-section--with-border-bottom">
                    <div className="card-section__wrap">
                      <h3 className="card-section__header">{title}</h3>
                      <div className="sum-insured-row">
                        <InformationRow
                          title={translations.step7.sumInsured.title}
                          price={
                            <Price value={sumInsuredData} withSpace unstyled />
                          }
                          icon={<CheckColorIcon />}
                          showTooltip={true}
                          toolTipComponent={<SumInsured />}
                          dataTestId="sum-insured-row"
                          onOpened={handleFirstUSPModal}
                        />
                      </div>
                      <ul className="list">
                        {features.map(feature => (
                          <li className="list__element" key={feature.id}>
                            <InformationRow
                              title={feature.label}
                              subtitle={feature.subLabel}
                              icon={<CheckColorIcon />}
                              showTooltip={true}
                              toolTipComponent={feature.toolTip}
                              onOpened={(): void =>
                                handleInfoIcons(feature.name as InfoIcons)
                              }
                            />
                          </li>
                        ))}
                      </ul>
                      <ComparativeServicesButtonRender
                        useClick={handleLinkOpenModal}
                      />
                    </div>
                  </div>
                  <ModuleBenefits
                    fields={fields}
                    handleChangeOption={handleChangeVariantTariff}
                    onOpenInfoIcon={handleInfoIcons}
                  />
                </RadioTab>
              );
            })}
          </RadioTabs>
          <ModuleCycleAndEBike
            fields={fields}
            handleChangeOption={handleChangeBike}
            moduleIsChecked={moduleIsChecked}
            onOpenInfoIcon={handleInfoIcons}
          />
          <InsuranceCoveragePayment
            fields={fields}
            handleChangeOption={handleChangeOption}
            onModalOpen={onOpenInfoIcon}
          />

          {render()}
        </div>
      )}

      <LoadingSpinner show={isLoading} />
    </>
  );
};
