import addressValidationApi from './addressValidation';
import documentEngineApi from './documentEngine';
import hausratApi from './hausrat';
import offerEngineApi from './offerEngine';
import spcsApi from './spcs';
import submitEngineApi from './submitEngine';
import insuranceCompaniesApi from './insuranceCompanies';

export {
  addressValidationApi,
  documentEngineApi,
  hausratApi,
  insuranceCompaniesApi,
  offerEngineApi,
  spcsApi,
  submitEngineApi,
};
