import { Angebot } from '../../offerEngine/offers/types';
import { Agency } from '../../spcs/agencies/types';
import { Person } from '../../spcs/persons/types';

export declare type CreateOfferResponse = {
  angebot: Angebot;
  agency: Agency;
  person?: Person;
};
export declare type UpdateOfferResponse = {
  angebot: Angebot;
  person?: Person;
};

export declare type DownloadOrSubmitOfferErrorResponse = {
  errors: string[];
};

export type GetBankDataParams = {
  businessId?: string;
  personId?: string;
  iban?: string;
  mrn?: string;
  paymentId?: string;
};

export type InsuranceVariant = {
  variantId: string;
  startDate: string;
};

export type Address = {
  zipCode: string;
  city: string;
  streetName: string;
  streetNumber: string;
};

export type InsuranceCoverage = {
  leistungsvereinbarungen?: Angebot['leistungsvereinbarungen'];
  versicherungen?: Angebot['versicherungen'];
  vertraege?: Angebot['vertraege'];
};

export type InsurancePolicyTerminated = 'policyHolder' | 'insurer';
export type DamageNumber = 'one' | 'moreThanOne';
export type HouseholdDamage =
  | 'fire'
  | 'burglary'
  | 'robbery'
  | 'tapWater'
  | 'storm'
  | 'vandalism'
  | 'otherNaturalHazards'
  | 'other';

export type GlassDamage = 'glassBreakage' | 'other';

export type InsuranceEndDate = { day?: string; month?: string; year?: string };

export type PreviousInsurance = {
  companyName?: string;
  vuNr?: string;
  policyNumber?: string;
  policyTerminatedBy?: InsurancePolicyTerminated;
  endDate?: InsuranceEndDate;
  selected?: boolean;
};

export type DamageType = HouseholdDamage | GlassDamage;

type BasePreviousDamage = {
  selected?: boolean;
  number?: DamageNumber;
  cost?: number;
  year?: number;
};

export type HouseholdPreviousDamage = BasePreviousDamage & {
  type?: HouseholdDamage;
};

export type GlassPreviousDamage = BasePreviousDamage & {
  type?: GlassDamage;
};

export type PreviousDamage = HouseholdPreviousDamage | GlassPreviousDamage;

export type hasDoorLocks = {
  selected?: boolean;
};

export type AdditionalQuestionsForm = {
  previousInsurance: PreviousInsurance;
  previousGlassInsurance?: PreviousInsurance;
  previousHouseholdDamage: HouseholdPreviousDamage;
  previousGlassDamage?: GlassPreviousDamage;
  hasElectronicOrMechatronicLocks: hasDoorLocks;
};

export type PersonalData = {
  gender: string;
  firstName: string;
  lastname: string;
  addressConfirmation: string;
  email: string;
  telephoneNumber: string;
};

export type BirthDateParams = {
  businessId: string;
  dateOfBirth: string;
  personId?: string;
};

export type BirthDateResponse = {
  person: Person;
  angebot: Angebot;
};

export type AgencyDetails = {
  channelType: ChannelType;
  oeNr: string;
  actionNumber: string;
  agentNumber?: string;
};

export enum ChannelType {
  ERGO_PAGE = 'ERGO_PAGE',
  AGENT_BROKER = 'AGENT_BROKER',
  MAKLER = 'MAKLER',
  COOP_SALES_PARTNER = 'COOP_SALES_PARTNER',
}
