import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import TooltipIcon from '@eg/elements/TooltipIcon';
import BicycleIcon from '@eg/elements/Icons/BicycleIcon';
import Radio from '../../components/ERadio/ERadio';
import RadioGroup from '../../components/ERadioGroup/ERadioGroup';
import SelectRow from '@eg/elements/SelectRow';
import CheckBoxRow from '../../components/CheckBoxRow/CheckBoxRow';
import { useForm } from '../../store/useForm';
import { bikeAndEbikeConUnserdOptions, eBikeModule } from './data';
import {
  BicycleAndEbikeCoInsuredCoverType,
  BicycleAndEbikeCoInsuredType,
  CoverageModuleType,
} from './InsuranceCoverageTypes';

import {
  insuranceCoverageErrorsSelector,
  setBicycleAndEbikeCoInsuredAmount,
  setBicycleAndEbikeCoInsuredCover,
  setEbikeCheckbox,
  updateProductModule,
} from './insuranceCoverageSlice';
import translations from '../../constants/translations';
import { InsuranceCoverageFields } from '../../store/offerSlice';
import { formatDecimals } from '../../utils';
import { InfoIcons } from './TrackingTypes';
import { CustomerSelectionEbike } from './modals/CustomerSelectionEbike';
import { TrackingIDs } from './TrackingElements';
import { useInsuranceFromOffer } from './hooks/useInsuranceFromOffer';

const defaultValueForInsuranceLimit = 1500;

type Props = {
  fields: InsuranceCoverageFields;
  handleChangeOption: (
    moduleName: string,
    selected: boolean,
    value?: number
  ) => void;
  moduleIsChecked: (moduleName: CoverageModuleType) => boolean;
  onOpenInfoIcon?: (key: InfoIcons) => void;
};

const ModuleCycleAndEBike = ({
  fields,
  handleChangeOption,
  moduleIsChecked,
  onOpenInfoIcon,
}: Props): JSX.Element => {
  const { step7 } = translations;
  const {
    bicycleAndEbikeCoInsuredCover,
    bicycleAndEbikeCoInsuredAmount: bicycleAndEbikeCoInsuredAmountFromOffer,
  } = useInsuranceFromOffer();

  const {
    bicycleAndEbikeCoInsuredAmount = defaultValueForInsuranceLimit,
    dispatch,
  } = useForm();
  const bikeInsuranceAmount =
    bicycleAndEbikeCoInsuredAmountFromOffer || bicycleAndEbikeCoInsuredAmount;
  const errors = useSelector(insuranceCoverageErrorsSelector);
  const bikeCoverageError = errors.find(
    error => error.type === 'bikeModulePartialFilling'
  )?.message;

  const bikeSectionRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (errors && bikeCoverageError) {
      bikeSectionRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errors, bikeCoverageError]);

  const onBikeModuleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const checked = event.target.checked;
    const amount = checked
      ? bikeInsuranceAmount
      : defaultValueForInsuranceLimit;
    dispatch(updateProductModule('bicycleAndEbike'));
    if (!checked) {
      dispatch(setBicycleAndEbikeCoInsuredAmount(amount));
      dispatch(setEbikeCheckbox(false));
    } else {
      dispatch(setEbikeCheckbox(true));
    }

    // This module has to be `bike-module` because of tracking purposes
    handleChangeOption(TrackingIDs.bikeModule, checked, amount);
  };

  const onInsuredAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const amount = parseInt(event.target.value) as BicycleAndEbikeCoInsuredType;

    dispatch(setBicycleAndEbikeCoInsuredAmount(amount));
    handleChangeOption(TrackingIDs.bikeAmount, true, amount);
  };

  const onCoverageTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const moduleName = event.target.value as BicycleAndEbikeCoInsuredCoverType;
    dispatch(setBicycleAndEbikeCoInsuredCover(moduleName));
    handleChangeOption(moduleName, true, bikeInsuranceAmount);
  };

  const onOpenInfoIconHandler = (): void => {
    onOpenInfoIcon && onOpenInfoIcon(InfoIcons.BICYCLE);
  };

  const onModalOpenHandler = (isOpen: boolean): void => {
    if (isOpen && onOpenInfoIcon) {
      onOpenInfoIcon(InfoIcons.BICYCLE_INSURANCE_TYPE);
    }
  };

  return (
    <section className="card-section card-section--ebike" ref={bikeSectionRef}>
      <div className="card-section__wrap">
        <header className="card-section__header card-section__header--with-icon">
          <BicycleIcon />
          <h3 className="bike-options__title">{step7.bikeOptionsTitle}</h3>
        </header>

        <CheckBoxRow
          name={eBikeModule.name}
          title={eBikeModule.label}
          subtitle={eBikeModule.subLabel}
          checkedValue={moduleIsChecked(eBikeModule.name)}
          onChange={onBikeModuleChange}
          showTooltip={true}
          toolTipComponent={eBikeModule.toolTip}
          onOpened={onOpenInfoIconHandler}
        />

        <div className="bike-options">
          {moduleIsChecked(eBikeModule.name) && (
            <>
              <SelectRow
                name="bikeInsuredLimit"
                label="Mitversichert bis zu"
                description="Wert des teuersten Fahrrads bzw. E-Bikes"
                onChange={onInsuredAmountChange}
                placeholder={`${bikeInsuranceAmount}`}
                value={bikeInsuranceAmount}
              >
                {bikeAndEbikeConUnserdOptions.map(option => (
                  <option
                    value={option}
                    key={option}
                    data-testid="select-option"
                  >
                    {formatDecimals(option)} €
                  </option>
                ))}
              </SelectRow>

              <RadioGroup
                className="insurance-coverage--bikeRadios"
                label="Ihre Auswahl"
                name={'ihre-auswahl'}
                tooltip={
                  <TooltipIcon isModal onToggledOpen={onModalOpenHandler}>
                    <CustomerSelectionEbike />
                  </TooltipIcon>
                }
                onChange={onCoverageTypeChange}
                placeholder={bicycleAndEbikeCoInsuredCover}
                value={bicycleAndEbikeCoInsuredCover}
                error={bikeCoverageError}
              >
                {fields.bikeAndEBikes.map(field => (
                  <Radio
                    key={field.name}
                    id={field.name}
                    checked={field.checked}
                    label={field.label}
                    name={field.name}
                    required={field.mandatory}
                    value={field.name}
                  />
                ))}
              </RadioGroup>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ModuleCycleAndEBike;
