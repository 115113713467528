import React from 'react';
import translations from '../../constants/translations';

import type {
  CoverageModuleType,
  CoverageProductVariantType,
} from './InsuranceCoverageTypes';

import GrossNegligence from './modals/GrossNegligence';
import SimpleTheftIncluded from './modals/SimpleTheftIncluded';
import HouseAndFlat from './modals/HouseAndFlat';
import Glass from './modals/Glass';
import OtherNaturalHazards from './modals/OtherNaturalHazards';
import OtherDangers from './modals/OtherDangers';
import BikeTheft from './modals/BikeTheft';

const {
  step7: {
    dataFile: {
      smartSpecialFeatures: { grossNegligenceSmart },
      bestSpecialFeatures: { grossNegligenceBest, simpleTheftIncludedBest },
      benefitModules: {
        simpleTheft,
        houseAndFlat,
        glass,
        otherNaturalHazards,
        unnamedPerils,
        bicycleAndEbike,
      },
      paymentMethodsOptionsLabels,
      deductibleOptionsLabels,
      contractPeriodOptionsLabels,
    },
  },
} = translations;

export const smartSpecialFeatures = [
  {
    id: 1,
    name: 'grossNegligence',
    label: grossNegligenceSmart.label,
    subLabel: grossNegligenceSmart.subLabel,
    toolTip: <GrossNegligence />,
  },
];

export const bestSpecialFeatures = [
  {
    id: 1,
    name: 'grossNegligence',
    label: grossNegligenceBest.label,
    subLabel: grossNegligenceBest.subLabel,
    toolTip: <GrossNegligence />,
  },
  {
    id: 2,
    name: 'simpleTheftIncluded',
    label: simpleTheftIncludedBest.label,
    subLabel: simpleTheftIncludedBest.subLabel,
    toolTip: <SimpleTheftIncluded />,
  },
];

export const benefitModules: {
  id: number;
  label: string;
  name: CoverageModuleType;
  subLabel?: string;
  in: CoverageProductVariantType[];
  toolTip?: React.ReactNode;
}[] = [
  {
    id: 1,
    name: 'simpleTheft',
    label: simpleTheft.label,
    subLabel: simpleTheft.subLabel,
    in: ['smart', 'best'],
    toolTip: <SimpleTheftIncluded />,
  },
  {
    id: 2,
    name: 'houseAndFlat',
    label: houseAndFlat.label,
    subLabel: houseAndFlat.subLabel,
    in: ['smart', 'best'],
    toolTip: <HouseAndFlat />,
  },
  {
    id: 3,
    name: 'glass',
    label: glass.label,
    subLabel: glass.subLabel,
    in: ['smart', 'best'],
    toolTip: <Glass />,
  },
  {
    id: 4,
    name: 'otherNaturalHazards',
    label: otherNaturalHazards.label,
    subLabel: otherNaturalHazards.subLabel,
    in: ['smart', 'best'],
    toolTip: <OtherNaturalHazards />,
  },
  {
    id: 5,
    name: 'unnamedPerils',
    label: unnamedPerils.label,
    subLabel: unnamedPerils.subLabel,
    in: ['best'],
    toolTip: <OtherDangers />,
  },
  {
    id: 6,
    name: 'bicycleAndEbike',
    label: bicycleAndEbike.label,
    subLabel: bicycleAndEbike.subLabel,
    in: ['best', 'smart'],
    toolTip: <BikeTheft />,
  },
];

export const eBikeModule = benefitModules.find(
  module => module.name === 'bicycleAndEbike'
);

export const bikeAndEbikeConUnserdOptions = [
  500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000,
  9000, 10000,
];

export const paymentMethodsOptions = [
  {
    id: 1,
    label: paymentMethodsOptionsLabels.firstOptionLabel,
    name: 'monthly',
  },
  {
    id: 2,
    label: paymentMethodsOptionsLabels.secondOptionLabel,
    name: 'quarterly',
  },
  {
    id: 3,
    label: paymentMethodsOptionsLabels.thirdOptionLabel,
    name: 'half-year',
  },
  {
    id: 4,
    label: paymentMethodsOptionsLabels.fourthOptionLabel,
    name: 'annual',
  },
];

export const deductibleOptions = [
  {
    id: 1,
    label: deductibleOptionsLabels.firstOptionLabel,
    name: 'zero',
  },
  {
    id: 2,
    label: deductibleOptionsLabels.secondOptionLabel,
    name: 'threeHundred',
  },
  {
    id: 3,
    label: deductibleOptionsLabels.thirdOptionLabel,
    name: 'flex300',
  },
];

export const contractPeriodOptions = [
  {
    id: 1,
    label: contractPeriodOptionsLabels.firstOptionLabel,
    name: '5',
  },
  {
    id: 2,
    label: contractPeriodOptionsLabels.secondOptionLabel,
    name: '1',
  },
];
