import EditIcon from '@eg/elements/Icons/EditIcon';
import React from 'react';
import Accordion from '../../../../components/Accordion/Accordion';
import EButton from '../../../../components/EButton/EButton';
import translations from '../../../../constants/translations';
import useStepNavigation from '../../../../hooks/useStepNavigation';
import { useOfferSummary } from '../../hooks/offerSumary';
import './DetailsList.scss';

interface Props {
  onOpenAccordion?: (index: number) => void;
  onClickLink?: (index: number) => void;
}

const DetailsList = ({ onOpenAccordion, onClickLink }: Props): JSX.Element => {
  const { goNext } = useStepNavigation();
  const summarySections = useOfferSummary();

  const handleClick = (route: number): void => {
    onClickLink(route);
    goNext(route);
  };

  return (
    <div className="details-list">
      {summarySections.map((section, sectionIndex) => (
        <Accordion
          key={section.name}
          summaryName={section.name}
          isInitiallyOpen={sectionIndex === 0}
          onOpen={(): void => onOpenAccordion(sectionIndex)}
        >
          {section.data.map((dataBlock, dataBlockIndex) => {
            if (!dataBlock) {
              return null;
            }

            const dataBlockKey = `${sectionIndex}-${dataBlockIndex}`;
            return (
              <React.Fragment key={dataBlockKey}>
                {!!dataBlock.headline && <h4>{dataBlock.headline}</h4>}

                <div key={dataBlock.title} className="details-list__fields">
                  <span
                    className="details-list__fields-title"
                    data-testid={`title-${dataBlock.title}`}
                  >
                    {dataBlock.title}
                  </span>

                  <div className="details-list__fields-description">
                    {dataBlock.description?.map((desc, descIndex) => {
                      const descriptionKey = `${dataBlockKey}-${descIndex}`;
                      return (
                        <React.Fragment key={descriptionKey}>
                          {desc}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            );
          })}

          <div className="details-list__button">
            <EButton
              iconLeft={<EditIcon />}
              onClick={(): void => handleClick(section.editRoute)}
              variant="primary"
              size="auto"
              colorScheme="inverted"
              data-testid={`edit-button-${section.name}`}
            >
              {translations.step10.buttonChange}
            </EButton>
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default DetailsList;
