import { TrackingElementPage1 } from '../../pages/step1/TrackingElements';
import { TrackingElementPage10 } from '../../pages/step10/TrackingElements';
import { TrackingElementPage11 } from '../../pages/step11/TrackingElements';
import { TrackingElementPage2 } from '../../pages/step2/TrackingElements';
import { TrackingElementPage3 } from '../../pages/step3/TrackingElements';
import { TrackingElementPage4 } from '../../pages/step4/TrackingElements';
import { TrackingElementPage5 } from '../../pages/step5/TrackingElements';
import { TrackingElementPage6 } from '../../pages/step6/TrackingElements';
import { TrackingElementPage7 } from '../../pages/step7/TrackingElements';
import { TrackingElementPage8 } from '../../pages/step8/TrackingElements';
import { TrackingElementPage9 } from '../../pages/step9/TrackingElements';
import { MarketingTrackingEventDetails } from '../../types';

export interface PageLoadedContext {
  tariffOptions1?: string;
  calculatedValue?: number;
  zipCode?: string;
  streetName?: string;
  streetNumber?: string;
  city?: string;
  email?: string;
  telephoneNumber?: string;
  prefixNumber?: string;
  firstName?: string;
  lastname?: string;
  insuranceStart?: string;
  birthday?: string;
  gender?: TrackingGender;
  isAddressCorrected?: boolean;
  isAddressValidationFailed?: boolean;
}

export interface TrackingClickProductAttrs {
  tariffOptions?: string;
  insuranceStart?: string;
}

export interface TrackingClickUserProfileAddress {
  zipCode: string;
  streetNumber?: string;
  streetName?: string;
  city?: string;
}

export interface TrackingClickUserProfileInfo {
  email?: string;
  telephoneNumber?: string;
  prefixNumber?: string;
  firstName?: string;
  lastname?: string;
}

export interface TrackingTransactionValue {
  calculatedValue: number;
  conversionType?: TrackingConversionTypes;
}

export interface TrackingClickUserProfileAttrs {
  birthday?: string;
  gender?: TrackingGender;
  address?: TrackingClickUserProfileAddress;
}

export interface TrackingClickUserProfile {
  address?: TrackingClickUserProfileAddress;
  attributes?: TrackingClickUserProfileAttrs;
  profileInfo?: TrackingProfileInfo;
}

export interface TrackingClickOptions {
  productAttrs?: TrackingClickProductAttrs;
  userProfile?: TrackingClickUserProfile;
  transaction?: TrackingTransactionValue;
}

export interface TrackingSlice {
  isLoading: boolean;
  trackingContent: TrackingContent;
  // tariffOptions1: TrackingTarifOptions;
}
export interface TrackingContent {
  event?: TrackingEvents;
  page: TrackingPage;
  product: TrackingProduct[];
  transaction: TrackingTransaction;
  eventdetails?: TrackingEventDetails;
  user?: TrackingUser[];
  miscellaneous?: TrackingErrors;
}
export interface TrackingWindow extends Window {
  appEventData: TrackingContent[];
}

export enum TrackingEvents {
  PAGE_LOAD = 'PageLoaded',
  GENERAL_CLICK = 'GeneralClick',
  MARKETING = 'MarketingLogicReady',
}

export enum TrackingElementBasic {
  BUTTON_NEXT = 'Button_Weiter',
  BUTTON_BACK = 'Link_Zurueck',
}

export type TrackingElement =
  | TrackingElementBasic
  | TrackingElementPage1
  | TrackingElementPage2
  | TrackingElementPage3
  | TrackingElementPage4
  | TrackingElementPage5
  | TrackingElementPage6
  | TrackingElementPage7
  | TrackingElementPage8
  | TrackingElementPage9
  | TrackingElementPage10
  | TrackingElementPage11;

interface TrackingPage {
  pageInfo: TrackingPageInfo;
  attributes: TrackingPageAttributes;
  category: TrackingPageCategory;
}

export enum TrackingBoolean {
  YES = 'J',
  NO = 'N',
  NA = 'NA',
}

interface TrackingPageInfo {
  pageName?: string;
  sysEnv: string;
  issueDate: string;
}

interface TrackingPageAttributes {
  displayedBrand: TrackingBrand;
  brokerModus: TrackingBrokerModus;
}

export enum TrackingBrand {
  ERGO = 'ERGO',
}

export enum TrackingBrokerModus {
  ON = 'On',
  OFF = 'Off',
}
interface TrackingPageCategory {
  pagePurpose: TrackingPagePurpose;
  primaryCategory: TrackingCategory;
}

export enum TrackingPagePurpose {
  SALE = 'Sale',
}

export enum TrackingCategory {
  HR = 'hr2022',
}

interface TrackingProduct {
  productInfo: TrackingProductInfo;
  category: TrackingProductCategory;
  attributes: TrackingProductAttributes;
}

interface TrackingProductInfo {
  productName: string;
}

export interface TrackingProductAttributes {
  tariffOptions1: string;
  insuranceStart?: string;
}

export enum TrackingLivingType {
  APARTMENT = 'WoSi=MeFam',
  SINGLE = 'WoSi=EinFam',
}

export enum TrackingFloor {
  CELLAR = 'WoGes=Kel',
  GROUNDFLOOR = 'WoGes=EG',
  FLOOR_1ST = 'WoGes=1OG',
  FLOOR_2ND = 'WoGes=2OG',
  FLOOR_3RD_ABOVE = 'WoGes=3OG',
}

export enum TrackingSize {
  SM = 'WoFI',
}

export enum TrackingTarifName {
  HR = 'hr2022',
}

export enum TrackingOptionsName {
  Rechner = 'Rechner=hr2022',
  WoSi = 'WoSi=MeFam',
}

interface TrackingProductCategory {
  primaryCategory: string;
}

interface TrackingTransaction {
  transactionID: string;
  attributes?: TrackingTransactionAttributes;
  total?: TrackingTransactionTotal;
}

interface TrackingTransactionAttributes {
  conversionType?: TrackingConversionTypes;
  calculatedValue?: number;
  transactionTotal?: number;
}

interface TrackingTransactionTotal {
  transactionTotal: number;
}

interface TrackingEventDetails {
  clickType: 'other';
  clickedElement?: TrackingElement;
}

export enum TrackingClickType {
  OTHER = 'other',
}

export interface TrackingUser {
  profile: TrackingUserProfile;
}

interface TrackingUserProfile {
  address?: TrackingUserAddress;
  attributes?: TrackingUserAttributes;
  profileInfo?: TrackingProfileInfo;
}

export interface TrackingUserAddress {
  postalCode: string;
  line1?: string;
  city?: string;
}

export interface TrackingProfileInfo {
  email?: string;
  telephone?: string;
  telephoneE164?: string;
  firstName?: string;
  lastName?: string;
}

export interface TrackingUserAttributes {
  birthday: string;
  gender?: TrackingGender;
}

export interface TrackingPageLoadData {
  pageName?: string;
  conversionType?: TrackingConversionTypes;
}
export interface TrackingClickEventData {
  clickedElement: TrackingElement;
}

export interface TrackingErrors extends MarketingTrackingEventDetails {
  errors?: TrackingErrorDetails;
}
export interface TrackingErrorDetails {
  errorMessages: string;
  errorType: string;
  errorFlag?: boolean;
}

export enum TrackingMockValues {
  PRIMARY_CATEGORY = 'HausratGebaeude',
  PRODUCT_NAME = 'hr',
}

export enum TrackingConversionTypes {
  SALE_FUNNEL_START = 'SaleFunnelStart',
  SALE_FUNNEL_CALCULATION = 'SaleFunnelCalculation',
  SALE_FUNNEL_PERSONAL_DATA = 'SaleFunnelPersonalData',
  SALE_FUNNEL_FINAL_CHECK = 'SaleFunnelFinalCheck',
  SALE_FUNNEL_BANK_DATA = 'SaleFunnelBankData',
  IBAN_VERIFICATION_START = 'IbanVerificationStart',
  IBAN_VERIFICATION_SUCCESS = 'IbanVerificationSuccess',
  SALE = 'Sale',
  ADDRESS_CORRECTED = 'AdressVerificationDataCorrected',
  ADDRESS_VERIFICATION_FAILED = 'AdressVerificationFailed',
  ADDRESS_SUCCESS = 'AdressVerificationSuccess',
  POSTAL = 'OfferPost',
  EMAIL = 'OfferEmail',
  PDF_DOWNLOAD = 'OfferPdfDownload',
  EMPTY = '',
}

export enum TrackingGender {
  MALE = 'Herr',
  FEMALE = 'Frau',
  UNKNOWN = '',
}
