import { rest } from 'msw';
import { Handler } from '../../../types';
import { createAgency, getUrl } from '../constants';
import { CreateAgencyResponse } from './types';

export const createAgencyHandler = (
  rest[createAgency.method.toLowerCase()] as Handler
)(getUrl(createAgency.path), (req, res, ctx) => {
  const { businessId } = req.params;
  return res(
    ctx.status(201),
    ctx.json({
      contextDetails: {
        businessId,
      },
      data: {
        agencies: [
          {
            agencyId: 'agencyIdMocked',
          },
        ],
      },
      responseDetails: {},
    } as CreateAgencyResponse)
  );
});
