export const emailValidation = (email: string): boolean => {
  console.log('Here');
  const regEx =
    /^(?!.*\.{2})[a-zA-Z0-9_-][a-zA-Z0-9._-]*[a-zA-Z0-9-_]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  return regEx.test(email) && email !== '';
};

export const noErgoemailValidation = (email: string): boolean | string => {
  const [, mailDestination] = email.split('@');
  const invalidMailDestinations = [
    'ergo.de',
    'ergo.com',
    'dkv.com',
    'dkv.de',
    'das.de',
    'itergo.com',
  ];
  const emailFound = invalidMailDestinations.some(
    end => end === mailDestination
  );
  if (emailFound) {
    return 'ergo email provided';
  }
  const regEx =
    /^(?!.*\.{2})[a-zA-Z0-9_-][a-zA-Z0-9._-]*[a-zA-Z0-9-_]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  return regEx.test(email) && email !== '';
};
