import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';
import translations from '../../../constants/translations';
import { ModalProps } from './types';
import { InfoIcons } from '../TrackingTypes';

const { step7 } = translations;

const content = (
  <div data-testid="payment-method-info">
    <h3 data-testid="payment-method-title">{step7.paymentMethod.title}</h3>
    <p data-testid="payment-method-p1">{step7.paymentMethod.paragraph1}</p>
    <ul className="modal-list" data-testid="modal-list-1">
      <li>{step7.paymentMethod.listOptions1.a}</li>
      <li>{step7.paymentMethod.listOptions1.b}</li>
      <li>{step7.paymentMethod.listOptions1.c}</li>
      <li>{step7.paymentMethod.listOptions1.d}</li>
    </ul>
    <p data-testid="payment-method-p2">{step7.paymentMethod.paragraph2}</p>
  </div>
);

const PaymentMethod = ({ onOpen }: ModalProps): JSX.Element => {
  const onToggleHandler = (isOpen: boolean): void =>
    isOpen && onOpen(InfoIcons.PAYMENT_METHOD);

  return (
    <TooltipIcon isModal onToggledOpen={onToggleHandler}>
      {content}
    </TooltipIcon>
  );
};

export { PaymentMethod };
