import { rest } from 'msw';
import { mockedOffer } from '../../../mocks/offer';
import { Handler } from '../../../types';
import {
  createOffer,
  getUrl,
  readOffer,
  updateOffer,
  isOfferLocked,
  deletePreviousDamage,
  deletePreviousInsurace,
} from '../constants';
import { createRoleHandler } from '../roles/index.handlers';

const createOfferHandler = (rest[createOffer.method.toLowerCase()] as Handler)(
  getUrl(createOffer.path),
  (req, res, ctx) =>
    res(
      ctx.status(201),
      ctx.json({
        angebot: {
          ...mockedOffer,
          businessId: '08d098a0s98d0as98d09a8s0d98as0',
        },
      })
    )
);

const updateOfferHandler = (rest[updateOffer.method.toLowerCase()] as Handler)(
  getUrl(updateOffer.path),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          businessId: req.params.businessId,
        },
      })
    )
);

const readOfferHandler = (rest[readOffer.method.toLowerCase()] as Handler)(
  getUrl(readOffer.path),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        angebot: {
          ...mockedOffer,
          businessId: req.params.businessId,
        },
      })
    )
);

const deletePreviousInsuranceHandler = (
  rest[deletePreviousInsurace.method.toLowerCase()] as Handler
)(getUrl(deletePreviousInsurace.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        businessId: req.params.businessId,
      },
    })
  )
);

const deletePreviousDamageHandler = (
  rest[deletePreviousDamage.method.toLowerCase()] as Handler
)(getUrl(deletePreviousDamage.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        businessId: req.params.businessId,
      },
    })
  )
);

const isOfferLockedHandler = (
  rest[isOfferLocked.method.toLowerCase()] as Handler
)(getUrl(isOfferLocked.path), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      angebot: {
        businessId: req.params.businessId,
      },
    })
  )
);

const offerEngineHandlers = [
  createOfferHandler,
  updateOfferHandler,
  readOfferHandler,
  createRoleHandler,
  isOfferLockedHandler,
  deletePreviousInsuranceHandler,
  deletePreviousDamageHandler,
];

export default offerEngineHandlers;
