import { getInputValue } from '..';
import React, { useEffect, useState } from 'react';
import { AemDownloadLinkProps } from './AemDownloadLinkTypes';
import './AemDownloadLink.scss';

const AemDownloadLink: React.FC<AemDownloadLinkProps> = ({
  fragmentName,
  children,
}): JSX.Element => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    try {
      setUrl(getInputValue(fragmentName));
    } catch (e) {
      console.error(e);
    }
  }, [fragmentName]);
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      className={
        url ? 'fee-page__pdf-download-text' : 'download-link--disabled'
      }
    >
      {children}
    </a>
  );
};

export default AemDownloadLink;
