import Card from '@eg/elements/Card';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import React, { useEffect } from 'react';
import { TextInput } from '../../../../components/TextInput/TextInput';
import translations from '../../../../constants/translations';
import { BankDetailsState } from '../../BankDetailsTypes';
import { InputTitle } from '../InputTitle/InputTitle';
import './AccountDetails.scss';
import { trackFrontendError } from '../../../../utils/errorHandler';

type Props = {
  bankData: BankDetailsState;
  ibanError?: string;
  isLoading: boolean;
  isValid: boolean;
  onChangeIban: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleFocus: (value: string) => void;
  onBlurIban: (value: string) => void;
  value: string;
};

const AccountDetailsNumber = ({
  bankData,
  ibanError,
  isLoading,
  isValid,
  onChangeIban,
  onHandleFocus,
  onBlurIban,
  value,
}: Props): JSX.Element => {
  useEffect(() => {
    if (ibanError) {
      trackFrontendError({
        errorMessages: ibanError,
      });
    }
  }, [ibanError]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChangeIban(e);
  };

  const handleOnBlurIban = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onBlurIban(e.target.value);
  };

  return (
    <div className="account-details">
      <div
        className={`account-details-input ${
          isValid ? 'account-details-input--is-valid' : ''
        }`}
      >
        <InputTitle text={translations.step11.ibanLabel} />

        <TextInput
          className="account-details-input__iban"
          data-testid="iban-number-input"
          errors={ibanError && [ibanError]}
          id="iban-number"
          onChange={handleChange}
          onFocus={onHandleFocus}
          onBlur={handleOnBlurIban}
          placeholder={translations.step11.ibanPlaceholder}
          type="text"
          value={value}
        />
      </div>
      {isLoading && <LoadingIndicator />}
      {isValid && !isLoading && (
        <Card className="form-section-body__card">
          <div className="form-section-body__card-icon">
            <CheckColorIcon />
          </div>

          <p className="form-section-body__card-paragraph">
            {translations.step11.ibanSuccessMsg}

            <span>
              {translations.step11.bicLabel}: {bankData.bic} /{' '}
              {translations.step11.bankLabel}:{' '}
              {bankData.financialInstitutionName} {bankData.city}
            </span>
          </p>
        </Card>
      )}
    </div>
  );
};

export default AccountDetailsNumber;
