import { ChangeEvent, useState } from 'react';
import { setGender } from '../../personalDataSlice';
import { useGenderTypes, PersonalDataForm } from '../../PersonalDataTypes';
import { useAppDispatch } from '../../../../store/hooks';

const useGenderChoice = (form: PersonalDataForm): useGenderTypes => {
  const dispatch = useAppDispatch();
  const [genderErrorMessage, setGenderErrorMessage] = useState('');
  const { gender } = form;
  const handleOnChangeGender = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setGender(e.target.value));
    setGenderErrorMessage('');
  };

  const handleGenderValidation = (): void => {
    setGenderErrorMessage(!gender ? genderErrorMessage : '');
  };
  return {
    handleOnChangeGender,
    gender,
    handleGenderValidation,
    setGenderErrorMessage,
    genderErrorMessage,
  };
};

export default useGenderChoice;
