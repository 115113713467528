import { rest } from 'msw';
import { Handler } from '../../../types';
import { submitOffer, getUrl } from '../constants';

const submitOfferHandler = (rest[submitOffer.method.toLowerCase()] as Handler)(
  getUrl(submitOffer.path),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        angebot: {
          businessId: req.params.businessId,
        },
      })
    );
  }
);

const submitOfferHandlers = [submitOfferHandler];

export default submitOfferHandlers;
