import { AxiosInstance } from 'axios';
import { HttpMethod } from '../../types';
import { createInstance } from './commons';

const getAuth = (): string => {
  const { SUBMITENGINE_USER, SUBMITENGINE_PASSWORD } = process.env;
  const token = `${SUBMITENGINE_USER}:${SUBMITENGINE_PASSWORD}`;
  return `Basic ${Buffer.from(token).toString('base64')}`;
};

type HttpOptions = {
  method?: HttpMethod;
  payload?: Record<string, unknown> | Record<string, unknown>[];
};

let fetch: AxiosInstance | undefined;
export const submitEngineHttp = async <T = unknown>(
  url: string,
  options?: HttpOptions
): Promise<T> => {
  try {
    if (!fetch) {
      fetch = createInstance(getAuth());
    }
    const { method = 'GET', payload } = options ?? {};
    const body = method !== 'GET' ? payload : undefined;

    const { data, ...response } = await fetch[method.toLowerCase()](url, {
      ...body,
    });
    if (!/^2[0-9]{2,2}/.test(response.status.toString())) {
      throw new Error(response.statusText);
    }

    return data as T;
  } catch (error) {
    throw error;
  }
};
