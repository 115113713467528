import React from 'react';
import { PersonalDataInformationProps } from './PersonalDataInformation.d';
import Checkbox from '../../../../components/ECheckbox/ECheckbox';
import { TextInput } from '../../../../components/TextInput/TextInput';
import translations from '../../../../constants/translations';

import './PersonalDataInformation.scss';

const PersonalDataInput = ({
  handleOnChangeSurname,
  handleOnChangeFirstName,
  handleValidationFirstName,
  handleValidationSurname,
  firstNameErrorMessage,
  lastNameErrorMessage,
  firstName,
  lastname,
  handleCheckboxAddress,
  addressConfirmation,
  checkBoxErrorMessage,
  zipCode,
  streetName,
  streetNumber,
  dateOfBirth,
  city,
  hasAddress,
  firstNameInputRef,
  lastNameInputRef,
  addressCheckboxRef,
}: PersonalDataInformationProps): JSX.Element => {
  return (
    <>
      <div className="personal-data-input__name-container">
        <h4 className="personal-data-input__title">
          {translations.step9.name}
        </h4>
        <div className="personal-data-input__content personal-data-input__content__name">
          <div
            className="personal-data-input__firstname"
            ref={firstNameInputRef}
          >
            <label htmlFor="firstName" />
            <TextInput
              type="text"
              placeholder={translations.step9.firstName}
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={handleOnChangeFirstName}
              onBlur={handleValidationFirstName}
              data-testid="firstName"
            />
            <p className="personal-data-input__container-error">
              {firstNameErrorMessage}
            </p>
          </div>
          <div className="personal-data-input__lastname" ref={lastNameInputRef}>
            <label htmlFor="lastname" />
            <TextInput
              type="text"
              placeholder={translations.step9.lastName}
              id="lastname"
              name="lastname"
              value={lastname}
              onChange={handleOnChangeSurname}
              onBlur={handleValidationSurname}
              data-testid="lastname"
            />
            <p className="personal-data-input__container-error--fix">
              {lastNameErrorMessage}
            </p>
          </div>
        </div>
      </div>
      <div className="personal-data-input__birthdate">
        <h4 className="personal-data-input__title">
          {translations.step9.dateOfBirth}
        </h4>
        <div className="personal-data-input__content">
          {dateOfBirth ? (
            <p className="personal-data-input__text">{dateOfBirth} </p>
          ) : (
            <p className="personal-data-input--error">
              {translations.step9.dateOfBirthErrorMessage}
            </p>
          )}
        </div>
      </div>

      <div className="personal-data-input__address">
        <h4 className="personal-data-input__title">
          {translations.step9.address}
        </h4>
        <div className="personal-data-input__content">
          {hasAddress ? (
            <>
              <p className="personal-data-input__text">{`${streetName} ${streetNumber}`}</p>
              <p className="personal-data-input__text">{`${zipCode} ${city}`}</p>
            </>
          ) : (
            <p className="personal-data-input--error">
              {translations.step9.addressErrorMessage}
            </p>
          )}
        </div>
      </div>
      <div className="personal-data-input__checkbox" ref={addressCheckboxRef}>
        <h4 className="personal-data-input__title"></h4>
        <Checkbox
          type="checkbox"
          onChange={handleCheckboxAddress}
          value={addressConfirmation}
          checked={!!addressConfirmation}
          verticalAlign="top"
          label={translations.step9.addressCheckBox}
          errorMessage={checkBoxErrorMessage}
          data-testid="personal-data-consult-checkbox"
          id="personal-data-consult-checkbox"
        />
      </div>
    </>
  );
};
export default PersonalDataInput;
