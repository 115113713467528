import React, { useEffect } from 'react';
import { useAemIntegration } from '../../api/aemIntegration/hooks/aemIntegration';
import { useSteps } from '../../hooks/useSteps';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import RateCalculator from '../RateCalculator/RateCalculator';
import './Header.scss';

const Header = (): JSX.Element => {
  const { adjustHeaderToAEMWrapper, adjustContentToHeader } =
    useAemIntegration();
  const { numberOfSteps, currentStep } = useSteps();

  useEffect(adjustHeaderToAEMWrapper);
  useEffect(adjustContentToHeader);

  return (
    <header className="header">
      <ProgressBar max={numberOfSteps} value={currentStep} />
      <RateCalculator title="ERGO Hausratversicherung" />
    </header>
  );
};

export default Header;
