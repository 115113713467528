import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isValidSquareMeters } from 'commons';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import { squareMeters } from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import type { RootState } from '../../store/types';
import { saveOffer, savePerson } from '../../store/offerSlice';
import type { HomeSquareMetersState } from './HomeSquareMetersTypes.d';

const initialState: HomeSquareMetersState = {
  isValidated: false,
  isLoading: false,
  isLoadingSumInsured: false,
  form: {
    sizeInSM: 0,
  },
};

export const homeSquareMetersFetchSumInsured = createAsyncThunk(
  'homeSquareMeters/fetchSumInsured',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      if (!isValidSquareMeters(state.homeSquareMeters.form.sizeInSM)) {
        return;
      }

      const response = await squareMeters(
        state.app.businessId,
        (state.homeSquareMeters.form.sizeInSM ?? 0).toString()
      );

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }
    } catch (error) {
      throw Error(error);
    }
  }
);

export const homeSquareMetersFetchValidation = createAsyncThunk(
  'homeSquareMeters/validation',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const response = await squareMeters(
        state.app.businessId,
        (state.homeSquareMeters.form.sizeInSM ?? 0).toString()
      );

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      dispatch(setStepValidated(3));
    } catch (error) {
      throw Error(error);
    }
  }
);

export const HomeSquareMetersSlice = createSlice({
  name: 'homeSquareMeters',
  initialState,
  reducers: {
    setSizeInSM: (state, action: PayloadAction<number>) => {
      state.form.sizeInSM = action.payload;
    },
    setValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(homeSquareMetersFetchSumInsured.pending, state => {
      state.isLoadingSumInsured = true;
    });
    builder.addCase(homeSquareMetersFetchSumInsured.fulfilled, state => {
      state.isLoadingSumInsured = false;
    });
    builder.addCase(homeSquareMetersFetchSumInsured.rejected, state => {
      state.isLoadingSumInsured = false;
    });
    builder.addCase(homeSquareMetersFetchValidation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(homeSquareMetersFetchValidation.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(
      homeSquareMetersFetchValidation.rejected,
      (state, action) => {
        state.isLoading = false;
        console.error(action.error.message);
      }
    );
  },
});

export const { setSizeInSM, setValidated: setHomeSquareMetersValid } =
  HomeSquareMetersSlice.actions;

export const selectIsLoading = (state: RootState): boolean =>
  state.homeSquareMeters.isLoading;

export const isLoadingSumInsuredSelector = (state: RootState): boolean =>
  state.homeSquareMeters.isLoadingSumInsured;
export default HomeSquareMetersSlice.reducer;
