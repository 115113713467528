import React from 'react';
import translations from '../../../../constants/translations';

export const AddressTooltipInfo = (): JSX.Element => {
  return (
    <div data-testid="address-tooltip-info">
      <h3>{translations.step4.addressTooltipTitle}</h3>
      <p>{translations.step4.addressTooltipMsg1}</p>
      <p>{translations.step4.addressTooltipMsg2}</p>
    </div>
  );
};
