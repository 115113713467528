import { productModuleMapping } from '../../../pages/step7/utils';
import { CheckboxField, DropdownField } from '../../../types/formFields';
import {
  createCheckboxFromTariff,
  createDropdownFromVariant,
  idsToModuleNames,
} from '../../../utils/fieldsFromOfferEngine';
import { RootState } from '../../types';
import {
  getContractsFromOffer,
  getSelectedVariantFromOffer,
  getTariffsFromOffer,
} from './generics';

export interface InsuranceCoverageFields {
  services: CheckboxField[];
  bikeAndEBikes: CheckboxField[];
  contractTerms: DropdownField[];
}

const serviceIds = [
  productModuleMapping.simpleTheft,
  productModuleMapping.houseAndFlat,
  productModuleMapping.glass,
  productModuleMapping.otherNaturalHazards,
  productModuleMapping.unnamedPerils,
];

const bikeAndEBikeIds = [
  productModuleMapping.fahrraddiebstahl,
  productModuleMapping.fahrradkaskoUndMobilitätsschutz,
];

const contractTermIds = [productModuleMapping['deductible-amount']];

const defaultFields: InsuranceCoverageFields = {
  /**
   * Checkboxes at the top of the page
   */
  services: [],
  bikeAndEBikes: [],
  /**
   * 3 Dropdowns at the bottom of the page
   */
  contractTerms: [],
};

export const getInsuranceCoverageFields = (
  state: RootState
): InsuranceCoverageFields => {
  const selectedVariant = getSelectedVariantFromOffer(state);

  const contracts = getContractsFromOffer(selectedVariant?.versicherungsId)(
    state
  );

  const isGlasSelected = contracts.some(
    c => c.vertragsId === 'HRG' && c.vereinbart
  );

  const rawTariffs = selectedVariant?.versicherungsId
    ? getTariffsFromOffer(selectedVariant.versicherungsId)(state)
    : [];

  const insuranceCoverageFields = rawTariffs.reduce(
    (fields, tariff) => {
      if (serviceIds.includes(tariff.leistungsvereinbarungsId)) {
        fields.services = [
          ...fields.services,
          createCheckboxFromTariff(
            tariff.leistungsvereinbarungsId === productModuleMapping.glass
              ? {
                  ...tariff,
                  auswaehlbar: true,
                  obligatorisch: false,
                  vereinbart: isGlasSelected,
                }
              : tariff
          ),
        ];
      }

      if (bikeAndEBikeIds.includes(tariff.leistungsvereinbarungsId)) {
        fields.bikeAndEBikes = [
          ...fields.bikeAndEBikes,
          createCheckboxFromTariff(tariff),
        ];
      }

      if (contractTermIds.includes(tariff.leistungsvereinbarungsId)) {
        fields.contractTerms = [
          ...fields.contractTerms,
          ...tariff.wertebereiche.map(rawField => {
            /**
             * Selected value for the field
             */
            const vorbelegung = tariff[rawField.attribut];

            const field = createDropdownFromVariant(
              'Selbstbeteiligung',
              idsToModuleNames[tariff.leistungsvereinbarungsId] ?? '',
              { ...rawField, vorbelegung }
            );

            return field;
          }),
        ];
      }

      return fields;
    },
    { ...defaultFields }
  );

  const rawPaymentMethod = (selectedVariant?.wertebereiche ?? []).find(
    f => f.attribut === 'zahlweise'
  );
  if (rawPaymentMethod) {
    const selectedValue = selectedVariant.zahlweise;
    insuranceCoverageFields.contractTerms = [
      ...insuranceCoverageFields.contractTerms,
      createDropdownFromVariant('Zahlungsweise', 'payment-method', {
        ...rawPaymentMethod,
        vorbelegung: selectedValue,
      }),
    ];
  }

  const rawContractTerm = (selectedVariant?.wertebereiche ?? []).find(
    f => f.attribut === 'laufzeit'
  );
  if (rawContractTerm) {
    const selectedValue = selectedVariant.laufzeit;
    insuranceCoverageFields.contractTerms = [
      ...insuranceCoverageFields.contractTerms,
      createDropdownFromVariant('Vertragslaufzeit', 'contract-term', {
        ...rawContractTerm,
        vorbelegung: selectedValue,
      }),
    ];
  }

  return insuranceCoverageFields;
};
