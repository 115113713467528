import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpMethod } from '../../types';

const createInstance = (config?: AxiosRequestConfig): AxiosInstance =>
  axios.create({
    ...config,
    headers: {
      ...config?.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

type HttpOptions = {
  method?: HttpMethod;
  payload?: Record<string, unknown>;
};

const fetch = createInstance();

export const hausratHttp = async <T = unknown>(
  url: string,
  options?: HttpOptions & AxiosRequestConfig
): Promise<T> => {
  try {
    const { method = 'GET', payload, ...otherOptions } = options ?? {};
    const body = method !== 'GET' ? payload : undefined;

    const { data, ...response } = await fetch(url, {
      ...otherOptions,
      data: body,
      method,
    });

    if (!/^2[0-9]{2,2}/.test(response.status.toString())) {
      throw new Error(response.statusText);
    }

    return data as T;
  } catch (error) {
    throw error;
  }
};
