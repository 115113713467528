import React from 'react';
import translations from '../../../constants/translations';

const DateOfBirthInfo = (): JSX.Element => {
  return (
    <div data-testid="date-of-bith-info">
      <h3 data-testid="date-of-bith-info-title">
        {translations.step6.modalTitle}
      </h3>
      <p
        data-testid="date-of-bith-info-p1"
        dangerouslySetInnerHTML={{
          __html: translations.step6.modalSubtitle,
        }}
      />
    </div>
  );
};

export default DateOfBirthInfo;
