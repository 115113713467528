import { isBoolean } from '../helpers/booleans/booleans';
import {
  sessionStorageGet,
  sessionStorageRemove,
  sessionStorageSet,
} from '../helpers/sessionStorage/sessionStorage';

import type { AppStepsKeys, StepData, LocalStepsData } from '../store/types';
import { TrackingContent } from '../api/tracking/TrackingTypes';

const STORED_STEPS_KEY = '_egHausSteps';
const STORED_BUSINESS_ID_KEY = '_egHausBusinessId';
const STORED_PERSON_ID_KEY = '_egHausPersonId';
const STORED_TRACKING_DATA_KEY = '_egTracking';
const STORED_LAST_TRACKED_PAGE = '_egLastTrackedPage';
const STORED_REACHED_FEEDBACK_KEY = '_egHausReachedFeedback';
const STORED_ADDITIONAL_QUESTIONS_TOUCHED_KEY = '_egAdditionalQuestionsTouched';

export const storeStepData = (state: StepData, step: AppStepsKeys): void => {
  const data = sessionStorageGet<LocalStepsData>(STORED_STEPS_KEY);
  sessionStorageSet(STORED_STEPS_KEY, {
    ...data,
    [step]: state.isLoading ? { ...state, isLoading: false } : state,
  });
};

export const retrieveStepData = (step: AppStepsKeys): StepData => {
  const data = sessionStorageGet<LocalStepsData>(STORED_STEPS_KEY);
  return data?.[step];
};

export const storeBusinessId = (businessId: string): void => {
  if (businessId) {
    sessionStorageSet<string>(STORED_BUSINESS_ID_KEY, businessId);
  }
};

export const retrieveBusinessId = (): string => {
  return sessionStorageGet<string>(STORED_BUSINESS_ID_KEY);
};

export const storePersonId = (personId: string): void => {
  if (personId) {
    sessionStorageSet<string>(STORED_PERSON_ID_KEY, personId);
  }
};

export const retrievePersonId = (): string => {
  return sessionStorageGet<string>(STORED_PERSON_ID_KEY);
};

export const storeTrackingData = (trackingData: TrackingContent): void => {
  sessionStorageSet<TrackingContent>(STORED_TRACKING_DATA_KEY, trackingData);
};

export const retrieveTrackingData = (): TrackingContent => {
  return sessionStorageGet<TrackingContent>(STORED_TRACKING_DATA_KEY);
};

export const storeLastTrackedPage = (pathname: string): void =>
  sessionStorageSet<string>(STORED_LAST_TRACKED_PAGE, pathname);

export const retrieveLastTrackedPage = (): string =>
  sessionStorageGet<string>(STORED_LAST_TRACKED_PAGE);

export const removeLastTrackedPage = (): void =>
  sessionStorageRemove(STORED_LAST_TRACKED_PAGE);

export const storeReachedFeedback = (reachedFeedback: boolean): void => {
  if (isBoolean(reachedFeedback)) {
    sessionStorageSet<string>(
      STORED_REACHED_FEEDBACK_KEY,
      reachedFeedback.toString()
    );
  }
};

export const retrieveReachedFeedback = (): boolean => {
  return sessionStorageGet<string>(STORED_REACHED_FEEDBACK_KEY) === 'true';
};

export const storeAdditionalQuestionsTouched = (touched: boolean): void =>
  sessionStorageSet<string>(
    STORED_ADDITIONAL_QUESTIONS_TOUCHED_KEY,
    touched.toString()
  );

export const retrieveAdditionalQeustionsTouched = (): boolean =>
  !!sessionStorageGet<string>(STORED_ADDITIONAL_QUESTIONS_TOUCHED_KEY);
