import {
  householdDamageCostValidator,
  isValidYear,
  validateDamageNumberField,
  validateHouseholdDamageTypeField,
  validateSelectedField,
} from 'commons';
import {
  DamageNumber,
  HouseholdDamage,
  HouseholdPreviousDamage,
} from 'commons/apis/hausrat/types';
import translations from '../../../../constants/translations';
import { ValidatorFunction } from '../../../../store/types';
import { PreviousDamageKey } from '../../AdditionalQuestionsTypes';
import { generateInvalidField, generateValidField } from './validators';

export const previousHouseholdDamageErrors: Record<PreviousDamageKey, string> =
  {
    selected: translations.step8.damageAmountQuestion,
    number: translations.step8.damageAmountError,
    type: translations.step8.damageTypeError,
    cost: translations.step8.damageCostError,
    year: translations.step8.damageYearError,
  };

const costAmountError = {
  cost: translations.step8.moreThanOneDamageError,
};

export const previousHouseholdDamageValidations: Record<
  PreviousDamageKey,
  ValidatorFunction<HouseholdPreviousDamage[PreviousDamageKey]>
> = {
  selected: (value: boolean) => {
    return validateSelectedField(value)
      ? generateValidField()
      : generateInvalidField(previousHouseholdDamageErrors, 'selected');
  },
  number: (value: DamageNumber) => {
    return validateDamageNumberField(value)
      ? generateValidField()
      : generateInvalidField(previousHouseholdDamageErrors, 'number');
  },
  cost: (value: number) => {
    const isValid = householdDamageCostValidator(value);
    if (isValid) {
      return generateValidField();
    }
    const errorCollection =
      typeof value === undefined
        ? previousHouseholdDamageErrors
        : costAmountError;
    return generateInvalidField(errorCollection, 'cost');
  },
  type: (value: HouseholdDamage) => {
    return validateHouseholdDamageTypeField(value)
      ? generateValidField()
      : generateInvalidField(previousHouseholdDamageErrors, 'type');
  },
  year: (value: number) => {
    const isValid = isValidYear(value);
    return isValid
      ? generateValidField()
      : generateInvalidField(previousHouseholdDamageErrors, 'year');
  },
};
