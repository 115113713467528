import LoadingSpinner from '@eg/elements/LoadingSpinner';
import Provider from '@eg/elements/Provider';
import { NumberPages } from 'commons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTracking } from '../../api/tracking/hooks/useTracking';
import { setupInitialState } from '../../helpers/stateInit';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useSteps } from '../../hooks/useSteps';
import Sections from '../../router';
import { retrieveBusinessId, storeBusinessId } from '../../storage/storage';
import {
  businessIdSelector,
  getInitialOffer,
  initialLoadSelector,
  reachedFeedbackSelector,
  readySelector,
} from '../../store/appSlice';
import { useAppSelector } from '../../store/hooks';
import { FeedbackHeader } from '../FeedbackHeader/FeedbackHeader';
import Header from '../Header/Header';
import './App.scss';

export default function App(): JSX.Element {
  const location = useLocation();
  const { goNext } = useStepNavigation();
  const { initializeTracking } = useTracking();
  const { currentStep } = useSteps();
  const reachedFeedback = useAppSelector(reachedFeedbackSelector);
  const dispatch = useDispatch();
  const loading = useAppSelector(initialLoadSelector);
  const isReady = useAppSelector(readySelector);
  const businessId = useAppSelector(businessIdSelector);
  const { stepName } = useSteps();
  const displayHeader = stepName !== 'feedback';
  const businessIdIsStored = retrieveBusinessId() === null;

  useEffect(() => {
    if (currentStep !== NumberPages.FEEDBACK) {
      dispatch(getInitialOffer());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isReady) {
      setupInitialState(dispatch);
      initializeTracking(businessId, location.pathname);
    }
  }, [dispatch, isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reachedFeedback && currentStep !== NumberPages.FEEDBACK) {
      goNext(NumberPages.FEEDBACK);
    }

    if (businessIdIsStored && currentStep !== NumberPages.LIVING_PLACE) {
      storeBusinessId(businessId);
      goNext(NumberPages.LIVING_PLACE);
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const appBodyClasses = (): string => {
    const classes = ['app-body-spacing'];

    if (!displayHeader) {
      classes.push('app-body-spacing--feedback');
    }

    return classes.join(' ');
  };

  return (
    <Provider theme="ergo-one">
      {displayHeader ? <Header /> : <FeedbackHeader />}

      <div className={appBodyClasses()}>
        <Sections />
      </div>

      <LoadingSpinner show={loading} />
    </Provider>
  );
}
