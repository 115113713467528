import { rest } from 'msw';
import { Handler } from '../../../types';
import { downloadOffer, getUrl } from '../constants';

const downloadOfferHandler = (
  rest[downloadOffer.method.toLowerCase()] as Handler
)(getUrl(downloadOffer.path), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      angebot: {
        businessId: req.params.businessId,
      },
    })
  );
});

const downloadOfferHandlers = [downloadOfferHandler];

export default downloadOfferHandlers;
