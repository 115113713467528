import TooltipIcon from '@eg/elements/TooltipIcon';
import React from 'react';
import translations from '../../../../constants/translations';
import './FooterNotes.scss';

interface Props {
  onOpened?: () => void;
}
export const FooterNotes = ({ onOpened }: Props): JSX.Element => {
  const onToggleOpenHandler = (isOpen: boolean): void => {
    if (onOpened && isOpen) {
      onOpened();
    }
  };

  return (
    <div className="footer-notes">
      <p className="footer-notes__authorization-text">
        {translations.step11.authorizationText}
      </p>

      <span>{translations.step11.authorizationText2}</span>

      <h5 className="footer-notes__notice-title-text">
        {translations.step11.noteTitle}
      </h5>

      <p className="footer-notes__notice-text">
        {translations.step11.noteText}
      </p>

      <div className="footer-notes__direct-debit-box">
        <p>{translations.step11.directDebitText}</p>

        <TooltipIcon isModal onToggledOpen={onToggleOpenHandler}>
          <h3>{translations.step11.directDebitTextModalTitle}</h3>

          <p>{translations.step11.directDebitTextModalText}</p>
        </TooltipIcon>
      </div>
    </div>
  );
};

export default FooterNotes;
