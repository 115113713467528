import React from 'react';
import { TrackingElementBasic } from '../../../../api/tracking/TrackingTypes';
import EButton from '../../../EButton/EButton';
import type { FormActionsProps } from './FormActionsTypes';
import './FormActions.scss';

export const FormActions = ({
  continueLabel,
  cancelLabel,
  onClickContinue,
  onClickCancel,
  disableContinue,
  disableCancel,
  extraComponent,
  isLoading,
  trackingOptions,
}: FormActionsProps): JSX.Element => {
  return (
    <section className="esc_generated-content form-action-section">
      {continueLabel && (
        <EButton
          trackingOptions={{
            element: TrackingElementBasic.BUTTON_NEXT,
            options: trackingOptions,
          }}
          disabled={disableContinue}
          onClick={onClickContinue}
          className="next-button"
          variant="primary"
          size="large"
          colorScheme="regular"
          data-testid="submit-button"
          loading={isLoading}
        >
          {continueLabel}
        </EButton>
      )}
      {cancelLabel && (
        <EButton
          trackingOptions={{
            element: TrackingElementBasic.BUTTON_BACK,
            options: trackingOptions,
          }}
          disabled={disableCancel}
          onClick={onClickCancel}
          variant="text-link"
          data-testid="cancel-button"
        >
          {cancelLabel}
        </EButton>
      )}
      {extraComponent}
    </section>
  );
};
