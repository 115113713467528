import React, { useCallback, useEffect } from 'react';
import FormFooter from '@eg/elements/FormFooter';
import { dateOfBirthFetchValidation } from './dateOfBirthSlice';
import { useForm } from '../../store/useForm';
import FormSection from '../../components/FormSection/FormSection';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import DateOfBirthDateInput from './components/DateOfBirthDateInput';
import translations from '../../constants/translations';
import useStepNavigation from '../../hooks/useStepNavigation';
import DateOfBirthInfo from './components/DateOfBirthInfo';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import { useAppSelector } from '../../store/hooks';
import useClickTracking from './hooks/useClickTracking';
import {
  isDateOfBirthUpdated,
  getBirthDateFromPerson,
} from '../../store/offerSlice';

const DateOfBirth = (): JSX.Element => {
  const isError = useAppSelector(getGenericErrorSelector);
  const { goNext, goPrev } = useStepNavigation();
  const isFormModified = useAppSelector(isDateOfBirthUpdated);
  const dateOfBirthFromOffer = useAppSelector(getBirthDateFromPerson);
  const { dispatch, isLoadingDateOfBirth, isDateOfBirthValid } = useForm();
  const { trackingOptions, onModalTracking } = useClickTracking();

  const handleContinue = useCallback((): void => {
    if (isFormModified) {
      dispatch(dateOfBirthFetchValidation())
        .unwrap()
        .then(() => goNext());
    } else {
      goNext();
    }
  }, [dispatch, isFormModified, goNext]);

  useEffect(() => {
    if (isDateOfBirthValid && dateOfBirthFromOffer && isFormModified) {
      dispatch(dateOfBirthFetchValidation());
    }
  }, [dispatch, dateOfBirthFromOffer, isDateOfBirthValid, isFormModified]);

  const handleBack = (): void => {
    goPrev();
  };
  return (
    <FormSection>
      <FormSectionHeader
        title={translations.step6.pageTitle}
        subtitle={translations.step6.pageSubtitle1}
        showTooltip={true}
        toolTipComponent={<DateOfBirthInfo />}
        onOpened={onModalTracking}
      />
      <FormSectionBody>
        <DateOfBirthDateInput
          dataTestId="date-birth-input"
          isDateOfBirthValid={isDateOfBirthValid}
        ></DateOfBirthDateInput>
      </FormSectionBody>
      <FormSectionFooter>
        <FormActions
          continueLabel={translations.commons.continueLabel}
          cancelLabel={translations.commons.cancelLabel}
          onClickContinue={handleContinue}
          onClickCancel={handleBack}
          extraComponent={null}
          disableContinue={!isDateOfBirthValid}
          isLoading={isLoadingDateOfBirth}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors isShowing={isError} />
    </FormSection>
  );
};

export default DateOfBirth;
