import React from 'react';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import { InsuranceDateOptionsProps } from './InsuranceDateOptionsTypes';
import { typesOfDates } from './helpers/constants';
import InsuranceDateInput from './components/InsuranceDateInput';
import type { TypesOfDate } from '../../InsuranceDateTypes';

const InsuranceDateOptions = ({
  onSelectOption,
  typeOfDateSelected,
}: InsuranceDateOptionsProps): JSX.Element => {
  return (
    <div
      data-testid="insurance-date-options"
      className="insurance-date__options"
    >
      {Object.values(typesOfDates).map(type => {
        return (
          <RadioButton
            key={type.value}
            inline
            text={type.label}
            subtitle={type.subtitle}
            onClick={(value): void => onSelectOption(value as TypesOfDate)}
            value={type.value}
            checked={typeOfDateSelected === type.value}
            dataTestId={type.dataTestId}
          />
        );
      })}
      {typeOfDateSelected === typesOfDates.anotherDate.value && (
        <InsuranceDateInput />
      )}
    </div>
  );
};

export default InsuranceDateOptions;
