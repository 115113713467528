import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  submitOffer,
  downloadOffer,
  acceptConditions,
  validateOffer,
} from '../../services/offerService/offerService';
import { setStepValidated, setGenericError } from '../../store/appSlice';
import { RootState } from '../../store/types';
import { TermsOfServiceForm, TermsOfServiceState } from './TermsOfServiceTypes';

const defaultState: TermsOfServiceState = {
  isValidated: false,
  isLoading: false,
  form: {
    acceptConditions: false,
    documentDownloaded: false,
    isDownloadLoading: false,
    isSubmitLoading: false,
    acceptConditionsLoading: false,
    downloadErrors: [],
    submitErrors: [],
  },
};

const initialState: TermsOfServiceState = defaultState;

export const downloadDocument = createAsyncThunk(
  'downloadOffer',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const validateOfferResponse = await validateOffer(state.app.businessId);
      if (validateOfferResponse?.errors) {
        dispatch(setDocumentErrors(validateOfferResponse?.errors));
      } else {
        const downloadOfferResponse = (await downloadOffer(
          state.app.businessId
        )) as Blob;
        if (downloadOfferResponse.type === 'application/json') {
          dispatch(setDocumentDownloaded(false));
          const blobReader = new FileReader();
          blobReader.onload = function (): void {
            const errorResponse = JSON.parse(this.result as string);
            dispatch(setDocumentErrors(errorResponse.errors));
          };
          blobReader.readAsText(downloadOfferResponse);
        } else {
          dispatch(setDocumentDownloaded(true));
          dispatch(setDocumentErrors(null));
          const file = new Blob([downloadOfferResponse], {
            type: 'application/pdf',
          });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = fileURL;
          link.click();
          link.remove();
        }
      }
    } catch (error) {
      // We've got an error from document engine
      console.error('downloadDocument rejected', error);
      throw Error(error);
    }
  }
);

export const checkAcceptConditions = createAsyncThunk(
  'checkAcceptConditions',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      await acceptConditions(
        state.app.businessId,
        state.termsOfService.form.acceptConditions
      );
    } catch (error) {
      console.error('acceptConditions rejected', error);
      throw Error(error);
    }
  }
);

export const TermsOfServiceSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    setAgreeConditions: (
      state,
      action: PayloadAction<TermsOfServiceForm['acceptConditions']>
    ) => {
      state.form.acceptConditions = action.payload;
    },
    setDocumentDownloaded: (
      state,
      action: PayloadAction<TermsOfServiceForm['documentDownloaded']>
    ) => {
      state.form.documentDownloaded = action.payload;
    },
    resetErrors: state => {
      state.form.downloadErrors = [];
      state.form.submitErrors = [];
    },
    setDocumentErrors: (state, action) => {
      state.form.downloadErrors = action.payload;
    },
    setSubmitErrors: (state, action) => {
      state.form.submitErrors = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadDocument.pending, state => {
      state.form.isDownloadLoading = true;
    });
    builder.addCase(downloadDocument.fulfilled, state => {
      state.form.isDownloadLoading = false;
    });
    builder.addCase(downloadDocument.rejected, state => {
      state.form.isDownloadLoading = false;
    });
    builder.addCase(submitOfferValidation.pending, state => {
      state.form.isSubmitLoading = true;
      state.isValidated = false;
    });
    builder.addCase(submitOfferValidation.fulfilled, state => {
      state.form.isSubmitLoading = false;
      state.isValidated = true;
    });
    builder.addCase(submitOfferValidation.rejected, state => {
      state.form.isSubmitLoading = false;
      state.isValidated = false;
    });
    builder.addCase(checkAcceptConditions.pending, state => {
      state.form.acceptConditionsLoading = true;
    });
    builder.addCase(checkAcceptConditions.fulfilled, state => {
      state.form.acceptConditionsLoading = false;
    });
    builder.addCase(checkAcceptConditions.rejected, state => {
      state.form.acceptConditionsLoading = false;
    });
  },
});

export const submitOfferValidation = createAsyncThunk(
  'submitOffer/validation',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const validateOfferResponse = await validateOffer(state.app.businessId);
      if (validateOfferResponse?.errors) {
        dispatch(setSubmitErrors(validateOfferResponse?.errors));
      } else {
        const response = await submitOffer(state.app.businessId);
        if (!!response?.meldungen?.length) {
          dispatch(setGenericError(true));
        } else {
          dispatch(setStepValidated(12));
        }
      }
    } catch (error) {
      console.error('submitOfferValidation rejected', error);
      dispatch(setGenericError(true));
      throw Error(error);
    }
  }
);

export const isDocumentDownloaded = (state: RootState): boolean => {
  return state.termsOfService.form.documentDownloaded;
};

export const {
  setAgreeConditions,
  setDocumentErrors,
  setDocumentDownloaded,
  resetErrors,
  setSubmitErrors,
} = TermsOfServiceSlice.actions;

export default TermsOfServiceSlice.reducer;
