import type { FloorType } from 'commons';
import translations from '../../../../constants/translations';

export const typesOfFloorData: {
  value: FloorType;
  text: string;
}[] = [
  {
    value: 'basement',
    text: translations.step2.basement,
  },
  {
    value: 'groundFloor',
    text: translations.step2.groundFloor,
  },
  {
    value: 'firstFloor',
    text: translations.step2.firstFloor,
  },
  {
    value: 'secondFloor',
    text: translations.step2.secondFloor,
  },
  {
    value: 'otherFloor',
    text: translations.step2.otherFloor,
  },
];
