import { CustomerAddress } from '..';

export interface ErrorInfo {
  status: number;
  message: string;
}

export enum AddressErrors {
  streetName = 'streetName',
  streetNameLength = 'streetNameLength',
  streetNumber = 'streetNumber',
  streetNumberLength = 'streetNumberLength',
  postalCode = 'postalCode',
  majorCustomerZipcode = 'majorCustomerZipcode',
  addressNotIdentified = 'addressNotIdentified',
  correctTheAddressEntered = 'correctTheAddressEntered',
  streetAddressLength = 'streetAddressLength',
  invalidAddress = 'invalidAddress',
  city = 'city',
}

export enum ValidateAddressRules {
  postalCodeLength = 5,
  streetNameMinLength = 3,
  streetNameMaxlength = 30,
  streetNumberMinLength = 1,
  streetNumberMaxlength = 15,
}

export type AddressError = {
  code: number;
  message: string;
};

export type AddressValidationError = (error: unknown) => {
  isError: boolean;
  error: ErrorInfo | null;
};

export type ValidateAddressResult = {
  isValid: boolean;
  errors: AddressErrors[];
};

export type ValidateAddress = ({
  streetName,
  streetNumber,
  zipCode,
}: CustomerAddress) => ValidateAddressResult;
