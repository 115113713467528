import React from 'react';

type StepSectionProps = {
  title: string;
  subtitle: string;
  children: JSX.Element;
  error: string[];
};

const StepSection = ({
  title,
  subtitle,
  children,
  error,
}: StepSectionProps): JSX.Element => {
  return (
    <div className="step-section">
      <h3 className="step-section__title">{title}</h3>
      <p className="step-section__subtitle">{subtitle}</p>
      <div className="step-section__action">{children}</div>
      {/*todo this condition Array.isArray(error) is ugly - #quickFix - it should be clean up some day*/}
      {/*todo investigate why in case of successful submission error happens to be NOT an array anymore*/}
      {Array.isArray(error) &&
        error.map(err => {
          return (
            <p key={err} className="step-section__error">
              {err}
            </p>
          );
        })}
    </div>
  );
};

export default StepSection;
