import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import {
  insuranceCoverage,
  insuranceCoverageGlasField,
} from '../../services/offerService/offerService';
import { ApiOfferResponse } from '../../services/offerService/offerService.d';
import { setStepValidated } from '../../store/appSlice';

import {
  getInsuranceCoverageFromOffer,
  saveOffer,
  savePerson,
  getVariantsFromOffer,
} from '../../store/offerSlice';
import { RootState } from '../../store/types';
import type {
  InsuranceCoverageState,
  CoverageProductVariantType,
  CoverageModuleType,
  PaymentMethodType,
  DeductibleAmountType,
  BicycleAndEbikeCoInsuredType,
  BicycleAndEbikeCoInsuredCoverType,
  InsuranceCoverageError,
} from './InsuranceCoverageTypes';
import { updateOfferWithSelectedProps } from './utils';

const initialState: InsuranceCoverageState = {
  isLoading: false,
  isValidated: false,
  errors: [],
  updatedProps: {
    versicherungen: [],
    leistungsvereinbarungen: [],
  },
  form: {
    coverageProductVariant: 'best',
    productModules: ['simpleTheft'],
    paymentMethod: 'monthly',
    deductibleAmount: 'zero',
    contractPeriod: 'fiveYears',
    bicycleAndEbikeCoInsuredAmount: 1500,
    bicycleAndEbikeCoInsuredCover: undefined,
    ebikeCheckbox: false,
  },
};

export const insuranceCoverageFetchValidation = createAsyncThunk(
  'insuranceCoverage/validation',
  async (_, { dispatch }) => {
    dispatch(setStepValidated(7));
  }
);

type OptionsPayload = {
  id: string;
  selected: string | boolean;
  value?: number | string;
};

export const optionsSelected = createAsyncThunk(
  'insuranceCoverage/updateVariantsAndTariffs',
  async (
    { id, selected, value = null }: OptionsPayload,
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const { angebot } = state.offer;

      const { leistungsvereinbarungen, versicherungen } =
        updateOfferWithSelectedProps({
          angebot,
          id,
          selected,
          selectedVariant: state.insuranceCoverage.form.coverageProductVariant,
          value,
        });

      if (leistungsvereinbarungen || versicherungen) {
        dispatch(setUpdatedProps({ leistungsvereinbarungen, versicherungen }));

        const insuranceCoverageRequest = (): Promise<ApiOfferResponse> => {
          return insuranceCoverage(state.app.businessId, {
            leistungsvereinbarungen,
            versicherungen,
          });
        };
        const variants = getVariantsFromOffer()(state);
        const variantIds = [];
        variants.forEach(variant =>
          variantIds.push(variant.struktur.versicherungen)
        );

        const insuranceCoverageGlasFieldRequest =
          (): Promise<ApiOfferResponse> => {
            return insuranceCoverageGlasField(
              state.app.businessId,
              variantIds,
              selected as boolean
            );
          };

        const request =
          id === 'glass'
            ? insuranceCoverageGlasFieldRequest
            : insuranceCoverageRequest;

        const response = await request();

        if (response?.angebot) {
          dispatch(saveOffer(response.angebot as Angebot));
        }

        if (response?.person) {
          dispatch(savePerson(response.person as Person));
        }

        return response;
      }
    } catch (error) {
      console.error('rejected', error);
      throw Error(error);
    }
  }
);

export const insuranceCoverageSlice = createSlice({
  name: 'insuranceCoverage',
  initialState,
  reducers: {
    setCoverageProductVariant: (
      state,
      action: PayloadAction<CoverageProductVariantType>
    ) => {
      state.form.bicycleAndEbikeCoInsuredAmount = 1500;
      state.form.coverageProductVariant = action.payload;
      if (!state.form.productModules.includes('simpleTheft')) {
        state.form.productModules.push('simpleTheft');
      } else {
        state.form.productModules = [];
      }
    },
    initProductModule: (state, action: PayloadAction<CoverageModuleType[]>) => {
      state.form.productModules = action.payload;
    },
    addProductModule: (state, action: PayloadAction<CoverageModuleType>) => {
      state.form.productModules.push(action.payload);
    },
    removeProductModule: (state, action: PayloadAction<CoverageModuleType>) => {
      state.form.productModules.filter(module => action.payload !== module);
    },
    updateProductModule: (state, action: PayloadAction<CoverageModuleType>) => {
      if (state.form.productModules.includes(action.payload)) {
        state.form.productModules = state.form.productModules.filter(
          module => action.payload !== module
        );
      } else {
        state.form.productModules.push(action.payload);
      }
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethodType>) => {
      state.form.paymentMethod = action.payload;
    },
    setDeductibleAmount: (
      state,
      action: PayloadAction<DeductibleAmountType>
    ) => {
      state.form.deductibleAmount = action.payload;
    },
    setContractPeriod: (state, action: PayloadAction<string>) => {
      state.form.contractPeriod = action.payload;
    },
    setEbikeCheckbox: (state, action: PayloadAction<boolean>) => {
      state.form.ebikeCheckbox = action.payload;
    },
    setBicycleAndEbikeCoInsuredAmount: (
      state,
      action: PayloadAction<null | BicycleAndEbikeCoInsuredType>
    ) => {
      state.form.bicycleAndEbikeCoInsuredAmount = action.payload;
    },
    setBicycleAndEbikeCoInsuredCover: (
      state,
      action: PayloadAction<null | BicycleAndEbikeCoInsuredCoverType>
    ) => {
      state.form.bicycleAndEbikeCoInsuredCover = action.payload;
    },
    setUpdatedProps: (
      state,
      action: PayloadAction<InsuranceCoverageState['updatedProps']>
    ) => {
      state.updatedProps = action.payload;
    },
    setError: (state, action: PayloadAction<InsuranceCoverageError>) => {
      state.errors = [...state.errors, action.payload];
    },
    removeError: (
      state,
      action: PayloadAction<InsuranceCoverageError['type']>
    ) => {
      state.errors = state.errors.filter(
        error => error?.type !== action.payload
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(
      insuranceCoverageFetchValidation.rejected,
      (state, action) => {
        console.error(action.error.message);
      }
    );
    builder.addCase(optionsSelected.pending, state => {
      state.isLoading = true;
      state.isValidated = false;
    });
    builder.addCase(optionsSelected.fulfilled, state => {
      state.isLoading = false;
      state.isValidated = true;
    });
    builder.addCase(optionsSelected.rejected, state => {
      state.isLoading = false;
      state.isValidated = false;
    });
  },
});

export const {
  setCoverageProductVariant,
  initProductModule,
  addProductModule,
  removeProductModule,
  updateProductModule,
  setPaymentMethod,
  setDeductibleAmount,
  setContractPeriod,
  setBicycleAndEbikeCoInsuredAmount,
  setBicycleAndEbikeCoInsuredCover,
  setUpdatedProps,
  setError,
  removeError,
  setEbikeCheckbox,
} = insuranceCoverageSlice.actions;

export const isInsuranceCoverageValidSelector = ({
  insuranceCoverage: { form },
}: RootState): boolean => {
  if (
    !(form as InsuranceCoverageState['form']).coverageProductVariant?.length
  ) {
    return false;
  }

  if (
    form.coverageProductVariant === 'best' &&
    form.productModules.length === 0
  ) {
    return false;
  }

  const paymentOptionsFilled =
    form.paymentMethod.length &&
    form.deductibleAmount.length &&
    form.contractPeriod.length;

  if (!paymentOptionsFilled) {
    return false;
  }

  return true;
};

export const coverageProductVariantSelector = ({
  insuranceCoverage: { form },
}: RootState): CoverageProductVariantType =>
  form.coverageProductVariant as CoverageProductVariantType;

export const productModulesSelector = (
  state: RootState
): CoverageModuleType[] => {
  const { productModules } = getInsuranceCoverageFromOffer(state);
  return productModules;
};

export const selectIsLoading = (state: RootState): boolean =>
  state.insuranceCoverage.isLoading;

export const selectIsValidated = (state: RootState): boolean =>
  state.bankDetails.isValidated;

export const isBikeModulePartiallyFilledSelector = ({
  insuranceCoverage: { form },
}: RootState): boolean =>
  form.productModules.includes('bicycleAndEbike') &&
  (!form.bicycleAndEbikeCoInsuredAmount || !form.bicycleAndEbikeCoInsuredCover);

export const insuranceCoverageErrorsSelector = ({
  insuranceCoverage: { errors },
}: RootState): InsuranceCoverageError[] => errors;

export default insuranceCoverageSlice.reducer;
