import { DateInputValue } from '@eg/elements/DateInput';
import { dateFirstFormat } from '../helpers/dates/dates';
import { capitalizeFirstLetter } from '../helpers/strings/strings';
import { dateOfBirthFormSelector } from '../pages/step6/dateOfBirthSlice';
import { personalDataFormSelector } from '../pages/step9/personalDataSlice';
import { useAppSelector } from '../store/hooks';

type UsePersonReturn = {
  firstName: string;
  lastname: string;
  nameFormatted: string;
  gender: string;
  addressConfirmation: string;
  email: string;
  telephoneNumber: string;
  dateOfBirth: DateInputValue;
  dateOfBirthFormatted: string;
};

export const usePerson = (): UsePersonReturn => {
  const {
    firstName,
    lastname,
    gender,
    addressConfirmation,
    email,
    telephoneNumber,
  } = useAppSelector(personalDataFormSelector);

  const { dateOfBirth } = useAppSelector(dateOfBirthFormSelector);

  const nameFormatted = `${capitalizeFirstLetter(
    firstName
  )} ${capitalizeFirstLetter(lastname)}`;

  const dateOfBirthFormatted = dateFirstFormat(dateOfBirth);

  return {
    firstName,
    lastname,
    nameFormatted,
    gender,
    addressConfirmation,
    email,
    telephoneNumber,
    dateOfBirth,
    dateOfBirthFormatted,
  };
};
