import { yearFirstFormat } from '../../../helpers/dates/dates';
import { RootState } from '../../types';
import { getPersonSelector } from './generics';

export const getBirthDateFromPerson = (state: RootState): string | undefined =>
  getPersonSelector(state)?.dateOfBirth;

export const isDateOfBirthUpdated = (state: RootState): boolean => {
  const offerValue = getPersonSelector(state)?.dateOfBirth;
  return (
    yearFirstFormat(state.dateOfBirth.form.dateOfBirth, 'YYYY-MM-DD') !==
    offerValue
  );
};
