import { Dispatch, SetStateAction, useState } from 'react';

type UseSelectionErrors = {
  setIsGoNextValue: Dispatch<SetStateAction<boolean>>;
  isGoNextClicked: boolean;
};

export const useSelectionErrors = (): UseSelectionErrors => {
  const [isGoNextClicked, setIsGoNextValue] = useState();

  return {
    isGoNextClicked,
    setIsGoNextValue,
  };
};
