export const step9 = {
  pageTitle: 'Personalisieren Sie Ihren Vertrag.',
  pageSubtitle:
    'Das geht einfach mit wenigen Angaben. Vor dem Abschluss können Sie Ihre persönlichen Daten noch einmal prüfen.',
  subTitleToolTip: 'Datenschutz bei ERGO',
  subTitleToolTipInfo:
    'Bei der Verarbeitung personenbezogener Daten beachtet ERGO die Vorschriften der EU-Datenschutz-Grundverordnung. Ausführliche Informationen finden Sie im <a href="https://www.ergo.de/de/Service/Datenschutz" target="_blank" >Datenschutzbereich</a>. Wenn Sie ein Angebot anfordern, erhalten Sie die Informationen in Ihren Vertragsunterlagen.',
  personDataTitle: 'Persönliche Daten als Versicherungsnehmer',
  radioButtonMale: 'Herr',
  radioButtonFemale: 'Frau',
  gender: 'Anrede',
  genderErrorMessage: 'Bitte wählen Sie eine Anrede aus.',
  name: 'Name',
  firstName: 'Vorname',
  firstNameErrorMessage: 'Bitte geben Sie Ihren Vornamen an.',
  lastName: 'Nachname',
  lastNameErrorMessage: 'Bitte geben Sie Ihren Familien-/Nachnamen an.',
  dateOfBirth: 'Geburtsdatum',
  dateOfBirthErrorMessage: 'Bitte ergänzen Sie Ihr Geburtsdatum.',
  address: 'Adresse',
  addressErrorMessage: 'Bitte ergänzen Sie Ihr adresse.',
  addressCheckBox:
    'Ich bestätige, dass meine oben angegebene Postanschrift auch die des versicherten Objekts ist.',
  checkBoxErrorMessage:
    'Bitte bestätigen Sie, dass das versicherte Objekt die oben angegebene Postanschrift hat.',
  emailAddress: 'E-Mail-Adresse',
  emailAddressToolTipHeader: 'Warum benötigt ERGO Ihre E-Mail-Adresse?',
  emailAddressToolTipContent:
    'Ihre E-Mail-Adresse wird benötigt, um Ihnen die Anforderung per E-Mail zu bestätigen. Diese Angabe unterliegt natürlich strengen Datenschutzbestimmungen.',
  emailAddressMissing: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
  emailAddressInvalid:
    'Bitte überprüfen Sie die E-Mail-Adresse auf Umlaute und Sonderzeichen.',
  ergoEmailAddressInvalid:
    'Die Eingabe von E-Mail-Adressen mit den Endungen "@ergo.de", "@ergo.com" "@dkv.com", "@dkv.de", "@das.de" und "@itergo.com" ist nicht möglich. Bitte geben Sie eine andere E-Mail-Adresse an.',
  telephone: 'Telefon / Mobilfunknummer (optional)',
  telephoneInfoHeader: 'Warum benötigt ERGO Ihre Telefonnummer?',
  telephoneInfoContent:
    'Fragen zu Ihrem Antrag lassen sich am schnellsten telefonisch klären.',
  telephoneInvalid: 'Bitte prüfen Sie Ihre Telefonnummer.',
};
