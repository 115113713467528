import { trackClickEvent } from '../../../api/tracking';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { TrackingElementPage12 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onClickTracking: (key: string) => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onClickTracking = (key: string): void => {
    const element = TrackingElementPage12[key];
    trackClickEvent(element, trackingOptions);
  };

  return {
    trackingOptions,
    onClickTracking,
  };
};

export default useClickTracking;
