import { InsuranceEndDate } from '../apis/hausrat/types';
import { isEmptyObject } from './objects';
import { isEmptyString } from './string';

const yearToString = (date: Date): string =>
  date.getFullYear().toString().padStart(4, '0');
const monthToString = (date: Date): string =>
  (date.getMonth() + 1).toString().padStart(2, '0');
const dayToString = (date: Date): string =>
  date.getDate().toString().padStart(2, '0');

export const isValidDateObject = (date: Date): boolean => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date?.getTime())) {
      return false;
    }
  }

  return true;
};

/**
 * This function will transform any date object into a number that
 * can be added or substracted, with the following format: yyyyMMdd
 */
const dateToCalculableNumber = (date = new Date(Date.now())): number =>
  isValidDateObject(date)
    ? Number(yearToString(date) + monthToString(date) + dayToString(date))
    : 0;

/**
 * This will return the difference between two dates in
 * the following format: yyyyMMdd
 */
export const getDatesDifference = (
  endDate = new Date(Date.now()),
  startDate = new Date(Date.now())
): number => {
  if (!isValidDateObject(endDate) || !isValidDateObject(startDate)) {
    return 0;
  }

  return dateToCalculableNumber(endDate) - dateToCalculableNumber(startDate);
};

export const dateInputValueToDate = (
  dateInputValue: InsuranceEndDate
): Date | null => {
  if (isEmptyObject(dateInputValue)) {
    return null;
  }

  if (
    Object.keys(dateInputValue).length !== 3 ||
    Object.values(dateInputValue).some(value => isEmptyString(value))
  ) {
    return null;
  }

  const date = new Date(
    Number(dateInputValue.year),
    Number(dateInputValue.month) - 1,
    Number(dateInputValue.day)
  );

  return date;
};

export const addYears = ({
  yearsToAdd,
  from,
}: {
  yearsToAdd: number;
  from?: Date;
}): Date => {
  const date = from ? new Date(from.getTime()) : new Date(Date.now());

  date.setFullYear(date.getFullYear() + yearsToAdd);

  return date;
};

export const isDateOlderThan = ({
  startDate,
  dateToCheck,
  resetTime = false,
}: {
  startDate: Date;
  dateToCheck: Date;
  resetTime?: boolean;
}): boolean => {
  if (!isValidDateObject(startDate) || !isValidDateObject(dateToCheck)) {
    return false;
  }

  const copyOfStartDate = new Date(startDate.getTime());
  if (resetTime) {
    copyOfStartDate.setHours(0, 0, 0, 0);
  }

  const copyOfDateToCheck = new Date(dateToCheck.getTime());
  if (resetTime) {
    dateToCheck.setHours(0, 0, 0, 0);
  }

  return copyOfDateToCheck.getTime() < copyOfStartDate.getTime();
};
