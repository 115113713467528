import { CustomerAddressResponse, Validity } from '../types';

export const isFailingAddressValidation = (
  addressResponse: CustomerAddressResponse
): boolean => {
  const isNotInvalidAddress = addressResponse.validity !== Validity.INVALID;
  if (isNotInvalidAddress) {
    return false;
  }

  const errorMessageToDetect = 'VAUD0739: Haus-Nr. nicht zuzuordnen';

  const responseMessages = addressResponse.message?.messages || [];
  const hasNotErrorMessageToDetect =
    !responseMessages.includes(errorMessageToDetect);
  if (hasNotErrorMessageToDetect) {
    return false;
  }

  const hasOtherNotEmptyError = responseMessages.some(
    message => message !== errorMessageToDetect && message !== ''
  );
  if (hasOtherNotEmptyError) {
    return false;
  }

  return true;
};
