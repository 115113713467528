import './ErrorMsg.scss';
import React, { useEffect } from 'react';
import { TypeErrorMsg } from './ErrorMsgTypes';
import translations from '../../../../constants/translations';
import { trackFrontendError } from '../../../../utils/errorHandler';
import {
  isValidSquareMetersMaxWise,
  isValidSquareMetersMinWise,
} from 'commons';

const ErrorMsg = ({ value }: TypeErrorMsg): JSX.Element => {
  const getErrorMessage = (value): string => {
    if (!isValidSquareMetersMaxWise(value)) {
      return translations.step3.maxValueText;
    } else if (!isValidSquareMetersMinWise(value)) {
      return translations.step3.minValueText;
    } else {
      return '';
    }
  };
  const errorMessage = getErrorMessage(value);

  useEffect(() => {
    if (errorMessage) {
      trackFrontendError({ errorMessages: errorMessage });
    }
  }, [errorMessage]);

  return (
    <div className="error">
      <span className="error__message" data-testid="error-msg">
        <>{errorMessage}</>
      </span>
    </div>
  );
};

export default ErrorMsg;
