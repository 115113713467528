import { useSelector } from 'react-redux';
import { getInsuranceCoverageFromOffer } from '../../../store/offerSlice';
import type {
  CoverageModuleType,
  BicycleAndEbikeCoInsuredType,
  BicycleAndEbikeCoInsuredCoverType,
} from '../InsuranceCoverageTypes';

export type InsuranceSection = {
  productModules: CoverageModuleType[];
  bicycleAndEbikeCoInsuredAmount: BicycleAndEbikeCoInsuredType;
  bicycleAndEbikeCoInsuredCover: BicycleAndEbikeCoInsuredCoverType;
};

export const useInsuranceFromOffer = (): InsuranceSection => {
  const insuranceCoverage = useSelector(getInsuranceCoverageFromOffer);

  const {
    productModules,
    bicycleAndEbikeCoInsuredAmount,
    bicycleAndEbikeCoInsuredCover,
  } = insuranceCoverage;

  return {
    productModules,
    bicycleAndEbikeCoInsuredAmount,
    bicycleAndEbikeCoInsuredCover,
  };
};
