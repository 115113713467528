import { AxiosError } from 'axios';

export const isAxiosError = (error: unknown): boolean =>
  'toJSON' in (error as AxiosError);

export const isSerializedError = (error: unknown): boolean =>
  /^\{/.test((error as Error)?.message);

export const getErrorCode = (code: number, defaultCode = 400): number => {
  const apiErrors = /(4|5)\d\d$/;
  if (code.toString().match(apiErrors)) {
    return code;
  }
  return defaultCode;
};
