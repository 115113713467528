import { addressValidationHttp } from '../http/addressValidationHttp';
import {
  getCitiesFromAddress as getCitiesFromAddressRoute,
  getUrl,
  validateAddress as validateAddressRoute,
} from './constants';
import { isFailingAddressValidation } from './helpers';
import {
  Address,
  CustomerAddressResponse,
  GetCitiesFromAddressResponse,
  ValidateAddressParam,
  Validity,
} from './types';

export const getCitiesFromAddress = async ({
  streetName,
  zipCode,
}: ValidateAddressParam): Promise<Address[]> => {
  try {
    const { method, path } = getCitiesFromAddressRoute;
    const url = getUrl(path)
      .replace(':zipCode', zipCode)
      .replace(':streetName', streetName);
    const data = await addressValidationHttp<GetCitiesFromAddressResponse>(
      url,
      {
        method,
      }
    );

    return (data.matchingAddresses ?? []).map(
      a =>
        ({
          city: a.city,
          houseNumber: undefined,
          street: a.street,
          zipCode: a.zipCode,
        } as Address)
    );
  } catch (error) {
    throw error;
  }
};

export const validateAddress = async ({
  streetName,
  streetNumber,
  zipCode,
  city,
}: ValidateAddressParam): Promise<Address> => {
  try {
    const { method, path } = validateAddressRoute;
    const url = getUrl(path);

    const payload = {
      houseNumber: streetNumber,
      street: streetName,
      zipCode: zipCode,
      city,
      countryCode: '',
    };

    const data = await addressValidationHttp<CustomerAddressResponse>(url, {
      method,
      payload,
    });

    if (isFailingAddressValidation(data)) {
      return payload;
    }

    if (data.validity === Validity.INVALID) {
      throw Error(JSON.stringify(data.message));
    }

    if (data.validity === Validity.CORRECTED) {
      return {
        city: data.correctedAddress.city,
        street: data.correctedAddress.street,
        zipCode: data.correctedAddress.zipCode,
        houseNumber: streetNumber,
      };
    }

    return payload as Address;
  } catch (error) {
    throw error;
  }
};

const addressValidationApi = {
  getCitiesFromAddress,
  validateAddress,
};

export default addressValidationApi;
