import { ServerRoute } from '../../../types';

export const submitOffer: ServerRoute = {
  method: 'POST',
  path: '/submitengine/submit/:businessId',
};

export const getUrl = (path: string): string => {
  const baseUrl = process.env.SUBMITENGINE_URL;
  return `${baseUrl}${path}`;
};
