import React from 'react';
import { discountSelector } from '../../store/appSlice';
import { useAppSelector } from '../../store/hooks';
import {
  getOfferFeedbackSelector,
  getSelectedVariantFromOffer,
} from '../../store/offerSlice';
import translations from '../../constants/translations';
import RateHeader from '../RateHeader/RateHeader';
import type { RateCalculatorProps } from './RateCalculatorTypes';
import './RateCalculator.scss';

const PAYMENT_UNITS_MAP = {
  jährlich: translations.step7.dataFile.premiumUnits.annual,
  halbjährlich: translations.step7.dataFile.premiumUnits.halfYearly,
  vierteljährlich: translations.step7.dataFile.premiumUnits.quaterly,
  monatlich: translations.step7.dataFile.premiumUnits.monthly,
};

const RateCalculator = ({ title }: RateCalculatorProps): JSX.Element => {
  const discount = useAppSelector(discountSelector);
  const { premium, paymentMethod, bonus } = useAppSelector(
    getOfferFeedbackSelector
  );
  const variant = useAppSelector(getSelectedVariantFromOffer);
  const hasValidPrice = !!premium;
  const premUnits = PAYMENT_UNITS_MAP[paymentMethod];
  const variantAndBonusSubtitle = (): string => {
    let subTitle = `${variant.tarifvariante}`;

    if (bonus) {
      subTitle += ` inkl. ${discount}% Startbonus`;
    }

    return subTitle;
  };

  return (
    <section className="rate-calculator">
      {hasValidPrice ? (
        <RateHeader
          title={title}
          price={parseFloat(premium.replace(/\./g, '').replace(',', '.'))}
          subtitle={variantAndBonusSubtitle()}
          priceSuffix={premUnits}
        />
      ) : (
        <h6 className="rate-calculator__title">{title}</h6>
      )}
    </section>
  );
};

export default RateCalculator;
