import { Message } from '../apis/addressValidation/types';
import { AddressError, AddressErrors, AddressValidationError } from '../types';
import { isSerializedError } from './errors.helper';

const addressErrors: AddressError[] = [
  {
    code: 4,
    message: AddressErrors.majorCustomerZipcode,
  },
  {
    code: 5,

    message: AddressErrors.correctTheAddressEntered,
  },
  {
    code: 11,
    message: AddressErrors.addressNotIdentified,
  },
];

const getAddressError = (code: number): AddressError | undefined =>
  addressErrors.find(addressError => addressError.code === code);

export const addressValidationError: AddressValidationError = error => {
  if (!isSerializedError(error)) {
    return {
      isError: false,
      error: null,
    };
  }

  const errorMessage = JSON.parse((error as Error).message) as Message;
  const addressError = getAddressError(Number(errorMessage.code));
  if (!addressError) {
    return {
      isError: false,
      error: null,
    };
  }

  return {
    isError: true,
    error: {
      message: addressError.message,
      status: 400,
    },
  };
};
