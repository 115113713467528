import { ServerRoute } from '../../../types';

export const getBankDataFromIban: ServerRoute = {
  method: 'POST',
  path: '/payments/:businessId/person/:personId/bankdata',
};

export const deleteBankData: ServerRoute = {
  method: 'DELETE',
  path: '/payments/:businessId/person/:personId/bankdata/:paymentId',
};

export const createDocument: ServerRoute = {
  method: 'POST',
  path: '/general/:businessId',
};

export const createAgency: ServerRoute = {
  method: 'POST',
  path: '/agencies/:businessId/agency',
};

export const readPerson: ServerRoute = {
  method: 'GET',
  path: '/persons/:businessId',
};

export const createPerson: ServerRoute = {
  method: 'POST',
  path: `${readPerson.path}/person`,
};

export const updatePerson: ServerRoute = {
  method: 'PUT',
  path: `${createPerson.path}/:personId`,
};

export const validateAddressRoute: ServerRoute = {
  method: 'POST',
  path: '/validate/:businessId/person/:personId/address/:addressId',
};

export const getUrl = (path: string): string => {
  const baseUrl = process.env.SPCS_URL;
  return `${baseUrl}${path}`;
};

export const agencyRoles = [
  'HAUPTVERMITTLER',
  'BERATUNGSVERMITTLER',
  'BESTANDSVERMITTLER',
];

export const DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES = '8565';

export const DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES = '8504';

export const DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES = '01280200';
