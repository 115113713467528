import React from 'react';
import StepButton from '../../../../components/StepButton/StepButton';
import translations from '../../../../constants/translations';
import StepSection from '../StepSection';

type TermsofServiceStepsProps = {
  disableDownloadFileButton: boolean;
  onClickInDownloadDocument: () => void;
  documentDownloaded: boolean;
  isDownloadLoading: boolean;
  isSubmitLoading: boolean;
  disableContinueButton: boolean;
  onClickContinueButton: () => void;
  downloadError: [];
  submitError: [];
};

const TermsofServiceSteps = ({
  disableDownloadFileButton,
  onClickInDownloadDocument,
  documentDownloaded,
  isDownloadLoading,
  isSubmitLoading,
  disableContinueButton,
  onClickContinueButton,
  downloadError,
  submitError,
}: TermsofServiceStepsProps): JSX.Element => {
  return (
    <>
      <StepSection
        title={translations.step12.steps.one.title}
        subtitle={translations.step12.steps.one.subtitle}
        error={downloadError}
      >
        <StepButton
          step={1}
          text={translations.step12.steps.one.buttonText}
          disabled={disableDownloadFileButton}
          onClick={onClickInDownloadDocument}
          isDone={documentDownloaded}
          loading={isDownloadLoading}
        />
      </StepSection>

      <StepSection
        title={translations.step12.steps.two.title}
        subtitle={translations.step12.steps.two.subtitle}
        error={submitError}
      >
        <StepButton
          step={2}
          text={translations.step12.steps.two.buttonText}
          subText={translations.step12.steps.two.buttonAdditionalText}
          disabled={disableContinueButton}
          onClick={onClickContinueButton}
          loading={isSubmitLoading}
        />
      </StepSection>
    </>
  );
};

export default TermsofServiceSteps;
