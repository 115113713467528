import { damageTypeMapping, policyTerminatorMapping } from 'commons';
import {
  DamageNumber,
  GlassDamage,
  GlassPreviousDamage,
  hasDoorLocks,
  HouseholdDamage,
  InsurancePolicyTerminated,
  PreviousDamage,
  PreviousInsurance,
} from 'commons/apis/hausrat/types';
import { ElectronicOrMechatronickLocks } from 'commons/apis/offerEngine';
import { dateToDateInputValue } from '../../../helpers/dates/dates';
import { RootState } from '../../types';
import { getRisikoOrtFromOffer } from './generics';

type GetAdditionalQuestionsReturn = {
  previousHouseholdDamage: PreviousDamage;
  previousHouseholdInsurance: PreviousInsurance;
  previousGlassDamage: GlassPreviousDamage;
  previousGlassInsurance: PreviousInsurance;
  hasElectronicOrMechatronickLocks: hasDoorLocks;
};
export const getAdditionalQuestionsFromOffer = (
  state: RootState
): GetAdditionalQuestionsReturn => {
  const previousInsurances = state.offer.angebot?.vorversicherungen ?? [];
  const previousDamages = state.offer.angebot?.vorschaeden ?? [];

  const previousInsurance = previousInsurances.find(
    insurance => insurance.vertragsArt === 'HAUSRAT'
  );
  const [policyTerminatedBy] =
    Object.entries(policyTerminatorMapping).find(
      ([, value]) => value === previousInsurance?.status
    ) ?? [];
  const previousHouseholdInsurance = {
    selected: !!previousInsurance?.vorversicherungsId,
    endDate: previousInsurance?.versicherungsEnde
      ? dateToDateInputValue(new Date(previousInsurance.versicherungsEnde))
      : {},
    companyName: previousInsurance?.vuName ?? '',
    policyNumber: previousInsurance?.vsScheinNr,
    policyTerminatedBy: policyTerminatedBy as InsurancePolicyTerminated,
    vuNr: previousInsurance?.vuNr,
  };

  const previousDamage = previousDamages.find(
    insurance => insurance.vertragsArt === 'HAUSRAT'
  );
  const [damageType] =
    Object.entries(damageTypeMapping).find(
      ([, value]) => value === previousDamage?.schadenArt
    ) ?? [];
  const previousHouseholdDamage = {
    selected: !!previousDamage?.vorschadenId,
    cost: previousDamage?.schadensumme,
    number: 'one' as DamageNumber,
    type: damageType as HouseholdDamage,
    year: previousDamage?.schadenjahr,
  };

  const previousInsuranceGlass = previousInsurances.find(
    insurance => insurance.vertragsArt === 'HAUSHALT_GLAS'
  );
  const [glassPolicyTerminatedBy] =
    Object.entries(policyTerminatorMapping).find(
      ([, value]) => value === previousInsuranceGlass?.status
    ) ?? [];
  const previousGlassInsurance = {
    selected: !!previousInsuranceGlass?.vorversicherungsId,
    endDate: previousInsuranceGlass?.versicherungsEnde
      ? dateToDateInputValue(new Date(previousInsuranceGlass.versicherungsEnde))
      : {},
    companyName: previousInsuranceGlass?.vuName ?? '',
    policyNumber: previousInsuranceGlass?.vsScheinNr,
    policyTerminatedBy: glassPolicyTerminatedBy as InsurancePolicyTerminated,
    vuNr: previousInsuranceGlass?.vuNr,
  };

  const previousDamageGlass = previousDamages.find(
    insurance => insurance.vertragsArt === 'HAUSHALT_GLAS'
  );
  const [glassDamageType] =
    Object.entries(damageTypeMapping).find(
      ([, value]) => value === previousDamageGlass?.schadenArt
    ) ?? [];
  const previousGlassDamage = {
    selected: !!previousDamageGlass?.vorschadenId,
    cost: previousDamageGlass?.schadensumme,
    number: 'one' as DamageNumber,
    type: glassDamageType as GlassDamage,
    year: previousDamageGlass?.schadenjahr,
  };

  const otherLocks =
    getRisikoOrtFromOffer(state)?.elektronischMechatronischesSchloss;

  const hasElectronicOrMechatronickLocks = {
    selected: otherLocks === ElectronicOrMechatronickLocks.Present,
  };

  return {
    previousHouseholdDamage,
    previousHouseholdInsurance,
    previousGlassDamage,
    previousGlassInsurance,
    hasElectronicOrMechatronickLocks,
  };
};
