import React from 'react';
import { Route, Routes } from 'react-router-dom';
import type { RouterType } from './RouterTypes';
import HomeLocation from '../pages/step2/HomeLocation';
import { HomeSquareMeters } from '../pages/step3/HomeSquareMeters';
import InsuranceDate from '../pages/step5/InsuranceDate';
import DateOfBirth from '../pages/step6/DateOfBirth';
import InsuranceCoverage from '../pages/step7/InsuranceCoverage';
import BankDetails from '../pages/step11/BankDetails';
import { Feedback } from '../pages/step13/Feedback';
import PersonalData from '../pages/step9/PersonalData';
import AdditionalQuestions from '../pages/step8/AdditionalQuestions';
import ContractReview from '../pages/step10/ContractReview';
import { Address } from '../pages/step4/Address';
import { LivingPlace } from '../pages/step1/LivingPlace';
import { URLs } from 'commons';
import TermsOfService from '../pages/step12/TermsOfService';

export const routes: RouterType[] = [
  {
    name: 'livingPlace',
    path: URLs.livingPlaceType,
    element: <LivingPlace />,
    label: 'Living Place',
  },
  {
    name: 'homeLocation',
    path: URLs.homeLocation,
    element: <HomeLocation />,
    label: 'Home Location',
  },
  {
    name: 'homeSquareMeters',
    path: URLs.homeSquareMeters,
    element: <HomeSquareMeters />,
    label: 'Home Square Meters',
  },
  {
    name: 'address',
    path: URLs.address,
    element: <Address />,
    label: 'Address',
  },
  {
    name: 'insuranceDate',
    path: URLs.insuranceStartDate,
    element: <InsuranceDate />,
    label: 'Insurance Date',
  },
  {
    name: 'birthDate',
    path: URLs.birthDate,
    element: <DateOfBirth />,
    label: 'Birth Date',
  },
  {
    name: 'insuranceCoverage',
    path: URLs.insuranceCoverage,
    element: <InsuranceCoverage />,
    label: 'Insurance Coverage',
  },
  {
    name: 'additionalQuestions',
    path: URLs.additionalQuestions,
    element: <AdditionalQuestions />,
    label: 'Additional Questions',
  },
  {
    name: 'personalData',
    path: URLs.personalData,
    element: <PersonalData />,
    label: 'Personal Data',
  },
  {
    name: 'contractReview',
    path: URLs.contractReview,
    element: <ContractReview />,
    label: 'Contract Review',
  },
  {
    name: 'bankDetails',
    path: URLs.bankDetails,
    element: <BankDetails />,
    label: 'Bank Details',
  },
  {
    name: 'termsOfService',
    path: URLs.termsOfService,
    element: <TermsOfService />,
    label: 'Terms of Service',
  },
  {
    name: 'feedback',
    path: URLs.feedback,
    element: <Feedback />,
    label: 'Feedback',
  },
];

const Sections = (): JSX.Element => (
  <Routes>
    {routes.map(route => (
      <Route key={route.name} path={route.path} element={route.element} />
    ))}
    <Route key={0} path="*" element={<LivingPlace />} />
  </Routes>
);

export default Sections;
