import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../types';

export const businessIdSelector = (state: RootState): string | null =>
  state.app.businessId;

export const discountSelector = createSelector(
  (state: RootState) => state.app,
  app => app.discount
);

export const reachedFeedbackSelector = (state: RootState): boolean =>
  state.app.reachedFeedback;

export const initialLoadSelector = ({ app }: RootState): boolean =>
  app.isPendingBusinessId;

export const readySelector = ({ app }: RootState): boolean =>
  !!app.businessId && !app.isPendingBusinessId;

export const getGenericErrorSelector = ({ app }: RootState): boolean =>
  app.genericError;

export const isAppLoadingSelector = (state: RootState): boolean => {
  const { app, offer, ...pages } = state;
  return (
    Object.values(pages).some(page => page.isLoading) || app.isPendingBusinessId
  );
};
