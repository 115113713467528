import {
  Leistungsvereinbarungen,
  Wertebereiche,
} from 'commons/apis/offerEngine';
import { productModuleMapping } from '../pages/step7/utils';
import {
  CheckboxField,
  DropdownField,
  DropdownOption,
} from '../types/formFields';

export const idsToModuleNames = Object.entries(productModuleMapping).reduce(
  (aggr, curr) => {
    const [key, value] = curr;
    aggr[value] = key;
    return aggr;
  },
  {} as Record<string, string>
);

export const createCheckboxFromTariff = (
  tariff: Leistungsvereinbarungen
): CheckboxField => {
  const checkedAndDisabled = tariff.obligatorisch && tariff.vereinbart;

  return {
    checked: checkedAndDisabled || tariff.vereinbart,
    disabled: checkedAndDisabled || !tariff.auswaehlbar,
    label: tariff.bezeichnung,
    mandatory: tariff.obligatorisch,
    name: idsToModuleNames[tariff.leistungsvereinbarungsId],
  };
};

export const createDropdownFromVariant = (
  label: string,
  name: string,
  rawField: Wertebereiche,
  emptyOption?: DropdownOption
): DropdownField => {
  const labels = (rawField.bezeichnungen ?? []) as string[];
  const values = (rawField.werte ?? []) as string[];

  const options = labels.map<DropdownOption>((label, index) => ({
    label,
    value: values[index],
  }));

  if (emptyOption) {
    options.unshift(emptyOption);
  }

  return {
    disabled: false,
    label,
    mandatory: rawField.obligatorisch,
    name,
    options,
    value: rawField.vorbelegung,
  };
};
