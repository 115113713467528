import React from 'react';
import { useSelector } from 'react-redux';
import CheckBoxRow from '../../components/CheckBoxRow/CheckBoxRow';
import { useAppSelector } from '../../store/hooks';
import { getSelectedVariantFromOffer } from '../../store/offerSlice';

import { useForm } from '../../store/useForm';
import { isZursValueSelector } from '../step4/addressSlice';
import { benefitModules } from './data';
import { updateProductModule } from './insuranceCoverageSlice';
import { CoverageModuleType } from './InsuranceCoverageTypes';
import { ModuleBenefitsProps } from './ModuleBenefitsTypes';
import { InfoIcons } from './TrackingTypes';

const ModuleBenefits = ({
  fields,
  handleChangeOption,
  onOpenInfoIcon,
}: ModuleBenefitsProps): JSX.Element => {
  const { dispatch } = useForm();
  const zursValue = useAppSelector(isZursValueSelector);
  const onModalOpenHandler = (key: string): void => {
    onOpenInfoIcon(key as InfoIcons);
  };
  const selectedVariant = useSelector(getSelectedVariantFromOffer);

  const zursValue4 = zursValue === 'ZUERS_GK_4';
  const unnamedPerilsoff =
    selectedVariant.tarifvariante === 'Smart' || zursValue4;

  return (
    <section className="card-section">
      <div className="card-section__wrap">
        <h3 className="card-section__header">Wählbare Leistungsbausteine</h3>

        <ul className="list">
          {fields.services.map(field => {
            const name = field.name as CoverageModuleType;
            const { subLabel, toolTip: ToolTip } =
              benefitModules.find(m => m.name === field.name) || {};

            const handleChangeCheckBoxRow = (
              event: React.ChangeEvent<HTMLInputElement>
            ): void => {
              dispatch(updateProductModule(name));
              handleChangeOption(name, event.target.checked);
            };

            return (
              <li
                key={field.name}
                className={
                  (unnamedPerilsoff && name === 'unnamedPerils') ||
                  (zursValue4 && name === 'otherNaturalHazards')
                    ? 'list__element__benefit--none'
                    : 'list__element__benefit'
                }
              >
                <CheckBoxRow
                  checkedValue={field.checked}
                  disabled={field.disabled}
                  name={name}
                  onChange={handleChangeCheckBoxRow}
                  readOnly={field.disabled}
                  required={field.mandatory}
                  showTooltip={!!ToolTip}
                  subtitle={subLabel}
                  title={field.label}
                  toolTipComponent={ToolTip}
                  dataTestId={field.name}
                  onOpened={(): void => onModalOpenHandler(field.name)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ModuleBenefits;
