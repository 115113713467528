export enum InfoIcons {
  SUM_INSURANCE = 'sumInsurance',
  GROSS_NEGLIGENCE = 'grossNegligence',
  SIMPLE_THEFT_INCLUDED = 'simpleTheftIncluded',
  LINK_ALL_SERVICES = 'linkAlleLeistungen',
  SIMPLE_THEFT = 'simpleTheft',
  HOUSE_AND_FLAT = 'houseAndFlat',
  NATURAL_HAZARDS = 'otherNaturalHazards',
  UNNAMED_DANGER = 'unnamedPerils',
  GLASS = 'glass',
  BICYCLE = 'bicycleAndEbike',
  EXCESS = 'excess',
  PAYMENT_METHOD = 'paymentMethod',
  CONTRACT_TERM = 'contractTerm',
  BICYCLE_INSURANCE_TYPE = 'bicycleInsurance',
}
