import { hausratApi, AddressErrors } from 'commons';
import { BankData } from 'commons/apis/spcs/payments/types';
import { InsuranceCompany } from 'commons/apis/insuranceCompanies/types';
import { AddressFormTypes } from '../../pages/step4/AddressTypes';
import { PersonalDataForm } from '../../pages/step9/PersonalDataTypes';
import type {
  ApiBirthDateParams,
  ApiBirthDateResponse,
  ApiOfferResponse,
} from './offerService.d';
import {
  AdditionalQuestionsForm,
  CreateOfferResponse,
  InsuranceCoverage,
  DownloadOrSubmitOfferErrorResponse,
  InsuranceVariant,
  AgencyDetails,
} from 'commons/apis/hausrat/types';
import { SubmitOfferResponse } from 'commons/apis/submitEngine/submitOffer/types';
import { ibanValidationErrorCodes } from '../../constants/commons';
import { handleServiceError } from '../../utils/errorHandler';
// Screen 0
export const createOffer = async (
  businessId?: string,
  agencyDetails?: AgencyDetails
): Promise<CreateOfferResponse> => {
  try {
    const response = await hausratApi.offers.createOffer(
      businessId,
      agencyDetails
    );
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 1
export const livingPlace = async (
  businessId: string,
  value: string
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.livingPlace(businessId, value);
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 2
export const floorType = async (
  businessId: string,
  value: string
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.floorType(businessId, value);
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 3
export const squareMeters = async (
  businessId: string,
  value: string
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.squareMeters(businessId, value);
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 4
export const getCitiesFromAddress = async (
  businessId: string,
  personId: string,
  value: AddressFormTypes
): Promise<AddressFormTypes[]> => {
  try {
    const response = await hausratApi.offers.getCitiesFromAddress(
      businessId,
      personId,
      value
    );

    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

export const customerAddress = async (
  businessId: string,
  personId: string,
  value: AddressFormTypes
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.customerAddress(
      businessId,
      personId,
      value
    );
    return response;
  } catch (error) {
    const isAddressError = error?.errors?.some(
      (error: string) => AddressErrors[error]
    );

    handleServiceError({
      error,
      setGenericErrorRequired: !isAddressError,
      throwNewErrorRequired: false,
      particularThrowError: error,
    });
  }
};

// Screen 5
export const insuranceStartDate = async (
  businessId: string,
  payload: InsuranceVariant[]
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.insuranceStartDate(
      businessId,
      payload
    );
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 6
export const birthDate = async ({
  businessId,
  dateOfBirth,
  personId,
}: ApiBirthDateParams): Promise<ApiBirthDateResponse> => {
  try {
    const response = await hausratApi.offers.birthDate({
      businessId,
      dateOfBirth,
      personId,
    });
    return response;
  } catch (error) {
    handleServiceError({ error, setGenericErrorRequired: false });
  }
};

// Screen 7
export const insuranceCoverage = async (
  businessId: string,
  value: InsuranceCoverage
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.insuranceCoverage(
      businessId,
      value
    );
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

export const insuranceCoverageGlasField = async (
  businessId: string,
  variantIds: string[],
  selected: boolean
): Promise<ApiOfferResponse> => {
  try {
    const response = hausratApi.offers.insuranceGlasField(businessId, {
      variantIds,
      selected,
    });
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 8
export const additionalQuestions = async (
  businnessId: string,
  additionalQuestions: AdditionalQuestionsForm
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.additionalQuestions(
      businnessId,
      additionalQuestions
    );
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

export const previousInsuranceCompanies = async (
  searchValue: string
): Promise<InsuranceCompany[]> => {
  try {
    const response = await hausratApi.offers.previousInsuranceCompanies(
      searchValue
    );
    return response;
  } catch (error) {
    handleServiceError({ error, setGenericErrorRequired: false });
  }
};

// Screen 9
export const personalData = async (
  businessId: string,
  personId: string,
  value: PersonalDataForm
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.personalData(
      businessId,
      personId,
      value
    );
    return response;
  } catch (error) {
    const errorMessage = error?.response?.data?.message;
    if (errorMessage) {
      handleServiceError({
        error,
        throwNewErrorRequired: false,
        particularThrowError: errorMessage,
        setGenericErrorRequired: false,
      });
      throw errorMessage;
    }
    handleServiceError({ error, setGenericErrorRequired: false });
  }
};

// Screen 11
export const getBankDataFromIban = async (
  businessId: string,
  personId: string,
  value: string,
  paymentId: string
): Promise<BankData> => {
  try {
    const response = await hausratApi.offers.getBankDataFromIban(
      businessId,
      personId,
      value,
      paymentId
    );

    return response;
  } catch (error) {
    const isIbanValidationError = ibanValidationErrorCodes.includes(
      error?.response?.status
    );

    handleServiceError({
      error,
      setGenericErrorRequired: !isIbanValidationError,
    });
  }
};

export const bankDetails = async (
  businessId: string,
  value: string
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.bankDetails(businessId, value);
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

// Screen 12
export const submitOffer = async (
  businessId: string
): Promise<SubmitOfferResponse> => {
  try {
    const response = await hausratApi.offers.submitOffer(businessId);
    return response;
  } catch (error) {
    handleServiceError({ error, setGenericErrorRequired: false });
  }
};

export const downloadOffer = async (businessId: string): Promise<Blob> => {
  try {
    const response = await hausratApi.offers.downloadOffer(businessId);
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

export const acceptConditions = async (
  businessId: string,
  value: boolean
): Promise<ApiOfferResponse> => {
  try {
    const response = await hausratApi.offers.acceptConditions(
      businessId,
      value
    );
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};

export const validateOffer = async (
  businessId: string
): Promise<DownloadOrSubmitOfferErrorResponse> => {
  try {
    const response = await hausratApi.offers.validateOffer(businessId);
    return response;
  } catch (error) {
    handleServiceError({ error });
  }
};
