import React from 'react';
import translations from '../../../constants/translations';

const { step7 } = translations;

const SimpleTheftIncluded = (): JSX.Element => {
  return (
    <div data-testid="simple-theft-included-info">
      <h3 data-testid="simple-theft-included-title">
        {step7.simpleTheftIncluded.title}
      </h3>
      <p data-testid="simple-theft-included-p1">
        {step7.simpleTheftIncluded.paragraph1}
      </p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li>{step7.simpleTheftIncluded.listOptions1.a}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.b}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.c}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.d}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.e}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.f}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.g}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.h}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.i}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.j}</li>
        <li>{step7.simpleTheftIncluded.listOptions1.k}</li>
      </ul>
      <p data-testid="simple-theft-included-p2">
        {step7.simpleTheftIncluded.paragraph2}
      </p>
    </div>
  );
};

export default SimpleTheftIncluded;
