import React from 'react';
import { useSelector } from 'react-redux';
import { RadioDrawer } from '../../../../../components/RadioDrawer/RadioDrawer';
import translations from '../../../../../constants/translations';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { useForm } from '../../../../../store/useForm';
import {
  insuranceCompaniesSuggestionsSelector,
  setPreviousHouseholdInsurance,
} from '../../../additionalQuestionsSlice';
import {
  PreviousInsuranceKey,
  PreviousInsuranceQuestionProps,
} from '../../../AdditionalQuestionsTypes';
import { ErrorsForInput } from '../../../ErrorsForInput';
import { previousInsuranceValidations } from '../../../utils/validations/previousInsurance';
import { getErrorMessage } from '../../../utils/validations/validators';
import { PreviousInsuranceQuestionFields } from './PreviousInsuranceQuestionFields';

interface Props extends PreviousInsuranceQuestionProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (id: string) => void;
}

export const PreviousInsuranceQuestion = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const suggestions = useSelector(insuranceCompaniesSuggestionsSelector);
  const { previousInsurance, dispatch } = useForm();
  const validations = useFormValidations(
    previousInsurance,
    previousInsuranceValidations(suggestions.previousInsuranceCompanies)
  );
  const displayError = (field: PreviousInsuranceKey): string => {
    let error;
    if (typeof validations[field] !== 'undefined') {
      error = getErrorMessage(validations[field]);
    }
    if (typeof validations[field] == 'undefined' && isGoNextClicked) {
      error = translations.step8.pleaseSelectPreviousInsuranceRadio;
    }
    if (error) {
      updateTrackingErrors(error);
    }
    return error;
  };
  const selectedError = displayError('selected')
    ? [displayError('selected')]
    : undefined;

  const handleOnChangeRadioDrawer = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const value = target.value === 'true';
    dispatch(setPreviousHouseholdInsurance(value));

    if (onClick) {
      const trulyRadioBtn = 'RBUTTON_PRE_INSURANCE_YES';
      const falsyRadioBtn = 'RBUTTON_PRE_INSURANCE_NO';
      onClick(value ? trulyRadioBtn : falsyRadioBtn);
    }
  };

  const onClickRadioBtn = (id: string): void => {
    onClick && onClick(id);
  };

  const subtitle = (
    <p
      className="additional-question__subtitle-paragraph"
      dangerouslySetInnerHTML={{
        __html: translations.step8.previousHouseholdInsuranceSubtitle,
      }}
    ></p>
  );

  return (
    <div className="additional-question">
      <RadioDrawer
        title={translations.step8.previousInsurancesInLast5Years}
        subtitle={subtitle}
        value={previousInsurance.selected}
        onChange={handleOnChangeRadioDrawer}
        children={
          <PreviousInsuranceQuestionFields
            onClick={onClickRadioBtn}
            isGoNextClicked={isGoNextClicked}
            updateTrackingErrors={updateTrackingErrors}
          />
        }
      />
      <ErrorsForInput
        errors={selectedError}
        updateTrackingErrors={updateTrackingErrors}
      />
    </div>
  );
};
