import React from 'react';
import Radio from '../../../../../components/ERadio/ERadio';

import { useForm } from '../../../../../store/useForm';
import { setPreviousGlassDamageNumber } from '../../../additionalQuestionsSlice';
import { ErrorsForInput } from '../../../ErrorsForInput';
import MoreThanOneDamageFields from '../../MoreThanOneDamageFields';
import { OneDamageGlassFields } from './OneDamageGlassFields';
import { DamageNumber } from 'commons/apis/hausrat/types';
import { useFormValidations } from '../../../../../hooks/useFormValidations';
import { previousGlassDamageValidations } from '../../../utils/validations/previousGlassDamage';
import translations from '../../../../../constants/translations';
import { PreviousGlassDamageQuestionFieldsProps } from '../../../AdditionalQuestionsTypes';

interface Props extends PreviousGlassDamageQuestionFieldsProps {
  onClick?: (id: string) => void;
  updateTrackingErrors: (error: string) => void;
}

export const PreviousGlassDamageQuestionFields = ({
  onClick,
  isGoNextClicked,
  updateTrackingErrors,
}: Props): JSX.Element => {
  const { previousGlassDamage, dispatch } = useForm();
  const validations = useFormValidations(
    previousGlassDamage,
    previousGlassDamageValidations
  );

  const damageNumberError =
    validations.number == undefined && isGoNextClicked
      ? translations.step8.pleaseSelect
      : undefined;
  const handleGlassDamageNumber = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const damageNumber = value as DamageNumber;
    dispatch(setPreviousGlassDamageNumber(damageNumber));

    if (onClick) {
      const isOne = damageNumber === 'one';
      const elementKey = isOne
        ? 'RBUTTON_DAMAGE_GLAS_EQUAL1'
        : 'RBUTTON_DAMAGE_GLAS_SIZE1';
      onClick(elementKey);
    }
  };
  if (damageNumberError) {
    updateTrackingErrors(damageNumberError);
  }

  return (
    <div className="additional-question__card">
      <div className="additional-question-radio-group">
        <p>{translations.step8.damageAmountTitle}</p>
        <p>{translations.step8.damageGlassAmountQuestion}</p>
        <Radio
          value="one"
          label="1"
          checked={previousGlassDamage.number === 'one'}
          onChange={handleGlassDamageNumber}
        />
        <Radio
          value="moreThanOne"
          label={translations.step8.moreThanOne}
          checked={previousGlassDamage.number === 'moreThanOne'}
          onChange={handleGlassDamageNumber}
        />
      </div>
      <ErrorsForInput
        errors={[damageNumberError]}
        updateTrackingErrors={updateTrackingErrors}
      />

      {previousGlassDamage.number === 'one' && (
        <OneDamageGlassFields
          isGoNextClicked={isGoNextClicked}
          updateTrackingErrors={updateTrackingErrors}
        />
      )}

      {previousGlassDamage.number === 'moreThanOne' && (
        <MoreThanOneDamageFields updateTrackingErrors={updateTrackingErrors} />
      )}
    </div>
  );
};
