import { RootState } from '../../types';
import { getRisikoOrtFromOffer, getSelectedVariantFromOffer } from './generics';

export const getHomeSquareMetersFromOffer = (
  state: RootState
): number | undefined => getRisikoOrtFromOffer(state)?.wohnflaeche;

export const getInsuredAmountFromOffer = (
  state: RootState
): number | undefined => {
  const selectedVariant = getSelectedVariantFromOffer(state);
  return selectedVariant?.versicherungssumme ?? 0;
};

export const isHomeSquareMetersUpdated = (state: RootState): boolean => {
  const offerValue = getRisikoOrtFromOffer(state)?.wohnflaeche;
  return state.homeSquareMeters.form.sizeInSM !== offerValue;
};
