import { rest } from 'msw';
import { Handler } from '../../types';
import { getCitiesFromAddress, getUrl, validateAddress } from './constants';
import {
  CustomerAddressResponse,
  GetCitiesFromAddressResponse,
  SearchStatus,
  Validity,
} from './types';

const removeQueryParams = (url: string): string => {
  const [path] = url.split('?');
  return path;
};

const getCitiesFromAddressHandler = (
  rest[getCitiesFromAddress.method.toLowerCase()] as Handler
)(removeQueryParams(getUrl(getCitiesFromAddress.path)), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      correlationId: '123456',
      matchingAddresses: [
        {
          city: 'Berlin',
          street: 'Alexander-Abusch-Str.',
          zipCode: '12619',
          municipalityKey: '2',
        },
      ],
      message: {
        code: '1',
        messages: [],
      },
      statusCode: SearchStatus.FOUND,
    } as GetCitiesFromAddressResponse)
  )
);

const validateAddressHandler = (
  rest[validateAddress.method.toLowerCase()] as Handler
)(removeQueryParams(getUrl(validateAddress.path)), (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      correctedAddress: {
        city: 'Berlin',
        street: 'Alexander-Abusch-Str.',
        houseNumber: '40',
        zipCode: '12619',
        municipalityKey: '2',
      },
      correlationId: '123456',
      message: {
        code: '1',
        messages: [],
      },
      validity: Validity.CORRECTED,
    } as CustomerAddressResponse)
  )
);

const addressValidationHandlers = [
  getCitiesFromAddressHandler,
  validateAddressHandler,
];

export default addressValidationHandlers;
