import {
  InvalidField,
  ValidationResult,
  ValidField,
} from '../../../../store/types';

export const generateValidField = (): ValidField => ({ isValid: true });

export const getSanitizedNumber = (value: string | number): false | number => {
  const sanitizedValue = value === '' ? undefined : Number(value);
  return isNaN(sanitizedValue) ? undefined : sanitizedValue;
};

export const generateInvalidField = <T extends Record<string, string>>(
  collection?: T,
  field?: keyof T
): InvalidField => {
  if (collection && field) {
    return { isValid: false, error: collection[field] };
  }
  return { isValid: false };
};

export const getErrorMessage = (field: ValidationResult): string => {
  switch (field.isValid) {
    case false:
      return field.error;
    default:
      break;
  }
};
