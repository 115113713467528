import React from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { ECheckboxProps } from './ECheckbox.d';
import './ECheckbox.scss';

/** ERGO elements' radio button wrapped with tracking */
const ECheckbox = ({
  className,
  checked,
  'data-testid': dataTestId,
  disabled,
  id,
  label,
  name,
  errorMessage,
  onChange,
  required,
  trackingOptions,
  type,
  value,
  verticalAlign,
}: ECheckboxProps): JSX.Element => {
  const trackedOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // TODO, here we'll have the tracking dispatch logic, when it's shaped
    console.log('!!!!-----ಠಠ 🕵️ trackingOptions ------>', trackingOptions); // eslint-disable-line
    return onChange(e);
  };

  return (
    <div className="checkbox__content">
      <Checkbox
        checked={checked}
        className={className}
        data-testid={dataTestId}
        disabled={disabled}
        id={id}
        label={label}
        name={name}
        onChange={trackingOptions ? trackedOnChange : onChange}
        required={required}
        type={type}
        value={value}
        verticalAlign={verticalAlign}
      />
      {!!errorMessage && (
        <p className="checkbox__content-error">{errorMessage}</p>
      )}
    </div>
  );
};

export default ECheckbox;
