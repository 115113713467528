import './config'; // don't change the order of this line
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import getClientWorker from 'commons/mocks/client';

import { store } from './store/index';
import App from './components/AppComponent';
import { metatagType } from './types/app';

const metaOverwrite = [
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1.0, user-scalable=no',
  },
];

const setMetatags = (data: metatagType): void => {
  data.forEach(item => {
    const { name, content } = item;

    const existingMetaTag = document.querySelector(`meta[name=${name}]`);
    const metaTag = existingMetaTag || document.createElement('meta');

    metaTag.setAttribute('name', name);
    metaTag.setAttribute('content', content);

    if (!existingMetaTag) {
      document.head.append(metaTag);
    }
  });
};

const initApp = (): void => {
  if (process.env.REACT_APP_MOCKS === 'true') {
    const worker = getClientWorker();
    worker.start();
  }

  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('b2c-hausrat-otr')
  );

  setMetatags(metaOverwrite);
};

initApp();
