import { ValidationResult, ValidatorFunction } from '../store/types';

export const useFormValidations = <T extends Record<string, unknown>>(
  form: T,
  validations: Record<keyof T, ValidatorFunction<unknown>>
): Record<keyof T, ValidationResult> => {
  const formData = form ? Object.entries(form) : [];

  const validatedFields = formData.reduce((acc, [key, value]) => {
    const result = validations[key]?.(value);
    return { ...acc, [key]: result };
  }, {} as Record<keyof T, ValidationResult>);

  return validatedFields;
};
