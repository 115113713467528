import React from 'react';
import translations from '../../../constants/translations';

const BikeTheft = (): JSX.Element => {
  const { step7 } = translations;

  return (
    <div data-testid="bike-theft-info">
      <h3 data-testid="bike-theft-title">{step7.bikeTheftModal.title}</h3>
      <p
        data-testid="bike-theft-p1"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph1,
        }}
      ></p>
      <p
        data-testid="bike-theft-p2"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph2,
        }}
      ></p>
      <p
        data-testid="bike-theft-p3"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph3,
        }}
      ></p>
      <p data-testid="bike-theft-p4">{step7.bikeTheftModal.paragraph4}</p>
      <p
        data-testid="bike-theft-p5"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph5,
        }}
      ></p>
      <p
        data-testid="bike-theft-p6"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph6,
        }}
      ></p>
      <p
        data-testid="bike-theft-p7"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph7,
        }}
      ></p>
      <p data-testid="bike-theft-p8">{step7.bikeTheftModal.paragraph8}</p>
      <ul className="modal-list" data-testid="modal-list-1">
        <li
          dangerouslySetInnerHTML={{
            __html: step7.bikeTheftModal.listOption1,
          }}
        ></li>
        <li
          dangerouslySetInnerHTML={{
            __html: step7.bikeTheftModal.listOption2,
          }}
        ></li>
        <li
          dangerouslySetInnerHTML={{
            __html: step7.bikeTheftModal.listOption3,
          }}
        ></li>
      </ul>
      <p
        data-testid="bike-theft-p9"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph9,
        }}
      ></p>
      <p
        data-testid="bike-theft-p10"
        dangerouslySetInnerHTML={{
          __html: step7.bikeTheftModal.paragraph10,
        }}
      ></p>
      <p data-testid="bike-theft-p11">{step7.bikeTheftModal.paragraph11}</p>
    </div>
  );
};

export default BikeTheft;
