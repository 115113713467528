export const formatDecimals = (number: number): string => {
  const originalValue = number.toFixed(2).toString().split('.');
  const base = originalValue[0];
  const decimals = originalValue[1];

  let result = base.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (typeof decimals !== 'undefined' && decimals !== '00') {
    result += `,${decimals}`;
  }

  return result;
};

export const formatTelephoneNumber = (number: string): string => {
  if (number?.includes('+')) {
    return number?.split(' ').splice(1).join('');
  }
  return number;
};

export const extractCountryCode = (number: string): string => {
  if (number?.includes('+')) {
    return number?.split(' ')[0];
  }
};

export const round = (num: number): number => {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

const keyDownSpecialKeysNotAllowed = ['e'];
const isKeyDownNotAllowed = (key: string): boolean =>
  keyDownSpecialKeysNotAllowed.includes(key);

export const blockedCharactersOnInput = (
  e: React.KeyboardEvent<HTMLInputElement>
): void => {
  if (isKeyDownNotAllowed(e.key)) {
    e.preventDefault();
  }
};
