import {
  Angebot,
  Leistungsvereinbarungen,
  PaymentMethods,
} from 'commons/apis/offerEngine/offers/types';
import { PaymentMethodType } from '../InsuranceCoverageTypes';
import { TrackingIDs } from '../TrackingElements';

export const paymentMethodMapping: Record<PaymentMethodType, PaymentMethods> = {
  monthly: 'MONATLICH',
  quarterly: 'VIERTELJAEHRLICH',
  annual: 'JAEHRLICH',
  'half-year': 'HALBJAEHRLICH',
};

export const productModuleMapping = {
  simpleTheft: '001291',
  houseAndFlat: '001243',
  glass: '001130',
  otherNaturalHazards: '002201',
  unnamedPerils: '002264',
  fahrradkaskoUndMobilitätsschutz: '001246',
  fahrraddiebstahl: '001266',
  'deductible-amount': '008000',
};

const dependentFieldsInEbikeModule = [
  'fahrradkaskoUndMobilitätsschutz',
  'fahrraddiebstahl',
];

const canBeModified = (tariff: Leistungsvereinbarungen): boolean =>
  !(tariff.obligatorisch && tariff.vereinbart);

export const getPropFromAngebot = (id: string): string => {
  switch (id) {
    case 'simpleTheft':
    case 'houseAndFlat':
    case 'glass':
    case 'otherNaturalHazards':
    case 'unnamedPerils':
    case 'fahrradkaskoUndMobilitätsschutz':
    case 'fahrraddiebstahl':
    case 'deductible-amount':
      return productModuleMapping[id];
  }
};

const getFrontendTariffId = (backendId: string): string =>
  Object.entries(productModuleMapping).find(
    ([, value]) => value === backendId
  )?.[0];

interface UpdateDependentFieldsInEbikeParams {
  /**
   * Readable ID used in the frontend map elements
   */
  id: string;
  /**
   * Modified offer that's going to be sent to backend
   */
  updatedAngebot: Angebot;
  value?: number;
}

const updateEbikeFields = ({
  id,
  updatedAngebot,
  value,
}: UpdateDependentFieldsInEbikeParams): Leistungsvereinbarungen[] =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  updatedAngebot.leistungsvereinbarungen
    // Only get elements related to eBike
    .filter(
      tariff =>
        dependentFieldsInEbikeModule.includes(
          getFrontendTariffId(tariff.leistungsvereinbarungsId)
        ) && canBeModified(tariff)
    )
    .map(tariff => ({
      struktur: tariff.struktur,
      leistungsvereinbarungsId: tariff.leistungsvereinbarungsId,
      vereinbart:
        // Since `bike-amount` is not an independent module, we're keeping
        // the actual module's state in that scenario.
        id === TrackingIDs.bikeAmount
          ? tariff.vereinbart
          : getPropFromAngebot(id) === tariff.leistungsvereinbarungsId,
      versicherungssumme: value ?? tariff.versicherungssumme,
    }));

type UncheckEbikeModuleParams = {
  /**
   * Modified offer that's going to be sent to backend
   */
  updatedAngebot: Angebot;
  value?: number;
};

const uncheckEbikeModule = ({
  updatedAngebot,
  value,
}: UncheckEbikeModuleParams): Leistungsvereinbarungen[] =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  updatedAngebot.leistungsvereinbarungen
    // Only get elements related to eBike
    .filter(
      tariff =>
        dependentFieldsInEbikeModule.includes(
          getFrontendTariffId(tariff.leistungsvereinbarungsId)
        ) && canBeModified(tariff)
    )
    .map(tariff => ({
      struktur: tariff.struktur,
      leistungsvereinbarungsId: tariff.leistungsvereinbarungsId,
      vereinbart: false,
      versicherungssumme: value ?? tariff.versicherungssumme,
    }));

type UpdateOfferWithSelectedPropsParams = {
  angebot: Angebot;
  id: string;
  selected: string | boolean;
  selectedVariant: string;
  value?: number | string;
};

export const updateOfferWithSelectedProps = ({
  angebot,
  id,
  selected,
  value,
}: UpdateOfferWithSelectedPropsParams): Angebot => {
  const changedElement = getPropFromAngebot(id);
  const updatedAngebot = { ...angebot };
  const isVariant =
    updatedAngebot?.versicherungen && id === TrackingIDs.productSelector;
  const isEnablingBikeModule = id === TrackingIDs.bikeModule && selected;
  const isDisablingBikeModule = id === TrackingIDs.bikeModule && !selected;
  const isModifyingBikeModule =
    (id === TrackingIDs.bikeAmount && !!value) ||
    dependentFieldsInEbikeModule.includes(id);
  const isDeductibleAmount = id === TrackingIDs.deductibleAmount;
  const isPaymentMethod = id === TrackingIDs.paymentMethod;
  const isContractTerm = id === TrackingIDs.contractTerm;
  const isAnyOtherLvbObject =
    updatedAngebot?.leistungsvereinbarungen &&
    !!changedElement &&
    typeof selected === 'boolean';

  // Delete LVB Object
  if (isVariant || isEnablingBikeModule || isPaymentMethod || isContractTerm) {
    delete updatedAngebot.leistungsvereinbarungen;
  }

  // Delete versigechungen object
  if (
    isEnablingBikeModule ||
    isDisablingBikeModule ||
    isModifyingBikeModule ||
    isAnyOtherLvbObject ||
    isDeductibleAmount
  ) {
    delete updatedAngebot.versicherungen;
  }
  if (isVariant) {
    updatedAngebot.versicherungen = updatedAngebot.versicherungen?.map(
      variant => ({
        ...variant,
        struktur: variant.struktur,
        versicherungsId: variant.versicherungsId,
        ausgewaehlt: variant.versicherungsId.toLowerCase() === selected,
      })
    );
  }

  if (isPaymentMethod || isContractTerm) {
    let key: string;

    switch (id) {
      case 'payment-method':
        key = 'zahlweise';
        break;
      case 'contract-term':
        key = 'laufzeit';
        break;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    updatedAngebot.versicherungen = updatedAngebot.versicherungen?.map(
      variant => ({
        struktur: variant.struktur,
        versicherungsId: variant.versicherungsId,
        [key]: (selected === true ? value : selected) as string,
      })
    );
  }

  if (isDisablingBikeModule) {
    updatedAngebot.leistungsvereinbarungen = uncheckEbikeModule({
      updatedAngebot,
      value: Number(value),
    });
  }

  if (isModifyingBikeModule) {
    updatedAngebot.leistungsvereinbarungen = updateEbikeFields({
      id,
      updatedAngebot,
      value: Number(value),
    });
  }

  if (isDeductibleAmount || (!isModifyingBikeModule && isAnyOtherLvbObject)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updatedAngebot.leistungsvereinbarungen = (
      updatedAngebot.leistungsvereinbarungen ?? []
    )
      ?.filter(tariff => {
        if (changedElement === productModuleMapping['deductible-amount']) {
          return tariff.leistungsvereinbarungsId === changedElement;
        } else {
          return (
            tariff.leistungsvereinbarungsId === changedElement &&
            canBeModified(tariff)
          );
        }
      })
      ?.map(tariff => {
        const payload = {
          leistungsvereinbarungsId: tariff.leistungsvereinbarungsId,
          struktur: tariff.struktur,
          vereinbart: selected,
        };

        if (isDeductibleAmount) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          payload.selbstbehalt = (
            selected === true ? value : selected
          ) as string;
        }

        if (!isDeductibleAmount && value) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          payload.versicherungssumme = value as number;
        }

        return payload;
      });
  }
  return updatedAngebot;
};
