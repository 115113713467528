import React, { useEffect, useState } from 'react';
import FormActions from '../../components/FormSection/components/FormActions';
import FormSectionBody from '../../components/FormSection/components/FormSectionBody';
import FormSectionFooter from '../../components/FormSection/components/FormSectionFooter';
import FormSectionHeader from '../../components/FormSection/components/FormSectionHeader';
import FormSection from '../../components/FormSection/FormSection';
import { useForm } from '../../store/useForm';
import {
  setAgreeConditions,
  setDocumentDownloaded,
  resetErrors,
} from './TermsOfServiceSlice';
import './TermsOfService.scss';
import FormFooter from '@eg/elements/FormFooter';
import TermsOfServiceSteps from './components/TermsOfServiceSteps';
import ConsultationNote from './components/ConsultantNote';
import useStepNavigation from '../../hooks/useStepNavigation';
import { useAppSelector } from '../../store/hooks';
import {
  submitOfferValidation,
  downloadDocument,
  isDocumentDownloaded,
  checkAcceptConditions,
} from './TermsOfServiceSlice';
import translations from '../../constants/translations';
import { ModalErrors } from '../../components/ModalErrors/ModalErrors';
import { getGenericErrorSelector } from '../../store/appSlice';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import useClickTracking from './hooks/useClickTracking';

const TermsOfService = (): JSX.Element => {
  const [offerSubmitted, setofferSubmitted] = useState(false);
  const documentDownloaded = useAppSelector(isDocumentDownloaded);
  const { goPrev, goNext } = useStepNavigation();
  const isError = useAppSelector(getGenericErrorSelector);
  const {
    isSubmitLoading,
    isDownloadLoading,
    dispatch,
    acceptConditions,
    downloadErrors,
    submitErrors,
    acceptConditionsLoading,
  } = useForm();
  const { trackingOptions, onClickTracking } = useClickTracking();

  const handleOnChangeConsultationWeiver = (
    value: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { target } = value;
    const { checked } = target;
    onClickTracking(`CHECKBOX_ADVICE_${checked ? 'ACTIVE' : 'INACTIVE'}`);
    dispatch(setAgreeConditions(checked));
    dispatch(checkAcceptConditions());
    setIsPristineCheckbox(false);
  };

  const handleOnClickInDownloadDocument = (): void => {
    onClickTracking('BUTTON_IDD_DOWNLOAD');
    dispatch(downloadDocument());
  };

  const handleBackButton = (): void => {
    goPrev();
  };

  const handleOnClickContinueButton = (): void => {
    onClickTracking('BUTTON_NEXT');
    dispatch(submitOfferValidation())
      .unwrap()
      .then(() => {
        setofferSubmitted(true);
      })
      .then(() => goNext());
  };

  const [isPristineCheckbox, setIsPristineCheckbox] = useState(true);

  useEffect(() => {
    if (downloadErrors) {
      dispatch(setDocumentDownloaded(false));
    }
  }, [downloadErrors]);

  useEffect(() => {
    if (!submitErrors.length && offerSubmitted) {
      goNext();
    }
  }, [submitErrors, offerSubmitted]);

  useEffect(() => {
    dispatch(setAgreeConditions(false));
    dispatch(setDocumentDownloaded(false));
    dispatch(resetErrors());
  }, []);
  return (
    <FormSection>
      <FormSectionHeader
        title={translations.step12.title}
        subtitle={translations.step12.subtitle}
      />
      <FormSectionBody>
        <ConsultationNote
          showError={!acceptConditions && !isPristineCheckbox}
          consultationWeiver={acceptConditions}
          onChangeConsultationWeiver={handleOnChangeConsultationWeiver}
        />

        <TermsOfServiceSteps
          disableDownloadFileButton={!acceptConditions}
          onClickInDownloadDocument={handleOnClickInDownloadDocument}
          documentDownloaded={documentDownloaded}
          isSubmitLoading={isSubmitLoading}
          isDownloadLoading={isDownloadLoading}
          disableContinueButton={!acceptConditions || !documentDownloaded}
          onClickContinueButton={handleOnClickContinueButton}
          downloadError={downloadErrors}
          submitError={submitErrors}
        />
      </FormSectionBody>
      <LoadingSpinner show={acceptConditionsLoading} />
      <FormSectionFooter>
        <FormActions
          cancelLabel={translations.commons.cancelLabel}
          onClickCancel={handleBackButton}
          trackingOptions={trackingOptions}
        />
      </FormSectionFooter>
      <FormFooter />
      <ModalErrors
        isShowing={isError}
        subTitle={translations.step12.downloadOrSubmitError}
      />
    </FormSection>
  );
};

export default TermsOfService;
