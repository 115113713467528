import { AddressFormTypes } from '../../../pages/step4/AddressTypes';
import { RootState } from '../../types';
import { getRisikoOrtFromOffer } from './generics';

export const getAddressFromOffer = (
  state: RootState
): Partial<AddressFormTypes> => {
  const risikoOrt = getRisikoOrtFromOffer(state);
  const streetNumber = risikoOrt?.hausnummer;
  const streetName = risikoOrt?.strasse
    ?.replace(new RegExp(`${streetNumber}$`), '')
    .trim();
  const zipCode = risikoOrt?.plz;
  const city = risikoOrt?.ort;

  return {
    streetName,
    streetNumber,
    zipCode,
    city,
  };
};

export const getZursZoneFromOffer = (state: RootState): string => {
  return getRisikoOrtFromOffer(state)?.zuersGefaehrdungsklasse;
};

export const isZursZoneSelector = (state: RootState): boolean => {
  const zurs = getRisikoOrtFromOffer(state)?.zuersGefaehrdungsklasse;
  return !(
    zurs === 'ZUERS_GK_0' ||
    zurs === 'ZUERS_GK_4' ||
    zurs === 'ZUERS_GK_9'
  );
};

export const isZursForNaturalHazards = (state: RootState): boolean => {
  const zurs = getRisikoOrtFromOffer(state)?.zuersGefaehrdungsklasse;

  if (zurs === 'ZUERS_GK_0' || zurs === 'ZUERS_GK_4') {
    return false;
  }

  return (
    zurs === 'ZUERS_GK_1' || zurs === 'ZUERS_GK_2' || zurs === 'ZUERS_GK_3'
  );
};

export const isAddressUpdated = (state: RootState): boolean => {
  const { streetName, streetNumber, zipCode, city } =
    getAddressFromOffer(state);

  const offerFieldsChanged =
    !!streetName && !!streetNumber && !!zipCode && !!city;

  const {
    streetName: formStreetName,
    streetNumber: formStreetNumber,
    zipCode: formZipCode,
  } = state.address.form;

  const formFieldsChanged =
    !!formStreetName?.length &&
    !!formStreetNumber?.length &&
    !!formZipCode?.length;

  return offerFieldsChanged || formFieldsChanged;
};
