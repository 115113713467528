import React from 'react';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import './styles.scss';

const LoadingIndicator = (): JSX.Element => (
  <div className="LoadingIndicator">
    <LoadingSpinner viewport="relative" show />
  </div>
);

export default LoadingIndicator;
