import * as constants from './constants';
import * as services from './services';

const { getUrl, ...endpoints } = constants;

const insuranceCompaniesApi = {
  endpoints,
  services,
};

export default insuranceCompaniesApi;
