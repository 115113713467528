import { trackClickEvent } from '../../../api/tracking';
import { useTrackingOptions } from '../../../api/tracking/hooks/useTrackingData';
import { TrackingClickOptions } from '../../../api/tracking/TrackingTypes';
import { TrackingElementPage10 } from '../TrackingElements';

interface ClickTracking {
  trackingOptions: TrackingClickOptions;
  onOpenAccordion: (index: number) => void;
  onClickLink: (route: number) => void;
}

const useClickTracking = (): ClickTracking => {
  const trackingOptions = useTrackingOptions();

  const onOpenAccordion = (index: number): void => {
    let key = 'ACCORDION_TARIFF';

    switch (index) {
      case 1:
        key = 'ACCORDION_APARTMENT';
        break;
      case 2:
        key = 'ACCORDION_PRE_INSURANCE';
        break;
      case 3:
        key = 'ACCORDION_PERSONAL_DATA';
        break;
      default:
        key = 'ACCORDION_TARIFF';
        break;
    }

    const element = TrackingElementPage10[key];
    trackClickEvent(element, trackingOptions);
  };

  const onClickLink = (index: number): void => {
    let key = 'LINK_CHANGE_TARIFF_DETAILS';

    switch (index) {
      case 0:
        key = 'LINK_CHANGE_APARTMENT_DETAILS';
        break;
      case 7:
        key = 'LINK_CHANGE_PRE_INSURANCE_INFO';
        break;
      case 8:
        key = 'LINK_CHANGE_PERSONAL_DATA';
        break;
      default:
        key = 'LINK_CHANGE_TARIFF_DETAILS';
        break;
    }

    const element = TrackingElementPage10[key];
    trackClickEvent(element, trackingOptions);
  };

  return { trackingOptions, onOpenAccordion, onClickLink };
};

export default useClickTracking;
