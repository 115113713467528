import { configureStore } from '@reduxjs/toolkit';

import appReducer from './appSlice';
import offerReducer from './offerSlice';
import livingPlaceReducer from '../pages/step1/livingPlaceSlice';
import homeLocationReducer from '../pages/step2/homeLocationSlice';
import HomeSquareMeterReducer from '../pages/step3/HomeSquareMetersSlice';
import insuranceDateReducer from '../pages/step5/insuranceDateSlice';
import dateOfBirthReducer from '../pages/step6/dateOfBirthSlice';
import addressReducer from '../pages/step4/addressSlice';
import insuranceCoverageReducer from '../pages/step7/insuranceCoverageSlice';
import additionalQuestionsReducer from '../pages/step8/additionalQuestionsSlice';
import bankDetailsSliceReducer from '../pages/step11/bankDetailsSlice';
import personalDataReducer from '../pages/step9/personalDataSlice';
import termsOfServiceReducer from '../pages/step12/TermsOfServiceSlice';
import { RootState } from './types';

const reducer = {
  app: appReducer,
  offer: offerReducer,
  livingPlace: livingPlaceReducer,
  homeLocation: homeLocationReducer,
  homeSquareMeters: HomeSquareMeterReducer,
  insuranceDate: insuranceDateReducer,
  dateOfBirth: dateOfBirthReducer,
  address: addressReducer,
  insuranceCoverage: insuranceCoverageReducer,
  additionalQuestions: additionalQuestionsReducer,
  personalData: personalDataReducer,
  bankDetails: bankDetailsSliceReducer,
  termsOfService: termsOfServiceReducer,
};

export const store = configureStore({
  reducer,
});

export function getStoreWithState(preloadedState?: RootState): typeof store {
  return configureStore({ reducer, preloadedState });
}
