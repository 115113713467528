import { AemAssetsKeys, AemWindow, TrackingConstants, URLs } from 'commons';
import { trackingPageNames } from './AemInput/byDefault';
import { aemIntegrationHttp } from './aemIntegrationHttp';

// function provided by AEM to load external files
const loadExternalJs = (content: string): string => {
  const aemWindow = window as unknown as AemWindow;

  if (aemWindow.loadFetchedAemJs) {
    return aemWindow.loadFetchedAemJs(content);
  }
  return content;
};

const getHostnameWithProtocol = (): string =>
  `${window.location.protocol}//${window.location.hostname}`;

// Dynamic change of the name depending on the AEM environment
const getXfUrlByAemProperties = (fragmentName: string): string => {
  const { isEditMode } = window as unknown as AemWindow;

  const pathToXf: string = getInputValue(fragmentName);
  // Avoid calculations of CARBON environments if URL is null
  if (!pathToXf) {
    return null;
  }

  const fullPathToXF = pathToXf.endsWith('.html')
    ? pathToXf
    : `${pathToXf}.html`;
  // Necessary to load fragments in Carbon Edit mode
  if (!isEditMode) {
    return getHostnameWithProtocol() + fullPathToXF;
  }

  const newPathToFile = fullPathToXF.replace(
    'content/experience-fragments',
    'xf'
  );

  return getHostnameWithProtocol() + newPathToFile;
};

const getFragment = async (fragmentName: string): Promise<string> => {
  try {
    const url = getXfUrlByAemProperties(fragmentName);
    if (!url) {
      console.warn('CARBON is not used in this environment');
    }
    const response = await aemIntegrationHttp<string>(url);
    return loadExternalJs(response);
  } catch (error) {
    throw Error(error);
  }
};

export const getInputValue = (property: string): string => {
  const aemWindow = window as unknown as AemWindow;
  const value = aemWindow.aemAssets?.[property];
  return value || null;
};

export const getCheckboxValue = (property: string): boolean => {
  const textValue = getInputValue(property);
  return textValue === 'true';
};

export const getTrackingPageName = (
  location: string,
  isAddressModal = false
): string => {
  let key = AemAssetsKeys.LIVING_PLACE_PAGE;

  switch (location) {
    case URLs.livingPlaceType:
      key = AemAssetsKeys.LIVING_PLACE_PAGE;
      break;
    case URLs.homeLocation:
      key = AemAssetsKeys.HOME_LOCATION_PAGE;
      break;
    case URLs.homeSquareMeters:
      key = AemAssetsKeys.HOME_SQUARE_METERS_PAGE;
      break;
    case URLs.address:
      key =
        AemAssetsKeys[!isAddressModal ? 'ADDRESS_PAGE' : 'ADDRESS_FAIL_PAGE'];
      break;
    case URLs.insuranceStartDate:
      key = AemAssetsKeys.INSURANCE_DATE_PAGE;
      break;
    case URLs.birthDate:
      key = AemAssetsKeys.BIRTH_DATE_PAGE;
      break;
    case URLs.insuranceCoverage:
      key = AemAssetsKeys.INSURANCE_COVERAGE_PAGE;
      break;
    case URLs.additionalQuestions:
      key = AemAssetsKeys.ADDITIONAL_QUESTIONS_PAGE;
      break;
    case URLs.personalData:
      key = AemAssetsKeys.PERSONAL_DATA_PAGE;
      break;
    case URLs.contractReview:
      key = AemAssetsKeys.CONTRACT_REVIEW_PAGE;
      break;
    case URLs.bankDetails:
      key = AemAssetsKeys.BANK_DETAILS_PAGE;
      break;
    case URLs.termsOfService:
      key = AemAssetsKeys.TERMS_OF_SERVICE_PAGE;
      break;
    case URLs.feedback:
      key = AemAssetsKeys.FEEDBACK_PAGE;
      break;
  }
  const aemValue = getInputValue(key);
  return aemValue || trackingPageNames[key];
};

const getTrackingConstants = (): TrackingConstants => {
  const [shortName, productGroup, brokerModus] = [
    getInputValue(AemAssetsKeys.SHORT_NAME),
    getInputValue(AemAssetsKeys.PRODUCT_GROUP),
    getCheckboxValue(AemAssetsKeys.BROKER_MODUS),
  ];
  return {
    shortName: shortName || 'hr',
    productGroup: productGroup || 'HausratGebaeude',
    brokerModus,
  };
};

const aemIntegration = {
  loadExternalJs,
  getFragment,
  getXfUrlByAemProperties,
  getInputValue,
  getTrackingPageName,
  getTrackingConstants,
};

export default aemIntegration;
