import React from 'react';
import CountryCode from '../CountryCodes/CountryCodes';
import { TextInput } from '../TextInput/TextInput';
import { TelephoneInputProps } from './TelephoneInput.d';
import './TelephoneInput.scss';
import {
  formatTelephoneNumber,
  extractCountryCode,
  blockedCharactersOnInput,
} from '../../utils';

const TelephoneInput: React.FC<TelephoneInputProps> = ({
  code,
  value,
  errorMessageTelephone,
  handleChangeTelephone,
  handleChangePrefix,
  handleTelephoneValidation,
}): JSX.Element => {
  const prefixNumber = code || extractCountryCode(value);
  return (
    <div className="contact-information-telephone__content contact-information-telephone__content__name">
      <div className="contact-information-telephone__prefixNumber">
        <label htmlFor="prefix" />
        <CountryCode code={prefixNumber} onChange={handleChangePrefix} />
      </div>
      <div className="contact-information-telephone__phoneNumber">
        <label htmlFor="telephone" />
        <TextInput
          type="number"
          id="telephone"
          name="telephone"
          value={formatTelephoneNumber(value)}
          placeholder="30 123456"
          onChange={handleChangeTelephone}
          onBlur={handleTelephoneValidation}
          onKeyDown={blockedCharactersOnInput}
        />
        <p className="contact-information-telephone__inputs--error">
          {errorMessageTelephone}
        </p>
      </div>
    </div>
  );
};

export default TelephoneInput;
