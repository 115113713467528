import {
  validateHasExistingDoorLocksPayload,
  validatePreviousGlassDamageFields,
  validatePreviousHouseholdDamageFields,
  validatePreviousInsuranceFields,
} from 'commons';
import {
  AdditionalQuestionsForm,
  GlassPreviousDamage,
  hasDoorLocks,
  HouseholdPreviousDamage,
  PreviousInsurance,
} from 'commons/apis/hausrat/types';
import { InsuranceCompany } from 'commons/apis/insuranceCompanies/types';
import { useSelector } from 'react-redux';
import {
  InvalidField,
  ValidatorFunction,
  ValidField,
} from '../../../../store/types';
import { productModulesSelector } from '../../../step7/insuranceCoverageSlice';
import { insuranceCompaniesSuggestionsSelector } from '../../additionalQuestionsSlice';
import { generateInvalidField, generateValidField } from './validators';

type AdditionalQuestionsValidator = Record<
  keyof AdditionalQuestionsForm,
  ValidatorFunction<AdditionalQuestionsForm[keyof AdditionalQuestionsForm]>
>;

export const additionalQuestionsFormValidations = (
  previousInsuranceSuggestions: InsuranceCompany[],
  glassSuggestions: InsuranceCompany[]
): AdditionalQuestionsValidator => ({
  previousInsurance: (value: PreviousInsurance): ValidField | InvalidField => {
    if (value.selected === false) {
      return generateValidField();
    }
    const isValid = !value.companyName
      ? false
      : validatePreviousInsuranceFields({
          ...value,
          suggestions: previousInsuranceSuggestions,
        });
    return isValid ? generateValidField() : generateInvalidField();
  },
  previousHouseholdDamage: (
    value: HouseholdPreviousDamage
  ): ValidField | InvalidField => {
    if (value.selected === false) {
      return generateValidField();
    }
    const isValid = validatePreviousHouseholdDamageFields(value);
    return isValid ? generateValidField() : generateInvalidField();
  },
  previousGlassInsurance: (
    value: PreviousInsurance
  ): ValidField | InvalidField => {
    if (value.selected === false) {
      return generateValidField();
    }

    const isValid = !value.companyName
      ? false
      : validatePreviousInsuranceFields({
          ...value,
          suggestions: glassSuggestions,
        });

    return isValid ? generateValidField() : generateInvalidField();
  },
  previousGlassDamage: (
    value: GlassPreviousDamage
  ): ValidField | InvalidField => {
    if (value.selected === false) {
      return generateValidField();
    }
    const isValid = validatePreviousGlassDamageFields(value);
    return isValid ? generateValidField() : generateInvalidField();
  },
  hasElectronicOrMechatronicLocks: (
    value: hasDoorLocks
  ): ValidField | InvalidField => {
    const isValid = validateHasExistingDoorLocksPayload(value);
    return isValid ? generateValidField() : generateInvalidField();
  },
});

export const useAdditionalQuestionsValidator =
  (): AdditionalQuestionsValidator => {
    const suggestions = useSelector(insuranceCompaniesSuggestionsSelector);
    const productModules = useSelector(productModulesSelector);
    const hasGlassFields = productModules.includes('glass');
    const { previousGlassDamage, previousGlassInsurance, ...fields } =
      additionalQuestionsFormValidations(
        suggestions.previousInsuranceCompanies,
        suggestions.previousGlassInsuranceCompanies
      );

    const validator = hasGlassFields
      ? additionalQuestionsFormValidations(
          suggestions.previousInsuranceCompanies,
          suggestions.previousGlassInsuranceCompanies
        )
      : fields;

    return validator as AdditionalQuestionsValidator;
  };
