export enum TrackingElementPage10 {
  ACCORDION_TARIFF = 'Acc_Tarif',
  ACCORDION_PERSONAL_DATA = 'Acc_Personendaten',
  ACCORDION_APARTMENT = 'Acc_Wohnung',
  ACCORDION_PRE_INSURANCE = 'Acc_Vorversicherung',
  LINK_CHANGE_TARIFF_DETAILS = 'Link_AngabenAendernTarif',
  LINK_CHANGE_PERSONAL_DATA = 'Link_AngabenAendernPersondaten',
  LINK_CHANGE_APARTMENT_DETAILS = 'Link_AngabenAendernWohnung',
  LINK_CHANGE_PRE_INSURANCE_INFO = 'Link_AngabenAendernVorversicherung',
}
