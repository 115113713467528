import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PersonalDataForm, PersonalDataState } from './PersonalDataTypes';
import { RootState } from '../../store/types';
import { personalData } from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import { saveOffer, savePerson } from '../../store/offerSlice';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';

const initialState: PersonalDataState = {
  form: {
    gender: '',
    firstName: '',
    lastname: '',
    addressConfirmation: '',
    email: '',
    prefixNumber: undefined,
    telephoneNumber: null,
  },
  isValidated: false,
  isLoading: false,
};

export const personalDataFetchValidation = createAsyncThunk(
  'personalData/validation',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const payload = {
        ...state.personalData.form,
      };
      const response = await personalData(
        state.app.businessId,
        state.app.personId,
        payload
      );

      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      dispatch(setStepValidated(9));
    } catch (error) {
      console.error('rejected', error);
      throw Error(error);
    }
  }
);

export const personalDataSlice = createSlice({
  name: 'personalData',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.form.email = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.form.firstName = action.payload;
    },
    setSurname: (state, action: PayloadAction<string>) => {
      state.form.lastname = action.payload;
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.form.gender = action.payload;
    },
    setAddressConfirmation: (state, action: PayloadAction<string>) => {
      state.form.addressConfirmation = action.payload;
    },
    setPrefixNumber: (state, action: PayloadAction<string>) => {
      state.form.prefixNumber = action.payload;
    },
    setValidationError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setTelephoneNumber: (state, action: PayloadAction<string>) => {
      state.form.telephoneNumber = action.payload;
      if (!state.form.prefixNumber) {
        state.form.prefixNumber = '+49';
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(personalDataFetchValidation.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(personalDataFetchValidation.fulfilled, state => {
      state.isLoading = false;
      state.error = '';
      state.isValidated = true;
    });
    builder.addCase(personalDataFetchValidation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.error?.message;
      console.error(action?.error?.message);
    });
  },
});

export const isLoadingPersonalDataSelector = (state: RootState): boolean =>
  state.personalData.isLoading;

export const personalDataFormSelector = (state: RootState): PersonalDataForm =>
  state.personalData.form;

export const getPersonalDataValidationError = (
  state: RootState
): string | undefined => state.personalData.error;

export const {
  setGender,
  setFirstName,
  setSurname,
  setAddressConfirmation,
  setEmail,
  setPrefixNumber,
  setTelephoneNumber,
  setValidationError,
} = personalDataSlice.actions;

export default personalDataSlice.reducer;
