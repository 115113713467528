import React from 'react';
import Radio from '../ERadio/ERadio';
import type { RadioDrawerProps } from './RadioDrawer.d';
import './RadioDrawer.scss';

export const RadioDrawer = ({
  title,
  subtitle,
  value,
  onChange,
  children,
  tooltip,
  'data-testid': dataTestId = 'radio-drawer',
}: RadioDrawerProps): JSX.Element => {
  return (
    <div data-testid={dataTestId}>
      <h3 className="additional-question__header">
        {title}
        <span>{tooltip && tooltip}</span>
      </h3>
      <p>{subtitle}</p>
      <div className="drawer">
        <Radio
          className="drawer__radio"
          value={true}
          onChange={onChange}
          label="Ja"
          checked={value === true}
        />
        <Radio
          className="drawer__radio"
          value={false}
          onChange={onChange}
          label="Nein"
          checked={value === false}
        />
      </div>
      {value && children}
    </div>
  );
};
