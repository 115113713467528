import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Angebot } from 'commons/apis/offerEngine/offers/types';
import { Person } from 'commons/apis/spcs/persons/types';
import translations from '../../constants/translations';
import {
  bankDetails,
  getBankDataFromIban,
} from '../../services/offerService/offerService';
import { setStepValidated } from '../../store/appSlice';
import type { RootState, StepData } from '../../store/types';
import { saveOffer, savePerson } from '../../store/offerSlice';
import { setPaymentId } from '../../store/appSlice';
import type {
  BankDetailsFormTypes,
  BankDetailsState,
} from './BankDetailsTypes';
import { removeSpaces } from 'commons';

const initialState: BankDetailsState = {
  isValidated: false,
  isLoading: false,
  isLoadingBankData: false,
  bic: '',
  financialInstitutionName: '',
  city: '',
  paymentId: '',
  form: {
    iban: '',
  },
};

export const fetchBankDataFromIban = createAsyncThunk(
  'bankData/fetchBankDataFromIban',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const { paymentId } = state.app;
      const response = await getBankDataFromIban(
        state.app.businessId,
        state.app.personId,
        getNoSpacesIbanSelector(state),
        paymentId
      );
      if (response?.id) {
        dispatch(setPaymentId(response.id));
      }
      return response;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const bankDetailsFetchValidation = createAsyncThunk(
  'bankDetails/validation',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const response = await bankDetails(
        state.app.businessId,
        state.bankDetails.paymentId
      );
      if (response?.angebot) {
        dispatch(saveOffer(response.angebot as Angebot));
      }

      if (response?.person) {
        dispatch(savePerson(response.person as Person));
      }

      dispatch(setStepValidated(11));
    } catch (error) {
      throw Error(error);
    }
  }
);

export type SetBankDetailsPayload = {
  bic?: string;
  city?: string;
  financialInstitutionName?: string;
  id?: string;
};

export const bankDetailsSlice = createSlice({
  name: 'bankDetails',
  initialState,
  reducers: {
    setIban: (state, action: PayloadAction<BankDetailsFormTypes['iban']>) => {
      const isNewIban =
        removeSpaces(state.form.iban) !== removeSpaces(action.payload);
      if (isNewIban) {
        state.isValidated = false;
      }

      state.form.iban = action.payload;
    },
    setIbanError: (
      state,
      action: PayloadAction<BankDetailsFormTypes['iban']>
    ) => {
      state.error = action.payload;
    },
    setBankDetails: (state, action: PayloadAction<SetBankDetailsPayload>) => {
      const { bic, city, financialInstitutionName, id } =
        action.payload as SetBankDetailsPayload;
      state.bic = bic;
      state.city = city;
      state.financialInstitutionName = financialInstitutionName;
      state.paymentId = id;
    },
    setIbanIsValidated: (
      state,
      action: PayloadAction<StepData['isValidated']>
    ) => {
      state.isValidated = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchBankDataFromIban.pending, state => {
      state.isLoadingBankData = true;
      state.isValidated = false;
      state.bic = undefined;
      state.city = undefined;
      state.financialInstitutionName = undefined;
      state.error = undefined;
      state.paymentId = undefined;
    });
    builder.addCase(fetchBankDataFromIban.fulfilled, (state, action) => {
      state.isLoadingBankData = false;
      state.isValidated = true;
      const { bic, city, financialInstitutionName, id } =
        action.payload as SetBankDetailsPayload;
      state.paymentId = id;
      state.bic = bic;
      state.city = city;
      state.financialInstitutionName = financialInstitutionName;
    });
    builder.addCase(fetchBankDataFromIban.rejected, (state, action) => {
      state.isLoadingBankData = false;
      state.isValidated = false;
      if (action.error) {
        if (/409/.test(action.error.message)) {
          state.error = translations.step11.ibanErrorSepa;
        } else {
          state.error = translations.step11.ibanErrorInvalid;
        }
      }
      console.error(action.error.message);
    });
    builder.addCase(bankDetailsFetchValidation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(bankDetailsFetchValidation.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(bankDetailsFetchValidation.rejected, (state, action) => {
      state.isLoading = false;

      console.error(action.error.message);
    });
  },
});

export const { setIban, setIbanError, setBankDetails, setIbanIsValidated } =
  bankDetailsSlice.actions;

export const isLoadingBankDataSelector = (state: RootState): boolean =>
  state.bankDetails.isLoadingBankData;

export const isLoadingSelector = (state: RootState): boolean =>
  state.bankDetails.isLoading;

export const isValidatedSelector = (state: RootState): boolean =>
  state.bankDetails.isValidated;

export const getBankDataSelector = (state: RootState): BankDetailsState =>
  state.bankDetails;

export const getIbanError = (state: RootState): string | undefined =>
  state.bankDetails.error;

export const countryCodeSelector = (state: RootState): string => {
  const ibanLocal = state.bankDetails.form.iban;
  return ibanLocal && +ibanLocal?.length > 2 && ibanLocal.slice(0, 2);
};

export const getNoSpacesIbanSelector = (state: RootState): string => {
  return state.bankDetails.form.iban?.replace(/\s/g, '');
};

export default bankDetailsSlice.reducer;
