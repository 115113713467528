import { Angebot } from '../apis/offerEngine';

export const smartId = '0aff5849-e6f1-43f9-985a-18e4d02df456';
export const bestId = '7a233c2e-1196-4df4-aa56-6edf4e4496a8';

export const mockedOffer: Angebot = {
  leistungsvereinbarungen: [
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '008000',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: true,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: ['EUR_300_FLEXI', 'EUR_300_FEST'],
          bezeichnungen: ['300 EUR (Flexi-SB)', '300 EUR'],
        },
      ],
      bezeichnung: 'Hausrat',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '002201',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 'EUR_500_ELEMENTAR',
          obligatorisch: true,
          werte: [
            'EUR_250_ELEMENTAR',
            'EUR_500_ELEMENTAR',
            'EUR_1000_ELEMENTAR',
            'EUR_5000_ELEMENTAR_ZUERS_4',
            'INTEGRALFRANCHISE_80',
            'INTEGRALFRANCHISE_50',
            'INTEGRALFRANCHISE_30',
          ],
          bezeichnungen: [
            '250 EUR (Elementar)',
            '500 EUR (Elementar)',
            '1000 EUR (Elementar)',
            '5000 EUR (Elementar Zürs 4)',
            'Integralfranchise 80%',
            'Integralfranchise 50%',
            'Integralfranchise 30%',
          ],
        },
      ],
      bezeichnung: 'Weitere Naturgefahren',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: 'EUR_500_ELEMENTAR',
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001262',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: true,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Überspannungs- und Sengschäden',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001243',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Haus- und Wohnungsschutzbrief',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001266',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: 1500,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
        {
          attribut: 'versicherungssumme',
          typ: 'BEREICH',
          vorbelegung: 1500,
          obligatorisch: true,
          min: 500,
          max: 10000,
          schrittweite: 500,
        },
      ],
      bezeichnung: 'Fahrraddiebstahl',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001246',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: 1500,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
        {
          attribut: 'versicherungssumme',
          typ: 'BEREICH',
          vorbelegung: 1500,
          obligatorisch: true,
          min: 500,
          max: 10000,
          schrittweite: 500,
        },
      ],
      bezeichnung: 'Fahrradkasko',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001247',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: true,
          werte: [],
          bezeichnungen: [],
        },
        {
          attribut: 'versicherungssumme',
          typ: 'BEREICH',
          vorbelegung: null,
          obligatorisch: true,
          min: 40,
          max: 100,
          schrittweite: 10,
        },
      ],
      bezeichnung: 'Wertsachen plus',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001291',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Diebstahl 10.000 EUR',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '008000',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: true,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: ['EUR_300_FLEXI', 'EUR_300_FEST'],
          bezeichnungen: ['300 EUR (Flexi-SB)', '300 EUR'],
        },
      ],
      bezeichnung: 'Hausrat',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '002201',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 'EUR_500_ELEMENTAR',
          obligatorisch: true,
          werte: [
            'EUR_250_ELEMENTAR',
            'EUR_500_ELEMENTAR',
            'EUR_1000_ELEMENTAR',
            'EUR_5000_ELEMENTAR_ZUERS_4',
            'INTEGRALFRANCHISE_80',
            'INTEGRALFRANCHISE_50',
            'INTEGRALFRANCHISE_30',
          ],
          bezeichnungen: [
            '250 EUR (Elementar)',
            '500 EUR (Elementar)',
            '1000 EUR (Elementar)',
            '5000 EUR (Elementar Zürs 4)',
            'Integralfranchise 80%',
            'Integralfranchise 50%',
            'Integralfranchise 30%',
          ],
        },
      ],
      bezeichnung: 'Weitere Naturgefahren',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: 'EUR_500_ELEMENTAR',
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001262',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: true,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Überspannungs- und Sengschäden',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001243',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Haus- und Wohnungsschutzbrief',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001291',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: true,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Diebstahl 10.000 EUR',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001266',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: 1500,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
        {
          attribut: 'versicherungssumme',
          typ: 'BEREICH',
          vorbelegung: 1500,
          obligatorisch: true,
          min: 500,
          max: 10000,
          schrittweite: 500,
        },
      ],
      bezeichnung: 'Fahrraddiebstahl',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001246',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: 1500,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
        {
          attribut: 'versicherungssumme',
          typ: 'BEREICH',
          vorbelegung: 1500,
          obligatorisch: true,
          min: 500,
          max: 10000,
          schrittweite: 500,
        },
      ],
      bezeichnung: 'Fahrradkasko',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001292',
      leistungsvereinbarungsart: null,
      vereinbart: true,
      auswaehlbar: true,
      obligatorisch: true,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: false,
          werte: [],
          bezeichnungen: [],
        },
      ],
      bezeichnung: 'Grobe Fahrlässigkeit 100%',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '001247',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: null,
          obligatorisch: true,
          werte: [],
          bezeichnungen: [],
        },
        {
          attribut: 'versicherungssumme',
          typ: 'BEREICH',
          vorbelegung: null,
          obligatorisch: true,
          min: 40,
          max: 100,
          schrittweite: 10,
        },
      ],
      bezeichnung: 'Wertsachen plus',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: null,
    },
    {
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
        vertraege: 'HRH',
      },
      link: null,
      leistungsvereinbarungsId: '002264',
      leistungsvereinbarungsart: null,
      vereinbart: false,
      auswaehlbar: true,
      obligatorisch: false,
      versicherungssumme: null,
      wertebereiche: [
        {
          attribut: 'selbstbehalt',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 'EUR_300_UNBENANNTE_GEFAHREN',
          obligatorisch: true,
          werte: ['EUR_300_UNBENANNTE_GEFAHREN'],
          bezeichnungen: ['300 EUR (Unbenannte Gefahren)'],
        },
      ],
      bezeichnung: 'Unbenannte Gefahr',
      bezeichnungBestandstarif: null,
      beitragBestandstarif: null,
      zahlweiseBestandstarif: null,
      werbeaktionscode: null,
      aktionsnummer: null,
      mitarbeiternummer: null,
      alterUrsprungsbeginn: null,
      ursprungsbeginnBestandstarif: null,
      selbstbehalt: 'EUR_300_UNBENANNTE_GEFAHREN',
    },
  ],
  versicherungen: [
    {
      link: null,
      versicherungsId: smartId,
      tarifvariante: 'Smart',
      verkaufsname: 'ERGO Hausratversicherung 2021',
      variantennummer: null,
      berechenbar: false,
      ausgewaehlt: false,
      versicherungsstatus: 'ANGELEGT',
      versicherungsbeginn: '2022-09-01',
      zahlweise: 'MONATLICH',
      verkaufszeitpunkt: '2022-08-31',
      statusaenderungsdatum: null,
      workflownummer: null,
      antragseingang: null,
      abbuchungstag: 1,
      werbeaktionsdaten: null,
      historien: null,
      druckauftragsinformationen: null,
      wertebereiche: [
        {
          attribut: 'zahlweise',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 'MONATLICH',
          obligatorisch: true,
          werte: [
            'JAEHRLICH',
            'HALBJAEHRLICH',
            'VIERTELJAEHRLICH',
            'MONATLICH',
          ],
          bezeichnungen: [
            'jährlich',
            'halbjährlich',
            'vierteljährlich',
            'monatlich',
          ],
        },
        {
          attribut: 'abbuchungstag',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 1,
          obligatorisch: true,
          werte: [1, 16],
          bezeichnungen: [],
        },
        {
          attribut: 'laufzeit',
          typ: 'AUFZAEHLUNG',
          vorbelegung: '5',
          obligatorisch: true,
          werte: ['1', '5'],
          bezeichnungen: ['1 Jahr', '5 Jahre'],
        },
        {
          attribut: 'versicherungsbeginn',
          typ: 'BEREICH',
          vorbelegung: '2022-09-01',
          obligatorisch: true,
          min: '2022-09-01',
          max: '2023-08-30',
          schrittweite: null,
        },
      ],
      selbstzahler: false,
      laufzeit: '5',
      versicherungsende: '2027-09-01',
      hinweise: null,
      versicherungssteuer: 16.15,
      struktur: {
        versicherungen: '0aff5849-e6f1-43f9-985a-18e4d02df456',
      },
      paket: null,
      versicherungssumme: 78000,
      umzug: false,
      jungerVn: null,
      geburtsdatumVN: '1987-11-20',
    },
    {
      link: null,
      versicherungsId: bestId,
      tarifvariante: 'Best',
      verkaufsname: 'ERGO Hausratversicherung 2021',
      variantennummer: null,
      berechenbar: false,
      ausgewaehlt: true,
      versicherungsstatus: 'ANGELEGT',
      versicherungsbeginn: '2022-09-01',
      zahlweise: 'HALBJAEHRLICH',
      verkaufszeitpunkt: '2022-08-31',
      statusaenderungsdatum: null,
      workflownummer: null,
      antragseingang: null,
      abbuchungstag: 1,
      werbeaktionsdaten: null,
      historien: null,
      druckauftragsinformationen: null,
      wertebereiche: [
        {
          attribut: 'zahlweise',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 'MONATLICH',
          obligatorisch: true,
          werte: [
            'JAEHRLICH',
            'HALBJAEHRLICH',
            'VIERTELJAEHRLICH',
            'MONATLICH',
          ],
          bezeichnungen: [
            'jährlich',
            'halbjährlich',
            'vierteljährlich',
            'monatlich',
          ],
        },
        {
          attribut: 'abbuchungstag',
          typ: 'AUFZAEHLUNG',
          vorbelegung: 1,
          obligatorisch: true,
          werte: [1, 16],
          bezeichnungen: [],
        },
        {
          attribut: 'laufzeit',
          typ: 'AUFZAEHLUNG',
          vorbelegung: '5',
          obligatorisch: true,
          werte: ['1', '5'],
          bezeichnungen: ['1 Jahr', '5 Jahre'],
        },
        {
          attribut: 'versicherungsbeginn',
          typ: 'BEREICH',
          vorbelegung: '2022-09-01',
          obligatorisch: true,
          min: '2022-09-01',
          max: '2023-08-30',
          schrittweite: null,
        },
      ],
      selbstzahler: false,
      laufzeit: '1',
      versicherungsende: '2027-09-01',
      hinweise: null,
      versicherungssteuer: 16.15,
      struktur: {
        versicherungen: '7a233c2e-1196-4df4-aa56-6edf4e4496a8',
      },
      paket: null,
      versicherungssumme: 78000,
      umzug: false,
      jungerVn: null,
      geburtsdatumVN: '1987-11-20',
    },
  ],
  vertraege: [
    {
      struktur: {
        versicherungen: smartId,
      },
      vertragsId: 'HRH',
      vertragsArt: 'HRH',
      versicherungssumme: 58500,
      individuelleVersicherungssumme: false,
      vereinbart: true,
      selbstbehalt: 'KEINE_SELBSTBETEILIGUNG',
      individuelleSelbstbehaltSumme: null,
      wertebereiche: null,
    },
    {
      struktur: {
        versicherungen: smartId,
      },
      vertragsId: 'HRG',
      vertragsArt: 'HRG',
      versicherungssumme: null,
      individuelleVersicherungssumme: false,
      vereinbart: true,
      selbstbehalt: null,
      individuelleSelbstbehaltSumme: null,
      wertebereiche: null,
    },
    {
      struktur: {
        versicherungen: bestId,
      },
      vertragsId: 'HRH',
      vertragsArt: 'HRH',
      versicherungssumme: 58500,
      individuelleVersicherungssumme: false,
      vereinbart: true,
      selbstbehalt: 'EUR_300_FEST',
      individuelleSelbstbehaltSumme: null,
      wertebereiche: null,
    },
    {
      struktur: {
        versicherungen: bestId,
      },
      vertragsId: 'HRG',
      vertragsArt: 'HRG',
      versicherungssumme: null,
      individuelleVersicherungssumme: false,
      vereinbart: true,
      selbstbehalt: null,
      individuelleSelbstbehaltSumme: null,
      wertebereiche: null,
    },
  ],
} as unknown as Angebot;
